import { PlugZap } from 'lucide-react';
import { BaseToolTip, Button } from '@common/components';
import { truncateString } from '@utils';
import { COLORS } from '@constants';

export function AddDataSourceCard({ dataSource, setShowConnectDataSourceDialog, setConnectDataSourceLoading }) {
  return (
    <div
      key={dataSource?.id}
      className='flex h-[15.375rem] flex-col justify-between rounded-xl border border-secondaryStrokeButton bg-white p-5'
    >
      <div>
        <div className='flex flex-col gap-1'>
          <div className='h-[52px] w-[52px] rounded-full bg-tableHeaderBackground p-3'>
            <img src={dataSource?.logo?.image} alt='data-source-image' className='h-full w-full' />
          </div>
          <h6 className='font-Manrope text-lg font-semibold leading-[1.625rem] text-mainText'>
            {dataSource?.sourceName}
          </h6>
        </div>
        <div className='mt-2 h-[3.75rem]'>
          <p id={`dataSource-${dataSource?.id}`} className='text-sm leading-5 text-bodyText'>
            {truncateString(dataSource?.description, 30)}
          </p>
        </div>
        {dataSource?.description?.length > 30 && (
          <BaseToolTip
            className='max-w-[18.75rem]'
            id={`dataSource-${dataSource?.id}`}
            content={dataSource?.description}
          />
        )}
      </div>
      <Button
        className='w-full font-semibold text-highlighterText2'
        variant='tertiary'
        onClick={() => {
          setShowConnectDataSourceDialog({
            show: true,
            ...dataSource,
          });

          setConnectDataSourceLoading(false);
        }}
      >
        <PlugZap className='h-5 w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
        Connect
      </Button>
    </div>
  );
}
