const SlideModeIcon = ({ className }) => {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M59.984 34.0041C70.6412 34.0041 81.2976 34.0041 91.9532 34.0041C95.3319 34.0041 97.8284 35.7278 98.675 38.6667C98.8768 39.3994 98.9769 40.1565 98.9724 40.9166C98.9894 57.9838 98.9894 75.0501 98.9724 92.1156C98.9724 96.2036 96.1759 99.0001 92.1083 99.0001C70.6988 99.0001 49.2911 99.0001 27.8851 99.0001C23.759 99.0001 20.998 96.2036 20.998 92.0317C20.998 75.0289 20.998 58.0262 20.998 41.0234C20.998 36.7396 23.7183 34.0092 28.0173 34.0067C38.6728 33.9948 49.3284 33.9939 59.984 34.0041ZM46.9776 66.4894V53.5848C42.2693 53.206 37.0907 56.6152 35.062 61.3616C33.9871 63.8625 33.7244 66.6377 34.3107 69.2959C34.8971 71.954 36.303 74.3611 38.3302 76.1777C40.3574 77.9943 42.9037 79.1288 45.6101 79.4213C48.3164 79.7137 51.0462 79.1493 53.4147 77.8077C57.8917 75.2984 60.2789 70.0995 59.8543 66.4894H46.9776ZM66.4058 59.9507C66.7083 52.6594 59.928 46.6774 53.5672 47.1274V59.9507H66.4058ZM85.961 79.5746H66.5354V85.9125H85.9483L85.961 79.5746ZM66.5507 66.5403V72.9366H85.9305V66.5403H66.5507ZM85.9204 59.9761V53.5797H73.0411V59.9761H85.9204Z'
        fill='#E2E8F0'
      />
      <path
        d='M27.5748 27.4296C27.5113 24.0407 30.2493 21.2188 33.7348 21.0129C33.989 20.9976 34.2432 21.0002 34.4974 21.0002H85.4931C88.8718 21.0002 91.3887 22.795 92.22 25.7949C92.3598 26.3034 92.4056 26.8347 92.5073 27.4296H27.5748Z'
        fill='#AAB0B8'
      />
    </svg>
  );
};

export { SlideModeIcon };
