import { useEffect, useState } from 'react';
import { apiEndpoints, useGetQuery } from '@services';
import { TIME_UNITS } from '@constants';

export function useMembershipCards() {
  const [memberShipPlanValue, setMemberShipPlanValue] = useState(TIME_UNITS.MONTH);

  const handleSwitchToggle = e => setMemberShipPlanValue(e.target.value);

  const {
    data: membershipPlan,
    refetch: membershipRefetch,
    isFetching: membershipFetching,
  } = useGetQuery(
    'membership-plan-listing',
    apiEndpoints.MEMBERSHIP_PUBLIC_LIST,
    { interval: memberShipPlanValue },
    {
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  useEffect(() => {
    membershipRefetch();
  }, [memberShipPlanValue]);

  return {
    memberShipPlanValue,
    handleSwitchToggle,
    membershipPlan,
    membershipFetching,
  };
}
