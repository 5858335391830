import { useRef } from 'react';
import CountUp from 'react-countup';
import { EmptyWidget } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function CounterWidget({ value, isComparisonFilterApply, widget, className, onSlideMode, stats }) {
  const counterWidgetRef = useRef(null);

  const counterWidgetFontSize =
    Math.min(counterWidgetRef?.current?.clientHeight, counterWidgetRef?.current?.clientWidth) / 4;

  return (
    <>
      {!!value || value === 0 ? (
        <>
          <div ref={counterWidgetRef} className={`flex h-full items-center justify-center py-2 ${className}`}>
            <div
              className={`flex h-full items-center justify-center ${isComparisonFilterApply ? 'w-full pr-2 text-[0.5rem]' : 'text-[4rem]'} font-bold`}
              style={{ fontSize: onSlideMode ? '8px' : counterWidgetFontSize || '' }}
            >
              <CountUp
                duration={onSlideMode ? '0' : '2'}
                end={isComparisonFilterApply && (!!value?.data2 || value?.data2 === 0) ? value?.data : value}
                style={
                  widget?.themeColor === DEFAULT_VALUE
                    ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                    : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground }
                }
              />
            </div>
            {isComparisonFilterApply && (!!value?.data2 || value?.data2 === 0) && (
              <div
                className={`flex h-full items-center justify-center ${isComparisonFilterApply ? 'w-full pl-2 text-[0.5rem]' : 'text-[4rem]'} relative font-bold`}
                style={{ fontSize: onSlideMode ? '8px' : counterWidgetFontSize || '' }}
              >
                <span className='absolute left-0 h-[40%] w-[1px] bg-highlighterText2'></span>
                <CountUp
                  duration={onSlideMode ? '0' : '2'}
                  end={value?.data2}
                  style={
                    widget?.themeColor === DEFAULT_VALUE
                      ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
                      : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground }
                  }
                />
              </div>
            )}
          </div>
          {isComparisonFilterApply && (!!value?.data2 || value?.data2 === 0) && stats?.trend !== 0 && (
            <span className='absolute bottom-6 font-Manrope text-xs font-medium leading-4'>
              <span className={value.trend === 1 ? 'text-highlighterText2' : 'text-errorColor'}>
                {stats?.percentage}%
              </span>{' '}
              {stats?.trend === 1 && 'increase'}
              {stats?.trend === -1 && 'decrease'} compared to the previous period.
            </span>
          )}
        </>
      ) : (
        <EmptyWidget widget={widget} onSlideMode={onSlideMode} />
      )}
    </>
  );
}
