import { Modal, Popover, Table } from 'antd';
import { SquarePenIcon, Trash2, X } from 'lucide-react';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import EditScheduleReports from './EditScheduleReports';
import {
  BaseToolTip,
  CustomPagination,
  DeleteDialog,
  NoDataFoundScreen,
  TableSkeletonLoader,
} from '@common/components';
import { useScheduledReport } from '@pages/reports';
import { NumbersIntoDays, handleTimeChange, truncateString } from '@utils';
import { COLORS, DATE_FORMAT, ONE_TIME } from '@constants';

export function ScheduledReports() {
  const {
    reportsListing,
    showDeleteDialog,
    setShowDeleteDialog,
    dialogReport,
    setDialogReport,
    deleteReportLoading,
    page,
    setPage,
    showEditDialog,
    setShowEditDialog,
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    emailFieldErrorMessage,
    invalidEmails,
    selectDay,
    handleSelectedDay,
    handleDeleteReport,
    setEditReport,
    reportUpdateLoading,
    reportDetailFetching,
    totalPagesPerItem,
    setTotalPagesPerItem,
    setPaginationPage,
    paginationPage,
    reportsLoading,
    setReportDetails,
    reportDetails,
  } = useScheduledReport();

  const columns = [
    {
      title: 'Subject',
      key: 'subject',
      dataIndex: 'subject',
      render: (text, record) => (
        <>
          <p id={`scheduled-${record?.id}`}>{truncateString(record?.subject, 15, 'text-darkSecondary')}</p>
          {record?.subject?.length > 15 && (
            <BaseToolTip className='w-full' id={`scheduled-${record?.id}`} content={record?.subject} />
          )}
        </>
      ),
    },
    {
      title: 'Date',
      key: 'scheduledDate',
      dataIndex: 'scheduledDate',
      render: (text, record) => moment(record.scheduledDate).format(DATE_FORMAT.DATE_PICKER),
    },
    { title: 'Time', key: 'scheduledTime', dataIndex: 'scheduledTime', width: 'auto' },
    {
      title: 'Recipients',
      key: 'recipients',
      dataIndex: 'recipients',
      render: (text, record) => (
        <>
          {record?.recipients?.split(',')[0]}
          {record?.recipients.split(',')?.length > 1 && (
            <Popover
              placement='topRight'
              content={
                <div className='max-h-[9.75rem] w-min overflow-y-auto px-[0.9375rem] py-3'>
                  <ul className='flex flex-col gap-3'>
                    {record?.recipients
                      ?.split(',')
                      .slice(1)
                      ?.map(recipient => (
                        <li key={recipient} className='text-sm font-normal leading-5'>
                          {recipient}
                        </li>
                      ))}
                  </ul>
                </div>
              }
              trigger='click'
            >
              <span className='bg- ml-1 h-6 w-6 rounded-full bg-shade3 p-1 text-[0.5625rem] font-medium text-highlighterText2'>
                +{record?.recipients?.split(',').length - 1}
              </span>
            </Popover>
          )}
        </>
      ),
    },
    {
      title: 'Repeat',
      key: 'scheduledType',
      dataIndex: 'scheduledType',
      render: (text, record) =>
        record?.scheduledType === ONE_TIME
          ? 'One Time'
          : `Every ${record?.scheduledFrequency} ${record?.scheduledType?.toLowerCase()?.replace('ly', '(s)')} on ${NumbersIntoDays(record.scheduledDay)}`,
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'actions',
      fixed: 'right',
      render: (_, record) =>
        reportsLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='flex cursor-pointer flex-row items-center gap-3'>
            <span
              onClick={e => {
                e.stopPropagation();
                setDialogReport(record);
                setEditReport(record);
                (handleTimeChange(record?.scheduledDate, record?.scheduledTime)?.isFuture ||
                  record?.scheduledType !== ONE_TIME) &&
                  setShowEditDialog(true);
              }}
            >
              <SquarePenIcon
                className='h-5 w-5'
                color={
                  handleTimeChange(record?.scheduledDate, record?.scheduledTime)?.isFuture ||
                  record?.scheduledType !== ONE_TIME
                    ? COLORS.SECONDARY_GREEN_COLOR
                    : COLORS.TINT_GRAY
                }
              />
            </span>
            <span
              onClick={e => {
                e.stopPropagation();
                setDialogReport(record);
                setShowDeleteDialog(true);
              }}
            >
              <Trash2 color='#EF4444' className='h-5 w-5' />
            </span>
          </div>
        ),
    },
  ];

  return (
    <div>
      {reportsLoading ? (
        <div className='h-[calc(100vh-256px)] min-h-[372px] overflow-auto'>
          <TableSkeletonLoader columns={columns.map(({ dataIndex }) => ({ dataIndex }))} rowCount={12} />
        </div>
      ) : reportsListing?.data?.count === 0 || !reportsListing ? (
        <div className='h-[calc(100vh-256px)] min-h-[372px] overflow-auto'>
          <NoDataFoundScreen />
        </div>
      ) : (
        <div className='flex h-full flex-col'>
          <Table
            columns={columns}
            dataSource={reportsListing?.data?.results || []}
            className='h-[calc(100vh-256px)] min-h-[372px] overflow-auto'
            rowKey='id'
            pagination={false}
            onRow={record => ({
              onClick: () => {
                setReportDetails(record?.id);
              },
            })}
            scroll={{ x: 'max-content' }}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
            data={reportsListing}
            totalItems={totalPagesPerItem}
            setTotalItems={setTotalPagesPerItem}
            paginationPage={paginationPage}
            setPaginationPage={setPaginationPage}
          />
        </div>
      )}
      <Modal
        footer={null}
        className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
        closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
        open={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        centered={true}
      >
        <DeleteDialog
          text={dialogReport?.subject}
          deleteBtnClick={handleDeleteReport}
          goBackBtnClick={() => setShowDeleteDialog(false)}
          isLoading={deleteReportLoading}
        />
      </Modal>
      <EditScheduleReports
        setIsEditMode={setShowEditDialog}
        isEditMode={showEditDialog}
        formik={formik}
        handleInvitedEmailKeyDown={handleInvitedEmailKeyDown}
        invitedEmails={invitedEmails}
        handleRemoveInvitedEmail={handleRemoveInvitedEmail}
        emailFieldErrorMessage={emailFieldErrorMessage}
        invalidEmails={invalidEmails}
        selectDay={selectDay}
        handleSelectedDay={handleSelectedDay}
        scheduleLoading={reportUpdateLoading}
        reportDetailFetching={reportDetailFetching}
        reportDetails={reportDetails}
        setReportDetails={setReportDetails}
      />
    </div>
  );
}
