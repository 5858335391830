import { useEffect, useRef } from 'react';
import { Building2 } from 'lucide-react';
import Slider from 'react-slick';
import { BaseLoader, Button, SharedDashboardDatePicker } from '@common/components';
import { useShareDashboard } from '@pages/dashboard';
import { SharedDashboardLayout } from '@pages/sharedDashboard';
import { ARROW_KEYS, COLORS, DASHBOARD_VIEW_TYPE } from '@constants';
import { DownloadIconWhite } from '@assets/svgs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export function SharedDashboard() {
  const {
    shareDashboard,
    shareDashboardLoading,
    handleRangeDateFilter,
    handleClearRangeDateFilter,
    sharedDashboardRef,
    downloadScreenshot,
    handleTablePagination,
    handleCsvPagination,
  } = useShareDashboard();

  const sliderRef = useRef(null);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    infinite: shareDashboard?.data?.dashboardConfig?.length > 1,
    arrows: false,
  };

  useEffect(() => {
    const sliderElement = sliderRef.current;

    const handleKeyDown = event => {
      if (event.keyCode === ARROW_KEYS.RIGHT) {
        sliderElement.slickNext();
      } else if (event.keyCode === ARROW_KEYS.LEFT) {
        sliderElement.slickPrev();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return shareDashboardLoading ? (
    <BaseLoader />
  ) : (
    <div
      ref={sharedDashboardRef}
      className={`relative z-0 flex min-h-screen flex-col ${shareDashboard?.data?.isTextureApplied ? 'bg-[url("/src/assets/images/SlidesImgBg.png")]' : 'bg-themeColor'}`}
    >
      <div
        className='absolute left-0 top-0 z-[-1] h-full w-full opacity-[.4]'
        style={shareDashboard?.data?.isTextureApplied ? { backgroundColor: shareDashboard?.data?.headerColor } : {}}
      />
      <div
        style={shareDashboard?.data?.isTextureApplied ? {} : { backgroundColor: shareDashboard?.data?.headerColor }}
        className='h-[9.375rem] px-8 py-10'
      >
        <div className='flex items-center justify-between gap-6'>
          <div className='flex items-center gap-4'>
            <div className='flex h-[4.5rem] w-[4.5rem]'>
              {shareDashboard?.data?.logo?.image ? (
                <img src={shareDashboard?.data?.logo?.image} alt='logo' className='h-full w-full' />
              ) : (
                <Building2 color={COLORS.ICON_COLOR} className='h-full w-full' />
              )}
            </div>
            <p
              className='text-2xl font-bold leading-[2.125rem]'
              style={{
                color: shareDashboard?.data?.headerTextColor,
              }}
            >
              {shareDashboard?.data?.name}
            </p>
          </div>
          <div id='date-picker-downloadBtn' className='flex gap-3 md:gap-4'>
            <Button onClick={downloadScreenshot} size='lg' className='w-[12.5rem]'>
              <DownloadIconWhite className='h-4 w-4' />
              Download
            </Button>
            <SharedDashboardDatePicker
              placeholder='Date Filter'
              handleClearRangeDateFilter={handleClearRangeDateFilter}
              handleRangeDateFilter={handleRangeDateFilter}
              className='flex-grow rounded'
            />
          </div>
        </div>
      </div>
      <div className='flex flex-1 flex-col'>
        <>
          {shareDashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES && (
            <div className='relative flex flex-1 flex-col justify-between px-[1.25rem] pb-10 scrollbar-none md:px-10 md:py-6'>
              <Slider ref={sliderRef} {...settings} className='bg-transparent'>
                {shareDashboard?.data?.dashboardConfig?.map((item, i) => (
                  <div key={i} className='!max-h-[calc(100vh-24.5rem)] overflow-hidden'>
                    <SharedDashboardLayout
                      layout={item}
                      handleTablePagination={handleTablePagination}
                      handleCsvPagination={handleCsvPagination}
                      isSlideMode={true}
                    />
                  </div>
                ))}
              </Slider>
            </div>
          )}
          {shareDashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.NORMAL && (
            <div className='relative flex flex-1 flex-col justify-between px-[1.25rem] pb-10 scrollbar-none md:px-10 md:py-6'>
              <SharedDashboardLayout
                layout={shareDashboard?.data?.dashboardConfig}
                handleTablePagination={handleTablePagination}
                handleCsvPagination={handleCsvPagination}
              />
            </div>
          )}
          {shareDashboard?.data?.sponsorLogo?.image && (
            <div className='flex flex-col items-center justify-center gap-4 pb-[3.75rem]'>
              <span className='text-sm leading-5 text-bodyText'>Powered by</span>
              <img src={shareDashboard?.data?.sponsorLogo?.image} alt='logo' className='h-6 object-contain md:h-10' />
            </div>
          )}
        </>
      </div>
    </div>
  );
}
