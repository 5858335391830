import moment from 'moment';
import { NumbersIntoDays } from '@utils';
import { DATE_FORMAT, ONE_TIME } from '@constants';

const ReportDetails = ({ data, invitedEmails }) => {
  const { subject, description, scheduledDate, time, type, frequency, scheduledDay } = data || {};

  const heading = label => <p className='font-Manrope text-lg font-bold text-mainText'>{label}</p>;

  const chip = email => (
    <span
      key={email}
      className='flex max-w-fit items-center gap-2 truncate break-all rounded-full bg-tableRowHover px-4 py-2 font-Manrope text-sm font-normal leading-4 text-colorPrimary'
    >
      {email}
    </span>
  );

  const formattedDate = moment(scheduledDate).format(DATE_FORMAT.DATE_PICKER);
  const formattedTime = moment(time, 'HH:mm').format('hh:mm A');
  const repeatText =
    type === ONE_TIME
      ? 'One Time'
      : `Every ${frequency} ${type?.toLowerCase()?.replace('ly', '(s)')} on ${NumbersIntoDays(scheduledDay)}`;

  return (
    <div className='flex flex-col gap-3 p-6'>
      <div>{heading('Recipients')}</div>
      <div>{invitedEmails?.map(chip)}</div>

      <div>{heading('Subject')}</div>
      <div className='font-Manrope text-sm capitalize text-bodyText'>{subject}</div>

      <div>{heading('Description')}</div>
      <div className='ai-insights text-sm capitalize text-bodyText' dangerouslySetInnerHTML={{ __html: description }} />

      <div>{heading('Date')}</div>
      <div className='font-Manrope text-sm capitalize text-bodyText'>{formattedDate}</div>

      <div>{heading('Time')}</div>
      <div className='font-Manrope text-sm capitalize text-bodyText'>{formattedTime}</div>

      <div>{heading('Repeat Every')}</div>
      <div className='font-Manrope text-sm text-bodyText'>{repeatText}</div>
    </div>
  );
};

export default ReportDetails;
