export function DashboardEmptyScreen() {
  return (
    <svg width='248' height='200' viewBox='0 0 248 200' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='Group 26808'>
        <path
          id='Ellipse 653'
          d='M100.364 200C155.516 200 200.226 155.228 200.226 100C200.226 44.7715 155.516 0 100.364 0C45.211 0 0.500977 44.7715 0.500977 100C0.500977 155.228 45.211 200 100.364 200Z'
          fill='url(#paint0_linear_11177_85684)'
        />
        <g id='Mask Group 3'>
          <g id='Group 26805'>
            <g id='Group' filter='url(#filter0_d_11177_85684)'>
              <path
                id='Path 25918'
                d='M55.0901 45.3335H234.843C236.609 45.3335 238.302 46.0359 239.551 47.2861C240.799 48.5364 241.501 50.2321 241.501 52.0002V85.3335C241.501 87.1016 240.799 88.7973 239.551 90.0475C238.302 91.2978 236.609 92.0002 234.843 92.0002H55.0901C53.3244 92.0002 51.6311 91.2978 50.3826 90.0475C49.134 88.7973 48.4326 87.1016 48.4326 85.3335V52.0002C48.4326 50.2321 49.134 48.5364 50.3826 47.2861C51.6311 46.0359 53.3244 45.3335 55.0901 45.3335V45.3335Z'
                fill='white'
              />
            </g>
            <g id='Group 26775'>
              <path
                id='Rectangle 5617'
                d='M140.305 56H105.686C103.48 56 101.691 57.7909 101.691 60C101.691 62.2091 103.48 64 105.686 64H140.305C142.511 64 144.299 62.2091 144.299 60C144.299 57.7909 142.511 56 140.305 56Z'
                fill='#8CD4C2'
              />
              <path
                id='Rectangle 5618'
                d='M164.272 73.333H105.686C103.48 73.333 101.691 75.1239 101.691 77.333C101.691 79.5421 103.48 81.333 105.686 81.333H164.272C166.478 81.333 168.267 79.5421 168.267 77.333C168.267 75.1239 166.478 73.333 164.272 73.333Z'
                fill='#E2F8F4'
              />
            </g>
            <g id='Group 26804'>
              <path
                id='Rectangle 5624'
                d='M91.0442 68.6668C91.0442 57.9893 82.4003 49.3335 71.7375 49.3335C61.0746 49.3335 52.4307 57.9893 52.4307 68.6668C52.4307 79.3443 61.0746 88.0002 71.7375 88.0002C82.4003 88.0002 91.0442 79.3443 91.0442 68.6668Z'
                fill='#3FB79A'
              />
              <g id='bx-search'>
                <path
                  id='Path 25920'
                  d='M70.5377 74.9252C72.1906 74.925 73.7959 74.3712 75.0981 73.3519L79.1925 77.4519L80.5094 76.1332L76.4137 72.0319C77.4427 70.6996 77.9908 69.0577 77.9686 67.3735C77.9464 65.6894 77.3552 64.0625 76.2915 62.7579C75.2277 61.4533 73.754 60.548 72.1106 60.1894C70.4673 59.8309 68.7511 60.0402 67.2417 60.7835C65.7324 61.5267 64.5189 62.7598 63.7991 64.2821C63.0793 65.8043 62.8955 67.5257 63.2778 69.1659C63.6601 70.8061 64.5858 72.2682 65.9042 73.3141C67.2226 74.36 68.8558 74.9279 70.5377 74.9252ZM70.5377 61.8666C71.6438 61.8666 72.725 62.195 73.6447 62.8103C74.5643 63.4257 75.2811 64.3003 75.7044 65.3235C76.1276 66.3468 76.2384 67.4728 76.0226 68.5591C75.8068 69.6454 75.2742 70.6432 74.4921 71.4264C73.71 72.2095 72.7136 72.7429 71.6288 72.959C70.544 73.175 69.4195 73.0641 68.3977 72.6403C67.3758 72.2164 66.5024 71.4987 65.8879 70.5777C65.2734 69.6568 64.9454 68.5741 64.9454 67.4666C64.9454 65.9813 65.5346 64.557 66.5834 63.5068C67.6321 62.4566 69.0546 61.8666 70.5377 61.8666Z'
                  fill='white'
                />
              </g>
            </g>
          </g>
          <g id='Group 26784'>
            <g id='Group_2' filter='url(#filter1_d_11177_85684)'>
              <path
                id='Rectangle 5623'
                d='M214.869 105.333H35.1165C31.4397 105.333 28.459 108.318 28.459 112V145.333C28.459 149.015 31.4397 152 35.1165 152H214.869C218.546 152 221.527 149.015 221.527 145.333V112C221.527 108.318 218.546 105.333 214.869 105.333Z'
                fill='white'
              />
            </g>
            <g id='Group 26775_2'>
              <path
                id='Rectangle 5617_2'
                d='M120.332 116H85.7133C83.5072 116 81.7188 117.791 81.7188 120C81.7188 122.209 83.5072 124 85.7133 124H120.332C122.538 124 124.327 122.209 124.327 120C124.327 117.791 122.538 116 120.332 116Z'
                fill='#8CD4C2'
              />
              <path
                id='Rectangle 5618_2'
                d='M144.299 133.333H85.7133C83.5072 133.333 81.7188 135.124 81.7188 137.333C81.7188 139.542 83.5072 141.333 85.7133 141.333H144.299C146.505 141.333 148.294 139.542 148.294 137.333C148.294 135.124 146.505 133.333 144.299 133.333Z'
                fill='#E2F8F4'
              />
            </g>
            <g id='Group 26807'>
              <path
                id='Rectangle 5624_2'
                d='M71.0716 128.666C71.0716 117.989 62.4276 109.333 51.7648 109.333C41.102 109.333 32.458 117.989 32.458 128.666C32.458 139.344 41.102 148 51.7648 148C62.4276 148 71.0716 139.344 71.0716 128.666Z'
                fill='#3FB79A'
              />
              <g id='bx-search_2'>
                <path
                  id='Path 25920_2'
                  d='M50.5651 134.924C52.2179 134.924 53.8232 134.37 55.1255 133.351L59.2199 137.451L60.5367 136.132L56.441 132.031C57.47 130.699 58.0181 129.057 57.9959 127.373C57.9737 125.688 57.3826 124.062 56.3188 122.757C55.255 121.452 53.7814 120.547 52.138 120.188C50.4946 119.83 48.7784 120.039 47.2691 120.782C45.7597 121.526 44.5463 122.759 43.8265 124.281C43.1066 125.803 42.9229 127.525 43.3052 129.165C43.6874 130.805 44.6132 132.267 45.9316 133.313C47.2499 134.359 48.8832 134.927 50.5651 134.924ZM50.5651 121.866C51.6711 121.866 52.7524 122.194 53.672 122.809C54.5917 123.425 55.3084 124.299 55.7317 125.323C56.155 126.346 56.2657 127.472 56.0499 128.558C55.8342 129.644 55.3016 130.642 54.5195 131.425C53.7374 132.209 52.7409 132.742 51.6561 132.958C50.5713 133.174 49.4469 133.063 48.425 132.639C47.4031 132.215 46.5297 131.498 45.9153 130.577C45.3008 129.656 44.9728 128.573 44.9728 127.466C44.9728 125.98 45.562 124.556 46.6107 123.506C47.6595 122.456 49.0819 121.866 50.5651 121.866Z'
                  fill='white'
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_11177_85684'
          x='42.4326'
          y='42.3335'
          width='205.068'
          height='58.6665'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_11177_85684' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_11177_85684' result='shape' />
        </filter>
        <filter
          id='filter1_d_11177_85684'
          x='22.459'
          y='102.333'
          width='205.068'
          height='58.667'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_11177_85684' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_11177_85684' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_11177_85684'
          x1='100.364'
          y1='0'
          x2='100.364'
          y2='200'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7F9F3' />
          <stop offset='1' stopColor='#E2F8F4' />
        </linearGradient>
      </defs>
    </svg>
  );
}
