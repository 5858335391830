import { Popover } from 'antd';

export function MembersPopover({ members }) {
  const visibleMembers = members?.slice(0, 3);
  const remainingCount = members?.length - 3;

  return (
    <div className='flex'>
      {visibleMembers?.map((member, index) => {
        const isLastVisible = index === visibleMembers.length - 1;

        return isLastVisible && members?.length <= 3 ? (
          <Popover
            key={member?.id}
            placement='right'
            content={
              <div onClick={e => e.stopPropagation()} className='max-h-[16rem] w-[13.875rem] overflow-y-auto'>
                <ul className='flex flex-col'>
                  {members.map(member => (
                    <li
                      key={member?.id}
                      onClick={e => e.stopPropagation()}
                      className='flex cursor-pointer items-center gap-2 rounded-lg p-3 text-sm font-normal leading-5 text-bodyText hover:bg-shade3'
                    >
                      <div className='h-6 w-6'>
                        <img
                          src={member?.profileImage?.image}
                          alt='Member Avatar'
                          className='h-full w-full rounded-full object-contain'
                        />
                      </div>
                      {member?.name}
                    </li>
                  ))}
                </ul>
              </div>
            }
            trigger='hover'
          >
            <div
              onClick={event => event.stopPropagation()}
              className='-ml-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-white bg-tableHeaderBackground'
            >
              <img src={member?.profileImage?.image} alt='Member Avatar' className='h-full w-full rounded-full' />
            </div>
          </Popover>
        ) : (
          <div
            key={member?.id}
            className='-ml-1 flex h-6 w-6 items-center justify-center rounded-full border border-white bg-tableHeaderBackground'
          >
            <img src={member?.profileImage?.image} alt='Member Avatar' className='h-full w-full rounded-full' />
          </div>
        );
      })}

      {members?.length > 3 && (
        <Popover
          placement='right'
          content={
            <div onClick={e => e.stopPropagation()} className='max-h-[16rem] w-[13.875rem] overflow-y-auto'>
              <ul className='flex flex-col'>
                {members.map(member => (
                  <li
                    key={member?.id}
                    onClick={e => e.stopPropagation()}
                    className='flex cursor-pointer items-center gap-2 rounded-lg p-3 text-sm font-normal leading-5 text-bodyText hover:bg-shade3'
                  >
                    <div className='h-6 w-6'>
                      <img
                        src={member?.profileImage?.image}
                        alt='Member Avatar'
                        className='h-full w-full rounded-full object-contain'
                      />
                    </div>
                    {member?.name}
                  </li>
                ))}
              </ul>
            </div>
          }
          trigger='hover'
        >
          <span
            className='-ml-1 flex h-6 w-6 cursor-pointer items-center justify-center rounded-full border border-white bg-shade3 text-xs leading-4 !text-highlighterText2'
            onClick={e => e.stopPropagation()}
          >
            +{remainingCount}
          </span>
        </Popover>
      )}

      {members?.length === 0 && '-'}
    </div>
  );
}
