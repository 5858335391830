import { Popover } from 'antd';
import { isEmpty } from 'lodash';

export function TemplateDataSourcePopover({ dashboard }) {
  const connectorsListing = !isEmpty(dashboard?.connectors)
    ? dashboard.connectors
    : !isEmpty(dashboard?.widgets)
      ? dashboard?.widgets?.map(data => data)
      : [];

  return (
    <div className='flex'>
      {connectorsListing?.slice(0, 3)?.map((dataSource, i) => (
        <div
          key={i}
          onClick={event => event.stopPropagation()}
          className='-ml-1 flex h-6 w-6 items-center justify-center rounded-full border border-white bg-tableHeaderBackground'
          title={dataSource?.connector?.sourceName}
        >
          <span className='h-[0.875rem] w-[0.875rem]'>
            <img src={dataSource?.logo?.image} alt='DataSource Logo' className='h-full w-full' />
          </span>
        </div>
      ))}

      {isEmpty(connectorsListing) && (
        <p className='font-Manrope text-sm leading-5 text-labelText'>- No data source connected</p>
      )}

      {connectorsListing?.length > 3 && (
        <Popover
          content={
            <div onClick={e => e.stopPropagation()} className='max-h-[16rem] w-[13.875rem] overflow-y-auto'>
              <ul className='flex flex-col'>
                {connectorsListing?.map(dataSource => (
                  <li
                    key={dataSource?.id}
                    onClick={e => e.stopPropagation()}
                    className='flex cursor-pointer items-center gap-1 rounded-lg p-3 text-sm font-normal leading-5 text-bodyText hover:bg-shade3'
                  >
                    <span className='h-6 w-6 overflow-hidden p-1'>
                      <img
                        src={dataSource?.logo?.image}
                        alt='DataSource Logo'
                        className='h-full w-full object-contain'
                      />
                    </span>
                    {dataSource?.sourceName}
                  </li>
                ))}
              </ul>
            </div>
          }
          trigger='hover'
        >
          <span
            className='-ml-1 flex h-6 w-6 items-center justify-center rounded-full border border-white bg-shade3 text-xs leading-4 !text-highlighterText2'
            onClick={e => e.stopPropagation()}
          >
            +{connectorsListing?.length - 3}
          </span>
        </Popover>
      )}
    </div>
  );
}
