import { OTPInputs, Loader, AuthScreenWrapper, Button } from '@common/components';
import { useOTPVerification } from '@pages/otpVerification';

export function OTPVerification() {
  const {
    email,
    otp,
    setOtp,
    otpErrorMessage,
    showOtpError,
    countdown,
    handleOTPVerification,
    handleResend,
    isOtpExpire,
    isOTPLoading,
    setShowOtpError,
  } = useOTPVerification();

  return (
    <AuthScreenWrapper
      title='OTP Verification'
      desc='Enter the 4 - digit verification code that was sent to your email.'
      backBtnEnable={true}
    >
      <>
        <div className='mt-[2rem] flex flex-col'>
          <OTPInputs
            otp={otp}
            setOtp={e => {
              setOtp(e);
              setShowOtpError(false);
            }}
            showOtpError={showOtpError}
            errorMessage={otpErrorMessage}
            handleOtpVerification={() =>
              handleOTPVerification({
                payload: { email, otp },
              })
            }
            setShowOtpError={setShowOtpError}
          />
          <p className='mb-4 text-center font-Manrope text-xs font-normal leading-4 text-bodyText md:mb-8 md:text-sm md:leading-5'>
            Code expires in <span className='font-semibold text-highlighterText1 md:font-bold'>{countdown} min</span>
          </p>
          <Button
            size='lg'
            className='mb-4'
            disabled={!otp || isOTPLoading}
            onClick={() =>
              handleOTPVerification({
                payload: { email, otp },
              })
            }
          >
            Continue {isOTPLoading ? <Loader /> : ''}
          </Button>
        </div>
        <p className='flex items-center justify-center font-Manrope text-xs font-normal leading-4 text-bodyText md:text-sm md:leading-5'>
          If you didn’t receive an email?{'  '}
          <button
            className='bg-transparent font-semibold text-highlighterText2 disabled:!bg-transparent disabled:text-disableButton md:font-bold'
            disabled={!isOtpExpire}
            onClick={() =>
              handleResend({
                payload: { email },
              })
            }
          >
            &nbsp; Resend
          </button>
        </p>
      </>
    </AuthScreenWrapper>
  );
}
