import { useState } from 'react';
import { Tabs } from 'antd';
import { useAddWidgetTour } from '../hooks/useAddWidgetTour';
import { DashboardCustomWidgets, DashboardStaticWidgets } from '@pages/dashboard';
import { delay } from '@utils';
import { CUSTOM_WIDGETS_TAB_ID, WIDGETS_TAB_ID, WIDGETS_TABS } from '@constants';

export function DashboardTabsSection({ handleOnDrag, isEditMode, isTourActive }) {
  const [values, setValues] = useState(WIDGETS_TABS.CUSTOM_WIDGETS);
  const { addWidgetTour } = useAddWidgetTour();

  return (
    <Tabs
      className='custom-tabs'
      popupClassName='mb-6'
      id={WIDGETS_TAB_ID}
      activeKey={values}
      onTabClick={async value => {
        setValues(value);
        if (!isTourActive) return;
        await delay(1000);
        if (value === WIDGETS_TABS.STATIC_WIDGETS) {
          addWidgetTour().moveTo(4);
        } else addWidgetTour().moveNext();
      }}
      defaultValue={WIDGETS_TABS.STATIC_WIDGETS}
      items={[
        {
          label: 'Custom',
          className: 'overflow-y-auto h-[calc(100vh-438px)] overflow-hidden scrollbar-none',
          key: WIDGETS_TABS.CUSTOM_WIDGETS,
          children: (
            <div id={CUSTOM_WIDGETS_TAB_ID} className='h-full'>
              <DashboardCustomWidgets handleOnDrag={handleOnDrag} isEditMode={isEditMode} />
            </div>
          ),
        },
        {
          label: 'Static',
          key: WIDGETS_TABS.STATIC_WIDGETS,
          children: <DashboardStaticWidgets handleOnDrag={handleOnDrag} isEditMode={isEditMode} />,
        },
      ]}
    />
  );
}
