import { FormSelect } from '@common/components';
import { NON_AUTH_CONNECTOR_INPUT_FIELDS } from '@constants';

export function SelectField({ nonAuthSourceName, nonAuthFields, handleNonAuthFields }) {
  return (
    <div className='h-[12rem] overflow-y-auto'>
      <div className='mx-auto flex h-[12rem] w-full flex-col justify-center gap-2.5 px-1.5'>
        {NON_AUTH_CONNECTOR_INPUT_FIELDS[nonAuthSourceName]?.map(connector => (
          <div className='flex w-full flex-col justify-center gap-1.5 px-2.5' key={connector.label}>
            <FormSelect
              id={connector.name}
              labelText={connector.label}
              placeholder={connector.label}
              options={connector.dropDownOptions}
              value={nonAuthFields[nonAuthSourceName][connector.name]}
              handleChange={value => handleNonAuthFields(nonAuthSourceName, connector.name, value)}
              triggerClassName={`h-10 lg:h-8 xxl:h-10 ${nonAuthFields[nonAuthSourceName][connector.name] ? '' : 'custom-select'}`}
              showInfoIcon={!!nonAuthFields[nonAuthSourceName][connector.infoContent]}
              infoContent={nonAuthFields[nonAuthSourceName][connector.infoContent]}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
