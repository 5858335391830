import { times } from 'lodash';
import Skeleton from 'react-loading-skeleton';
import { SKELETON_TYPE } from '@constants';
import 'react-loading-skeleton/dist/skeleton.css';

export const BaseSkeltonLoader = ({ type, count, width, height }) => {
  return (
    <>
      {type === SKELETON_TYPE.IMAGE_UPLOADER && (
        <Skeleton circle={true} className='absolute left-0 top-0 h-full w-full' width={width} height={height} />
      )}
      {type === SKELETON_TYPE.SINGLE_LINE && <Skeleton count={count} width={width} height={height} />}
      {type === SKELETON_TYPE.SUBSCRIPTION_PLAN &&
        times(4).map(element => (
          <div
            key={element}
            className={
              'shadow-[0_0.97px_3.88px_0_rgba(25, 33, 61, 0.08)] w-[23.5%] rounded-lg border border-paleBlueGray px-[1.375rem] py-8 hover:border-darkPrimary'
            }
          >
            <div className='border-b border-paleBlueGray pb-4'>
              <Skeleton height={13} width={127} />
              <div className='my-4 flex items-center gap-2'>
                <h2 className='text-[2rem] font-semibold leading-[2.43rem]'>
                  <Skeleton height={23} width={112} />
                </h2>
                <span className='text-[0.6875rem] font-semibold leading-[1.0938rem] text-slateGray'>
                  <Skeleton height={8} width={55} />
                </span>
              </div>
              <div className='mb-[0.9719rem] flex items-center gap-1.5 text-darkSecondary'>
                <Skeleton height={13} width={98} />
              </div>
              <p className='relative text-xs font-normal leading-[1.3369rem] text-darkSecondary'>
                <Skeleton height={21} className='absolute left-0 top-0 w-full' />
              </p>
            </div>
            <ul className='mb-8 mt-4 flex flex-col gap-[0.875rem]'>
              {times(4).map(element => (
                <li
                  key={element}
                  className='flex justify-between gap-4 text-[0.8125rem] font-normal leading-[1.215rem] text-darkSecondary'
                >
                  <Skeleton height={9} width={185} />
                </li>
              ))}
            </ul>
            <div className='relative flex items-center gap-1'>
              <Skeleton height={40} className='absolute left-0 top-0 w-full' />
            </div>
          </div>
        ))}
    </>
  );
};
