import { FormInput } from '@common/components';

export function ColorPicker({ labelText, componentColor, handleChange, name, showError }) {
  return (
    <div className='flex w-full flex-col gap-2'>
      <label className='block font-Manrope text-sm font-semibold leading-5 text-mainText'>{labelText}</label>
      <div className='flex items-center gap-2'>
        <input
          type='color'
          name={name}
          className='h-12 min-w-12 overflow-hidden rounded-full border border-secondaryStrokeButton'
          style={{ backgroundColor: componentColor }}
          value={componentColor || ''}
          onChange={handleChange}
        />
        <FormInput
          disabled
          name={name}
          className='w-full'
          type='text'
          value={componentColor}
          placeholder='Enter hex code'
          handleChange={handleChange}
        />
      </div>
      {!!showError && <p className='h-min-content text-xs leading-4 text-errorTooltip'>{showError}</p>}
    </div>
  );
}
