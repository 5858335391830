import { useEffect, useRef, useState } from 'react';
import { Badge } from 'antd';
import { calculateBadgeColor, calculateTrend } from '@utils';
import { COLORS, THEME_STYLE } from '@constants';

export function GoalWidget({ widget, className, onSlideMode }) {
  const goalWidgetRef = useRef(null);

  const [percentageFontSize, setPercentageFontSize] = useState('60px');

  const trendStr = widget?.trend?.toString();
  const hasNegative = trendStr.includes('-');

  const color =
    widget?.themeStyle === THEME_STYLE.DARK
      ? '#fff'
      : widget?.trend === 0
        ? COLORS.ON_TRACK_COLOR
        : hasNegative
          ? COLORS.OFF_TRACK_COLOR
          : COLORS.COMPLETED;

  useEffect(() => {
    if (goalWidgetRef?.current) {
      setPercentageFontSize(Math.min(goalWidgetRef?.current?.clientHeight, goalWidgetRef?.current?.clientWidth) / 4);
    }
  }, [goalWidgetRef?.current?.clientHeight]);

  return (
    <div ref={goalWidgetRef} className={`relative flex h-full flex-col justify-center ${className}`}>
      <div className={`flex flex-col items-center ${onSlideMode ? 'mt-3' : '-mt-12'}`}>
        <span className='font-Manrope font-bold' style={{ fontSize: className ? '14px' : percentageFontSize, color }}>
          {widget?.achieved}
        </span>
      </div>
      {!onSlideMode && (
        <div className='absolute -bottom-4 flex w-full items-center justify-between gap-2 rounded-lg bg-GoalTrackingColor px-4 py-2'>
          <div className='inline-flex flex-col gap-2 font-Manrope text-sm leading-4 text-tableHeaderText2'>
            Target
            <span className='font-Manrope text-base font-medium leading-[1.375rem] text-bodyText'>
              <b className='text-mainText'>{widget?.target}</b>
            </span>
          </div>
          <div className='inline-flex flex-col gap-2 font-Manrope text-sm leading-4 text-tableHeaderText2'>
            Trend
            <Badge
              style={calculateBadgeColor(widget?.trend)}
              count={
                <span className='!inline-flex items-center gap-1 rounded-[1.25rem] px-1.5 py-1 text-xs font-medium'>
                  {calculateTrend(widget?.trend, 'icon')} {Math.floor(widget?.trend)}%
                </span>
              }
            />
          </div>
          <div className='inline-flex flex-col gap-2 text-sm leading-4 text-tableHeaderText2'>
            Time Left
            <span className='text-base font-medium leading-[1.375rem] text-mainText'>
              {widget?.timePeriod} {widget?.timePeriod === 1 ? 'day' : 'days'} left
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
