import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiEndpoints, useDeleteMutation, useGetQuery } from '@services';
import { AppContext } from '@useContext';
import { getLocalStorageItem } from '@utils';
import { INVALID_PAGE, REFETCH_INTERVAL_TIME, ROLES, SUCCESSFUL_MESSAGE, TOTAL_ITEMS_PER_PAGE } from '@constants';

export function useDataSource() {
  const { dataSourcePage, setDataSourcePage } = useContext(AppContext);

  const { organizationId } = useParams();
  const [search, setSearch] = useState('');

  const [paginationPage, setPaginationPage] = useState(dataSourcePage);
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteDataSourceLoading, setDeleteDataSourceLoading] = useState(false);
  const userRole = getLocalStorageItem('userOrganizationRole');
  const isNotMember = ROLES.MEMBER !== userRole;

  const {
    data: connectedDataSource,
    refetch: connectedDataSourceRefetch,
    isLoading: connectedDataSourceLoading,
    isFetching: connectedDataSourceFetching,
  } = useGetQuery(
    'poll-connectedDataSource-listing',
    apiEndpoints.CONNECTED_DATA_SOURCE_LIST(organizationId),
    { search, page: dataSourcePage, pageSize: totalPagesPerItem },
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchInterval: REFETCH_INTERVAL_TIME,
      onError: ({ response: { data } }) => {
        if (data?.detail === INVALID_PAGE) {
          setDataSourcePage(1);
          setPaginationPage(1);
        }
        toast.error(data?.detail);
      },
    }
  );

  const { mutate: handleDeleteDataSource } = useDeleteMutation(
    apiEndpoints.DELETE_DATA_SOURCE(organizationId, showDeleteDialog?.id),
    () => {
      setShowDeleteDialog(false);
      setDeleteDataSourceLoading(false);
      connectedDataSourceRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Data source deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setDeleteDataSourceLoading(false);
    }
  );

  useEffect(() => {
    if (dataSourcePage && isNotMember) connectedDataSourceRefetch();
  }, [dataSourcePage, search, totalPagesPerItem]);

  return {
    dataSourcePage,
    setDataSourcePage,
    connectedDataSource,
    connectedDataSourceRefetch,
    connectedDataSourceLoading,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDataSourceLoading,
    setDeleteDataSourceLoading,
    handleDeleteDataSource,
    setSearch,
    search,
    connectedDataSourceFetching,
    totalPagesPerItem,
    setTotalPagesPerItem,
    paginationPage,
    setPaginationPage,
  };
}
