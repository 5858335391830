import ReactQuill from 'react-quill';
import { Button, Loader } from '@common/components';
import { COLORS } from '@constants';
import { ChatbotIcon } from '@assets/svgs';
import 'react-quill/dist/quill.snow.css';

export function TextEditor({ formik, isReadOnly = false, className = '', refetchSummary, summaryFetching }) {
  const colors = [
    '#000000',
    '#e60000',
    '#ff9900',
    '#ffff00',
    '#008a00',
    '#0066cc',
    '#9933ff',
    '#ffffff',
    '#facccc',
    '#ffebcc',
    '#ffffcc',
    '#cce8cc',
    '#cce0f5',
    '#ebd6ff',
    '#bbbbbb',
    '#f06666',
    '#ffc266',
    '#ffff66',
    '#66b966',
    '#66a3e0',
    '#c285ff',
    '#888888',
    '#a10000',
    '#b26b00',
    '#b2b200',
    '#006100',
    '#0047b2',
    '#6b24b2',
  ];

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: colors }],
      [{ background: colors }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];

  const { values, setFieldValue, errors, touched } = formik;
  const showDescriptionError = touched['description'] && errors['description'];

  return (
    <div className='relative flex flex-col gap-3'>
      <ReactQuill
        className={`${className} ${showDescriptionError ? '!border !border-errorColor' : ''}`}
        theme='snow'
        modules={modules}
        formats={formats}
        value={values.description}
        readOnly={isReadOnly || summaryFetching}
        onChange={text => setFieldValue('description', text)}
      />

      {showDescriptionError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorTooltip'>
          {errors['description']}
        </p>
      )}

      <Button
        disabled={summaryFetching}
        className={`${summaryFetching ? 'bg-white' : 'chatbot-button'} chatbot-shadow absolute bottom-0 right-0 z-10 m-2 flex h-[2.5rem] w-[2.5rem] items-center justify-center !rounded-full !p-0 !pl-0 disabled:!bg-white`}
        onClick={refetchSummary}
      >
        {summaryFetching ? (
          <Loader className='ml-0 h-6 w-6 !pl-0' strokeColorClass={COLORS.PRIMARY_GREEN_COLOR} />
        ) : (
          <ChatbotIcon className='h-[1.75rem] min-w-[1.75rem]' />
        )}
      </Button>
    </div>
  );
}
