import { useEffect, useRef, useCallback } from 'react';

export function useOutsideClick(ref, callback, ignoreRefs = []) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const handleClickOutside = useCallback(
    event => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      for (const ignoreRef of ignoreRefs) {
        if (ignoreRef.current && ignoreRef.current.contains(event.target)) {
          return;
        }
      }

      callbackRef.current();
    },
    [ref, ignoreRefs]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);
}
