import { Collapse } from 'antd';
import { GripVertical } from 'lucide-react';
import { BaseLoader, NoDataFoundScreen, SearchInput } from '@common/components';
import { useCustomWidgets } from '@pages/dashboard';
import { formatWidgetName } from '@utils';
import { COLORS, CUSTOM_WIDGETS_ID, CUSTOM_WIDGETS_TAB_ID_SELECT } from '@constants';

export function DashboardCustomWidgets({ handleOnDrag, isEditMode }) {
  const { searchTerm, setSearchTerm, customWidgets, customWidgetsFetching } = useCustomWidgets(isEditMode);

  const items = customWidgets?.data?.results
    ?.filter(customWidget => customWidget?.dataSourceWidget?.length > 0)
    ?.map(customWidget => ({
      key: customWidget?.id,
      label: (
        <div className='flex items-center gap-2'>
          <div className='flex h-10 w-10 items-center justify-center overflow-hidden rounded-full bg-shade3 p-2.5'>
            <img src={customWidget?.connector?.logo?.image} alt='Logo' />
          </div>
          <div className='flex flex-col'>
            <span className='text-sm font-bold leading-4 text-mainText'>{customWidget?.name}</span>
            <span className='text-xs text-darkTextColor'>{customWidget?.connector?.sourceName}</span>
          </div>
        </div>
      ),
      children: (
        <div className='flex flex-col gap-6 pb-6'>
          {customWidget?.dataSourceWidget?.map(({ id, widget }) => (
            <div
              key={id}
              className='flex cursor-pointer gap-6'
              draggable
              onDragStart={e => handleOnDrag(e, widget, customWidget)}
              id={CUSTOM_WIDGETS_ID}
            >
              <div className='flex items-center gap-4' id={CUSTOM_WIDGETS_ID}>
                <GripVertical color={COLORS.BUTTON_DISABLE} />
                <div className='flex items-center gap-3'>
                  <span className='flex h-8 w-8 items-center justify-center'>
                    <img src={widget?.icon?.image} alt='Widget Icon' />
                  </span>
                  <div className='flex flex-col gap-0.5'>
                    <h3 className='text-sm font-semibold leading-5 '>{widget?.label}</h3>
                    <p className='text-xs leading-4'>{formatWidgetName(widget?.type)}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ),
    }));

  const noDataFound = customWidgets?.data?.results.length === 0 || items?.length === 0;

  return (
    <div id={CUSTOM_WIDGETS_TAB_ID_SELECT}>
      {customWidgets?.data?.results.length !== 0 && (
        <SearchInput
          className='mb-2'
          placeholder='Search custom widgets'
          value={searchTerm}
          onChange={e => setSearchTerm(e?.target?.value)}
        />
      )}
      {customWidgetsFetching ? (
        <div className='relative h-[calc(100vh-26rem)]'>
          <BaseLoader />
        </div>
      ) : noDataFound ? (
        <div className='mt-10 text-center text-darkTextColor' id={CUSTOM_WIDGETS_ID}>
          <NoDataFoundScreen
            title='No custom widget available'
            message='It looks like there aren’t any widgets here at the moment.'
          />
        </div>
      ) : (
        <Collapse
          className='widget-accordion border-0 bg-white'
          items={items}
          accordion
          id={CUSTOM_WIDGETS_TAB_ID_SELECT}
        />
      )}
    </div>
  );
}
