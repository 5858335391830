import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import { getLocalStorageItem } from '@utils';
import { INVALID_PAGE, MANAGE_ORGANIZATION_TABS, ROLES, SUCCESSFUL_MESSAGE, TOTAL_ITEMS_PER_PAGE } from '@constants';

export function useInvitesTab(currentTab) {
  const { organizationId } = useParams();
  const [resendLoading, setResendLoading] = useState({});

  const [page, setPage] = useState(1);
  const [paginationPage, setPaginationPage] = useState(page);
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);

  const {
    data: inviteListing,
    refetch: inviteListingRefetch,
    isFetching: inviteListingFetching,
  } = useGetQuery(
    'invite-listing',
    apiEndpoints.INVITATIONS_LISTING(organizationId),
    { page, pageSize: totalPagesPerItem },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onError: ({ response: { data } }) => {
        if (data?.detail === INVALID_PAGE) {
          setPage(1);
          setPaginationPage(1);
        }
        toast.error(data?.detail);
      },
    }
  );

  const { mutate: handleResendInvite, isLoading: resendInviteLoading } = usePostMutation(
    apiEndpoints.RESEND_INVITATION,
    () => {
      toast.success(SUCCESSFUL_MESSAGE('Invitation resent'));
      setResendLoading({});
    },
    ({ response: { data } }) => {
      toast.error(data[0]?.non_field_errors[0]);
      setResendLoading({});
    }
  );

  useEffect(() => {
    const userRole = getLocalStorageItem('userOrganizationRole');
    const isNotMember = ROLES.MEMBER !== userRole;

    if (!isNotMember) return;
    if (page || totalPagesPerItem) inviteListingRefetch();
    if (currentTab === MANAGE_ORGANIZATION_TABS.INVITES) inviteListingRefetch();
  }, [page, totalPagesPerItem, currentTab]);

  return {
    inviteListing,
    handleResendInvite,
    organizationId,
    resendInviteLoading,
    inviteListingFetching,
    resendLoading,
    setResendLoading,
    page,
    setPage,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
  };
}
