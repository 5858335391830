import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Modal, Skeleton, Table } from 'antd';
import { Trash2, X } from 'lucide-react';
import { Badge, CustomPagination, DeleteDialog, TableSkeletonLoader } from '@common/components';
import { ROUTES } from '@routes';
import { capitalizeString } from '@utils';
import { COLORS, POLL_STATUS_TYPE } from '@constants';

export function DataSourceTabularView({
  connectedDataSourceFetching,
  connectedDataSource,
  handleDeleteDataSource,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteDataSourceLoading,
  setDeleteDataSourceLoading,
  page,
  viewType,
  setPage,
  totalItems,
  setTotalItems,
  paginationPage,
  setPaginationPage,
}) {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const statusCheck = {
    [POLL_STATUS_TYPE.SUCCEEDED]: 'success',
    [POLL_STATUS_TYPE.FAILED]: 'failed',
    [POLL_STATUS_TYPE.PENDING]: 'pending',
    [POLL_STATUS_TYPE.RUNNING]: 'running',
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'sourceName',
      key: 'sourceName',
      render: (text, record) =>
        connectedDataSourceFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='inline-flex items-center gap-3'>
            <div className='flex h-10 w-10 items-center justify-center rounded-full bg-tableHeaderBackground'>
              <img src={record?.connector?.logo?.image} alt='dashboard' className='h-6 w-6 object-contain' />
            </div>
            <p className='text-sm font-medium leading-5 text-mainText'>{record?.connector?.sourceName}</p>
          </div>
        ),
    },
    {
      title: 'Account',
      dataIndex: 'name',
      key: 'modified',
      render: name =>
        connectedDataSourceFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <p className='text-sm leading-5 text-bodyText'>{name}</p>
        ),
    },
    {
      title: 'Status',
      dataIndex: 'syncStatus',
      key: 'syncStatus',
      render: text =>
        connectedDataSourceFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <Badge variant={statusCheck[text.toLowerCase()]}>
            <svg width='5' height='5' className={text.toLowerCase() === POLL_STATUS_TYPE.RUNNING && 'animate-ping'}>
              <circle cx='2.5' cy='2.5' r='10' fill='current-color' stroke='black' strokeWidth='2' />
            </svg>
            {capitalizeString(text)}
          </Badge>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      render: (text, record) =>
        connectedDataSourceFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div
            onClick={e => {
              e.stopPropagation();
              setShowDeleteDialog({
                ...showDeleteDialog,
                [record.id]: true,
                id: record.id,
                connector: record.connector,
              });
            }}
          >
            <Trash2 className='h-5 w-5' color={COLORS.ERROR_TOOLTIP} />
          </div>
        ),
    },
  ];

  const handleRowClick = record => {
    navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: record.id }), {
      state: { page, viewType },
    });
  };

  return (
    <>
      <div className='mt-6 h-full md:mt-0'>
        {connectedDataSourceFetching ? (
          <TableSkeletonLoader columns={columns} />
        ) : (
          <div className='flex h-full flex-col'>
            <Table
              columns={columns}
              dataSource={connectedDataSource?.data?.results}
              rowKey='id'
              rowClassName='cursor-pointer'
              className='table-bold-thin h-[calc(100vh-256px)] min-h-[372px] overflow-y-auto scrollbar-none'
              onRow={record => ({
                onClick: () => handleRowClick(record),
              })}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            <CustomPagination
              page={page}
              setPage={setPage}
              data={connectedDataSource}
              totalItems={totalItems}
              setTotalItems={setTotalItems}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
            />
          </div>
        )}
      </div>
      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        onCancel={() => setShowDeleteDialog(false)}
        centered={true}
        open={showDeleteDialog}
      >
        <DeleteDialog
          text={showDeleteDialog?.connector?.sourceName}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleDeleteDataSource();
            setDeleteDataSourceLoading(true);
          }}
          isLoading={deleteDataSourceLoading}
          goBackBtnClick={() => setShowDeleteDialog(false)}
        />
      </Modal>
    </>
  );
}
