import { useLocation } from 'react-router-dom';
import { NotificationDropdown, ProfileDropdown } from '@common/components';

export function UserAction({ userDetail, userDetailRefetch }) {
  const { pathname } = useLocation();

  return (
    <>
      <NotificationDropdown userDetail={userDetail} userDetailRefetch={userDetailRefetch} />
      {pathname.includes('home') && <ProfileDropdown />}
    </>
  );
}
