import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import { apiEndpoints, useGetQuery } from '@services';
import { DEFAULT_DEBOUNCE_INTERVAL } from '@constants';

export function useCustomWidgets(isEditMode) {
  const [searchTerm, setSearchTerm] = useState(null);
  const { organizationId, dashboardId } = useParams();

  const {
    data: customWidgets,
    refetch: fetchCustomWidgets,
    isFetching: customWidgetsFetching,
  } = useGetQuery(
    'custom-widgets',
    apiEndpoints.CUSTOM_WIDGETS(organizationId, dashboardId),
    { search: searchTerm },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: isEditMode,
    }
  );

  const debounceFetch = useCallback(
    debounce(() => fetchCustomWidgets(), DEFAULT_DEBOUNCE_INTERVAL),
    [fetchCustomWidgets]
  );

  useEffect(() => {
    if (searchTerm === null) return;
    debounceFetch();
  }, [searchTerm]);

  return { customWidgets, customWidgetsFetching, searchTerm, setSearchTerm };
}
