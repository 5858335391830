import { useCallback } from 'react';
import { useTour } from '@common/hooks/useTour';
import {
  CREATE_HOME_ORGANIZATION_BUTTON,
  CREATE_HOME_ORGANIZATION_ID,
  LOGO_ID,
  ORGANIZATION_NAME_ID,
  SAVE_BTN_ID,
} from '@constants';

export const useHomeTour = () => {
  const { createTour, createStep, createClickStep } = useTour();

  const getHomeTourSteps = () => [
    createClickStep(CREATE_HOME_ORGANIZATION_ID, 'Create Organization', 'Click here to create a new organization.'),
    {
      ...createStep(LOGO_ID, 'Upload Logo', 'Upload organization logo, it is a mandatory field.'),
      onHighlighted: (element, step, option, moveToNextStep, safelyAddEventListener) => {
        safelyAddEventListener(element, 'change', e => {
          if (option?.state?.activeIndex === step.index && e.target.files && e.target.files.length > 0)
            moveToNextStep();
        });
      },
    },
    createStep(SAVE_BTN_ID, 'Adjust and Save Logo', 'Adjust the image as needed, then click here to save the logo.'),
    createStep(
      ORGANIZATION_NAME_ID,
      'Enter Organization Name',
      'Enter Organization name here, it is a mandatory field.',
      { showButtons: ['next'] }
    ),
    createClickStep(CREATE_HOME_ORGANIZATION_BUTTON, 'Create Organization', 'Click here to create organization.', {
      showButtons: ['next'],
    }),
  ];

  const createHomeOrgTour = useCallback(() => createTour(getHomeTourSteps()), [createTour, getHomeTourSteps]);

  return {
    createHomeOrgTour,
  };
};
