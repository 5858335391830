import { useEffect, useState } from 'react';
import { Calendar } from '@shadcnComponent';
import { cn } from '@shadcnUtils';
import { Popover } from 'antd';
import { format } from 'date-fns';
import { CalendarDays } from 'lucide-react';
import { BaseDatePicker, Button, FormSelect } from '@common/components';
import { COLORS, DATE_RANGE_DROP_DOWN, DATE_RANGE_OPTIONS } from '@constants';

export function DatePicker({
  className,
  placeholder,
  date,
  setDate,
  showDateTimeFilter,
  setShowDateTimeFilter,
  handleRangeDateFilter,
  handleClearRangeDateFilter,
  dateRangeSet,
  handleSelectDateRange,
  dateRangeStart,
  setDateRangeStart,
  dateRangeEnd,
  setDateRangeEnd,
  compareRangeSet,
  handleSelectCompareRange,
  compareRangeEnd,
  compareRangeStart,
  setCompareRangeEnd,
  setCompareRangeStart,
  isComparable,
}) {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    setWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  return (
    <div className={cn(className)}>
      <Popover
        open={showDateTimeFilter}
        onOpenChange={() => setShowDateTimeFilter(!showDateTimeFilter)}
        overlayClassName='calendar-popover'
        placement='bottomLeft'
        arrow={false}
        content={
          <div className='flex w-[auto] flex-col lg:flex-row'>
            <Calendar
              initialFocus
              mode='range'
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={width >= 768 ? 2 : 1}
              today={false}
            />
            {isComparable ? (
              <div className='flex flex-col justify-between border-t border-secondaryStrokeButton pt-4 lg:w-[234px] lg:border-l lg:border-t-0 lg:pt-3'>
                <div className='flex flex-col gap-4 pb-4 md:flex-row lg:flex-col lg:pb-0'>
                  <div className='flex flex-col gap-2 px-5 lg:px-4'>
                    <p className='font-Manrope text-sm font-bold leading-5 text-mainText'>Date Range</p>
                    <div className='flex flex-col gap-2 md:justify-between md:gap-3'>
                      <FormSelect
                        placeholder='Select date range'
                        value={dateRangeSet}
                        handleChange={handleSelectDateRange}
                        options={DATE_RANGE_DROP_DOWN}
                        className='date-range-select gap-2'
                        triggerClassName='w-full lg:w-[12.625rem]'
                        id={'date-range-select'}
                      />
                      <div className='flex w-full gap-1'>
                        <BaseDatePicker
                          className='w-full justify-start disabled:!bg-transparent disabled:!text-disableButton lg:w-[6.25rem] [&>span]:disabled:!text-disableButton'
                          date={dateRangeStart}
                          setDate={setDateRangeStart}
                          disabled={dateRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                        />
                        <BaseDatePicker
                          className='w-full justify-start disabled:!bg-transparent disabled:!text-disableButton lg:w-[6.25rem] [&>span]:disabled:!text-disableButton'
                          date={dateRangeEnd}
                          setDate={setDateRangeEnd}
                          disabled={dateRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='flex flex-col gap-2 px-5 lg:px-4'>
                    <p className='font-Manrope text-sm font-bold leading-5 text-mainText'>Compare To</p>
                    <div className='flex flex-col gap-2 md:justify-between md:gap-3'>
                      <FormSelect
                        placeholder='Select date range'
                        value={compareRangeSet}
                        handleChange={handleSelectCompareRange}
                        options={DATE_RANGE_DROP_DOWN}
                        className='date-range-select gap-2'
                        triggerClassName='w-full lg:w-[12.625rem]'
                        id={'compare-range-select'}
                      />
                      <div className='flex w-full gap-1'>
                        <BaseDatePicker
                          className='w-full justify-start disabled:!bg-transparent disabled:!text-disableButton lg:w-[6.25rem] [&>span]:disabled:!text-disableButton'
                          date={compareRangeStart}
                          setDate={setCompareRangeStart}
                          disabled={compareRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                        />
                        <BaseDatePicker
                          className='w-full justify-start disabled:!bg-transparent disabled:!text-disableButton lg:w-[6.25rem] [&>span]:disabled:!text-disableButton'
                          date={compareRangeEnd}
                          setDate={setCompareRangeEnd}
                          disabled={compareRangeSet !== DATE_RANGE_OPTIONS.CUSTOM}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='flex items-center justify-between gap-2 border-t border-secondaryStrokeButton px-5 py-4 lg:px-4 lg:py-3'>
                  <Button
                    size='xs'
                    variant='tertiary'
                    className='w-[8.75rem] lg:w-[6.125rem]'
                    onClick={handleClearRangeDateFilter}
                  >
                    Clear Filter
                  </Button>
                  <Button size='xs' onClick={handleRangeDateFilter} className='w-[8.75rem] lg:w-[6.125rem]'>
                    Apply Filter
                  </Button>
                </div>
              </div>
            ) : (
              <div className='mt-4 flex justify-center gap-2 lg:justify-end'>
                <Button size='xs' variant='tertiary' className='w-[6.125rem]' onClick={handleClearRangeDateFilter}>
                  Clear Filter
                </Button>
                <Button size='xs' className='w-[6.125rem]' onClick={handleRangeDateFilter}>
                  Apply Filter
                </Button>
              </div>
            )}
          </div>
        }
        trigger='click'
      >
        <Button variant='tertiary' className='w-full justify-start border-secondaryTextButton xxl:w-[15rem]'>
          <CalendarDays className='h-5 w-5' color={COLORS.ICON_COLOR} />
          {date?.from ? (
            date.to ? (
              <>
                {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
              </>
            ) : (
              format(date.from, 'LLL dd, y')
            )
          ) : (
            <span>{placeholder}</span>
          )}
        </Button>
      </Popover>
    </div>
  );
}
