import { Button, FormInput, ImageCrop, ImageUpload, Loader } from '@common/components';
import { getFile, handleSave } from '@utils';
import { CREATE_HOME_ORGANIZATION_BUTTON, CREATED_ORGANIZATION, LOGO_ID, ORGANIZATION_NAME_ID } from '@constants';
import { ImagePlaceholderIcon } from '@assets/svgs';

export function AddEditOrgDialog({
  dialogTitle,
  formik,
  imageRef,
  uploadedLogo,
  icon,
  handleDeleteBtn,
  isUploadedLogo,
  organizationLoading,
  organizationId,
  handleImageUpload,
  setIsUploadedLogo,
  defaultOrganizationId,
  manageOrganizationFetching,
  imageLoading = false,
  isInvitedOrganization = false,
}) {
  const { initialValues, values, setFieldValue, touched, errors, submitForm, dirty } = formik;

  return (
    <div id='org-modal' className='flex flex-col gap-4 md:gap-6'>
      <div className='font-Manrope text-xl font-bold leading-7 text-mainText md:text-2xl md:leading-[2.125rem]'>
        {isUploadedLogo ? 'Crop Image' : dialogTitle}
      </div>
      {!isUploadedLogo && (
        <>
          <div className='flex flex-col gap-4'>
            <label className='font-Manrope text-xs leading-4 text-labelText md:text-sm md:leading-5'>
              Organization Logo
            </label>
            <ImageUpload
              id={LOGO_ID}
              icon={icon}
              imageRef={imageRef}
              placeHolderIcon={<ImagePlaceholderIcon className='h-[6.25rem] w-[6.25rem]' />}
              className='h-[6.25rem] w-[6.25rem]'
              profileImage={uploadedLogo?.image || values?.logo}
              handleChange={e => getFile(e.target.files[0], 'logo', setFieldValue, setIsUploadedLogo)}
              inputWrapperClassName='bg-highlighterText1'
              showSkeletonLoader={manageOrganizationFetching || isUploadedLogo}
            />
          </div>
          <div id='org-input-div'>
            <FormInput
              id={ORGANIZATION_NAME_ID}
              labelText='Name'
              placeholder='Enter organization name'
              value={manageOrganizationFetching ? '' : values.organizationName}
              handleChange={e => setFieldValue('organizationName', e.target.value)}
              showError={touched.organizationName && errors.organizationName}
              labelClassName='text-sm leading-5 text-labelText'
              inputClassName=''
              className='gap-1'
            />
          </div>
          <div className='flex flex-col-reverse gap-3 md:gap-4'>
            {!isInvitedOrganization && (
              <Button
                className={dialogTitle === CREATED_ORGANIZATION ? 'hidden' : 'block'}
                variant='destructiveSecondary'
                size='lg'
                onClick={defaultOrganizationId !== Number(organizationId) && handleDeleteBtn}
                id='deleteOrganizationBtn'
                disabled={defaultOrganizationId === Number(organizationId) || isUploadedLogo || organizationLoading}
              >
                Delete Organization
              </Button>
            )}
            <Button
              id={CREATE_HOME_ORGANIZATION_BUTTON}
              onClick={submitForm}
              className='w-full font-Manrope'
              size='lg'
              disabled={isUploadedLogo || organizationLoading || !dirty}
            >
              {dialogTitle === CREATED_ORGANIZATION ? 'Create' : 'Save Changes'}
              {organizationLoading ? <Loader /> : ''}
            </Button>
          </div>
        </>
      )}
      {isUploadedLogo && (
        <ImageCrop
          uploadedImage={values?.logo}
          setIsUploaded={setIsUploadedLogo}
          handleCancel={() => {
            setIsUploadedLogo(false);
            setFieldValue('logo', initialValues.logo);
            imageRef.current.value = initialValues.logo;
          }}
          imageRef={imageRef}
          handleSave={file => handleSave(file, handleImageUpload)}
          imageLoading={imageLoading}
          onBackButtonClick={() => {
            setIsUploadedLogo(false);
            setFieldValue('logo', initialValues.logo);
            imageRef.current.value = initialValues.logo;
          }}
        />
      )}
    </div>
  );
}
