import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Popover, Skeleton } from 'antd';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery } from '@services';
import { clearLocalStorageItem, setLocalStorageItem } from '@utils';
import { DEFAULT_ORGANIZATION_ID } from '@constants';
import { ProfileIcon, LogoutIcon } from '@assets/svgs';

export function ProfileDropdown() {
  const { data: userDetail, isLoading: userDetailFetching } = useGetQuery(
    'user-detail',
    apiEndpoints.USER_DETAIL,
    {},
    {
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: resp => setLocalStorageItem(DEFAULT_ORGANIZATION_ID, resp?.data?.defaultOrganization),
    }
  );

  const [visible, setVisible] = useState(false);

  const handleLogout = () => {
    clearLocalStorageItem();
    window.location.reload();
  };

  const closePopover = () => setVisible(false);

  const content = (
    <div className='flex w-32 flex-col gap-0.5 rounded-xl md:w-[10.75rem]'>
      <NavLink onClick={closePopover} className='flex w-full items-center gap-2' to={ROUTES.PROFILE}>
        <div className='flex w-full cursor-pointer items-center gap-2 rounded-lg px-2 py-1.5 font-Manrope text-mainText hover:bg-shade4 md:px-3 md:py-2'>
          <ProfileIcon className='h-5 w-5' />
          My Profile
        </div>
      </NavLink>
      <div
        onClick={handleLogout}
        className='flex cursor-pointer items-center gap-2 rounded-lg px-2 py-1.5 font-Manrope text-mainText hover:bg-shade4 md:px-3 md:py-2'
      >
        <LogoutIcon className='h-5 w-5' />
        Log Out
      </div>
    </div>
  );

  return (
    <Popover
      placement='bottomLeft'
      trigger='click'
      open={visible}
      onOpenChange={status => setVisible(status)}
      content={content}
      arrow={false}
    >
      <div className='cursor-pointer'>
        <div className='h-10 w-10 overflow-hidden rounded-full md:h-12 md:w-12'>
          {userDetailFetching ? (
            <Skeleton.Avatar active size={48} shape='circle' />
          ) : (
            <img
              src={userDetail?.data?.profileImage?.image}
              alt='profile'
              className='h-full w-full rounded-full object-cover'
            />
          )}
        </div>
      </div>
    </Popover>
  );
}
