export function ImageUploaderIcon() {
  return (
    <svg width='96' height='96' viewBox='0 0 96 96' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M96 48C96 74.5097 74.5097 96 48 96C21.4903 96 0 74.5097 0 48C0 21.4903 21.4903 0 48 0C74.5097 0 96 21.4903 96 48Z'
        fill='#E1E1E1'
      />
      <path
        d='M17.723 85.2485C20.3206 72.4652 32.888 62.7692 48 62.7692C63.112 62.7692 75.6794 72.4652 78.277 85.2485C70.0174 91.9704 59.4793 96 48 96C36.5207 96 25.9826 91.9704 17.723 85.2485Z'
        fill='white'
      />
      <path
        d='M62.7692 41.5385C62.7692 49.8652 56.0191 56.6154 47.6923 56.6154C39.3656 56.6154 32.6154 49.8652 32.6154 41.5385C32.6154 33.2117 39.3656 26.4615 47.6923 26.4615C56.0191 26.4615 62.7692 33.2117 62.7692 41.5385Z'
        fill='white'
      />
    </svg>
  );
}
