export function EmptyInvitation({ className }) {
  return (
    <svg
      width='160'
      height='160'
      viewBox='0 0 160 160'
      fill='none'
      className={`${className}`}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M80 159.999C124.183 159.999 160 124.182 160 79.9997C160 35.8171 124.183 0 80 0C35.8173 0 0.000244141 35.8171 0.000244141 79.9997C0.000244141 124.182 35.8173 159.999 80 159.999Z'
        fill='url(#paint0_linear_10865_8064)'
      />
      <path
        d='M128 160H32.0002V56.5334C36.525 56.5283 40.863 54.7286 44.0625 51.5291C47.2621 48.3296 49.0618 43.9916 49.0669 39.4668H110.933C110.929 41.7083 111.368 43.9284 112.227 45.9988C113.086 48.0692 114.347 49.9487 115.937 51.5286C117.517 53.1191 119.396 54.3805 121.467 55.2395C123.538 56.0986 125.758 56.5384 128 56.5334V160Z'
        fill='white'
      />
      <path
        d='M80.0001 108.8C94.1385 108.8 105.6 97.3385 105.6 83.2C105.6 69.0616 94.1385 57.6001 80.0001 57.6001C65.8616 57.6001 54.4001 69.0616 54.4001 83.2C54.4001 97.3385 65.8616 108.8 80.0001 108.8Z'
        fill='#3FB79A'
      />
      <path
        d='M89.0512 95.2671L80.0002 86.2162L70.9493 95.2671L67.9323 92.2501L76.9833 83.1992L67.9323 74.1482L70.9493 71.1313L80.0002 80.1822L89.0512 71.1313L92.0682 74.1482L83.0172 83.1992L92.0682 92.2501L89.0512 95.2671Z'
        fill='white'
      />
      <path
        d='M93.8659 115.2H66.1326C64.3653 115.2 62.9326 116.633 62.9326 118.4C62.9326 120.167 64.3653 121.6 66.1326 121.6H93.8659C95.6332 121.6 97.0658 120.167 97.0658 118.4C97.0658 116.633 95.6332 115.2 93.8659 115.2Z'
        fill='#8CD4C2'
      />
      <path
        d='M103.467 128H56.534C54.7667 128 53.334 129.433 53.334 131.2C53.334 132.967 54.7667 134.4 56.534 134.4H103.467C105.234 134.4 106.667 132.967 106.667 131.2C106.667 129.433 105.234 128 103.467 128Z'
        fill='#E2F8F4'
      />
      <defs>
        <linearGradient
          id='paint0_linear_10865_8064'
          x1='80'
          y1='0'
          x2='80'
          y2='159.999'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7F9F3' />
          <stop offset='1' stopColor='#E2F8F4' />
        </linearGradient>
      </defs>
    </svg>
  );
}
