import { Tooltip } from 'antd';
import { COLORS } from '@constants';

export const SharedTooltip = ({
  title,
  placement = 'right',
  color = COLORS.WHITE_COLOR,
  mouseEnterDelay = 0.01,
  children,
}) => {
  return (
    <Tooltip
      mouseEnterDelay={mouseEnterDelay}
      placement={placement}
      title={title}
      color={color}
      overlayInnerStyle={{
        color: COLORS.BODY_TEXT_COLOR,
      }}
    >
      {children}
    </Tooltip>
  );
};
