import { GripVertical } from 'lucide-react';
import { BaseLoader } from '@common/components';
import { useStaticWidgets } from '@pages/dashboard';
import { capitalizeString } from '@utils';
import { COLORS, STATIC_WIDGETS_ID, WIDGETS_TYPE } from '@constants';

export function DashboardStaticWidgets({ handleOnDrag, isEditMode }) {
  const { staticWidgets, staticWidgetsFetching } = useStaticWidgets(isEditMode);

  return (
    <div className='h-[calc(100vh-26rem)]'>
      {staticWidgetsFetching ? (
        <BaseLoader />
      ) : (
        <div className='flex flex-col gap-4 pb-4' id={STATIC_WIDGETS_ID}>
          {staticWidgets?.data?.results.map(widget => (
            <div
              key={widget?.id}
              className='flex cursor-grab items-center gap-3.5 rounded-xl border border-secondaryStrokeButton px-4 py-3'
              draggable
              onDragStart={e => handleOnDrag(e, widget)}
            >
              <span>
                <GripVertical color={COLORS.BUTTON_DISABLE} />
              </span>
              <div className='flex flex-col gap-1'>
                <div className='flex items-center gap-2'>
                  <span className='flex h-[18px] w-[18px] items-center justify-center'>
                    <img src={widget?.icon?.image} alt='Widget Icon' />
                  </span>
                  <h3 className='font-Manrope text-base font-bold leading-5 text-mainText'>
                    {widget?.type === WIDGETS_TYPE.CSV ? widget?.type : capitalizeString(widget?.type)}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
