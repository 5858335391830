import { useState } from 'react';
import { Calendar } from '@shadcnComponent';
import { Popover } from 'antd';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { CalendarDays } from 'lucide-react';
import { Button } from '@common/components';
import { COLORS } from '@constants';

export function SharedDashboardDatePicker({
  className,
  placeholder,
  handleRangeDateFilter,
  handleClearRangeDateFilter,
}) {
  const [date, setDate] = useState();
  const [showCalendar, setShowCalendar] = useState(false);

  return (
    <div className={`grid gap-2 ${className}`}>
      <Popover
        open={showCalendar}
        onOpenChange={status => setShowCalendar(status)}
        overlayClassName='calendar-popover'
        placement='bottomLeft'
        content={
          <div className='flex w-[auto] flex-col'>
            <Calendar
              initialFocus
              mode='range'
              defaultMonth={date?.from}
              selected={date}
              onSelect={setDate}
              numberOfMonths={2}
              today={false}
            />
            <div className='flex justify-end gap-2 p-6 lg:gap-2.5 xxl:gap-2.5'>
              <Button
                variant='tertiary'
                className='w-[6.125rem]'
                disabled={isEmpty(date)}
                onClick={() => {
                  handleClearRangeDateFilter();
                  setDate();
                  setShowCalendar(false);
                }}
              >
                Clear Filter
              </Button>
              <Button
                className='w-[6.125rem]'
                disabled={isEmpty(date)}
                onClick={() => {
                  handleRangeDateFilter(date);
                  setShowCalendar(false);
                }}
              >
                Apply Filter
              </Button>
            </div>
          </div>
        }
        trigger='click'
      >
        <Button variant='tertiary' className='w-[15rem] justify-start border-secondaryTextButton'>
          <CalendarDays className='h-5 w-5' color={COLORS.ICON_COLOR} />
          {date?.from ? (
            date.to ? (
              <>
                {format(date.from, 'LLL dd, y')} - {format(date.to, 'LLL dd, y')}
              </>
            ) : (
              format(date.from, 'LLL dd, y')
            )
          ) : (
            <span>{placeholder}</span>
          )}
        </Button>
      </Popover>
    </div>
  );
}
