import { Tooltip } from 'react-tooltip';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';
import { TooltipIcon } from '@assets/svgs';
import 'react-tooltip/dist/react-tooltip.css';

export function WidgetTitle({ widget, titleText, hideTooltip, isSharedDashboard }) {
  return (
    <div>
      <span className='me-1.5 flex items-center gap-2'>
        <p
          className={`line-clamp font-semibold ${titleText || 'text-xl'} ${isSharedDashboard ? 'leading-[3rem]' : 'leading-7'}`}
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
              : { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }
          }
        >
          {widget?.displayLabel}
        </p>
        {widget?.tooltip?.length > 0 && (
          <span className='cursor-pointer' id={widget?.id}>
            <TooltipIcon
              strokeColor={
                widget?.themeColor === DEFAULT_VALUE
                  ? widget.themeStyle === THEME_STYLE.DARK
                    ? COLORS.WHITE_COLOR
                    : COLORS.DARK_PRIMARY
                  : widget.themeStyle === THEME_STYLE.DARK
                    ? COLORS.WHITE_COLOR
                    : COLORS.DARK_PRIMARY
              }
            />
          </span>
        )}
        {!hideTooltip && (
          <Tooltip
            className='z-[100] !max-w-[12.5rem] break-words !bg-highlighterText1 !opacity-[1]'
            anchorSelect={`#${widget?.id}`}
            content={widget?.tooltip}
            place='top'
          />
        )}
      </span>
      {widget?.widgetLabel?.length > 0 && (
        <p
          className={`font-medium ${titleText || 'leading-5.5 text-base'}`}
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
              : { color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY }
          }
        >
          {widget?.widgetLabel}
        </p>
      )}
    </div>
  );
}
