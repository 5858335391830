import { isEmpty } from 'lodash';
import { BaseLoader, Button } from '@common/components';
import { useEmailAcceptance } from '@pages/emailAcceptance';
import { capitalizeString } from '@utils';
import { InviteImage, Logo } from '@assets/svgs';

export function EmailAcceptance() {
  const { organizationInvitation, handleAcceptInvitation, expiredMessage, isLoading } = useEmailAcceptance();

  return isLoading ? (
    <BaseLoader />
  ) : (
    <div className='h-screen overflow-hidden bg-shade3 p-20'>
      <div className='min-h-37.5rem] relative h-full rounded-3xl border border-secondaryStrokeButton bg-white'>
        <div className='absolute left-14 top-[3.125rem]'>
          <Logo />
        </div>
        <div className='mx-auto flex h-full max-w-[22.25rem] flex-col items-center justify-center gap-5'>
          <InviteImage />
          {expiredMessage ? (
            <h2 className='text-center text-base text-mainText'>{expiredMessage}</h2>
          ) : !isEmpty(organizationInvitation?.data) ? (
            <div className='flex flex-col gap-2'>
              <h2 className='text-center font-Manrope text-xl font-semibold text-mainText'>
                {organizationInvitation?.data?.invitedBy} has invited you to join{' '}
                {organizationInvitation?.data?.organization?.name} on Zendash.
              </h2>
              <p className='text-center font-Manrope text-xs leading-5 text-bodyText'>
                Accept {organizationInvitation?.data?.invitedBy} invitation to join{' '}
                {organizationInvitation?.data?.organization?.name} as a{' '}
                {capitalizeString(organizationInvitation?.data?.role)}.
              </p>
            </div>
          ) : (
            <h2 className='text-center font-Manrope text-sm leading-5 text-bodyText'>This invite has been accepted.</h2>
          )}
          {!isEmpty(organizationInvitation?.data) && (
            <Button size='lg' onClick={handleAcceptInvitation} className='w-[17.875rem]'>
              Accept Invitation
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
