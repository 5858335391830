import { useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { Drawer, Modal, Skeleton } from 'antd';
import { isEmpty, truncate } from 'lodash';
import { Expand, Plus, X } from 'lucide-react';
import { BaseLoader, Button, Chatbot, DeleteDialog, Loader, TourButton } from '@common/components';
import {
  DashboardComments,
  WidgetsSidebar,
  useComments,
  useDashboard,
  useShareDashboard,
  DashboardWidgetsLayout,
  DashboardHeader,
  DashboardSlideWidgets,
  GoalTrackingSidebar,
  SaveTemplateDialog,
  CreateDashboardStep2,
  useAddWidgetTour,
} from '@pages/dashboard';
import { useManageOrganization } from '@pages/manageOrganization';
import { useMembershipTab } from '@pages/profile';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery } from '@services';
import { getLocalStorageItem } from '@utils';
import { ADD_WIDGETS_BUTTON_ID, COLORS, DASHBOARD_VIEW_TYPE, INDIVIDUAL_PLAN } from '@constants';
import CoinImage from '@assets/images/coin.png';
import { DashboardEmptyScreen, GradientSparkleIcon } from '@assets/svgs';

export function Dashboard() {
  const {
    isEditMode,
    setIsEditMode,
    isCommentMode,
    setIsCommentMode,
    handleGridLayoutChange,
    handleOnDrag,
    handleOnDrop,
    handleDragOver,
    handleDragLeave,
    logoRef,
    uploadedLogo,
    setIsUploadedLogo,
    isUploadedLogo,
    dashboardName,
    setDashboardName,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDashboardLoading,
    setDeleteDashboardLoading,
    handleDeleteDashboard,
    dashboard,
    dashboardFetching,
    removeLayoutWidget,
    handleTextChange,
    handleVideoInputChange,
    dateRange,
    handleRangeDateFilter,
    showDateTimeFilter,
    setShowDateTimeFilter,
    handleClearRangeDateFilter,
    setIsDateCleared,
    dateRangeSet,
    handleSelectDateRange,
    dateRangeStart,
    setDateRangeStart,
    dateRangeEnd,
    setDateRangeEnd,
    compareRangeSet,
    handleSelectCompareRange,
    compareRangeEnd,
    compareRangeStart,
    setCompareRangeEnd,
    setCompareRangeStart,
    handleDateRange,
    page,
    handleTablePagination,
    setLayoutWidth,
    isFilterApply,
    showAddDataSourceDialog,
    setShowAddDataSourceDialog,
    addedDataSource,
    handleSelectedDataSource,
    addDataSourceListing,
    selectedDataSourceIds,
    handleAddDataSource,
    addDataSourceLoading,
    layoutWidth,
    dropZonePosition,
    droppedWidget,
    isEditWidgetMode,
    setIsEditWidgetMode,
    summaryWidget,
    summaryWidgetFetching,
    handleClone,
    handleEditWidget,
    editWidget,
    setEditWidget,
    editWidgetDateRange,
    setEditWidgetDateRange,
    handleDateRangeChange,
    showDatePicker,
    setShowDatePicker,
    handleSelectEditWidgetDateRange,
    handleSelectEditWidgetCompareRange,
    handleEditClearRangeDateFilter,
    editWidgetDateRangeStart,
    editWidgetDateRangeEnd,
    editWidgetDateRangeSet,
    editWidgetCompareRangeStart,
    editWidgetCompareRangeEnd,
    editWidgetCompareRangeSet,
    setEditWidgetDateRangeStart,
    setEditWidgetDateRangeEnd,
    setEditWidgetCompareRangeStart,
    setEditWidgetCompareRangeEnd,
    handleEditWidgetRangeDateFilter,
    widgetDimension,
    widgetDimensionRefetch,
    handleImageInputChange,
    widgetImageRef,
    handleCsvInputChange,
    csvRef,
    selectSliderWidget,
    setSelectSliderWidget,
    addDashboardSlide,
    organizationId,
    dashboardId,
    handleRemoveSlide,
    isUploadedWidgetCsv,
    handleLogoUpload,
    isUploadedWidgetImage,
    handleEditCommentMode,
    commentedWidget,
    setCommentedWidget,
    handleCommentUpdate,
    csvPage,
    saveAsTemplateLoading,
    showTemplateDialog,
    setShowTemplateDialog,
    templateCategory,
    templateCategoryFetching,
    templateCategoryNextPage,
    templateCategoryHasNextPage,
    removeWidgetSlide,
    setRemoveWidgetSlide,
    isGoalTrackingMode,
    setIsGoalTrackingMode,
    showGoalDialog,
    setShowGoalDialog,
    createFormik,
    dashboardsListing,
    widgetListing,
    createGoalLoading,
    showDashboardGoalDialog,
    setShowDashboardGoalDialog,
    saveAsTemplateFormik,
    setSelectedDataSourceIds,
    summaryWidgetRefetch,
    queryParams,
    editWidgetCurrentTab,
    setEditWidgetCurrentTab,
    widgetDimensionFetching,
    dashboardEditLoading,
    handleDashboardUpdate,
    slideWidgetsLoading,
    setSlideWidgetsLoading,
  } = useDashboard();

  const { manageOrganization } = useManageOrganization();

  const {
    commentsListing,
    formik,
    createCommentLoading,
    scrollContainerRef,
    handleScroll,
    resetForm,
    tagUsersList,
    isUsersFetching,
    userDetail,
    commentsListingFetching,
  } = useComments(isCommentMode, commentedWidget);

  const { data: loggedUserDetails } = useGetQuery(['user-detail'], apiEndpoints.USER_DETAIL);

  const { sharedDashboardURL, shareDashboardUrlFetching, shareDashboardUrlRefetch, copyToClipboard, isCopied } =
    useShareDashboard();

  const { state } = useLocation();
  const navigate = useNavigate();
  const [showTokensModal, setShowTokensModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);

  const showAiToken = getLocalStorageItem('showAiToken');

  const { membershipPlan } = useMembershipTab();
  const { addWidgetTour, isTourActive, setIsTourActive } = useAddWidgetTour();

  const isNormalView = dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.NORMAL;
  const truncatedSummary = isExpanded ? summaryWidget : truncate(summaryWidget, { length: 350, omission: '...' });

  let findMembershipPlan = membershipPlan?.data?.results?.find(m => m.isActiveMembership === true)?.nickname;
  const showAiTokenModalHandler =
    loggedUserDetails?.data?.isSubscribed &&
    findMembershipPlan !== INDIVIDUAL_PLAN &&
    manageOrganization?.data?.isTokenLeft;

  return (
    <div className='relative h-full overflow-hidden'>
      {dashboardFetching ? (
        <BaseLoader />
      ) : (
        <>
          {!isEditMode && (
            <DashboardHeader
              uploadedLogo={uploadedLogo}
              isUploadedLogo={isUploadedLogo}
              dashboardName={dashboardName}
              isCommentMode={isCommentMode}
              isEditMode={isEditMode}
              dashboard={dashboard}
              setIsEditMode={setIsEditMode}
              setIsCommentMode={setIsCommentMode}
              setIsEditWidgetMode={setIsEditWidgetMode}
              setEditWidget={setEditWidget}
              setIsDateCleared={setIsDateCleared}
              dateRange={dateRange}
              handleDateRange={handleDateRange}
              handleRangeDateFilter={handleRangeDateFilter}
              showDateTimeFilter={showDateTimeFilter}
              setShowDateTimeFilter={setShowDateTimeFilter}
              handleClearRangeDateFilter={handleClearRangeDateFilter}
              dateRangeSet={dateRangeSet}
              handleSelectDateRange={handleSelectDateRange}
              dateRangeStart={dateRangeStart}
              setDateRangeStart={setDateRangeStart}
              dateRangeEnd={dateRangeEnd}
              setDateRangeEnd={setDateRangeEnd}
              compareRangeSet={compareRangeSet}
              handleSelectCompareRange={handleSelectCompareRange}
              compareRangeEnd={compareRangeEnd}
              compareRangeStart={compareRangeStart}
              setCompareRangeEnd={setCompareRangeEnd}
              setCompareRangeStart={setCompareRangeStart}
              resetForm={resetForm}
              shareDashboardUrlRefetch={shareDashboardUrlRefetch}
              shareDashboardUrlFetching={shareDashboardUrlFetching}
              copyToClipboard={copyToClipboard}
              isCopied={isCopied}
              sharedDashboardURL={sharedDashboardURL}
              organizationId={organizationId}
              dashboardId={dashboardId}
              setCommentedWidget={setCommentedWidget}
              isEditWidgetMode={isEditWidgetMode}
              setShowTemplateDialog={setShowTemplateDialog}
              isGoalTrackingMode={isGoalTrackingMode}
              setIsGoalTrackingMode={setIsGoalTrackingMode}
              page={state?.page}
              viewType={state?.viewType}
              saveAsTemplateFormik={saveAsTemplateFormik}
              widgetListing={widgetListing}
              dashboardsListing={dashboardsListing}
              handleOnDrag={handleOnDrag}
            />
          )}
          <WidgetsSidebar
            isTourActive={isTourActive}
            dashboardEditLoading={dashboardEditLoading}
            handleOnDrag={handleOnDrag}
            setIsEditMode={setIsEditMode}
            isEditMode={isEditMode}
            logoRef={logoRef}
            uploadedLogo={uploadedLogo}
            isUploadedLogo={isUploadedLogo}
            setIsUploadedLogo={setIsUploadedLogo}
            handleLogoUpload={handleLogoUpload}
            dashboardName={dashboardName}
            setDashboardName={setDashboardName}
            setShowDeleteDialog={setShowDeleteDialog}
            isEditWidgetMode={isEditWidgetMode}
            editWidget={editWidget}
            setEditWidget={setEditWidget}
            setIsEditWidgetMode={setIsEditWidgetMode}
            handleDateRangeChange={handleDateRangeChange}
            editWidgetDateRange={editWidgetDateRange}
            setEditWidgetDateRange={setEditWidgetDateRange}
            showDatePicker={showDatePicker}
            setShowDatePicker={setShowDatePicker}
            handleSelectEditWidgetDateRange={handleSelectEditWidgetDateRange}
            handleSelectEditWidgetCompareRange={handleSelectEditWidgetCompareRange}
            handleEditClearRangeDateFilter={handleEditClearRangeDateFilter}
            editWidgetDateRangeStart={editWidgetDateRangeStart}
            editWidgetDateRangeEnd={editWidgetDateRangeEnd}
            editWidgetDateRangeSet={editWidgetDateRangeSet}
            editWidgetCompareRangeStart={editWidgetCompareRangeStart}
            editWidgetCompareRangeEnd={editWidgetCompareRangeEnd}
            editWidgetCompareRangeSet={editWidgetCompareRangeSet}
            setEditWidgetDateRangeStart={setEditWidgetDateRangeStart}
            setEditWidgetDateRangeEnd={setEditWidgetDateRangeEnd}
            setEditWidgetCompareRangeStart={setEditWidgetCompareRangeStart}
            setEditWidgetCompareRangeEnd={setEditWidgetCompareRangeEnd}
            handleEditWidgetRangeDateFilter={handleEditWidgetRangeDateFilter}
            widgetDimension={widgetDimension}
            widgetDimensionRefetch={widgetDimensionRefetch}
            handleCommentUpdate={handleCommentUpdate}
            setIsCommentMode={setIsCommentMode}
            setIsGoalTrackingMode={setIsGoalTrackingMode}
            setShowAddDataSourceDialog={setShowAddDataSourceDialog}
            setSelectedDataSourceIds={setSelectedDataSourceIds}
            dashboard={dashboard}
            editWidgetCurrentTab={editWidgetCurrentTab}
            setEditWidgetCurrentTab={setEditWidgetCurrentTab}
            widgetDimensionFetching={widgetDimensionFetching}
          />

          {/* Save as template modal */}
          <Modal
            footer={null}
            className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
            closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
            open={showTemplateDialog}
            onCancel={() => setShowTemplateDialog(false)}
            centered={true}
          >
            <SaveTemplateDialog
              isLoading={saveAsTemplateLoading}
              templateCategory={templateCategory}
              templateCategoryFetching={templateCategoryFetching}
              templateCategoryNextPage={templateCategoryNextPage}
              templateCategoryHasNextPage={templateCategoryHasNextPage}
              formik={saveAsTemplateFormik}
            />
          </Modal>

          {/* Delete Dashboard modal  */}
          <Modal
            footer={null}
            width={420}
            closeIcon={<X color={COLORS.ICON_COLOR} />}
            open={showDeleteDialog}
            onCancel={() => setShowDeleteDialog(false)}
            centered={true}
          >
            <DeleteDialog
              text={dashboardName}
              textClass='!mb-4'
              deleteBtnClick={() => {
                handleDeleteDashboard();
                setDeleteDashboardLoading(true);
              }}
              goBackBtnClick={() => setShowDeleteDialog(false)}
              isLoading={deleteDashboardLoading}
            />
          </Modal>

          {/* Edit Data source drawer */}
          <Drawer
            open={showAddDataSourceDialog}
            onClose={() => {
              setShowAddDataSourceDialog(false);
              setSelectedDataSourceIds([]);
            }}
            width={500}
            title='Edit data source'
            className='dashboard-data-source-drawer rounded-l-[1.25rem]'
            closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
            footer={
              <div className='ml-auto lg:w-[11rem]'>
                <Button
                  className='h-12 md:h-10 lg:h-8 lg:text-[.625rem] lg:leading-3 xxl:h-10 xxl:text-sm xxl:leading-6'
                  disabled={isEmpty(selectedDataSourceIds) || addDataSourceLoading}
                  onClick={() => {
                    handleAddDataSource({
                      payload: { dataSources: selectedDataSourceIds },
                    });
                  }}
                >
                  Save {addDataSourceLoading ? <Loader /> : ''}
                </Button>
              </div>
            }
          >
            <div className='flex h-full'>
              <div className='w-full p-8'>
                <CreateDashboardStep2
                  addDataSourceListing={addDataSourceListing}
                  handleCheck={handleSelectedDataSource}
                  addedDataSource={addedDataSource}
                  selectedDataSourceIds={selectedDataSourceIds}
                />
              </div>
            </div>
          </Drawer>

          {isEmpty(dashboard?.data?.dashboardConfig) ? (
            <div
              className={`flex h-[calc(100vh-10rem)] w-full flex-col items-center justify-center transition-all ease-in ${isEditMode ? 'left-[40%] top-[50%] origin-top-left scale-[.9]' : ''}`}
              onDrop={handleOnDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <div className='flex max-w-[20.25rem] flex-col items-center'>
                <DashboardEmptyScreen />
                <span className='mb-3 mt-10 text-2xl font-semibold text-mainText '>Start Adding Widgets</span>
                <span className='text-center text-sm leading-5 text-bodyText'>
                  Start building your dashboard section by dragging widgets here
                </span>
              </div>
            </div>
          ) : (
            <div className={'relative flex h-full'}>
              <div
                className={`w-full overflow-y-auto p-5 scrollbar-none md:p-8 ${![isEditMode].includes(true) && 'pb-[120px]'}`}
                onDrop={handleOnDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
              >
                {[isEditMode, isGoalTrackingMode, isEditWidgetMode].includes(true) ? (
                  ''
                ) : (
                  <div
                    className={`widget-container relative ${[isCommentMode].includes(true) ? (isNormalView ? 'w-[58.681vw]' : 'w-[42%]') : 'w-full'}`}
                  >
                    <div className='relative mb-5 flex flex-col gap-2 overflow-hidden rounded-2xl border-t-4 border-t-highlighterText1 bg-white p-6'>
                      <div className='flex w-full flex-col items-start justify-between gap-4 md:flex-row md:items-center lg:gap-6'>
                        <div className='flex w-full flex-col gap-2'>
                          <h3 className='font-Manrope text-sm font-semibold leading-5 text-mainText md:text-xl md:leading-normal'>
                            All Insights
                          </h3>
                          {summaryWidgetFetching ? (
                            <Skeleton
                              active
                              title={false}
                              round
                              className='summary-skeleton'
                              paragraph={{ rows: 2, width: '80%', margin: 0 }}
                            />
                          ) : (
                            <div className='ai-insights text-bodyText'>
                              {summaryWidget ? (
                                <div className='text-bodyText' dangerouslySetInnerHTML={{ __html: truncatedSummary }} />
                              ) : (
                                <p className='static-text'>
                                  Get automatic summaries of user interactions on your dashboard, analyzing navigation
                                  patterns and feedback for deeper insights.
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                        <Button
                          className={`w-full text-xs md:w-[10rem] ${summaryWidgetFetching && 'pointer-events-none'}}`}
                          variant='outline'
                          onClick={() => {
                            if (showAiTokenModalHandler) summaryWidgetRefetch();
                            else setShowTokensModal(true);
                          }}
                        >
                          <GradientSparkleIcon />
                          Generate Insights
                        </Button>
                      </div>
                      {summaryWidget.length > 350 && (
                        <span
                          className={`flex cursor-pointer justify-center font-Manrope text-[1rem] font-semibold leading-[1.375rem] text-highlighterText1 ${isExpanded ? 'block' : 'none'}`}
                          onClick={toggleExpansion}
                        >
                          {isExpanded ? 'See less' : 'See more'}
                        </span>
                      )}
                    </div>
                    <span className='mx-auto mb-5 block h-1.5 w-20 rounded-[1.875rem] bg-secondaryStrokeButton'></span>
                  </div>
                )}
                <div
                  className={`flex gap-5 ${(isEditMode || isGoalTrackingMode) && isNormalView ? '' : 'overflow-y-hidden'} ${isNormalView ? 'min-h-[90vh]' : [isEditMode, isEditWidgetMode].includes(true) ? 'h-[86vh]' : 'h-[70vh]'}`}
                >
                  {dashboard?.data?.viewType === DASHBOARD_VIEW_TYPE.SLIDES ? (
                    <div
                      className={`${[isEditMode, isEditWidgetMode].includes(true) ? 'h-[86vh]' : 'h-[70vh]'} overflow-y-auto rounded-xl bg-highlighterText2 p-4 pr-0 ${isEditMode || isGoalTrackingMode ? '' : ''}`}
                    >
                      {isEditMode || isGoalTrackingMode ? (
                        <div className='border-b border-highlightedBorderColor pb-4 pr-4'>
                          <Button variant='secondary' onClick={addDashboardSlide}>
                            <Plus color='#fff' className='h-3.5 w-3.5' />
                            New slide
                          </Button>
                        </div>
                      ) : (
                        <div className='flex w-full items-center justify-between pr-4 text-lg font-semibold leading-[1.625rem] text-white'>
                          Slide Mode{' '}
                          <span
                            className='cursor-pointer'
                            onClick={() => {
                              navigate(
                                generatePath(ROUTES.DASHBOARD_FULL_VIEW, {
                                  organizationId,
                                  dashboardId,
                                  selectSliderWidget,
                                })
                              );
                            }}
                          >
                            <Expand color='#fff' className='h-5 w-5' />
                          </span>
                        </div>
                      )}
                      <div className={'flex flex-col justify-center gap-2 overflow-auto pt-7'}>
                        <DashboardSlideWidgets
                          dashboard={dashboard?.data?.dashboardConfig}
                          handleEditWidget={handleEditWidget}
                          handleClone={handleClone}
                          page={page}
                          csvPage={csvPage}
                          selectSliderWidget={selectSliderWidget}
                          setSelectSliderWidget={setSelectSliderWidget}
                          handleRemoveSlide={handleRemoveSlide}
                          removeWidgetSlide={removeWidgetSlide}
                          setRemoveWidgetSlide={setRemoveWidgetSlide}
                          compareRangeSet={compareRangeSet}
                          dateRangeSet={dateRangeSet}
                          isFilterApply={isFilterApply}
                          handleDashboardUpdate={handleDashboardUpdate}
                          setSlideWidgetsLoading={setSlideWidgetsLoading}
                        />
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                  <DashboardWidgetsLayout
                    dashboard={dashboard}
                    handleGridLayoutChange={handleGridLayoutChange}
                    layoutWidth={layoutWidth}
                    setLayoutWidth={setLayoutWidth}
                    isEditMode={isEditMode}
                    isGoalTrackingMode={isGoalTrackingMode}
                    isEditWidgetMode={isEditWidgetMode}
                    removeLayoutWidget={removeLayoutWidget}
                    handleEditWidget={handleEditWidget}
                    handleClone={handleClone}
                    compareRangeSet={compareRangeSet}
                    dateRangeSet={dateRangeSet}
                    isFilterApply={isFilterApply}
                    page={page}
                    handleTablePagination={handleTablePagination}
                    handleTextChange={handleTextChange}
                    handleVideoInputChange={handleVideoInputChange}
                    handleImageInputChange={handleImageInputChange}
                    widgetImageRef={widgetImageRef}
                    handleCsvInputChange={handleCsvInputChange}
                    csvRef={csvRef}
                    dropZonePosition={dropZonePosition}
                    droppedWidget={droppedWidget}
                    editWidget={editWidget}
                    selectSliderWidget={selectSliderWidget}
                    isUploadedWidgetCsv={isUploadedWidgetCsv}
                    isUploadedWidgetImage={isUploadedWidgetImage}
                    handleEditCommentMode={handleEditCommentMode}
                    csvPage={csvPage}
                    isCommentMode={isCommentMode}
                    showGoalDialog={showGoalDialog}
                    setShowGoalDialog={setShowGoalDialog}
                    createFormik={createFormik}
                    dashboardsListing={dashboardsListing}
                    widgetListing={widgetListing}
                    createGoalLoading={createGoalLoading}
                    isNormalView={isNormalView}
                    slideWidgetsLoading={slideWidgetsLoading}
                  />
                </div>
              </div>

              <GoalTrackingSidebar
                handleOnDrag={handleOnDrag}
                isGoalTrackingMode={isGoalTrackingMode}
                setIsGoalTrackingMode={setIsGoalTrackingMode}
                showDashboardGoalDialog={showDashboardGoalDialog}
                setShowDashboardGoalDialog={setShowDashboardGoalDialog}
                createFormik={createFormik}
                dashboardsListing={dashboardsListing}
                widgetListing={widgetListing}
                createGoalLoading={createGoalLoading}
              />
              {showAiToken && showAiTokenModalHandler && <Chatbot queryParams={queryParams} />}

              <Modal
                open={showTokensModal}
                footer={null}
                width={420}
                closeIcon={<X color={COLORS.ICON_COLOR} />}
                centered={true}
                onCancel={() => setShowTokensModal(false)}
              >
                <div className='flex flex-col items-center justify-center gap-6'>
                  <img src={CoinImage} alt='dashboard image' className='h-20 w-20' />
                  <h3 className='text-center text-3xl font-semibold leading-normal text-mainText'>
                    You don’t have enough Tokens
                  </h3>
                  <p className='text-center text-sm leading-5 text-bodyText '>
                    AI token limit reached. Please buy additional tokens to continue generating summaries.
                  </p>
                  <Button
                    className='text-xs'
                    onClick={() => {
                      setShowTokensModal(false);
                      navigate('/settings/aitokens-tab');
                    }}
                  >
                    Buy Tokens
                  </Button>
                </div>
              </Modal>
            </div>
          )}
          <DashboardComments
            isCommentMode={isCommentMode}
            setIsCommentMode={setIsCommentMode}
            commentsListing={commentsListing}
            formik={formik}
            createCommentLoading={createCommentLoading}
            scrollContainerRef={scrollContainerRef}
            handleScroll={handleScroll}
            isUsersFetching={isUsersFetching}
            tagUsersList={tagUsersList?.data?.results || []}
            userDetail={userDetail}
            isLoading={commentsListingFetching}
          />
        </>
      )}
      {!isCommentMode && dashboard?.data?.canEdit && (
        <TourButton
          className='bottom-[7.125rem] right-[2.4375rem] h-12 p-3 md:bottom-[9.75rem] md:right-12 md:h-16 md:p-4 lg:bottom-[6.25rem] lg:right-[2.875rem] lg:!h-10 lg:p-2.5 xxl:bottom-[8.5rem] xxl:right-[4.1875rem] xxl:!h-12 xxl:p-3'
          iconClass='h-6 w-6 md:h-8 md:w-8 lg:h-5 lg:w-5 xxl:h-6 xxl:w-6'
          Label='"Add Widgets"'
          id={ADD_WIDGETS_BUTTON_ID}
          onClick={() => {
            addWidgetTour().drive();
            setIsEditWidgetMode(false);
            setEditWidget({});
            setIsTourActive(true);
          }}
        />
      )}
    </div>
  );
}
