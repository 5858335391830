import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { useScreenshot, createFileName } from 'use-react-screenshot';
import { apiEndpoints, useGetQuery } from '@services';
import { DATE_FORMAT } from '@constants';

export function useShareDashboard() {
  const [dateRange, setDateRange] = useState();
  const [isDateCleared, setIsDateCleared] = useState();
  const [sharedDashboardURL, setSharedDashboardURL] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [page, setPage] = useState(1);
  const [tableId, setTableId] = useState();
  const [csvPage, setCsvPage] = useState(1);
  const [csvId, setCsvId] = useState();

  const { organizationId, dashboardId, uuid } = useParams();

  const sharedDashboardRef = useRef(null);

  const { refetch: shareDashboardUrlRefetch, isFetching: shareDashboardUrlFetching } = useGetQuery(
    'share-dashboard',
    apiEndpoints.GET_SHARE_DASHBOARD_URL(organizationId, dashboardId),
    {},
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: false,
      onSuccess: resp => setSharedDashboardURL(resp?.data?.shareLink),
    }
  );

  let queryParams = {};
  if (dateRange)
    queryParams = {
      startDate: moment(dateRange?.from).format(DATE_FORMAT.RANGE_DATE),
      endDate: moment(dateRange?.to).format(DATE_FORMAT.RANGE_DATE),
    };
  else if (!!page && !!tableId) {
    queryParams = {
      page,
      tableId,
    };
  } else if (!!csvPage && !!csvId) {
    queryParams = {
      csvPage,
      csvId,
    };
  } else {
    queryParams = {
      isInitial: true,
      startDate: moment().subtract(30, 'days').format(DATE_FORMAT.RANGE_DATE),
      endDate: moment().format(DATE_FORMAT.RANGE_DATE),
    };
  }

  const {
    data: shareDashboard,
    isLoading: shareDashboardLoading,
    refetch: shareDashboardRefetch,
  } = useGetQuery('shared-dashboard', apiEndpoints.GET_SHARED_DASHBOARD(uuid), queryParams, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: !!uuid,
  });

  const [image, takeScreenShot] = useScreenshot({
    type: 'image/png',
    quality: 1.0,
  });

  const download = image => {
    const a = document.createElement('a');
    a.href = image;
    a.download = createFileName('jpg', 'shared-dashboard');
    a.click();
    const datePickerDownloadBtnDiv = sharedDashboardRef.current.querySelector('#date-picker-downloadBtn');
    if (datePickerDownloadBtnDiv) datePickerDownloadBtnDiv.style.display = 'flex';
  };

  const downloadScreenshot = () => {
    const datePickerDownloadBtnDiv = sharedDashboardRef.current.querySelector('#date-picker-downloadBtn');
    if (datePickerDownloadBtnDiv) datePickerDownloadBtnDiv.style.display = 'none';

    takeScreenShot(sharedDashboardRef.current).then(download);
  };

  const handleRangeDateFilter = selectedDate => {
    setDateRange(selectedDate);
  };

  const handleClearRangeDateFilter = () => {
    setDateRange();
    setIsDateCleared(true);
  };

  const handleTablePagination = (page, tableId) => {
    setPage(page + 1);
    setTableId(tableId);
  };

  const handleCsvPagination = (page, csvTableId) => {
    setCsvPage(page + 1);
    setCsvId(csvTableId);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(sharedDashboardURL);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  useEffect(() => {
    isDateCleared && shareDashboardRefetch();
  }, [isDateCleared]);

  useEffect(() => {
    if (!!page && !!tableId) shareDashboardRefetch();
  }, [page, tableId]);

  useEffect(() => {
    if (!!csvPage && !!csvId) shareDashboardRefetch();
  }, [csvPage, csvId]);

  useEffect(() => {
    !isEmpty(dateRange) && shareDashboardRefetch();
  }, [dateRange]);

  return {
    image,
    sharedDashboardURL,
    shareDashboardUrlFetching,
    shareDashboardUrlRefetch,
    copyToClipboard,
    isCopied,
    setIsCopied,
    shareDashboard,
    shareDashboardLoading,
    handleRangeDateFilter,
    handleClearRangeDateFilter,
    sharedDashboardRef,
    downloadScreenshot,
    handleTablePagination,
    handleCsvPagination,
  };
}
