export const TEAMS_TAB_TOUR_ID = 'TEAMS_TAB_TOUR_ID';
export const CREATE_TEAM_BUTTON_ID = 'CREATE_TEAM_BUTTON_ID';
export const CREATE_TEAM_MODAL_BUTTON_ID = 'CREATE_TEAM_MODAL_BUTTON_ID';
export const CREATE_TEAM_NAME_ID = 'teamName';
export const CREATE_TEAM_PERMISSION_ID = 'permission';
export const CREATE_TEAM_PERMISSION_DROPDOWN_ID = 'CREATE_TEAM_PERMISSION_DROPDOWN_ID';
export const CREATE_TEAM_DASHBOARD_ID = 'dashboardsTeam';
export const CREATE_TEAM_DASHBOARD_DROPDOWN_ID = 'CREATE_TEAM_DASHBOARD_DROPDOWN_ID';
export const CREATE_TEAM_MEMBER_ID = 'members';
export const CREATE_TEAM_MEMBER_DROPDOWN_ID = 'CREATE_TEAM_MEMBER_DROPDOWN_ID';
export const CREATE_DASHBOARD_BUTTON_ID = 'CREATE_DASHBOARD_BUTTON_ID';
export const CREATE_DASHBOARD_LOGO_ID = 'CREATE_DASHBOARD_LOGO_ID';
export const CREATE_DASHBOARD_LOGO_TYPE_ID = 'CREATE_DASHBOARD_LOGO_TYPE_ID';
export const CREATE_DASHBOARD_LOGO_UPLOAD = 'CREATE_DASHBOARD_LOGO_UPLOAD';
export const CREATE_DASHBOARD_NAME_ID = 'name';
export const CREATE_DASHBOARD_DOMAIN_ID = 'domain';
export const CREATE_DASHBOARD_CURRENCY_ID = 'CREATE_DASHBOARD_CURRENCY_ID';
export const SYSTEM_LOGO_ID = 'SYSTEM_LOGO_ID';
export const CREATE_DASHBOARD_STEP1_BUTTON = 'CREATE_DASHBOARD_STEP1_BUTTON';
export const ADD_BUTTON = 'ADD_BUTTON';
export const CREATE_DASHBOARD_CURRENCY_DROPDOWN = 'CREATE_DASHBOARD_CURRENCY_DROPDOWN';
export const CREATE_DASHBOARD_DATA_SOURCE_ID = 'CREATE_DASHBOARD_DATA_SOURCE_ID';
export const CREATE_DASHBOARD_MODE_TYPE_ID = 'CREATE_DASHBOARD_MODE_TYPE_ID';
export const SIDEBAR_CONTENT_DROPDOWN_ID = 'SIDEBAR_CONTENT_DROPDOWN_ID';
export const CREATE_DASHBOARD_TYPE_ID = 'CREATE_DASHBOARD_TYPE_ID';
export const CREATE_DASHBOARD_TEMPLATE_ID = 'CREATE_DASHBOARD_TEMPLATE_ID';
export const CREATE_DASHBOARD_TYPE_CLASS = 'CREATE_DASHBOARD_TYPE_CLASS';
export const ADD_WIDGETS_BUTTON_ID = 'ADD_WIDGET_BUTTON_ID';
export const EDIT_DASHBOARD_BUTTON_ID = 'EDIT_DASHBOARD_BUTTON_ID';
export const STATIC_WIDGETS_ID = 'STATIC_WIDGETS_ID';
export const CUSTOM_WIDGETS_ID = 'CUSTOM_WIDGETS_ID';
export const STATIC_WIDGETS_TAB_ID = 'STATIC_WIDGET_TAB_ID';
export const WIDGETS_TAB_ID = 'WIDGETS_TAB_ID';
export const CUSTOM_WIDGETS_TAB_ID = 'CUSTOM_WIDGETS_TAB_ID';
export const CUSTOM_WIDGETS_TAB_ID_SELECT = 'CUSTOM_WIDGETS_TAB_ID_SELECT';
export const INVITE_MEMBER_TAB = 'INVITE_MEMBER_TAB';
export const INVITE_MEMBER_BUTTON = 'INVITE_MEMBER_BUTTON';
export const INVITE_MEMBER_ROLE = 'INVITE_MEMBER_ROLE';
export const INVITE_MEMBER_ROLE_DROPDOWN = 'INVITE_MEMBER_ROLE_DROPDOWN';
export const INVITE_MEMBER_TEAM = 'INVITE_MEMBER_TEAM';
export const INVITE_MEMBER_TEAM_DROPDOWN = 'INVITE_MEMBER_TEAM_DROPDOWN';
export const INVITE_MEMBER_EMAIL = 'email';
export const INVITE_MEMBER_SUBMIT = 'INVITE_MEMBER_SUBMIT';
export const SAVE_BTN_ID = 'SAVE_BTN_ID';
export const CREATE_HOME_ORGANIZATION_ID = 'CREATE_HOME_ORGANIZATION_ID';
export const LOGO_ID = 'LOGO_ID';
export const ORGANIZATION_NAME_ID = 'ORGANIZATION_NAME_ID';
export const CREATE_HOME_ORGANIZATION_BUTTON = 'CREATE_HOME_ORGANIZATION_BUTTON';
export const CREATE_ORG_BUTTON_ID = 'CREATE_ORG_BUTTON_ID';
export const CREATE_REPORT_EMAIL_ID = 'email';
export const CREATE_REPORT_SUBJECT_ID = 'subject';
export const CREATE_REPORT_DESCRIPTION_ID = 'description';
export const CREATE_REPORT_DATE_ID = 'date';
export const CREATE_REPORT_DATE_TOUR_ID = 'CREATE_REPORT_DATE_TOUR_ID';
export const CREATE_REPORT_TIME_ID = 'time';
export const CREATE_REPORT_TIME_TOUR_ID = 'CREATE_REPORT_TIME_TOUR_ID';
export const CREATE_REPORT_OPTIONAL = 'optional';
export const CREATE_REPORT_SUBMIT = 'CREATE_REPORT_SUBMIT';
export const CREATE_GOAL_BUTTON_ID = 'CREATE_GOAL_BUTTON_ID';
export const CREATE_GOAL_NAME = 'name';
export const CREATE_GOAL_DASHBOARD = 'dashboard';
export const CREATE_GOAL_DASHBOARD_TOUR_ID = 'CREATE_GOAL_DASHBOARD_TOUR_ID';
export const CREATE_GOAL_TIME_PERIOD = 'timePeriod';
export const CREATE_GOAL_TIME_PERIOD_TOUR_ID = 'CREATE_GOAL_TIME_PERIOD_TOUR_ID';
export const CREATE_GOAL_WIDGET = 'widget';
export const CREATE_GOAL_WIDGET_TOUR_ID = 'CREATE_GOAL_WIDGET_TOUR_ID';
export const CREATE_GOAL_WIDGET_DROPDOWN = 'CREATE_GOAL_WIDGET_DROPDOWN';
export const CREATE_GOAL_CONDITION = 'condition';
export const CREATE_GOAL_CONDITION_TOUR_ID = 'CREATE_GOAL_CONDITION_TOUR_ID';
export const CREATE_GOAL_METRIC = 'metric';
// export const CREATE_GOAL_DESCRIPTION = 'description';
export const CREATE_GOAL = 'CREATE_GOAL';
