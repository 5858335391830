import OtpInput from 'react-otp-input';
import { COLORS, ENTER, ENTER_KEY } from '@constants';

export function OTPInputs({ otp, setOtp, showOtpError, errorMessage, handleOtpVerification }) {
  return (
    <div className='mb-[1rem] flex flex-col gap-2 lg:gap-1.5'>
      <div className='otp-container'>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={4}
          inputType='number'
          shouldAutoFocus={true}
          inputStyle={showOtpError ? { borderColor: COLORS.ERROR_TOOLTIP } : ''}
          renderInput={(props, index) => (
            <div>
              <input
                {...props}
                onKeyDown={e => {
                  if (e.code === ENTER || e.keyCode === ENTER_KEY) handleOtpVerification();
                }}
              />
              {index === 0 && (
                <>
                  {showOtpError && (
                    <p className='mt-1.5 h-min font-Manrope text-xs font-normal leading-5 text-errorTooltip'>
                      {errorMessage}
                    </p>
                  )}
                </>
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
}
