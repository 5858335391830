import { NavLink } from 'react-router-dom';
import { SharedTooltip } from '@common/components';
import { DATA_SOURCES_TEXT, GOAL_TRACKING_TEXT, ROLES, SIDEBAR_CONTENT } from '@constants';

export const SidebarContent = ({ manageOrganization, switcherSelectedOrganization, openSidebar }) => {
  return (
    <ul className='flex flex-col gap-1 border-b border-secondaryStrokeButton px-4 py-6 duration-500'>
      {SIDEBAR_CONTENT.map((data, i) => {
        const isMember = manageOrganization?.data?.userRole === ROLES.MEMBER;
        const shouldRenderDataSource = isMember ? ![DATA_SOURCES_TEXT, GOAL_TRACKING_TEXT].includes(data.link) : true;

        if (!shouldRenderDataSource) return null;

        return (
          <li key={i}>
            <NavLink
              id={data.link}
              to={`${data.link}/${switcherSelectedOrganization?.id}`}
              className={`sidebarLink flex items-center gap-3 whitespace-nowrap rounded-full font-normal text-mainText ${openSidebar ? 'w-full duration-300' : 'h-11 w-11'}`}
            >
              <div className='h-5 w-5 [&>svg]:h-5 [&>svg]:w-5 [&>svg]:stroke-icon1'>
                {openSidebar ? (
                  <data.iconUrl />
                ) : (
                  <SharedTooltip title={data.text}>
                    <data.iconUrl />
                  </SharedTooltip>
                )}
              </div>
              <span className='font-Manrope text-sm font-medium leading-5'>{openSidebar && data.text}</span>
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};
