import { Popover } from 'antd';
import { Button, ChatbotBody, ChatbotHeader } from '.';
import { useChatbot } from '@pages/dashboard';
import { ChatbotIcon } from '@assets/svgs';

export function Chatbot({ queryParams }) {
  const {
    handleChatMessage,
    message,
    setMessage,
    chatbotRef,
    showChatbotPopup,
    setShowChatbotPopup,
    chatbotMessages,
    chatMessageLoading,
  } = useChatbot(queryParams);
  return (
    <Popover
      placement='topRight'
      trigger='click'
      overlayClassName='chat-popover !w-[27.5rem] !rounded-xl'
      open={showChatbotPopup}
      onOpenChange={setShowChatbotPopup}
      className='chat-popover'
      content={
        <>
          <ChatbotHeader setShowChatbotPopup={setShowChatbotPopup} />
          <ChatbotBody
            handleChatMessage={handleChatMessage}
            message={message}
            setMessage={setMessage}
            chatbotRef={chatbotRef}
            chatbotMessages={chatbotMessages}
            chatMessageLoading={chatMessageLoading}
          />
        </>
      }
      arrow={false}
    >
      <Button className='chatbot-button chatbot-shadow fixed bottom-10 right-8 z-10 h-[3.875rem] w-[3.875rem] !rounded-full md:bottom-[3.75rem] md:right-10 md:h-20 md:w-20 lg:bottom-10 lg:h-[3.25rem] lg:w-[3.25rem] xxl:bottom-[3.75rem] xxl:right-[3.75rem] xxl:!h-[3.875rem] xxl:!w-[3.875rem]'>
        <ChatbotIcon className='h-[2.1738rem] min-w-[2.1738rem] md:h-[2.805rem] md:min-w-[3.055rem] lg:h-[1.8231rem] lg:min-w-[1.8231rem] xxl:h-[2.1738rem] xxl:min-w-[2.1738rem]' />
      </Button>
    </Popover>
  );
}
