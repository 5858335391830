import { Drawer, Steps } from 'antd';
import { isEmpty } from 'lodash';
import { Check, X } from 'lucide-react';
import { toast } from 'react-toastify';
import { Button, Loader } from '@common/components';
import {
  useCreateDashboard,
  CreateDashboardStep1,
  CreateDashboardStep2,
  CreateDashboardStep3,
  CreateDashboardStep4,
  CreateDashboardStep5,
} from '@pages/dashboard';
import { COLORS, CREATE_DASHBOARD_STEP1_BUTTON, CREATE_DASHBOARD_TYPE, ERROR_MESSAGE } from '@constants';

export function CreateDashboardDialog({ open, onClose, isTourActive, createDashboardTour }) {
  const {
    formik: createDashboardFormik,
    currentStep,
    handleBack,
    addDataSourceListing,
    handleSelectedDataSource,
    addedDataSource,
    handleAddDataSource,
    selectedDataSourceIds,
    selectDashboardType,
    setSelectDashboardType,
    selectDashboardMode,
    setSelectDashboardMode,
    handleDashboardUpdate,
    imageRef,
    uploadedImage,
    setIsUploadedImage,
    isUploadedImage,
    templateListing,
    handleScroll,
    handleCategoryScroll,
    createTemplateDashboardStep,
    handleSelectedTemplate,
    selectedTemplateId,
    handleCreateTemplatedDashboard,
    createdDashboardId,
    createDashboardLoading,
    selectedTemplateImg,
    setTemplateName,
    handleImageUpload,
    selectedType,
    setSelectedType,
    handleCustomDashboardStep,
    selectedCategory,
    setSelectedCategory,
    templateCategory,
    selectedTemplateTab,
    setSelectedTemplateTab,
    handleClearForm,
    imageLoading,
    addDataSourceLoading,
    dashboardUpdateLoading,
    createTemplateDashboardLoading,
    systemGeneratedImages,
    setUploadedImage,
    templateListingFetching,
    dashboardsListingRefetch,
  } = useCreateDashboard(isTourActive, createDashboardTour);

  const nextBtnLoadingState =
    createDashboardLoading || addDataSourceLoading || dashboardUpdateLoading || createTemplateDashboardLoading;

  const handleNextBtn = () => {
    switch (currentStep) {
    case 1:
      createDashboardFormik?.submitForm();
      break;
    case 2:
      handleAddDataSource({
        payload: { dataSources: selectedDataSourceIds },
      });
      break;
    case 3:
      selectDashboardType === CREATE_DASHBOARD_TYPE.CUSTOM
        ? handleCustomDashboardStep()
        : createTemplateDashboardStep();
      break;
    case 4:
      handleCreateTemplatedDashboard({ payload: { dashboardId: createdDashboardId } });
      break;
    case 5:
      handleDashboardUpdate({
        payload: { viewType: selectDashboardMode },
      });
      break;
    default:
      toast.error(ERROR_MESSAGE);
      break;
    }
  };

  return (
    <Drawer
      open={open}
      onClose={v => {
        onClose(v);
        handleClearForm();
        currentStep > 1 && dashboardsListingRefetch();
      }}
      width={1002}
      title='Create Dashboard'
      className={'rounded-l-[20px] [&>.ant-drawer-body]:overflow-hidden '}
      closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
      footer={
        <>
          {[1, 2, 3, 4, 5].map(
            step =>
              currentStep === step && (
                <div
                  className={`flex w-full flex-row ${[1, 2].includes(step) ? 'justify-end' : 'justify-between'}`}
                  key={step}
                >
                  {![1, 2].includes(step) && (
                    <Button variant='tertiary' className='w-[140px]' onClick={() => handleBack(currentStep)}>
                      Back
                    </Button>
                  )}
                  {!isUploadedImage && (
                    <Button
                      id={CREATE_DASHBOARD_STEP1_BUTTON}
                      onClick={handleNextBtn}
                      disabled={
                        nextBtnLoadingState ||
                        (currentStep === 1 && !createDashboardFormik.dirty) ||
                        (currentStep === 2 && isEmpty(selectedDataSourceIds)) ||
                        (currentStep === 4 && !selectedTemplateId)
                      }
                      className='w-[8.75rem]'
                    >
                      {currentStep > 3 ? 'Create' : 'Next'} {nextBtnLoadingState && <Loader />}
                    </Button>
                  )}
                </div>
              )
          )}
        </>
      }
    >
      <div className='flex h-full'>
        <div className='h-full border-r border-secondaryStrokeButton'>
          <div className='sticky top-0 w-[15.625rem] px-8 py-10'>
            <Steps
              current={currentStep - 1}
              onChange={handleNextBtn}
              direction='vertical'
              icon={<Check className='h-5 w-5' color='white' />}
              items={[
                {
                  title: 'Dashboard Details',
                  description: 'Enter dashboard name and further details',
                },
                {
                  title: 'Data Sources',
                  description: 'Add the data sources you want on this dashboard',
                },
                {
                  title: 'Creation Mode',
                  description: 'Choose the dashboard mode',
                },
                {
                  title: 'Display Mode',
                  description: 'Select Standard or Slide View',
                },
              ]}
            />
          </div>
        </div>
        <div className='my-10 ml-[6.25rem] overflow-y-auto scrollbar-none'>
          {currentStep === 1 && (
            <CreateDashboardStep1
              formik={createDashboardFormik}
              imageRef={imageRef}
              handleImageUpload={handleImageUpload}
              uploadedImage={uploadedImage}
              setIsUploadedImage={setIsUploadedImage}
              isUploadedImage={isUploadedImage}
              isTourActive={isTourActive}
              imageLoading={imageLoading}
              systemGeneratedImages={systemGeneratedImages}
              setUploadedImage={setUploadedImage}
              createDashboardTour={createDashboardTour}
            />
          )}
          {currentStep === 2 && (
            <CreateDashboardStep2
              addDataSourceListing={addDataSourceListing}
              handleCheck={handleSelectedDataSource}
              addedDataSource={addedDataSource}
              selectedDataSourceIds={selectedDataSourceIds}
            />
          )}
          {currentStep === 3 && (
            <CreateDashboardStep3 value={selectDashboardType} handleSelect={setSelectDashboardType} />
          )}
          {currentStep === 4 && (
            <CreateDashboardStep4
              templateListing={templateListing}
              handleScroll={handleScroll}
              handleCategoryScroll={handleCategoryScroll}
              handleSelectedTemplate={handleSelectedTemplate}
              selectedTemplateId={selectedTemplateId}
              selectedTemplateImg={selectedTemplateImg}
              setTemplateName={setTemplateName}
              selectedType={selectedType}
              setSelectedType={setSelectedType}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              templateCategory={templateCategory}
              selectedTemplateTab={selectedTemplateTab}
              setSelectedTemplateTab={setSelectedTemplateTab}
              templateListingFetching={templateListingFetching}
            />
          )}
          {currentStep === 5 && (
            <CreateDashboardStep5 value={selectDashboardMode} handleSelect={setSelectDashboardMode} />
          )}
        </div>
      </div>
    </Drawer>
  );
}
