import { cn } from '@shadcnUtils';
import { Button, Loader } from '@common/components';

export function DeleteDialog({ text, deleteBtnClick, isLoading, textClass, goBackBtnClick, showBackBtn = false }) {
  return (
    <div className='flex flex-col items-center justify-center gap-4 md:gap-6'>
      <div className='flex flex-col gap-3'>
        <h3 className='font-Manrope text-2xl font-bold leading-[34px]'>
          Delete <span className='break-all'>{text}</span>?
        </h3>
        <p className={cn(`text-text-body font-Manrope text-sm leading-[1.625rem] ${textClass}`)}>
          Are you sure, you want to delete {text}?
        </p>
      </div>
      <div className='flex w-full flex-col gap-3 md:gap-4'>
        <Button onClick={deleteBtnClick} className='w-full' variant='destructive' size='lg' disabled={isLoading}>
          Delete
          {isLoading ? <Loader /> : ''}
        </Button>
        {showBackBtn && (
          <Button onClick={goBackBtnClick} className='w-full' variant='tertiary' size='lg'>
            Go Back
          </Button>
        )}
      </div>
    </div>
  );
}
