import { Plus } from 'lucide-react';
import { Button } from '@common/components';
import { CREATE_GOAL_BUTTON_ID } from '@constants';
import { NoGoalsImage } from '@assets/svgs';

export const NoGoalsView = ({ handleClick = () => null }) => {
  return (
    <div className='flex h-[33.25rem] items-center justify-center'>
      <div className='flex flex-col items-center gap-5 md:gap-6'>
        <NoGoalsImage />
        <div className='flex w-[14.875rem] flex-col items-center gap-3'>
          <h1 className='font-Manrope text-lg font-bold leading-[1.625rem] text-mainText md:text-xl md:leading-7'>
            No goals set yet!
          </h1>
          <p className='text-center font-Manrope text-xs font-normal leading-4 text-bodyText md:text-sm md:leading-5'>
            Start by creating your first goal to track progress and achieve success.
          </p>
        </div>
        <Button size='lg' className='w-[14rem] md:w-[10.625rem]' onClick={handleClick} id={CREATE_GOAL_BUTTON_ID}>
          <Plus className='h-3.5 w-3.5 md:h-5 md:w-5' color='white' />
          Create New
        </Button>
      </div>
    </div>
  );
};
