import moment from 'moment';
import { CustomDatePicker } from './CustomDatePicker';
import { Button, FormInput, FormSelect, Loader, TourButton } from '@common/components';
import { TextEditor, CustomTimePicker, useCreateReportTour } from '@pages/reports';
import { calculateMonthlyOptions, convertToDayNumber, restrictedCharacterOnNumberField } from '@utils';
import {
  COLORS,
  CREATE_REPORT_DATE_ID,
  CREATE_REPORT_DESCRIPTION_ID,
  CREATE_REPORT_EMAIL_ID,
  CREATE_REPORT_SUBJECT_ID,
  CREATE_REPORT_SUBMIT,
  DAY_VALUE,
  MONTHLY,
  REPORT_TIME_PERIOD,
  WEEKLY,
} from '@constants';
import { CrossIcon } from '@assets/svgs';

export function CreateEditReport({
  formik,
  handleInvitedEmailKeyDown,
  invitedEmails,
  handleRemoveInvitedEmail,
  emailFieldErrorMessage,
  invalidEmails,
  handleSelectedDay,
  scheduleLoading,
  scheduleClass,
  isEditMode = false,
  refetchSummary,
  summaryFetching,
}) {
  const { values, handleChange, setFieldValue, touched, errors, submitForm } = formik;

  const { createReportsTour } = useCreateReportTour();

  return (
    <div className='relative flex h-full flex-col justify-between'>
      <div className='p-5 md:p-6'>
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <FormInput
              id={CREATE_REPORT_EMAIL_ID}
              labelText='Add Recipients'
              placeholder='Enter Recipients'
              value={values.email}
              handleChange={handleChange}
              showError={emailFieldErrorMessage}
              onKeyDown={handleInvitedEmailKeyDown}
              isMultipleEmail={true}
              isMultipleEmailMessage='*Press enter after adding an recipient'
              labelClassName='text-sm leading-[1.0625rem]'
            />
            {!!invitedEmails?.length > 0 && (
              <div className={'flex flex-wrap gap-2'}>
                {invitedEmails.map(email => (
                  <span
                    key={email}
                    className={`flex items-center gap-2 break-all rounded-lg bg-tableRowHover px-2.5 py-1 text-sm font-normal leading-4 text-colorPrimary ${invalidEmails.includes(email) ? 'border border-errorColor text-errorTooltip' : ''}`}
                  >
                    {email}
                    <span onClick={() => handleRemoveInvitedEmail(email)} className='cursor-pointer'>
                      <CrossIcon
                        strokeColor={invalidEmails.includes(email) ? COLORS.ERROR_TOOLTIP : COLORS.DARK_SECONDARY}
                      />
                    </span>
                  </span>
                ))}
              </div>
            )}
          </div>
          <FormInput
            labelText='Subject'
            placeholder='Enter subject'
            id={CREATE_REPORT_SUBJECT_ID}
            value={values.subject}
            handleChange={handleChange}
            showError={touched.subject && errors.subject}
            labelClassName='text-sm leading-[1.0625rem]'
          />
          <div id={CREATE_REPORT_DESCRIPTION_ID}>
            <TextEditor
              formik={formik}
              className={`${isEditMode ? 'h-[18.75rem]' : 'h-[13.25rem]'}`}
              error={errors.description && touched.description}
              refetchSummary={refetchSummary}
              summaryFetching={summaryFetching}
            />
          </div>
          <div
            className={`flex ${isEditMode ? 'w-full' : 'w-full'} flex-col justify-between gap-4 md:flex-row`}
            id={CREATE_REPORT_DATE_ID}
          >
            <div className={'flex w-full  flex-col gap-1.5'}>
              <CustomDatePicker
                className={'custom-date-picker'}
                scheduleClass={scheduleClass}
                value={values.date}
                handleChange={value => setFieldValue('date', value)}
                showError={touched.date && errors.date}
              />
            </div>
            <CustomTimePicker
              className={'custom-time-picker'}
              scheduleClass={scheduleClass}
              value={values.time}
              handleChange={value => setFieldValue('time', value)}
              showError={touched.time && errors.time}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <h3 className='border-b border-secondaryStrokeButton pb-3 font-Manrope text-sm font-bold leading-5 text-mainText'>
              Schedule Report <span className='font-normal text-labelText'>(Optional)</span>
            </h3>
            <div className='flex flex-col gap-4 lg:flex-row'>
              {/* ${isEditMode ? 'w-[21.4375rem]' : 'w-[37.900rem]'}  */}
              <div className='flex flex-col gap-1.5'>
                <div className='font-Manrope text-xs leading-4 text-labelText md:text-sm md:leading-5'>
                  Repeat Every
                </div>
                {/* ${isEditMode ? 'w-full' : values?.type === '' ? 'w-[32.5rem]' : 'w-[56rem]'} */}
                <div className='flex flex-row items-center gap-2 md:gap-4'>
                  {/* ${isEditMode ? (values?.type ? 'max-w-[8rem]' : 'max-w-[8rem]') : 'min-w-[9.75rem]'} */}
                  <FormInput
                    type='number'
                    placeholder='Enter Number'
                    id='frequency'
                    value={values.frequency}
                    handleChange={handleChange}
                    onKeyDown={restrictedCharacterOnNumberField}
                    showError={touched.frequency && errors.frequency}
                    triggerClassName={`${values?.type && values?.type !== '' ? '' : 'custom-select'}`}
                    className='w-full lg:w-[156px]'
                  />
                  {/* <div className={`${isEditMode ? 'w-[11.75rem]' : 'w-full'} mr-auto`}> */}
                  <FormSelect
                    id='type'
                    placeholder='Schedule Type'
                    value={values?.type || null}
                    handleChange={value => setFieldValue('type', value)}
                    options={REPORT_TIME_PERIOD}
                    showError={touched.type && errors.type}
                    triggerClassName={` ${values?.type && values?.type !== '' ? '' : 'custom-select'}`}
                    className='w-full lg:w-[18.75rem] xxl:w-[22.375rem]'
                  />
                </div>
              </div>
              {values?.type === WEEKLY && (
                <div className='flex flex-col gap-1.5'>
                  <div className='font-Manrope text-xs leading-4 text-labelText md:text-sm md:leading-5'>
                    Select day
                  </div>

                  <div className='flex h-full w-full items-center'>
                    <div className={'flex gap-1.5 md:gap-4'}>
                      {DAY_VALUE.map(({ value, label }) => (
                        <div
                          key={value}
                          onClick={() => handleSelectedDay(value)}
                          className={`border-paleGray flex h-[2.2906rem] w-[2.2906rem] cursor-pointer items-center justify-center rounded-full border font-Manrope text-sm font-semibold md:text-base ${values?.dayChecked?.includes(value) ? 'bg-highlighterText2 text-white' : ''}`}
                        >
                          {label}
                        </div>
                      ))}
                    </div>
                    {touched.dayChecked && errors.dayChecked && (
                      <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorTooltip'>
                        {touched.dayChecked && errors.dayChecked}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {values?.type === MONTHLY && (
                <div className='flex w-full items-end'>
                  <FormSelect
                    id='monthly'
                    placeholder='Select monthly'
                    value={values?.scheduledLabel || null}
                    handleChange={value => {
                      setFieldValue('dayChecked', convertToDayNumber(moment().format('dddd')));
                      setFieldValue('scheduleMonthly', {
                        id: value,
                        name: calculateMonthlyOptions(values?.date).find(item => item.id === value).name,
                      });
                      setFieldValue(
                        'scheduledLabel',
                        calculateMonthlyOptions(values?.date).find(item => item.id === value).name
                      );
                    }}
                    options={calculateMonthlyOptions(values?.date)}
                    showError={touched.scheduleMonthly && errors.scheduleMonthly}
                    triggerClassName={`w-full ${values?.scheduleMonthly || values.scheduledLabel ? '' : 'custom-select'}`}
                    className='w-full md:w-[calc(50%-8px)] lg:w-[300px] xxl:w-[358px]'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className='flex justify-end border-t border-secondaryStrokeButton px-5 py-5 md:px-6 md:py-4'>
        <Button
          className='w-full md:w-[11.25rem]'
          id={CREATE_REPORT_SUBMIT}
          disabled={scheduleLoading || !formik.dirty || summaryFetching}
          onClick={submitForm}
        >
          {isEditMode ? 'Reschedule' : 'Schedule'} {scheduleLoading && <Loader />}
        </Button>
      </div>
      <TourButton onClick={() => createReportsTour().drive()} Label='"Create Report Tour"' />
    </div>
  );
}
