import { useCreateDashboardTour } from '../hooks/useCreateDashboardTour';
import { BaseLoader, Checkbox } from '@common/components';
import { CREATE_DASHBOARD_DATA_SOURCE_ID } from '@constants';

export function CreateDashboardStep2({ addDataSourceListing, handleCheck, addedDataSource, selectedDataSourceIds }) {
  const { createDashboardTour } = useCreateDashboardTour();
  return (
    <div>
      <div className='flex flex-col gap-2'>
        <h2 className='font-Manrope text-xl font-bold leading-7 text-mainText'>Data Sources</h2>
        <p className='font-Manrope text-sm leading-5 text-bodyText'>Add the data sources you want on this dashboard.</p>
      </div>
      {addDataSourceListing?.data?.results?.length > 0 ? (
        <div className='mb-10 flex flex-col gap-4 pt-10'>
          <p className='font-Manrope text-xs leading-4 text-bodyText'>
            {addedDataSource?.length || '0'} out of {addDataSourceListing?.data?.results?.length} selected
          </p>
          <div
            className='data-source-drawer flex h-[33.75rem] flex-col gap-4 overflow-y-auto pr-5'
            id={CREATE_DASHBOARD_DATA_SOURCE_ID}
          >
            {addDataSourceListing?.data?.results?.map(dataSource => (
              <div
                key={dataSource?.id}
                className={`relative flex w-[35rem] cursor-pointer items-center justify-between gap-4 rounded-xl border px-4 py-3 ${selectedDataSourceIds.includes(dataSource?.id) ? 'border-highlighterText1' : 'border-secondaryStrokeButton'}`}
                onClick={() => {
                  handleCheck(dataSource?.id);
                  createDashboardTour().moveNext();
                }}
              >
                <div className='flex h-12 min-w-12 items-center justify-center rounded-full bg-shade4'>
                  <img src={dataSource?.connector?.logo?.image} className='h-6 w-6' alt='dataSourceIcon' />
                </div>
                <div className='flex w-full flex-col gap-1'>
                  <p className='font-Manrope text-sm font-semibold leading-5 text-mainText'>{dataSource?.name}</p>
                  <span className='font-Manrope text-xs leading-4 text-bodyText'>
                    {dataSource?.connector?.sourceName}
                  </span>
                </div>
                <Checkbox
                  id={`dataSource-${dataSource?.id}`}
                  checked={selectedDataSourceIds.includes(dataSource?.id)}
                  // className='static right-[0.3125rem] top-[0.3125rem] h-5 w-5'
                  // onClick={() => {
                  //   handleCheck(dataSource?.id);
                  //   createDashboardTour().moveNext();
                  // }}
                />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <BaseLoader id={CREATE_DASHBOARD_DATA_SOURCE_ID} />
      )}
    </div>
  );
}
