import { useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiEndpoints, performGetRequest } from '@services';
import { getNextPage } from '@utils';

export function useNotification() {
  const [errorMessage, setErrorMessage] = useState('');
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const getNotificationListing = ({ pageParam = 1 }) => performGetRequest(apiEndpoints.NOTIFICATION(pageParam));

  const {
    data: notificationListing,
    isFetching: notificationListingFetching,
    isLoading: notificationListingLoading,
    refetch: notificationListingRefetch,
    fetchNextPage: notificationListingNextPage,
    hasNextPage: notificationListingHasNextPage,
  } = useInfiniteQuery({
    refetchOnWindowFocus: false,
    retry: false,
    enabled: false,
    queryKey: ['notification-listing'],
    queryFn: getNotificationListing,
    getNextPageParam: getNextPage,
    onError: ({ response: { data } }) => setErrorMessage(data?.detail),
    onSuccess: () => setErrorMessage(''),
  });

  return {
    errorMessage,
    notificationListing,
    notificationListingFetching,
    notificationListingRefetch,
    notificationListingNextPage,
    notificationListingHasNextPage,
    notificationListingLoading,
    openNotificationDrawer,
    setOpenNotificationDrawer,
  };
}
