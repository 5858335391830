export function InviteImage({ className }) {
  return (
    <svg
      width='160'
      height='160'
      viewBox='0 0 160 160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M79.9997 159.999C124.182 159.999 159.999 124.182 159.999 79.9997C159.999 35.8171 124.182 0 79.9997 0C35.8171 0 0 35.8171 0 79.9997C0 124.182 35.8171 159.999 79.9997 159.999Z'
        fill='url(#paint0_linear_12853_163634)'
      />
      <path
        d='M128 160H32V56.5334C36.5248 56.5283 40.8628 54.7286 44.0623 51.5291C47.2618 48.3296 49.0615 43.9916 49.0666 39.4668H110.933C110.928 41.7083 111.368 43.9284 112.227 45.9988C113.086 48.0692 114.347 49.9487 115.937 51.5286C117.517 53.1191 119.396 54.3805 121.467 55.2395C123.537 56.0986 125.758 56.5384 128 56.5334V160Z'
        fill='white'
      />
      <path
        d='M93.8649 119.199H66.1316C64.3643 119.199 62.9316 120.632 62.9316 122.399C62.9316 124.167 64.3643 125.599 66.1316 125.599H93.8649C95.6322 125.599 97.0649 124.167 97.0649 122.399C97.0649 120.632 95.6322 119.199 93.8649 119.199Z'
        fill='#8CD4C2'
      />
      <path
        d='M103.467 132H56.534C54.7667 132 53.334 133.433 53.334 135.2C53.334 136.967 54.7667 138.4 56.534 138.4H103.467C105.234 138.4 106.667 136.967 106.667 135.2C106.667 133.433 105.234 132 103.467 132Z'
        fill='#E2F8F4'
      />
      <g clipPath='url(#clip0_12853_163634)'>
        <path
          d='M95.2613 61H57.4222C56.0845 61 55 62.0845 55 63.4222V87.8259C55 89.1636 56.0845 90.2481 57.4222 90.2481H95.2613C96.5991 90.2481 97.6836 89.1636 97.6836 87.8259V63.4222C97.6836 62.0845 96.5991 61 95.2613 61Z'
          fill='#8CD4C2'
        />
        <path
          d='M96.7018 89.7312C97.0074 89.4976 97.255 89.1967 97.4253 88.8518C97.5956 88.5069 97.6841 88.1274 97.6838 87.7427V63.5054C97.6838 62.8409 97.4198 62.2037 96.95 61.7338C96.4801 61.264 95.8429 61 95.1784 61H70.9355L96.6502 89.7123L96.7018 89.7312Z'
          fill='#8CD4C2'
        />
        <path
          d='M81.7483 61H57.5054C56.8409 61 56.2037 61.264 55.7338 61.7338C55.264 62.2037 55 62.8409 55 63.5054V87.7427C54.9997 88.1158 55.083 88.4842 55.2436 88.8209C55.4042 89.1577 55.6382 89.4542 55.9283 89.6887L56.0336 89.7123L81.7483 61Z'
          fill='#8CD4C2'
        />
        <path
          d='M95.1782 61H57.5054C56.8409 61 56.2037 61.264 55.7338 61.7338C55.264 62.2037 55 62.8409 55 63.5054V64.4685L73.0799 76.9268C74.0392 77.5879 75.1767 77.9419 76.3418 77.9419C77.5069 77.9419 78.6444 77.5879 79.6037 76.9268L97.6836 64.4685V63.5054C97.6836 62.8409 97.4196 62.2037 96.9498 61.7338C96.4799 61.264 95.8427 61 95.1782 61Z'
          fill='#3FB79A'
        />
        <path
          d='M66.1136 109.999C65.9566 110 65.8033 109.951 65.6746 109.861C65.5459 109.771 65.448 109.644 65.3941 109.497C65.3403 109.349 65.333 109.189 65.3735 109.037C65.4139 108.885 65.5 108.749 65.6201 108.648L76.2037 99.8516C76.2803 99.7846 76.3694 99.7335 76.466 99.7013C76.5625 99.6692 76.6645 99.6566 76.766 99.6643C76.8675 99.672 76.9664 99.6999 77.0569 99.7463C77.1475 99.7927 77.2279 99.8567 77.2935 99.9345C77.359 100.012 77.4084 100.102 77.4387 100.2C77.4691 100.297 77.4797 100.399 77.4701 100.5C77.4605 100.602 77.4307 100.7 77.3826 100.79C77.3346 100.879 77.2691 100.958 77.19 101.023L66.6065 109.819C66.4687 109.936 66.294 110 66.1136 109.999Z'
          fill='#8CD4C2'
        />
        <path
          d='M75.0902 108.333C74.9345 108.333 74.7824 108.286 74.6543 108.197C74.5262 108.109 74.4281 107.983 74.3733 107.837C74.3184 107.692 74.3093 107.533 74.3472 107.381C74.3851 107.23 74.4682 107.094 74.5854 106.992L77.1906 104.612C77.2662 104.545 77.3542 104.494 77.4495 104.461C77.5449 104.429 77.6458 104.415 77.7464 104.421C77.847 104.428 77.9453 104.454 78.0357 104.499C78.1261 104.543 78.2069 104.605 78.2733 104.681C78.3397 104.757 78.3905 104.845 78.4227 104.941C78.455 105.036 78.4681 105.137 78.4612 105.238C78.4544 105.338 78.4278 105.436 78.3829 105.527C78.338 105.617 78.2757 105.697 78.1996 105.763L75.5949 108.144C75.4554 108.266 75.2759 108.334 75.0902 108.333Z'
          fill='#35ACEF'
        />
        <path d='M77.9297 93.1898L82.7599 104.76L91.5844 91.5219L103.99 79.7012L77.9297 93.1898Z' fill='#8CD4C2' />
        <path d='M103.99 79.6152L78.5304 94.4662L71.0391 92.5227L103.99 79.6152Z' fill='#386360' />
        <path d='M95.024 89.4375L82.7598 104.76L84.2071 95.984L95.024 89.4375Z' fill='#386360' />
        <path
          d='M103.99 79.7005C103.997 79.6788 104.006 79.6622 103.99 79.6783L84.207 95.9831L96.4622 99.3191L103.99 79.7005Z'
          fill='#3FB79A'
        />
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_12853_163634'
          x1='79.9997'
          y1='0'
          x2='79.9997'
          y2='159.999'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7F9F3' />
          <stop offset='1' stopColor='#E2F8F4' />
        </linearGradient>
        <clipPath id='clip0_12853_163634'>
          <rect width='49' height='49' fill='white' transform='translate(55 61)' />
        </clipPath>
      </defs>
    </svg>
  );
}
