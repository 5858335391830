import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { X } from 'lucide-react';
import Cropper from 'react-easy-crop';
import { Button, Loader } from '@common/components';
import { getCircularCrop, getRectCrop } from '@utils';
import { COLORS, SAVE_BTN_ID } from '@constants';

export function ImageCrop({
  uploadedImage,
  setIsUploaded,
  handleSave,
  handleCancel,
  isDialog = false,
  cropShape = 'round',
  imageRef,
  applyRef = true,
  handleCloseDialog,
  imageLoading = false,
  cancel = true,
  title = 'Edit',
  onBackButtonClick = () => null,
  showBackButton = true,
}) {
  const [image, setImage] = useState(null);
  const [cropImage, setCropImage] = useState(null);
  const [rotation, setRotation] = useState(0);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [open, setIsOpen] = useState(false);

  const onFileChange = file => {
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setIsOpen(true);
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = async (_, croppedAreaPixels) => {
    const croppedImageComplete =
      cropShape === 'rect'
        ? await getRectCrop(image, croppedAreaPixels)
        : await getCircularCrop(image, croppedAreaPixels);
    setCropImage(croppedImageComplete);
  };

  const calculatePercentage = value => {
    const min = 1;
    const max = 3;
    return ((value - min) / (max - min)) * 100;
  };

  const CropImage = (
    <div className='flex w-full flex-col gap-4 md:w-[21.25rem] md:gap-6'>
      <Cropper
        image={image}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        aspect={1}
        cropShape={cropShape}
        showGrid={false}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        classes={{
          containerClassName: 'w-full md:w-[21.25rem] h-[13.75rem] rounded-lg',
        }}
      />
      <input
        type='range'
        value={zoom}
        min={1}
        max={3}
        step={0.1}
        onChange={e => setZoom(e.target.value)}
        className='flex-1 accent-highlighterText1'
        style={{
          background: `linear-gradient(to right, ${COLORS.HIGHLIGHT_TEXT_COLOR} ${calculatePercentage(zoom)}%, ${COLORS.SECONDARY_STROKE_BTN} ${calculatePercentage(zoom)}%)`,
        }}
      />
    </div>
  );

  const handleDialog = () => {
    setIsUploaded(false);
    setIsOpen(false);
    applyRef && (imageRef.current.value = '');
    handleCloseDialog();
  };

  useEffect(() => {
    onFileChange(uploadedImage);
  }, [uploadedImage]);

  return (
    <div className='h-full' id={SAVE_BTN_ID}>
      {!isDialog && (
        <div className='flex h-full flex-col gap-6'>
          {image && <div className='flex justify-center'>{CropImage}</div>}
          <Button onClick={() => handleSave(cropImage)} size='lg' className='w-full' disabled={imageLoading}>
            Save
            {imageLoading && <Loader />}
          </Button>
        </div>
      )}

      {showBackButton && (
        <Button className='mt-3 w-full' variant='tertiary' size='lg' onClick={onBackButtonClick}>
          Go Back
        </Button>
      )}

      {isDialog && (
        <Modal
          footer={null}
          className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
          closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
          onCancel={handleDialog}
          centered={true}
          open={open}
        >
          <div id='crop-modal' className='flex flex-col gap-4 md:gap-6'>
            <h2 className='font-Manrope text-xl font-bold md:text-2xl md:leading-[2.125rem]'>{title} Image</h2>
            {image && <>{CropImage}</>}
            <div className='flex flex-row justify-between gap-4'>
              {cancel && (
                <Button size='lg' onClick={handleCancel} variant='outline'>
                  Cancel
                </Button>
              )}

              <Button id={SAVE_BTN_ID} size='lg' onClick={() => handleSave(cropImage)} disabled={imageLoading}>
                Save
                {imageLoading && <Loader />}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
