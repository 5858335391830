import { Table, Skeleton } from 'antd';

export const TableSkeletonLoader = ({ columns, rowCount = 10 }) => {
  const skeletonRows = Array.from({ length: rowCount }, (_, rowIndex) => ({
    key: rowIndex,
    ...columns.reduce((acc, column) => {
      acc[column.dataIndex] = <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />;
      return acc;
    }, {}),
  }));

  return <Table columns={columns} dataSource={skeletonRows} pagination={false} />;
};
