export const apiEndpoints = {
  GOOGLE_LOGIN: 'users/google-login/',
  EXPRESS_LOGIN: 'users/login/',
  VERIFY_OTP: 'users/verify-otp/',
  USER_ONBOARDING: 'users/onboarding/',
  USER_DETAIL: 'users/detail/',
  UPLOAD_IMAGE: 'core/upload-image/',
  ORGANIZATIONS: 'organizations/',
  SYSTEM_GENERATED_LOGO: 'core/system-images/',
  UPDATE_ORGANIZATIONS: id => `organizations/${id}/update/`,
  DELETE_ORGANIZATIONS: id => `organizations/${id}/delete/`,
  ORGANIZATIONS_MEMBERS: id => `organizations/${id}/members/`,
  ORGANIZATIONS_TEAMS: id => `organizations/${id}/teams/`,
  CREATE_TEAM: id => `organizations/${id}/teams/create/`,
  EDIT_TEAM: (id, teamId) => `organizations/${id}/teams/${teamId}/`,
  CREATE_TEAM_DASHBOARDS_LISTING: id => `dashboards/${id}/list/`,
  CREATE_TEAM_MEMBERS_LISTING: id => `organizations/${id}/members/list/`,
  DELETE_TEAM: (organizationId, teamId) => `organizations/${organizationId}/teams/${teamId}/`,
  GET_TEAM_DETAILS: (organizationId, teamId) => `organizations/${organizationId}/teams/${teamId}/`,
  TEAMS_LISTING: id => `organizations/${id}/teams/list/`,
  SEND_INVITATION: 'organizations/invitations/send/',
  RESEND_INVITATION: 'organizations/invitations/resend/',
  INVITATIONS_LISTING: id => `organizations/${id}/invitations/`,
  ORGANIZATIONS_INVITATIONS_ACCEPT: uuid => `organizations/invitations/accept/${uuid}/`,
  VERIFY_INVITATIONS: uuid => `organizations/invitations/verify/${uuid}/`,
  DELETE_ORGANIZATION_MEMBER: (organizationId, memberId) => `organizations/${organizationId}/members/${memberId}/`,
  NOTIFICATION: (page = 1) => `communications/notifications/?page=${page}`,
  MEMBERSHIP: 'memberships/',
  MEMBERSHIP_CHECKOUT: 'memberships/checkout/',
  MEMBERSHIP_UPGRADE: 'memberships/upgrade/',
  MEMBERSHIP_CANCEL: 'memberships/cancel/',
  BILLING_LISTING: 'memberships/invoices/',
  ADD_DATA_SOURCE: `datasources/connectors/?page_size=${24}`,
  CONNECT_DATA_SOURCE: id => `datasources/${id}/connect/`,
  CONNECTED_DATA_SOURCE_LIST: id => `datasources/${id}/`,
  DELETE_DATA_SOURCE: (organizationId, dataSourceId) => `datasources/${organizationId}/datasource/${dataSourceId}/`,
  ORGANIZATIONS_HOME: 'organizations/home/',
  CREATE_DASHBOARDS: organizationId => `dashboards/${organizationId}/create/`,
  DASHBOARD_DATA_SOURCE: organizationId => `datasources/${organizationId}/list/`,
  ADD_DASHBOARD_DATA_SOURCE: (organizationId, dashboardId) =>
    `dashboards/${organizationId}/dashboard/${dashboardId}/add/`,
  DASHBOARDS_LISTING: organizationId => `dashboards/${organizationId}/`,
  CUSTOM_WIDGETS: (organizationId, dashboardId) => `datasources/${organizationId}/dashboard/${dashboardId}/widgets/`,
  STATIC_WIDGETS: 'dashboards/static-widgets/',
  DASHBOARD_DETAIL: (organizationId, dashboardId) => `dashboards/${organizationId}/dashboard/${dashboardId}/`,
  COMMENTS_LISTING: (dashboardId, page = 1, commentedWidget) =>
    `dashboards/${dashboardId}/comments/?page=${page}${commentedWidget ? `&widget_identifier=${commentedWidget}` : ''}`,
  TAG_USERS_LISTING: dashboardId => `dashboards/${dashboardId}/users/`,
  CREATE_COMMENT: dashboardId => `dashboards/${dashboardId}/comments/`,
  DATA_SOURCE_SYNC_STATUS: connectionId => `datasources/${connectionId}/sync-status/`,
  DATA_SOURCES_OAUTH: 'datasources/oauth-url/',
  COMPLETE_OAUTH: 'datasources/complete-oauth/',
  WHITE_LABEL: organizationId => `organizations/${organizationId}/white-label/`,
  DASHBOARD_TEMPLATES: (organizationId, dashboardId, page = 1, search, type, category, templateTab) =>
    `dashboards/${organizationId}/templates/dashboard/${dashboardId}/?page=${page}${templateTab ? `&is_system=${templateTab}` : ''}${search ? `&search=${search}` : ''}${type && type !== '-1' ? `&type=${type}` : ''}${category && category !== '-1' ? `&category=${category}` : ''}`,
  CREATE_DASHBOARD_TEMPLATES: (organizationId, templateId) => `dashboards/${organizationId}/templates/${templateId}/`,
  GET_SHARE_DASHBOARD_URL: (organizationId, dashboardId) =>
    `dashboards/${organizationId}/dashboard/${dashboardId}/share/`,
  GET_SHARED_DASHBOARD: uuid => `dashboards/${uuid}/`,
  TABLE_WIDGET: 'dashboards/table-widgets/',
  DATA_SOURCE_OAUTH_LISTING: 'datasources/oauths/',
  GOOGLE_ANALYTICS_ACCOUNT: id => `datasources/google-analytics/${id}/accounts/`,
  LINKEDIN_ADS: id => `datasources/linkedin-ads/${id}/accounts/`,
  GOOGLE_ANALYTICS_ACCOUNT_PROPERTIES: (id, accountId) =>
    `datasources/google-analytics/${id}/accounts/${accountId}/properties/`,
  FACEBOOK_ACCOUNT: id => `datasources/facebook/${id}/accounts/`,
  GET_SUMMARY_WIDGET: (organizationId, dashboardId) => `dashboards/${organizationId}/dashboard/${dashboardId}/summary/`,
  GET_SHARED_SUMMARY: uuid => `dashboards/${uuid}/summary/`,
  DASHBOARD_CHATBOT: (organizationId, dashboardId) => `dashboards/${organizationId}/dashboard/${dashboardId}/chat/`,
  WIDGET_DIMENSION: connectorId => `dashboards/connector/${connectorId}/widget-dimension/`,
  UPLOAD_CSV: 'core/upload-csv/',
  SHOPIFY_INSTALL: 'datasources/webhooks/shopify/install/',
  OAUTH_ADD_USER: 'datasources/oauths/add-user/',
  DELETE_WIDGET_COMMENT: (dashboardId, widgetId) => `dashboards/${dashboardId}/comments/${widgetId}/`,
  UPDATE_COMMENT: (dashboardId, widgetId) => `dashboards/${dashboardId}/comments/${widgetId}/`,
  DUPLICATE_DASHBOARD: (organizationId, dashboardId) =>
    `dashboards/${organizationId}/dashboard/${dashboardId}/duplicate/`,
  CREATE_REPORT: organizationId => `reports/${organizationId}/create/`,
  SAVE_AS_TEMPLATE: (organizationId, dashboardId) =>
    `dashboards/${organizationId}/dashboard/${dashboardId}/save-as-template/`,
  TEMPLATE_CATEGORY: (page = 1) => `dashboards/template/category/?page=${page}`,
  GET_REPORTS: (organizationId, dashboardId) => `reports/${organizationId}/dashboard/${dashboardId}/`,
  REPORT_DETAIL: (organizationId, dashboardId, reportId) =>
    `reports/${organizationId}/dashboard/${dashboardId}/report/${reportId}/`,
  WHITE_LABEL_EMAIL_DETAIL: (organizationId, domainId) =>
    `organizations/${organizationId}/white-label/email/${domainId}/detail/`,
  MEMBERSHIP_PUBLIC_LIST: 'memberships/public-list/',
  AI_PRODUCT_LISTING: 'memberships/ai-products/',
  AI_PRODUCT_CHECKOUT: 'memberships/ai-product/checkout/',
  WHITE_LABEL_EMAIL: 'users/white-label/email/',
  WHITE_LABEL_EMAIL_LIST: 'users/white-label/email/list/',
  WHITE_LABEL_EMAIL_VALIDATE: domainId => `users/white-label/email/${domainId}/verify/`,
  WHITE_LABEL_EMAIL_VERIFIED_LIST: 'users/white-label/email/verified-list/',
  DELETE_DOMAIN: domainId => `users/white-label/email/${domainId}/delete/`,
  GOALS_PROGRESS: organizationId => `dashboards/${organizationId}/goals/progress/`,
  ORGANIZATION_GOALS: organizationId => `dashboards/${organizationId}/goals/`,
  DELETE_GOAL: (organizationId, goalId) => `dashboards/${organizationId}/goals/${goalId}/`,
  CREATE_GOAL: organizationId => `dashboards/${organizationId}/goals/create/`,
  WIDGET_LIST: (organizationId, dashboardId) => `dashboards/${organizationId}/dashboard/${dashboardId}/widget-list/`,
  UPDATE_GOAL: (organizationId, goalId) => `dashboards/${organizationId}/goals/${goalId}/`,
  GOALS_WIDGET: (organizationId, dashboardId) => `dashboards/${organizationId}/dashboard/${dashboardId}/goals/`,
  ADD_TO_DASHBOARD: (organizationId, dashboardId, goalId) =>
    `dashboards/${organizationId}/dashboard/${dashboardId}/goals/${goalId}/add-to-dashboard/`,
  DELETE_WIDGET_GOAL: (organizationId, widgetId) => `dashboards/${organizationId}/widgets/${widgetId}/delete/`,
  INSTAGRAM_DATA_SOURCES_PAGES: oAuthId => `datasources/instagram/${oAuthId}/pages/`,
  TIK_TOK_ADVERTISERS: oAuthId => `datasources/tiktok/${oAuthId}/advertisers/`,
  GOOGLE_ADS_MANAGERS: oAuthId => `datasources/google-ads/${oAuthId}/managers/`,
  GOOGLE_ADS_MANAGERS_ACCOUNTS: (oAuthId, managerId) =>
    `datasources/google-ads/${oAuthId}/managers/${managerId}/accounts/`,
  DATASOURCE_VALIDATE_NAME: 'datasources/validate-name/',
  YOUTUBE_DATA_SOURCES: oAuthId => `datasources/youtube/${oAuthId}/channels/`,
  WOO_COMMERCE_STORE_VALIDATE: 'datasources/woocommerce/validate-store/',
  DATA_SOURCES_RE_OAUTH: oAuthId => `datasources/re-oauth/${oAuthId}/`,
};
