import { useEffect } from 'react';
import { Form } from 'antd';
import { isEmpty } from 'lodash';
import { FormInput, ImageUpload, Loader, ImageCrop, AuthScreenWrapper, Button } from '@common/components';
import { useOnBoarding } from '@pages/onBoarding';
import { getFile, handleSave, removeLocalStorageItem } from '@utils';
import { ORGANIZATION_ID_BY_INVITE_ACCEPTANCE } from '@constants';
import { ImageUploaderIcon, PlusIconWhite } from '@assets/svgs';

export function OnBoarding() {
  const {
    formik,
    imageRef,
    uploadedImage,
    isUploadedImage,
    invitedOrganization,
    onBoardingLoading,
    handleImageUpload,
    setIsUploadedImage,
  } = useOnBoarding();

  const { initialValues, values, handleChange, touched, errors, handleSubmit, setFieldValue, dirty } = formik;

  useEffect(() => {
    return () => {
      removeLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
    };
  }, []);

  return (
    <AuthScreenWrapper title='Onboarding' desc='We are excited to have you join us on this journey! 🎉'>
      <div>
        <Form onFinish={handleSubmit} autoComplete='off' className='mt-10 flex flex-col justify-center'>
          <div className='mb-8'>
            <ImageUpload
              id='profileImage'
              icon={<PlusIconWhite className='h-[1.25rem] w-[1.25rem]' />}
              imageRef={imageRef}
              placeHolderIcon={<ImageUploaderIcon />}
              className='h-[6rem] min-w-[6rem]'
              profileImage={uploadedImage?.image || values?.profileImage}
              handleChange={e => getFile(e.target.files[0], 'profileImage', setFieldValue, setIsUploadedImage)}
              showError={touched.profileImage && errors.profileImage}
              errorMessage={errors.profileImage}
              inputWrapperClassName=''
              showSkeletonLoader={isUploadedImage}
            />
          </div>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col items-start gap-4 md:flex-row'>
              <FormInput
                id='firstName'
                className='w-full'
                labelText='First Name'
                placeholder='Enter first name'
                value={values.firstName}
                handleChange={handleChange}
                showError={touched.firstName && errors.firstName}
              />
              <FormInput
                id='lastName'
                className='w-full'
                labelText='Last Name'
                placeholder='Enter last name'
                value={values.lastName}
                handleChange={handleChange}
                showError={touched.lastName && errors.lastName}
              />
            </div>
            <FormInput labelText='Email' placeholder='Email' disabled={true} value={values.email} />
            {!isEmpty(invitedOrganization) && (
              <FormInput
                labelText='Organization Name'
                placeholder='Organization Name'
                disabled={true}
                value={invitedOrganization?.name}
              />
            )}
            <Button
              htmlType='submit'
              className='md:mt-3.5'
              size='lg'
              disabled={isUploadedImage || onBoardingLoading || !dirty}
            >
              Get Started {onBoardingLoading ? <Loader /> : ''}
            </Button>
          </div>
        </Form>
        {isUploadedImage && (
          <ImageCrop
            uploadedImage={values?.profileImage}
            handleCancel={() => {
              setIsUploadedImage(false);
              setFieldValue('profileImage', initialValues.profileImage);
              imageRef.current.value = initialValues.profileImage;
            }}
            handleCloseDialog={() => setFieldValue('profileImage', initialValues.profileImage)}
            imageRef={imageRef}
            setIsUploaded={setIsUploadedImage}
            handleSave={file => handleSave(file, handleImageUpload)}
            isDialog
            cancel={false}
            title='Crop'
          />
        )}
      </div>
    </AuthScreenWrapper>
  );
}
