import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { Modal, Skeleton, Table } from 'antd';
import { X } from 'lucide-react';
import moment from 'moment';
import { CustomPagination, DeleteDialog, NoDataFoundScreen, TableSkeletonLoader } from '@common/components';
import { DashboardActionPopover, DataSourcePopover, TeamPopover } from '@pages/dashboard';
import { ROUTES } from '@routes';
import { COLORS } from '@constants';

export function DashboardTabularView({
  dashboardsListingFetching,
  dashboardsListing,
  setDashboardId,
  handleDeleteDashboard,
  selectedDashboard,
  setSelectedDashboard,
  showDeleteDialog,
  setShowDeleteDialog,
  deleteDashboardLoading,
  handleDuplicateDashboard,
  isMember,
  setDeleteDashboardLoading,
  page,
  viewType,
  setPage,
  data,
  totalItems,
  setTotalItems,
  paginationPage,
  setPaginationPage,
  noDashboards,
}) {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) =>
        dashboardsListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <div className='inline-flex items-center gap-3'>
            <span className='inline-block h-10 w-10'>
              <img src={record?.logo?.image} alt='dashboard' className='h-full w-full' />
            </span>
            <p className='text-sm font-medium leading-5 text-mainText'>{text}</p>
          </div>
        ),
    },
    {
      title: 'Last Update',
      dataIndex: 'modified',
      key: 'modified',
      render: text =>
        dashboardsListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          moment(text).fromNow()
        ),
    },
    {
      title: 'Teams With Access',
      dataIndex: 'teams',
      key: 'teams',
      render: (text, record) =>
        dashboardsListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <TeamPopover dashboard={record} />
        ),
    },
    {
      title: 'Data Sources',
      dataIndex: 'data_sources',
      key: 'data_sources',
      render: (text, record) =>
        dashboardsListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <DataSourcePopover dashboard={record} />
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      render: (text, record) =>
        dashboardsListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <DashboardActionPopover
            dashboard={record}
            setDashboardId={setDashboardId}
            handleDuplicateDashboard={handleDuplicateDashboard}
            setSelectedDashboard={setSelectedDashboard}
            setShowDeleteDialog={setShowDeleteDialog}
            showDeleteDialog={showDeleteDialog}
            isMember={isMember}
          />
        ),
    },
  ];

  const handleRowClick = record => {
    navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: record.id }), {
      state: { page, viewType },
    });
  };

  return (
    <>
      <div className=''>
        {dashboardsListingFetching ? (
          <div className='h-[calc(100vh-215px)] min-h-[23.25rem] overflow-y-auto rounded-b-xl scrollbar-none'>
            <TableSkeletonLoader columns={columns} rowCount='12' />
          </div>
        ) : noDashboards ? (
          <div className='h-[calc(100vh-215px)] min-h-[23.25rem] overflow-y-auto scrollbar-none'>
            <NoDataFoundScreen />
          </div>
        ) : (
          <div className='flex h-full flex-col'>
            <Table
              columns={columns}
              dataSource={dashboardsListing?.data?.results}
              rowKey='id'
              rowClassName='cursor-pointer'
              className='table-bold-thin h-[calc(100vh-256px)] min-h-[23.25rem] overflow-auto scrollbar-none'
              onRow={record => ({
                onClick: () => handleRowClick(record),
              })}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
            <CustomPagination
              page={page}
              setPage={setPage}
              data={data}
              totalItems={totalItems}
              setTotalItems={setTotalItems}
              paginationPage={paginationPage}
              setPaginationPage={setPaginationPage}
            />
          </div>
        )}
      </div>
      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        onCancel={() => setShowDeleteDialog(false)}
        centered={true}
        open={showDeleteDialog}
      >
        <DeleteDialog
          heading={selectedDashboard?.name}
          text={selectedDashboard?.name}
          className='min-h-[12.75rem] w-[calc(100vw-2.5rem)] max-w-[24.375rem] rounded-md px-5 py-6 md:min-h-[20rem] md:max-w-[37.0625rem] md:p-10 lg:min-h-[12.75rem] lg:max-w-[21.375rem] xxl:min-h-[16.5625rem] xxl:max-w-[28.125rem]'
          textClass='!mb-4'
          deleteBtnClick={() => {
            handleDeleteDashboard();
            setDeleteDashboardLoading(true);
          }}
          isLoading={deleteDashboardLoading}
        />
      </Modal>
    </>
  );
}
