import { useEffect } from 'react';
import { FormInput, Loader, AuthScreenWrapper, Button } from '@common/components';
import { useGoogleSignIn, useExpressLogin } from '@pages/signIn';
import { removeLocalStorageItem } from '@utils';
import { ENTER, ENTER_KEY, ORGANIZATION_ID_BY_INVITE_ACCEPTANCE } from '@constants';
import { Google } from '@assets/svgs';

export function SignIn() {
  const { handleGoogleLogin } = useGoogleSignIn();
  const { formik, isExpressLoading } = useExpressLogin();

  const { values, handleChange, touched, errors, submitForm } = formik;

  useEffect(() => {
    return () => {
      removeLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
    };
  }, []);
  return (
    <AuthScreenWrapper title='Let’s Get Started' desc='Welcome! Please enter your email below to get started.'>
      <div className='mt-6 flex flex-col gap-4 md:mt-10 md:gap-[1.875rem]'>
        <FormInput
          id='email'
          labelText='Email'
          placeholder='Enter your email'
          type='email'
          value={values.email}
          handleChange={handleChange}
          showError={touched.email && errors.email}
          onKeyDown={e => {
            if (e.code === ENTER || e.keyCode === ENTER_KEY) submitForm();
          }}
        />
        <div className='flex flex-col gap-3 md:gap-4'>
          <Button size='lg' disabled={!values.email || isExpressLoading} onClick={submitForm}>
            Continue {isExpressLoading ? <Loader /> : ''}
          </Button>
          <div className='flex items-center gap-2 whitespace-nowrap font-Manrope text-xs leading-4 text-bodyText md:text-sm md:leading-5'>
            <div className='h-px w-full bg-secondaryStrokeButton'></div>
            Or continue with
            <div className='h-px w-full bg-secondaryStrokeButton'></div>
          </div>
          <Button size='lg' variant='tertiary' onClick={handleGoogleLogin}>
            <Google className='h-4 w-4 md:h-5 md:w-5' />
            Google
          </Button>
        </div>
      </div>
    </AuthScreenWrapper>
  );
}
