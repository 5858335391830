import { Popover } from 'antd';
import { InfoIcon } from 'lucide-react';
import { COLORS } from '@constants';

export function ConnectorGuide({ showInfoIcon = false, infoContent }) {
  return (
    <>
      {showInfoIcon && (
        <Popover
          placement='topLeft'
          content={
            <p>
              {infoContent.text}{' '}
              {!!infoContent.link && (
                <a className='text-darkPrimary underline' target='_blank' href={infoContent.link} rel='noreferrer'>
                  link
                </a>
              )}
            </p>
          }
        >
          <InfoIcon
            color={COLORS.DARK_GREEN_THEME}
            className='absolute right-[-20px] top-[2px] h-4 w-4 cursor-pointer'
          />
        </Popover>
      )}
    </>
  );
}
