import { Drawer } from 'antd';
import { X } from 'lucide-react';
import ReportDetails from './ReportDetails';
import { CreateEditReport } from '..';
import { Loader } from '@common/components';
import { COLORS } from '@constants';

const EditScheduleReports = ({
  setIsEditMode,
  isEditMode,
  formik,
  handleInvitedEmailKeyDown,
  invitedEmails,
  handleRemoveInvitedEmail,
  emailFieldErrorMessage,
  invalidEmails,
  selectDay,
  handleSelectedDay,
  scheduleLoading,
  reportDetailFetching,
  setReportDetails,
  reportDetails,
}) => {
  const { resetForm } = formik;

  const handleClose = () => {
    setIsEditMode(false);
    setReportDetails(null);
    resetForm();
  };

  return (
    <Drawer
      title={reportDetails ? formik.values.subject : 'Edit Report'}
      mask={true}
      width={672}
      className='rounded-s-2xl'
      onClose={() => {
        setIsEditMode(false);
        setReportDetails(null);
      }}
      open={reportDetails ? reportDetails : isEditMode}
      closeIcon={null}
      extra={
        <div
          onClick={handleClose}
          className='flex h-6 w-6 items-center justify-center rounded-[0.25rem] border border-icon1 md:h-8 md:w-8'
        >
          <X className='h-3.5 w-3.5 md:h-5 md:w-5' color={COLORS.ICON_COLOR} />
        </div>
      }
    >
      {reportDetailFetching ? (
        <div className='flex h-full items-center justify-center '>
          <Loader className='h-12 w-12' strokeColorClass={COLORS.PRIMARY_GREEN_COLOR} />
        </div>
      ) : reportDetails ? (
        <ReportDetails data={formik.values} invitedEmails={invitedEmails} />
      ) : (
        <CreateEditReport
          formik={formik}
          handleInvitedEmailKeyDown={handleInvitedEmailKeyDown}
          invitedEmails={invitedEmails}
          handleRemoveInvitedEmail={handleRemoveInvitedEmail}
          emailFieldErrorMessage={emailFieldErrorMessage}
          invalidEmails={invalidEmails}
          selectDay={selectDay}
          handleSelectedDay={handleSelectedDay}
          scheduleLoading={scheduleLoading}
          isEditMode={isEditMode}
        />
      )}
    </Drawer>
  );
};

export default EditScheduleReports;
