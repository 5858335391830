import { Card, CardContent } from '@shadcnComponent';
import { ChevronLeft, Plus } from 'lucide-react';
import { BaseLoader, BaseToolTip, Progressbar } from '@common/components';
import { useGoalWidgets } from '@pages/dashboard';
import { truncateString } from '@utils';

export function GoalTrackingSidebar({
  handleOnDrag,
  isGoalTrackingMode,
  setIsGoalTrackingMode,
  showDashboardGoalDialog,
  setShowDashboardGoalDialog,
}) {
  const { goalWidgets, goalWidgetsFetching } = useGoalWidgets(isGoalTrackingMode);

  return (
    <div>
      <div
        className={`z-[20] hidden flex-col gap-4 overflow-y-auto border-l border-secondaryStrokeButton bg-white transition-all duration-500 ease-in-out scrollbar-none md:absolute md:flex md:h-[calc(100vh-17.6875rem)] md:w-[19.375rem] md:pb-8 md:pt-4 lg:w-[20.1875rem] lg:gap-3  lg:pb-7 xxl:h-[calc(100vh-10.625rem)] xxl:w-[21.3125rem] xxl:gap-3 xxl:pb-[2.3125rem] xxl:pt-6 ${isGoalTrackingMode ? 'right-0' : '-right-full'}`}
      >
        <h5 className='flex items-center justify-between border-b border-secondaryStrokeButton pb-5 text-2xl font-bold leading-[1.8156rem] md:px-4 md:text-lg md:leading-6 lg:text-base xxl:text-lg'>
          <span className='inline-flex cursor-pointer items-center gap-1' onClick={() => setIsGoalTrackingMode(false)}>
            <ChevronLeft /> Goal tracking
          </span>
          <span
            className='cursor-pointer rounded-sm bg-darkPrimary p-1 hover:opacity-[.7]'
            onClick={() => setShowDashboardGoalDialog(!showDashboardGoalDialog)}
          >
            <Plus color='#fff' />
          </span>
        </h5>
        {goalWidgetsFetching ? (
          <BaseLoader />
        ) : goalWidgets?.data?.count > 0 ? (
          <ul className='flex flex-col gap-4 md:px-4 lg:p-5 lg:pl-[1.375rem] xxl:px-7'>
            {goalWidgets?.data?.results?.map(widget => (
              <li key={widget.id} draggable onDragStart={e => handleOnDrag(e, widget)}>
                <Card>
                  <CardContent className='px-4 py-[1.125rem]'>
                    <div className='mb-[0.8125rem] flex items-center justify-between'>
                      <span className='text-sm font-medium leading-6 text-slateGray'>Name</span>
                      <h4 id={`name-${widget?.id}`} className='text-sm font-medium leading-6'>
                        {widget?.name?.length > 22 && <BaseToolTip id={`name-${widget?.id}`} content={widget?.name} />}
                        {truncateString(widget.name, 22)}
                      </h4>
                    </div>
                    <div className='mb-4 flex items-center justify-between'>
                      <span className='text-sm font-medium leading-6 text-slateGray'>Widget</span>
                      <h4 id={`label-${widget?.id}`} className='text-sm font-medium leading-6'>
                        {widget?.widgetLabel?.length > 22 && (
                          <BaseToolTip id={`label-${widget?.id}`} content={widget?.widgetLabel} />
                        )}
                        {truncateString(widget.widgetLabel, 22)}
                      </h4>
                    </div>
                    <div className='flex items-center justify-between'>
                      <span className='text-sm font-medium leading-6 text-slateGray'>Progress</span>
                      <div className='w-[10.375rem]'>
                        <Progressbar progressValue={(widget.achieved * 100) / widget.target} />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </li>
            ))}
          </ul>
        ) : (
          <p className='mt-4 flex justify-center text-[1rem] text-darkSecondary'>No goal found</p>
        )}
      </div>
    </div>
  );
}
