export function WelcomeHeaderIcon() {
  return (
    <svg width='1144' height='208' viewBox='0 0 1144 208' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_176_10988'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='1144'
        height='208'
      >
        <path
          d='M1124 0H20C8.9543 0 0 8.9543 0 20V188C0 199.046 8.9543 208 20 208H1124C1135.05 208 1144 199.046 1144 188V20C1144 8.9543 1135.05 0 1124 0Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_176_10988)'>
        <mask
          id='mask1_176_10988'
          style={{ maskType: 'luminance' }}
          maskUnits='userSpaceOnUse'
          x='321'
          y='0'
          width='816'
          height='232'
        >
          <path d='M321 0H1137V232H321V0Z' fill='white' />
        </mask>
        <g mask='url(#mask1_176_10988)'>
          <g opacity='0.5'>
            <path
              d='M1113.62 200.795H1232.44C1237.13 200.795 1240.93 204.503 1240.93 209.077V242.205C1240.93 246.779 1237.13 250.487 1232.44 250.487H1113.62C1108.94 250.487 1105.14 246.779 1105.14 242.205V209.077C1105.14 204.503 1108.94 200.795 1113.62 200.795Z'
              fill='url(#paint0_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M1079.68 200.795H960.862C956.175 200.795 952.375 204.503 952.375 209.077V242.205C952.375 246.779 956.175 250.487 960.862 250.487H1079.68C1084.36 250.487 1088.16 246.779 1088.16 242.205V209.077C1088.16 204.503 1084.36 200.795 1079.68 200.795Z'
              fill='url(#paint1_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M808.1 200.795H926.915C931.602 200.795 935.402 204.503 935.402 209.077V242.205C935.402 246.779 931.602 250.487 926.915 250.487H808.1C803.413 250.487 799.613 246.779 799.613 242.205V209.077C799.613 204.503 803.413 200.795 808.1 200.795Z'
              fill='url(#paint2_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M774.153 200.795H655.338C650.651 200.795 646.851 204.503 646.851 209.077V242.205C646.851 246.779 650.651 250.487 655.338 250.487H774.153C778.84 250.487 782.64 246.779 782.64 242.205V209.077C782.64 204.503 778.84 200.795 774.153 200.795Z'
              fill='url(#paint3_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M502.576 200.795H621.391C626.078 200.795 629.878 204.503 629.878 209.077V242.205C629.878 246.779 626.078 250.487 621.391 250.487H502.576C497.889 250.487 494.089 246.779 494.089 242.205V209.077C494.089 204.503 497.889 200.795 502.576 200.795Z'
              fill='url(#paint4_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M468.629 200.795H349.814C345.127 200.795 341.327 204.503 341.327 209.077V242.205C341.327 246.779 345.127 250.487 349.814 250.487H468.629C473.316 250.487 477.116 246.779 477.116 242.205V209.077C477.116 204.503 473.316 200.795 468.629 200.795Z'
              fill='url(#paint5_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M197.052 200.795H315.867C320.554 200.795 324.354 204.503 324.354 209.077V242.205C324.354 246.779 320.554 250.487 315.867 250.487H197.052C192.365 250.487 188.565 246.779 188.565 242.205V209.077C188.565 204.503 192.365 200.795 197.052 200.795Z'
              fill='url(#paint6_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M1113.62 134.538H1232.44C1237.13 134.538 1240.93 138.246 1240.93 142.82V175.949C1240.93 180.523 1237.13 184.231 1232.44 184.231H1113.62C1108.94 184.231 1105.14 180.523 1105.14 175.949V142.82C1105.14 138.246 1108.94 134.538 1113.62 134.538Z'
              fill='url(#paint7_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M1079.68 134.538H960.862C956.175 134.538 952.375 138.246 952.375 142.82V175.949C952.375 180.523 956.175 184.231 960.862 184.231H1079.68C1084.36 184.231 1088.16 180.523 1088.16 175.949V142.82C1088.16 138.246 1084.36 134.538 1079.68 134.538Z'
              fill='url(#paint8_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M808.1 134.538H926.915C931.602 134.538 935.402 138.246 935.402 142.82V175.949C935.402 180.523 931.602 184.231 926.915 184.231H808.1C803.413 184.231 799.613 180.523 799.613 175.949V142.82C799.613 138.246 803.413 134.538 808.1 134.538Z'
              fill='url(#paint9_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M774.153 134.538H655.338C650.651 134.538 646.851 138.246 646.851 142.82V175.949C646.851 180.523 650.651 184.231 655.338 184.231H774.153C778.84 184.231 782.64 180.523 782.64 175.949V142.82C782.64 138.246 778.84 134.538 774.153 134.538Z'
              fill='url(#paint10_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M502.576 134.538H621.391C626.078 134.538 629.878 138.246 629.878 142.82V175.949C629.878 180.523 626.078 184.231 621.391 184.231H502.576C497.889 184.231 494.089 180.523 494.089 175.949V142.82C494.089 138.246 497.889 134.538 502.576 134.538Z'
              fill='url(#paint11_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M468.629 134.538H349.814C345.127 134.538 341.327 138.246 341.327 142.82V175.949C341.327 180.523 345.127 184.231 349.814 184.231H468.629C473.316 184.231 477.116 180.523 477.116 175.949V142.82C477.116 138.246 473.316 134.538 468.629 134.538Z'
              fill='url(#paint12_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M197.052 134.538H315.867C320.554 134.538 324.354 138.246 324.354 142.82V175.949C324.354 180.523 320.554 184.231 315.867 184.231H197.052C192.365 184.231 188.565 180.523 188.565 175.949V142.82C188.565 138.246 192.365 134.538 197.052 134.538Z'
              fill='url(#paint13_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M1113.62 68.282H1232.44C1237.13 68.282 1240.93 71.99 1240.93 76.5641V109.692C1240.93 114.266 1237.13 117.974 1232.44 117.974H1113.62C1108.94 117.974 1105.14 114.266 1105.14 109.692V76.5641C1105.14 71.99 1108.94 68.282 1113.62 68.282Z'
              fill='url(#paint14_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M1079.68 68.282H960.862C956.175 68.282 952.375 71.99 952.375 76.5641V109.692C952.375 114.266 956.175 117.974 960.862 117.974H1079.68C1084.36 117.974 1088.16 114.266 1088.16 109.692V76.5641C1088.16 71.99 1084.36 68.282 1079.68 68.282Z'
              fill='url(#paint15_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M808.1 68.282H926.915C931.602 68.282 935.402 71.99 935.402 76.5641V109.692C935.402 114.266 931.602 117.974 926.915 117.974H808.1C803.413 117.974 799.613 114.266 799.613 109.692V76.5641C799.613 71.99 803.413 68.282 808.1 68.282Z'
              fill='url(#paint16_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M774.153 68.282H655.338C650.651 68.282 646.851 71.99 646.851 76.5641V109.692C646.851 114.266 650.651 117.974 655.338 117.974H774.153C778.84 117.974 782.64 114.266 782.64 109.692V76.5641C782.64 71.99 778.84 68.282 774.153 68.282Z'
              fill='url(#paint17_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M502.576 68.282H621.391C626.078 68.282 629.878 71.99 629.878 76.5641V109.692C629.878 114.266 626.078 117.974 621.391 117.974H502.576C497.889 117.974 494.089 114.266 494.089 109.692V76.5641C494.089 71.99 497.889 68.282 502.576 68.282Z'
              fill='url(#paint18_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M468.629 68.282H349.814C345.127 68.282 341.327 71.99 341.327 76.5641V109.692C341.327 114.266 345.127 117.974 349.814 117.974H468.629C473.316 117.974 477.116 114.266 477.116 109.692V76.5641C477.116 71.99 473.316 68.282 468.629 68.282Z'
              fill='url(#paint19_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M197.052 68.282H315.867C320.554 68.282 324.354 71.99 324.354 76.5641V109.692C324.354 114.266 320.554 117.974 315.867 117.974H197.052C192.365 117.974 188.565 114.266 188.565 109.692V76.5641C188.565 71.99 192.365 68.282 197.052 68.282Z'
              fill='url(#paint20_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M1113.62 2.02563H1232.44C1237.13 2.02563 1240.93 5.73361 1240.93 10.3077V43.4359C1240.93 48.0099 1237.13 51.718 1232.44 51.718H1113.62C1108.94 51.718 1105.14 48.0099 1105.14 43.4359V10.3077C1105.14 5.73361 1108.94 2.02563 1113.62 2.02563Z'
              fill='url(#paint21_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M1079.68 2.02563H960.862C956.175 2.02563 952.375 5.73361 952.375 10.3077V43.4359C952.375 48.0099 956.175 51.718 960.862 51.718H1079.68C1084.36 51.718 1088.16 48.0099 1088.16 43.4359V10.3077C1088.16 5.73361 1084.36 2.02563 1079.68 2.02563Z'
              fill='url(#paint22_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M808.1 2.02563H926.915C931.602 2.02563 935.402 5.73361 935.402 10.3077V43.4359C935.402 48.0099 931.602 51.718 926.915 51.718H808.1C803.413 51.718 799.613 48.0099 799.613 43.4359V10.3077C799.613 5.73361 803.413 2.02563 808.1 2.02563Z'
              fill='url(#paint23_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M774.153 2.02563H655.338C650.651 2.02563 646.851 5.73361 646.851 10.3077V43.4359C646.851 48.0099 650.651 51.718 655.338 51.718H774.153C778.84 51.718 782.64 48.0099 782.64 43.4359V10.3077C782.64 5.73361 778.84 2.02563 774.153 2.02563Z'
              fill='url(#paint24_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M502.576 2.02563H621.391C626.078 2.02563 629.878 5.73361 629.878 10.3077V43.4359C629.878 48.0099 626.078 51.718 621.391 51.718H502.576C497.889 51.718 494.089 48.0099 494.089 43.4359V10.3077C494.089 5.73361 497.889 2.02563 502.576 2.02563Z'
              fill='url(#paint25_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M468.629 2.02563H349.814C345.127 2.02563 341.327 5.73361 341.327 10.3077V43.4359C341.327 48.0099 345.127 51.718 349.814 51.718H468.629C473.316 51.718 477.116 48.0099 477.116 43.4359V10.3077C477.116 5.73361 473.316 2.02563 468.629 2.02563Z'
              fill='url(#paint26_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              d='M197.052 2.02563H315.867C320.554 2.02563 324.354 5.73361 324.354 10.3077V43.4359C324.354 48.0099 320.554 51.718 315.867 51.718H197.052C192.365 51.718 188.565 48.0099 188.565 43.4359V10.3077C188.565 5.73361 192.365 2.02563 197.052 2.02563Z'
              fill='url(#paint27_radial_176_10988)'
              fillOpacity='0.3'
            />
            <path
              opacity='0.4'
              d='M618 234L645.757 215.634C653.984 210.191 664.651 210.13 672.94 215.479L685.857 223.814C695.195 229.841 707.4 228.932 715.743 221.59L727.29 211.429C734.593 205.002 744.976 203.429 753.856 207.405L761.603 210.873C772.814 215.892 786.01 211.966 792.654 201.635L804.844 182.679C813.016 169.971 830.893 168.183 841.42 179.022C852.147 190.066 870.424 187.964 878.364 174.774L891.991 152.136C899.163 140.221 915.171 137.315 926.073 145.95C934.767 152.835 947.13 152.559 955.507 145.292L963.029 138.768C974.556 128.77 992.286 131.426 1000.38 144.363L1014.77 167.377C1025.28 184.189 1050.28 182.518 1058.47 164.456L1102.97 66.2078C1104.78 62.2167 1107.61 58.7746 1111.18 56.2307L1211.44 -15.2671C1220.37 -21.6376 1232.42 -21.3944 1241.09 -14.6689L1328.95 53.4903L1331.94 55.3895C1363.4 75.3191 1404.65 69.5938 1429.49 41.8512L1468 -1.15931'
              stroke='url(#paint28_linear_176_10988)'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <g style={{ mixBlendMode: 'color-burn' }}>
              <path d='M321 -47H1137V211H321V-47Z' />
            </g>
          </g>
        </g>
      </g>
      <defs>
        <radialGradient
          id='paint0_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint1_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint2_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint3_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint4_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint5_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint6_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint7_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint8_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint9_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint10_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint11_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint12_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint13_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint14_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint15_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint16_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint17_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint18_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint19_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint20_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint21_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint22_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint23_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint24_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint25_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint26_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <radialGradient
          id='paint27_radial_176_10988'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(794.149 258.736) scale(732.393 354.938)'
        >
          <stop stopColor='#EDECF3' stopOpacity='0.23' />
          <stop offset='1' stopColor='#E6E5F5' stopOpacity='0.01' />
        </radialGradient>
        <linearGradient
          id='paint28_linear_176_10988'
          x1='791.989'
          y1='315.258'
          x2='865.66'
          y2='-141.091'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' stopOpacity='0.01' />
          <stop offset='0.177083' stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0.01' />
        </linearGradient>
      </defs>
    </svg>
  );
}
