import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import { SUCCESSFUL_MESSAGE, TOTAL_ITEMS_PER_PAGE } from '@constants';

export function useDomainTab() {
  const [domainId, setDomainId] = useState(null);
  const [search, setSearch] = useState('');
  const [showAddDomain, setShowAddDomain] = useState(false);
  const [deleteDomainId, setDeleteDomainId] = useState(null);
  const [accordionItem, setAccordionItem] = useState();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [paginationPage, setPaginationPage] = useState(1);
  const [page, setPage] = useState(1);
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);

  const domainInitialValues = {
    emailDomain: '',
  };

  const validationSchema = Yup.object({
    emailDomain: Yup.string(),
  });

  const handleSubmit = () => {
    let payload = { emailDomain: values.emailDomain };
    handleAddDomain({ payload });
  };

  const queryKey = `email-listing-${totalPagesPerItem}-${page}-${search}`;

  const {
    data: emailListing,
    refetch: emailDomainDetailRefetch,
    isFetching: emailListingFetching,
  } = useGetQuery(
    queryKey,
    apiEndpoints.WHITE_LABEL_EMAIL_LIST,
    {
      page,
      pageSize: Number(totalPagesPerItem),
      search,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: error => toast.error(error?.response?.data?.detail || 'Something went wrong'),
    }
  );

  const { mutate: handleAddDomain, isLoading: addDomainLoading } = usePostMutation(
    apiEndpoints.WHITE_LABEL_EMAIL,
    () => {
      setShowAddDomain(false);
      emailDomainDetailRefetch();
      resetForm();
    },
    ({ response: { data } }) => setErrors({ emailDomain: data.detail[0] && data.detail[0] })
  );

  const { mutate: handleAddDomainValidate, isLoading: addDomainValidateLoading } = usePostMutation(
    apiEndpoints.WHITE_LABEL_EMAIL_VALIDATE(domainId),
    data => {
      if (!data.data.valid) {
        toast.error(
          'Please ensure you have pasted the required Host and Data values into your domain platform and saved the changes. Check if the values are correctly pasted. Once complete, click Verify to proceed.'
        );
      } else emailDomainDetailRefetch();
      setDomainId(null);
    },
    ({ response: { data } }) => setErrors({ emailDomain: data.detail[0] && data.detail[0] })
  );

  const { mutate: handleDeleteDomain, isLoading: deleteDomainLoading } = useDeleteMutation(
    apiEndpoints.DELETE_DOMAIN(deleteDomainId),
    () => {
      emailDomainDetailRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Domain deleted'));
      setDeleteDomainId(null);
      setShowDeleteDialog(false);
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setShowDeleteDialog(false);
    }
  );

  const { ...formik } = useFormikForm(domainInitialValues, handleSubmit, validationSchema);
  const { values, resetForm, setErrors } = formik;

  const copyToClipboard = text => {
    navigator.clipboard.writeText(text);
    toast.success('Copied');
  };

  useEffect(() => {
    domainId && handleAddDomainValidate({ payload: {} });
  }, [domainId]);

  useEffect(() => {
    setPage(1);
    setPaginationPage(1);
  }, [search, totalPagesPerItem]);

  return {
    formik,
    emailListing,
    handleAddDomain,
    addDomainLoading,
    showAddDomain,
    setShowAddDomain,
    handleAddDomainValidate,
    addDomainValidateLoading,
    setDomainId,
    accordionItem,
    setAccordionItem,
    copyToClipboard,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDomainLoading,
    setDeleteDomainId,
    handleDeleteDomain,
    page,
    setPage,
    search,
    setSearch,
    setTotalPagesPerItem,
    totalPagesPerItem,
    emailListingFetching,
    deleteDomainId,
    setPaginationPage,
    paginationPage,
    setExpandedRowKeys,
    expandedRowKeys,
    domainId,
  };
}
