import Markdown from 'react-markdown';
import SyncLoader from 'react-spinners/SyncLoader';
import { COLORS, MESSAGE } from '@constants';

export function ChatbotMessage({ Message, text, isLoading }) {
  return (
    <div className={`flex ${!(Message === MESSAGE.RECEIVER) && 'justify-end'}`}>
      <div
        className={`max-w-[15.8125rem] break-words rounded-lg px-4 py-3 text-sm font-normal leading-5  ${Message === MESSAGE.RECEIVER ? 'bg-tableHeaderBackground2' : 'bg-highlighterText1'}`}
      >
        {isLoading ? (
          <SyncLoader size='6' color={`${Message === MESSAGE.RECEIVER ? COLORS.DARK_SECONDARY : COLORS.WHITE_COLOR}`} />
        ) : (
          <Markdown className={`mark-down-field ${Message === MESSAGE.RECEIVER ? 'text-mainText' : 'text-white'}`}>
            {text}
          </Markdown>
        )}
      </div>
    </div>
  );
}
