import { RefreshCcw } from 'lucide-react';
import { CustomCheckbox } from '@common/components';
import { COLORS } from '@constants';

export function OAuthUrlListing({ listing, checkedId, setCheckedId, handleReOauth, reOauthLoading }) {
  return (
    <div className='flex h-[12rem] flex-col overflow-y-auto'>
      {listing?.data?.results?.map(oauth => (
        <div
          key={oauth?.id}
          className='flex cursor-pointer items-center justify-between gap-2 border-b border-secondaryStrokeButton px-4 py-3.5'
          onClick={() => !oauth?.isExpired && setCheckedId(prev => (prev === oauth?.id ? null : oauth.id))}
        >
          <div className='flex items-center gap-2 text-sm leading-5'>
            {oauth?.label && (
              <CustomCheckbox
                id={`checkbox-${oauth?.id}`}
                checked={!oauth?.isExpired && checkedId === oauth?.id}
                disabled={oauth?.isExpired}
                // onChange={() => setCheckedId(oauth.id)}
              />
            )}
            <label className={`cursor-pointer ${oauth?.isExpired && 'text-errorTooltip'}`}>{oauth?.label}</label>
          </div>
          {oauth?.isExpired && (
            <span
              className='cursor-pointer hover:opacity-[.7]'
              onClick={() => {
                setCheckedId(oauth.id);
                handleReOauth({});
              }}
            >
              <RefreshCcw
                color={COLORS.ERROR_TOOLTIP}
                className={`h-4 w-4 ${checkedId === oauth?.id && reOauthLoading && 'duration-[500ms] animate-spin'} transition-all`}
              />
            </span>
          )}
        </div>
      ))}
    </div>
  );
}
