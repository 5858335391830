import { Modal } from 'antd';
import { Building2, ChevronDown, CirclePlus, X } from 'lucide-react';
import Skeleton from 'react-loading-skeleton';
import { AddEditOrgDialog, BaseToolTip } from '@common/components';
import { truncateString } from '@utils';
import { COLORS, CREATE_ORG_BUTTON_ID, SIDEBAR_CONTENT_DROPDOWN_ID } from '@constants';
import { PlusIconWhite } from '@assets/svgs';

export const OrganizationsNames = ({
  dropdownRef,
  openSidebar,
  setOpenSidebar,
  orgDropdown,
  setOrgDropdown,
  organizationListingLoading,
  switcherSelectedOrganization,
  handleSelectedOrganization,
  organizationListing,
  showAiToken,
  setShowAddEditOrgDialog,
  createOrganizationLoading,
  formik,
  imageRef,
  uploadedLogo,
  handleImageUpload,
  setUploadedLogo,
  setIsUploadedLogo,
  showAddEditOrgDialog,
  isUploadedLogo,
}) => {
  return (
    <div
      ref={dropdownRef}
      id={SIDEBAR_CONTENT_DROPDOWN_ID}
      className={`relative ${openSidebar ? 'mx-6 w-[14.5rem]' : 'mx-auto w-[3.375rem]'} mt-6`}
    >
      <div
        onClick={() => {
          setOpenSidebar(true);
          setOrgDropdown(prev => !prev);
        }}
        className='flex h-[3.375rem] cursor-pointer items-center justify-center rounded-xl border border-secondaryStrokeButton p-3'
      >
        <div className='flex w-full items-center gap-2'>
          {organizationListingLoading ? (
            <Skeleton containerClassName='h-8 w-8 block' className='flex h-7 w-7' circle={true} />
          ) : switcherSelectedOrganization?.logo?.image ? (
            <div className='h-7 min-w-7'>
              <img src={switcherSelectedOrganization?.logo?.image} alt='profile-image' className='h-full w-full' />
            </div>
          ) : (
            <Building2 color={COLORS.ICON_COLOR} />
          )}
          {openSidebar && (
            <div className='flex w-full items-center justify-between'>
              {truncateString(switcherSelectedOrganization?.name, 12, 'text-mainText')} <ChevronDown />
            </div>
          )}
        </div>
      </div>
      {openSidebar && orgDropdown && (
        <div className='dropdown-shadow absolute mt-1 w-[14.5rem] rounded-xl border border-secondaryStrokeButton bg-white p-2'>
          <div className='max-h-[12.5rem] overflow-y-auto pb-2 scrollbar-none'>
            {organizationListing?.data?.results?.map(organization => (
              <div
                className='flex cursor-pointer items-center gap-2 rounded-xl px-3 py-2 hover:bg-shade4'
                key={organization.id}
                onClick={() => {
                  handleSelectedOrganization(organization);
                  setOrgDropdown(false);
                }}
              >
                <div className='flex h-8 w-8 min-w-8 items-center justify-center overflow-hidden rounded-full'>
                  {organization?.logo?.image ? (
                    <img src={organization?.logo?.image} alt='organization-logo' className='h-full w-full' />
                  ) : (
                    <Building2 color={COLORS.ICON_COLOR} />
                  )}
                </div>
                <p
                  id={`organizationName-${organization.id}`}
                  className='text-[0.625rem] font-normal leading-5 xxl:text-sm xxl:leading-[1.0588rem]'
                >
                  {truncateString(organization?.name, 30, 'text-darkSecondary')}
                </p>
                {organization?.name?.length > 30 && (
                  <BaseToolTip
                    className='!w-[7.5rem] xxl:!w-[11.25rem]'
                    id={`organizationName-${organization.id}`}
                    content={organization?.name}
                  />
                )}
              </div>
            ))}
          </div>
          {showAiToken && (
            <div
              id={CREATE_ORG_BUTTON_ID}
              className='cursor-pointer border-t border-secondaryStrokeButton p-3'
              onClick={() => {
                setShowAddEditOrgDialog(true);
                setOrgDropdown(false);
              }}
            >
              <div className='flex items-center gap-2 font-Manrope text-sm leading-4 text-mainText'>
                <CirclePlus className='h-5 w-5' color={COLORS.TAB_ACTIVE_COLOR} />
                Create New Organization
              </div>
            </div>
          )}
        </div>
      )}
      <Modal
        footer={null}
        width={420}
        closeIcon={<X color={COLORS.ICON_COLOR} />}
        open={showAddEditOrgDialog}
        onCancel={() => setShowAddEditOrgDialog(false)}
        centered={true}
      >
        <AddEditOrgDialog
          dialogTitle='Create Organization'
          formik={formik}
          imageRef={imageRef}
          uploadedLogo={uploadedLogo}
          handleImageUpload={handleImageUpload}
          setUploadedLogo={setUploadedLogo}
          setIsUploadedLogo={setIsUploadedLogo}
          icon={
            <div className='h-5 w-5'>
              <PlusIconWhite />
            </div>
          }
          isUploadedLogo={isUploadedLogo}
          organizationLoading={createOrganizationLoading}
        />
      </Modal>
    </div>
  );
};
