import { BaseLoader, Button, Loader } from '@common/components';
import { useAIToken } from '@pages/profile';
import { INSTRUCTION_DATA } from '@constants';

export function AiTokensTab() {
  const {
    aiTokenListing,
    handleAiTokenCheckout,
    aiTokenListingFetching,
    aiTokenCheckoutLoading,
    setSelectedTokenId,
    selectedTokenId,
  } = useAIToken();
  const planNames = ['Basic', 'Growth', 'Premium'];

  return (
    <div className='h-[calc(100vh-228px)] min-h-[372px] overflow-auto overflow-y-auto rounded-b-xl bg-white scrollbar-none'>
      {aiTokenListingFetching ? (
        <BaseLoader />
      ) : (
        <>
          <div className='flex flex-col gap-1 border-b px-5 py-4 font-Manrope md:p-6'>
            <h2 className='text-lg font-bold md:text-2xl'>AI Credits</h2>
            <p className='text-xs font-normal text-bodyText md:text-sm'>
              Unlock Your Potential: Choose the Perfect Plan for You!
            </p>
          </div>
          <div className='flex flex-col justify-between gap-5 px-5 pt-5 font-Manrope md:flex-row md:gap-3 md:px-6 md:pt-6 lg:gap-6'>
            {aiTokenListing?.data?.results?.map((token, index) => (
              <div key={token.id} className='w-full rounded-xl border p-6'>
                <div className='flex flex-col gap-2'>
                  <p className='text-base font-medium leading-[1.375rem] md:text-sm lg:text-base'>{planNames[index]}</p>
                  <span className='text-xl font-semibold md:text-lg lg:text-xl'>{token.nickname}</span>
                  <h3 className='text-[2rem] font-extrabold leading-[2.75rem] text-highlighterText1 md:text-2xl lg:text-[2rem]'>
                    ${token?.unitAmount / 100}
                  </h3>
                </div>
                <p className='pt-3 text-sm font-normal leading-5 md:text-[0.625rem] lg:text-sm'>
                  {JSON.parse(token?.metadata.key).description}
                </p>
                <div className='pt-6 font-semibold'>
                  <Button
                    size='sm'
                    className='gradientButton h-11 md:h-10 lg:h-11'
                    onClick={() => {
                      setSelectedTokenId(token?.id);
                      handleAiTokenCheckout({ payload: { priceId: token?.id } });
                    }}
                    disabled={aiTokenCheckoutLoading}
                  >
                    {aiTokenCheckoutLoading && selectedTokenId === token?.id ? <Loader size='sm' /> : 'Buy Now'}
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <div className='px-6 font-Manrope'>
            {!aiTokenListingFetching && (
              <div className='my-5 w-full md:mt-6 lg:mt-10'>
                <h2 className='mb-4 text-[1.5rem] font-bold leading-[2.125rem]'>Instruction</h2>
                <ul className='flex flex-col gap-2 pl-4 md:gap-3'>
                  {INSTRUCTION_DATA.map(data => (
                    <li key={data.id} className='instruction-list-item !text-xs font-normal text-bodyText md:!text-sm'>
                      {data.instruction}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
