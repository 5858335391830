import { Route, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom';
import { Layout } from '@common/components';
import { AccessDenied } from '@pages/AccessDenied';
import { Dashboard, DashboardFullView, DashboardListing } from '@pages/dashboard';
import { AddDataSource, DataSource, OauthConnection, ShopifyInstall } from '@pages/dataSource';
import { EmailAcceptance } from '@pages/emailAcceptance';
import { GoalTrack, GoalTrackGridView, GoalTrackTabularView } from '@pages/goalTracking';
import { Home, InvitedOrganizationTab, MyOrganizationTab } from '@pages/Home';
import { InvitesTab, ManageOrganization, MembersTab, TeamsTab } from '@pages/manageOrganization';
import { Membership } from '@pages/Membership';
import { OnBoarding } from '@pages/onBoarding';
import { OTPVerification } from '@pages/otpVerification';
import {
  Profile,
  MembershipTab,
  BillingTab,
  ProfileTab,
  SubscriptionExpired,
  AiTokensTab,
  DomainTab,
} from '@pages/profile';
import { CreateReport, Reports, ScheduledReports } from '@pages/reports';
import { SharedDashboard } from '@pages/sharedDashboard';
import { SignIn } from '@pages/signIn';
import { WhiteLabel } from '@pages/whiteLabel';
import { ROUTES, PrivateRoute } from '@routes';
import { getLocalStorageItem } from '@utils';
import { ACCESS_TOKEN, LOGIN_STATE, STATE } from '@constants';

const createPrivateRoute = Component => {
  return (
    <PrivateRoute>
      <Component />
    </PrivateRoute>
  );
};

const accessToken = getLocalStorageItem(ACCESS_TOKEN);
const loginState = getLocalStorageItem(STATE);

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.ORGANIZATIONS_INVITATIONS} element={<EmailAcceptance />} />
      <Route path={ROUTES.ACCESS_DENIED} element={<AccessDenied />} />
      <Route path={ROUTES.SUBSCRIPTION_EXPIRED} element={<SubscriptionExpired />} />
      <Route path={ROUTES.SHARED_DASHBOARD} element={<SharedDashboard />} />
      <Route path={ROUTES.DASHBOARD_FULL_VIEW} element={<DashboardFullView />} />
      <Route path={ROUTES.OAUTH_REDIRECTION} element={<OauthConnection />} />
      <Route path={ROUTES.MEMBERSHIPS} element={<Membership />} />
      {accessToken ? (
        loginState === LOGIN_STATE.VERIFIED ? (
          <>
            <Route path={ROUTES.ON_BOARDING} element={<OnBoarding />} />
            <Route path='*' element={<Navigate to={ROUTES.ON_BOARDING} />} />
          </>
        ) : (
          <>
            <Route path={ROUTES.SHOPIFY_INSTALL} element={<ShopifyInstall />} />
            <Route path={ROUTES.HOME} element={createPrivateRoute(Home)}>
              <Route path={ROUTES.MY_ORGANIZATIONS} element={createPrivateRoute(MyOrganizationTab)} />
              <Route path={ROUTES.INVITED_ORGANIZATIONS} element={createPrivateRoute(InvitedOrganizationTab)} />
            </Route>
            <Route path={ROUTES.LAYOUT} element={<Layout />}>
              <Route path={ROUTES.DASHBOARD} element={createPrivateRoute(DashboardListing)} />
              <Route path={ROUTES.CUSTOM_DASHBOARD} element={createPrivateRoute(Dashboard)} />
              <Route path={ROUTES.REPORTS} element={createPrivateRoute(Reports)}>
                <Route path={ROUTES.CREATE_REPORT} element={createPrivateRoute(CreateReport)} />
                <Route path={ROUTES.SCHEDULED_REPORTS} element={createPrivateRoute(ScheduledReports)} />
              </Route>
              <Route path={ROUTES.DATA_SOURCE} element={createPrivateRoute(DataSource)} />
              <Route path={ROUTES.MANAGE_ORGANIZATION} element={createPrivateRoute(ManageOrganization)}>
                <Route path={ROUTES.MANAGE_ORGANIZATION_MEMBER} element={createPrivateRoute(MembersTab)} />
                <Route path={ROUTES.MANAGE_ORGANIZATION_TEAM} element={createPrivateRoute(TeamsTab)} />
                <Route path={ROUTES.MANAGE_ORGANIZATION_INVITES} element={createPrivateRoute(InvitesTab)} />
              </Route>
              <Route path={ROUTES.PROFILE} element={createPrivateRoute(Profile)}>
                <Route path={ROUTES.PROFILE_TAB} element={createPrivateRoute(ProfileTab)} />
                <Route path={ROUTES.MEMBERSHIPS_TAB} element={createPrivateRoute(MembershipTab)} />
                <Route path={ROUTES.BILLING_TAB} element={createPrivateRoute(BillingTab)} />
                <Route path={ROUTES.AI_TOKENS_TAB} element={createPrivateRoute(AiTokensTab)} />
                <Route path={ROUTES.DOMAIN_TAB} element={createPrivateRoute(DomainTab)} />
              </Route>
              <Route path={ROUTES.WHITE_LABEL} element={createPrivateRoute(WhiteLabel)} />
              <Route path={ROUTES.ADD_DATA_SOURCE} element={createPrivateRoute(AddDataSource)} />
              <Route path={ROUTES.GOAL_TRACKING} element={createPrivateRoute(GoalTrack)}>
                <Route path={ROUTES.GOAL_TRACKING_TABULAR_VIEW} element={createPrivateRoute(GoalTrackTabularView)} />
                <Route path={ROUTES.GOAL_TRACKING_GRID_VIEW} element={createPrivateRoute(GoalTrackGridView)} />
              </Route>
            </Route>
            <Route path='*' element={<Navigate to={ROUTES.HOME} />} />
          </>
        )
      ) : (
        <>
          <Route path={ROUTES.SIGN_IN} element={<SignIn />} />
          <Route path={ROUTES.OTP_VERIFICATION} element={<OTPVerification />} />
          <Route path='*' element={<Navigate to={ROUTES.SIGN_IN} />} />
        </>
      )}
    </>
  ),
  { basename: '/app' }
);
