import { useCallback } from 'react';
import { useTour } from '@common/hooks/useTour';
import {
  CREATE_REPORT_DATE_ID,
  CREATE_REPORT_DESCRIPTION_ID,
  CREATE_REPORT_EMAIL_ID,
  CREATE_REPORT_SUBJECT_ID,
} from '@constants';

export const useCreateReportTour = () => {
  const { createTour, createStep, createClickStep } = useTour();

  const getCreateReportTourSteps = () => [
    {
      ...createStep(
        CREATE_REPORT_EMAIL_ID,
        'Input Recipient Email',
        'Please enter the email address of the recipient, then press "ENTER" to confirm.'
      ),
      onHighlighted: (element, step, option, moveToNextStep, safelyAddEventListener) => {
        safelyAddEventListener(element, 'keydown', e => {
          if (option?.state?.activeIndex === step.index && e.key === 'Enter') {
            moveToNextStep();
          }
        });
      },
    },
    createStep(CREATE_REPORT_SUBJECT_ID, 'Specify Report Subject', 'Enter a concise subject for the report.', {
      showButtons: ['next'],
    }),
    createStep(
      CREATE_REPORT_DESCRIPTION_ID,
      'Provide Report Description',
      'Provide a detailed description of the report.',
      {
        showButtons: ['next'],
      }
    ),
    createClickStep(
      CREATE_REPORT_DATE_ID,
      'Choose Date and Time',
      'Select both the date and time for when the report should be sent. The tour will close upon selection.',
      {
        showButtons: ['next'],
      }
    ),
  ];

  const createReportsTour = useCallback(
    () => createTour(getCreateReportTourSteps()),
    [createTour, getCreateReportTourSteps]
  );

  return {
    createReportsTour,
  };
};
