import { Radio } from 'antd';
import { useCreateDashboardTour } from '../hooks/useCreateDashboardTour';
import { CREATE_DASHBOARD_MODE_TYPE_ID } from '@constants';
import DashboardMODE2Image from '@assets/images/dashboardMode2Image.png';
import DashboardModeImage from '@assets/images/dashboardModeImage.png';
import { SlideModeIcon, StandardModeIcon } from '@assets/svgs';

export function CreateDashboardStep5({ value, handleSelect }) {
  const { createDashboardTour } = useCreateDashboardTour();
  return (
    <div id='step-5' className='mt-4 md:mt-6 lg:mt-4 xxl:mt-6'>
      <div className='flex flex-col gap-2'>
        <h2 className='font-Manrope text-xl font-bold leading-7 text-mainText'>Display Mode</h2>
        <p className='font-Manrope text-sm leading-5 text-bodyText'>Choose one of the mode below for this dashboard</p>
      </div>
      <div className='flex flex-col gap-5' id={CREATE_DASHBOARD_MODE_TYPE_ID}>
        <Radio.Group
          className='!me-0 flex gap-5 pt-10'
          defaultValue='NORMAL'
          value={value}
          onChange={e => {
            handleSelect(e?.target?.value);
            createDashboardTour().moveNext();
          }}
        >
          <Radio
            value='NORMAL'
            id='NORMAL'
            className='relative !me-0 h-[13.125rem] w-[16.25rem] !rounded-xl border border-secondaryStrokeButton px-[3.75rem] py-8 after:content-[unset]'
          >
            <div className='flex w-[8.75rem] flex-col items-center gap-0.5'>
              <StandardModeIcon className='h-[7.5rem] min-w-[7.5rem]' />
              <h6 className='font-Manrope text-base font-semibold leading-normal text-mainText'>Standard Mode</h6>
            </div>
          </Radio>
          <Radio
            value='SLIDES'
            id='SLIDES'
            className='relative !me-0 h-[13.125rem] w-[16.25rem] !rounded-xl border border-secondaryStrokeButton px-[3.75rem] py-8'
          >
            <div className='flex w-[8.75rem] flex-col items-center gap-0.5'>
              <SlideModeIcon className='h-[7.5rem] min-w-[7.5rem]' />
              <h6 className='font-Manrope text-base font-semibold leading-normal text-mainText'>Slide Mode</h6>
            </div>
          </Radio>
        </Radio.Group>
        <div className='mb-10 w-[33.75rem] flex-1 rounded-xl bg-placeholderText px-7 py-6'>
          {value === 'NORMAL' ? (
            <img src={DashboardModeImage} className='h-[19.375rem] w-full' />
          ) : (
            <img src={DashboardMODE2Image} className='h-[19.375rem] w-full' />
          )}
        </div>
      </div>
    </div>
  );
}
