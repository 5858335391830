export function UploadFromDevice({ className }) {
  return (
    <svg
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M47.9469 26.9971C47.6046 27.8067 47.4284 28.6753 47.4284 29.5527C47.4284 30.4301 47.6046 31.2988 47.9469 32.1084H23.2779C19.8202 32.1084 17.6757 34.22 17.6757 37.6292C17.6757 44.4426 17.6757 51.2569 17.6757 58.072V58.8719C19.3624 58.6124 20.9888 58.2863 22.6322 58.1244C31.9885 57.2102 40.3737 59.6599 47.8096 65.3259C48.8577 66.1258 48.8433 66.133 50.0312 65.4902C55.1298 62.731 60.5272 61.9763 66.2065 63.1547C66.339 63.1833 66.4691 63.2166 66.6017 63.2381C66.6737 63.2331 66.7449 63.2195 66.8137 63.1976V50.8871C67.7125 51.0204 68.5558 51.2466 69.3991 51.2466C70.2425 51.2466 71.0858 51.0228 71.9894 50.8895V51.7132C71.9894 60.39 71.9894 69.0683 71.9894 77.7483C71.9894 83.8856 67.5004 88.3304 61.3031 88.3327C48.5854 88.3327 35.8694 88.3327 23.155 88.3327C16.9962 88.3256 12.5 83.8809 12.5 77.7911C12.5 64.3768 12.5 50.9601 12.5 37.5411C12.5 31.4418 16.9842 27.0018 23.1454 26.9995C31.121 26.9995 39.0958 26.9995 47.0698 26.9995L47.9469 26.9971Z'
        fill='#AAB0B8'
      />
      <path
        d='M87.4998 29.5222C87.5166 30.7887 86.695 31.7672 85.2902 32.0933C83.3794 32.5385 81.4639 32.9599 79.5483 33.3861C76.1652 34.1431 74.0794 36.1881 73.2906 39.521C72.8449 41.4089 72.4232 43.3039 71.9823 45.1942C71.657 46.5988 70.7124 47.4249 69.4354 47.4416C68.1583 47.4582 67.1535 46.6202 66.8162 45.1799C66.3343 43.1325 65.915 41.0732 65.3705 39.0449C64.5994 36.1572 62.7079 34.2836 59.7803 33.5313C57.7539 33.0051 55.6985 32.579 53.6528 32.1219C52.1733 31.7886 51.3155 30.8721 51.2987 29.5889C51.2818 28.27 52.1468 27.3296 53.6673 26.9892C55.5829 26.5654 57.5033 26.156 59.4116 25.6965C62.7079 24.9013 64.7488 22.8563 65.5102 19.5877C65.9536 17.6974 66.3776 15.8048 66.8162 13.9145C67.1463 12.5099 68.0932 11.6815 69.3655 11.6672C70.6377 11.6529 71.6473 12.4861 71.9823 13.9288C72.4256 15.8453 72.8352 17.7712 73.3051 19.6805C74.1075 22.9397 76.1773 24.9537 79.4856 25.7131C81.4253 26.1583 83.3722 26.5702 85.3095 27.0225C86.6757 27.3344 87.4853 28.2843 87.4998 29.5222Z'
        fill='#E2E8F0'
      />
      <path
        d='M61.2238 45.6395C61.1973 47.0299 60.7531 48.3812 59.9475 49.5216C59.1419 50.662 58.0114 51.54 56.6995 52.0441C55.3877 52.5482 53.9538 52.6556 52.58 52.3526C51.2063 52.0496 49.9547 51.35 48.9844 50.3426C48.014 49.3352 47.3687 48.0655 47.1304 46.6949C46.8922 45.3242 47.0717 43.9146 47.6462 42.645C48.2207 41.3754 49.1643 40.3032 50.357 39.5647C51.5497 38.8262 52.9376 38.4547 54.3445 38.4975C56.2119 38.5535 57.9815 39.3349 59.2694 40.672C60.5573 42.0091 61.2596 43.794 61.2238 45.6395Z'
        fill='#E2E8F0'
      />
    </svg>
  );
}
