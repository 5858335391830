import { Upload } from 'lucide-react';
import { BarLoader } from 'react-spinners';
import { WidgetMenu, WidgetTitle } from '@common/widgets';
import { COLORS, THEME_STYLE } from '@constants';

export function ImageWidget({
  widget,
  onRemoveClick,
  handleImageInputChange,
  isSharedDashboard,
  handleEditWidget,
  handleClone,
  widgetImageRef,
  className,
  titleText,
  inputText,
  inputWrapper,
  uploadIcon,
  isUploadedWidgetImage,
  handleEditCommentMode,
  hideTooltip,
  onSlideMode,
  setIsPopUpMenuOpen,
}) {
  return (
    <div className={`flex h-full flex-col gap-3 ${className}`}>
      <div
        className={`flex items-center justify-between ${!onSlideMode && 'gap-3'} ${isSharedDashboard ? 'h-10' : 'h-6'}`}
      >
        {widget?.showLabel && (
          <WidgetTitle
            titleText={titleText}
            widget={widget}
            hideTooltip={hideTooltip}
            isSharedDashboard={isSharedDashboard}
          />
        )}
        {!isSharedDashboard && !onSlideMode && (
          <WidgetMenu
            widget={widget}
            handleEditWidget={handleEditWidget}
            handleClone={handleClone}
            onRemoveClick={onRemoveClick}
            handleEditCommentMode={() => handleEditCommentMode(widget)}
            setIsPopUpMenuOpen={setIsPopUpMenuOpen}
          />
        )}
      </div>
      {!widget.image && (
        <div
          className={`relative flex h-full justify-center rounded-lg border border-secondaryStrokeButton ${inputWrapper}`}
        >
          <input
            ref={widgetImageRef}
            type='file'
            accept='.jpg, .jpeg, .png'
            onChange={e => handleImageInputChange(e, widget)}
            className='w-available absolute z-10 h-full w-full opacity-0'
            disabled={isSharedDashboard || onSlideMode}
          />
          <div
            className={`flex flex-col items-center justify-center font-medium ${inputText || 'gap-2.5 text-sm leading-6'}`}
            style={{
              color: widget?.themeStyle === THEME_STYLE.DARK ? '#fff' : COLORS.SECONDARY_GREEN_COLOR,
            }}
          >
            <span
              className={`flex items-center justify-center rounded-full bg-shade1 ${onSlideMode ? 'h-3 w-3' : 'h-10 w-10'}`}
            >
              <Upload color={COLORS.SECONDARY_GREEN_COLOR} className={uploadIcon || 'h-5 w-5'} />
            </span>
            Upload
            {isUploadedWidgetImage?.index === widget?.i && <BarLoader />}
          </div>
        </div>
      )}
      {!!widget.image && (
        <div className='h-[calc(100%-48px)] w-full'>
          <img src={widget.image} className='h-full w-full' />
        </div>
      )}
    </div>
  );
}
