import { useState } from 'react';
import { isEmpty } from 'lodash';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import { Tooltip } from 'react-tooltip';
import { EmptyWidget } from '@common/widgets';
import { COLORS, THEME_STYLE } from '@constants';
import 'react-tooltip/dist/react-tooltip.css';

const geoUrl = 'https://unpkg.com/world-atlas@2.0.2/countries-110m.json';

export function MapWidget({ widget, mapImage }) {
  const [tooltipContent, setTooltipContent] = useState('');
  return (
    <div className={`h-[calc(100%-3.4375rem)] w-full overflow-auto scrollbar-none ${mapImage}`}>
      {isEmpty(widget?.widgetData?.data) ? (
        <EmptyWidget widget={widget} />
      ) : (
        <>
          <div id='map'>
            <ComposableMap>
              <Geographies geography={geoUrl}>
                {({ geographies }) =>
                  geographies.map(geo => (
                    <Geography
                      key={geo.rsmKey}
                      geography={geo}
                      onMouseEnter={() =>
                        setTooltipContent(() => {
                          let dataCountry = widget?.widgetData?.data.find(item =>
                            item?.label?.includes(geo?.properties?.name)
                          );
                          return isEmpty(dataCountry) ? { label: geo.properties.name } : dataCountry;
                        })
                      }
                      onMouseLeave={() => setTooltipContent('')}
                      style={{
                        default: {
                          fill: widget?.widgetData?.data.find(item => item?.label?.includes(geo?.properties?.name))
                            ? widget?.themeStyle === THEME_STYLE.DARK
                              ? COLORS.MAP_WIDGET_HOVER_COLOR
                              : widget.foreground
                            : widget?.themeStyle === THEME_STYLE.DARK
                              ? COLORS.WHITE_COLOR
                              : COLORS.MAP_WIDGET_HOVER_COLOR,
                          stroke: widget?.themeStyle === THEME_STYLE.DARK ? widget?.background : COLORS.WHITE_COLOR,
                        },
                        hover: {
                          fill: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : `${widget.foreground}90`,
                        },
                        pressed: {
                          display: 'none',
                        },
                      }}
                    />
                  ))
                }
              </Geographies>
            </ComposableMap>
          </div>
          <Tooltip
            anchorSelect='#map'
            content={
              isEmpty(tooltipContent) ? (
                tooltipContent
              ) : (
                <div className='flex flex-col'>
                  <span className='text-white'>{tooltipContent?.label}</span>
                  {tooltipContent?.data && (
                    <span className='text-white'>
                      {widget.label.split(' ')[0]}: {tooltipContent?.data}
                    </span>
                  )}
                  {tooltipContent?.data2 && (
                    <span className='text-white'>
                      {widget.label.split(' ')[0]}: {tooltipContent?.data2}
                    </span>
                  )}
                </div>
              )
            }
            float
          />
        </>
      )}
    </div>
  );
}
