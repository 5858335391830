import { useRef, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { debounce } from 'lodash';
import { Search, X } from 'lucide-react';
import { FormSelect, SearchInput } from '@common/components';
import { TemplateListingTabContent } from '@pages/dashboard';
import { COLORS, CREATE_DASHBOARD_MODE_TYPE_ID, CREATE_DASHBOARD_TEMPLATE_ID, TEMPLATE_TAB } from '@constants';

export function CreateDashboardStep4({
  templateListing,
  templateListingFetching,
  handleScroll,
  handleCategoryScroll,
  handleSelectedTemplate,
  selectedTemplateId,
  setTemplateName,
  selectedType,
  setSelectedType,
  selectedCategory,
  setSelectedCategory,
  templateCategory,
  selectedTemplateTab,
  setSelectedTemplateTab,
  selectedTemplateImg,
}) {
  const categoryRef = useRef();
  const [ListingDashboardView, setListingDashboardView] = useState(false);

  return (
    <div id='step-4'>
      <div className='flex flex-col items-center justify-between gap-3 md:flex-row md:gap-2.5'>
        <div className='flex flex-col gap-2'>
          <h2 className='font-Manrope text-xl font-bold leading-7 text-mainText'>Select a Template</h2>
          <p className='font-Manrope text-sm leading-5 text-bodyText'>
            Choose a pre-designed template to quickly set up your dashboard.
          </p>
        </div>
      </div>
      <div className='flex flex-col gap-6 pt-10' id={CREATE_DASHBOARD_MODE_TYPE_ID}>
        <div className='flex items-center gap-3.5'>
          <SearchInput
            size='lg'
            onChange={debounce(e => setTemplateName(e.target.value), 500)}
            onBlur={e => !e.target.value?.length > 0}
            placeholder='Search'
            parentClassName='w-[21.625rem]'
            className='pl-11'
            leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
          />
          <FormSelect
            placeholder='Select mode'
            value={selectedType}
            handleChange={type => setSelectedType(type)}
            options={[
              { id: '', name: 'Select mode' },
              { id: 'NORMAL', name: 'Normal' },
              { id: 'SLIDES', name: 'Slides' },
            ]}
            className='w-[180px]'
            labelClassName='text-sm'
          />
        </div>
        <div
          id={CREATE_DASHBOARD_TEMPLATE_ID}
          className='relative mb-10 rounded-xl border border-secondaryStrokeButton'
        >
          <div className='absolute right-6 top-3 z-10'>
            <FormSelect
              selectRef={categoryRef}
              handleScroll={handleCategoryScroll}
              placeholder='Select category'
              value={selectedCategory?.id}
              handleChange={selectedId => {
                const selectedCategoryObject = templateCategory?.pages
                  ?.flatMap(page => page.data?.results || [])
                  ?.find(category => category.id === selectedId);
                setSelectedCategory(selectedCategoryObject);
              }}
              options={templateCategory?.pages
                ?.flatMap(page => [{ id: '', name: 'Select category' }, ...page.data.results] || [])
                ?.map(result => result)}
              labelClassName='text-sm'
            />
          </div>
          <Tabs
            className='templates-tabs'
            activeKey={selectedTemplateTab}
            onTabClick={value => {
              setSelectedTemplateTab(value);
            }}
            items={[
              {
                label: 'System Templates',
                key: TEMPLATE_TAB.SYSTEM_TEMPLATE,
                children: (
                  <TemplateListingTabContent
                    setListingDashboardView={setListingDashboardView}
                    templateListing={templateListing}
                    handleScroll={handleScroll}
                    handleSelectedTemplate={handleSelectedTemplate}
                    selectedTemplateId={selectedTemplateId}
                    templateListingFetching={templateListingFetching}
                  />
                ),
              },
              {
                label: 'My Templates',
                key: TEMPLATE_TAB.MY_TEMPLATE,
                children: (
                  <TemplateListingTabContent
                    setListingDashboardView={setListingDashboardView}
                    templateListing={templateListing}
                    handleScroll={handleScroll}
                    handleSelectedTemplate={handleSelectedTemplate}
                    selectedTemplateId={selectedTemplateId}
                    templateListingFetching={templateListingFetching}
                  />
                ),
              },
            ]}
          />
        </div>
        <Modal
          footer={null}
          width={820}
          closeIcon={<X color={COLORS.ICON_COLOR} />}
          open={ListingDashboardView}
          centered={true}
          onCancel={() => setListingDashboardView(false)}
          className='modal-without-padding'
        >
          <img src={selectedTemplateImg} className='h-[43.875rem] w-[51.25rem]' />
        </Modal>
      </div>
    </div>
  );
}
