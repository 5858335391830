import { useCallback } from 'react';
import { useTour } from '@common/hooks/useTour';
import {
  INVITE_MEMBER_BUTTON,
  INVITE_MEMBER_EMAIL,
  INVITE_MEMBER_ROLE,
  INVITE_MEMBER_ROLE_DROPDOWN,
  INVITE_MEMBER_SUBMIT,
  INVITE_MEMBER_TEAM,
  INVITE_MEMBER_TEAM_DROPDOWN,
} from '@constants';

export const useInviteMembersTour = () => {
  const { createTour, createStep, createClickStep, createMousedownStep } = useTour();

  const getInviteMembersTourSteps = useCallback(
    () => [
      createClickStep(INVITE_MEMBER_BUTTON, 'Invite Members Button', 'Click here to invite a member.'),
      createClickStep(
        INVITE_MEMBER_ROLE,
        'Select the role of the member.',
        'Member can be admin or member of the team.'
      ),
      {
        ...createStep(INVITE_MEMBER_ROLE_DROPDOWN, 'Select the role of the member from the dropdown.'),
        onHighlighted: (element, step, option, moveToNextStep, safelyAddEventListener, moveToStep) => {
          safelyAddEventListener(element, 'click', e => {
            setTimeout(() => {
              if (option?.state?.activeIndex === step.index) {
                if (e.target.outerText === 'Admin') moveToStep(5);
                else moveToNextStep();
              }
            }, 500);
          });
        },
      },
      createClickStep(INVITE_MEMBER_TEAM, 'Select the team of the member.'),
      createMousedownStep(INVITE_MEMBER_TEAM_DROPDOWN, 'Select the team from the dropdown.'),
      createStep(INVITE_MEMBER_EMAIL, 'Enter the email of the member.', 'Input a valid email, and press enter.', {
        showButtons: ['next'],
      }),
      {
        ...createStep(INVITE_MEMBER_SUBMIT, 'Click to invite the member.', '', { showButtons: ['next'] }),
        onHighlighted: (element, step, option, moveToNextStep, safelyAddEventListener) => {
          safelyAddEventListener(element, 'click', () => {
            step.destroy();
          });
        },
      },
    ],
    [createStep, createClickStep, createMousedownStep]
  );

  const inviteMemberTour = useCallback(
    () => createTour(getInviteMembersTourSteps()),
    [createTour, getInviteMembersTourSteps]
  );

  return { inviteMemberTour };
};
