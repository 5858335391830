export function ImagePlaceholderIcon({ className }) {
  return (
    <svg
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='50' cy='50' r='50' fill='#E1E1E1' />
      <path
        d='M24.9033 68.3709V31.6251C24.9344 31.5725 24.9595 31.5166 24.9783 31.4584C25.547 28.5126 27.7408 26.6667 30.7095 26.6667C43.4428 26.6667 56.1762 26.6667 68.9095 26.6667C72.2074 26.6667 74.6699 29.1167 74.672 32.4022C74.6803 44.1313 74.6803 55.8605 74.672 67.5897C74.6721 68.2523 74.5659 68.9107 74.3574 69.5397C73.5574 71.8876 71.4366 73.3334 68.8616 73.3334H39.5533C36.5699 73.3334 33.5908 73.3334 30.6116 73.3334C28.4262 73.3188 26.7366 72.3605 25.6533 70.4647C25.2928 69.823 25.147 69.0688 24.9033 68.3709ZM50.1824 68.1251C55.8019 68.1251 61.422 68.1251 67.0428 68.1251C67.6908 68.1251 68.3949 68.1459 68.7387 67.4438C69.0658 66.7772 68.6658 66.2251 68.3033 65.7063C66.4144 62.998 64.5199 60.2897 62.6199 57.5813C61.5783 56.1105 60.1637 55.9917 58.8908 57.2584C58.2012 57.9438 57.5553 58.6792 56.8241 59.3147C56.4084 59.6924 55.9084 59.9653 55.3658 60.1105C54.3033 60.3459 53.5637 59.7417 52.947 58.9313C49.972 55.0313 46.9915 51.1341 44.0053 47.2397C43.8444 47.0157 43.6638 46.8066 43.4658 46.6147C43.0491 46.223 42.547 46.0022 41.9553 46.198C41.3637 46.3938 41.0387 46.9376 40.7658 47.4772C37.7519 53.4647 34.7366 59.4445 31.7199 65.4167C31.5787 65.689 31.4663 65.9753 31.3845 66.2709C31.1658 67.1042 31.5595 67.8001 32.4074 68.0126C32.7709 68.0894 33.1424 68.1216 33.5137 68.1084C39.0706 68.1195 44.6269 68.1251 50.1824 68.1251ZM62.5241 40.4688C62.5224 39.3924 62.2014 38.3406 61.6017 37.4467C61.002 36.5527 60.1505 35.8569 59.155 35.4471C58.1596 35.0374 57.065 34.9322 56.0097 35.1449C54.9545 35.3576 53.9861 35.8786 53.2271 36.6419C52.4681 37.4053 51.9527 38.3767 51.7461 39.4331C51.5394 40.4896 51.6509 41.5836 52.0664 42.5766C52.4819 43.5697 53.1826 44.4172 54.08 45.0117C54.9774 45.6063 56.031 45.9213 57.1074 45.9167C58.5459 45.9052 59.9216 45.3266 60.9359 44.3065C61.9501 43.2864 62.5208 41.9073 62.5241 40.4688Z'
        fill='white'
      />
    </svg>
  );
}
