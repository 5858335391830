import { Radio } from 'antd';
import { useCreateDashboardTour } from '../hooks/useCreateDashboardTour';
import { CREATE_DASHBOARD_TYPE_ID } from '@constants';
import { CreateCustomIcon, TemplateIcon } from '@assets/svgs';

export function CreateDashboardStep3({ value, handleSelect }) {
  const { createDashboardTour } = useCreateDashboardTour();
  return (
    <div id='step-3' className=''>
      <div className='flex flex-col gap-2'>
        <h2 className='font-Manrope text-xl font-bold leading-7 text-mainText'>Creation Mode</h2>
        <p className='font-Manrope text-sm leading-5 text-bodyText'>Choose one of the mode below for this dashboard.</p>
      </div>
      <Radio.Group
        id={CREATE_DASHBOARD_TYPE_ID}
        defaultValue='custom'
        className='flex flex-col gap-6 pt-10'
        value={value}
        onChange={e => {
          handleSelect(e?.target?.value);
          createDashboardTour().moveNext();
        }}
      >
        <Radio
          value='custom'
          id='custom'
          className='relative h-[9rem] w-[33.75rem] !rounded-xl border border-secondaryStrokeButton px-4 py-3'
        >
          <div className='flex items-center gap-4'>
            <CreateCustomIcon className='h-[7.5rem] min-w-[7.5rem]' />
            <div className='flex flex-col gap-2 pr-5'>
              <h6 className='font-Manrope text-lg font-bold leading-6 text-mainText'>Create Custom</h6>
              <p className='font-Manrope text-xs leading-5 text-bodyText'>
                Design a fully customized dashboard from the ground up, giving you complete control over layout, data
                visualization, and functionality to meet your unique requirements and goals.
              </p>
            </div>
          </div>
        </Radio>
        <Radio
          value='template'
          id='template'
          className='relative h-[9rem] w-[33.75rem] !rounded-xl border border-secondaryStrokeButton px-4 py-3'
        >
          <div className='flex items-center gap-4'>
            <TemplateIcon className='h-[7.5rem] min-w-[7.5rem]' />
            <div className='flex flex-col gap-2 pr-5'>
              <h6 className='font-Manrope text-lg font-bold leading-6 text-mainText'>Start from a Template</h6>
              <p className='font-Manrope text-xs leading-5 text-bodyText'>
                Jumpstart your dashboard creation with a pre-built template, featuring a structured layout and key
                components designed to meet your needs.
              </p>
            </div>
          </div>
        </Radio>
      </Radio.Group>
    </div>
  );
}
