export function EmptyDashboardScreenIcon({ className }) {
  return (
    <svg
      width='228'
      height='160'
      viewBox='0 0 228 160'
      fill='none'
      className={`${className}`}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M184.857 0H47.7142C41.4024 0 36.2856 5.11675 36.2856 11.4286V148.571C36.2856 154.883 41.4024 160 47.7142 160H184.857C191.169 160 196.286 154.883 196.286 148.571V11.4286C196.286 5.11675 191.169 0 184.857 0Z'
        fill='url(#paint0_linear_10865_8155)'
      />
      <g filter='url(#filter0_d_10865_8155)'>
        <path
          d='M166.572 11.4287H12.2858C9.12991 11.4287 6.57153 13.9871 6.57153 17.143V45.7144C6.57153 48.8703 9.12991 51.4287 12.2858 51.4287H166.572C169.727 51.4287 172.286 48.8703 172.286 45.7144V17.143C172.286 13.9871 169.727 11.4287 166.572 11.4287Z'
          fill='white'
        />
      </g>
      <path
        d='M85.4285 20.5713H55.7142C53.8207 20.5713 52.2856 22.1063 52.2856 23.9999C52.2856 25.8934 53.8207 27.4284 55.7142 27.4284H85.4285C87.322 27.4284 88.8571 25.8934 88.8571 23.9999C88.8571 22.1063 87.322 20.5713 85.4285 20.5713Z'
        fill='#8CD4C2'
      />
      <path
        d='M106 35.4287H55.7145C53.8209 35.4287 52.2859 36.9637 52.2859 38.8573C52.2859 40.7508 53.8209 42.2859 55.7145 42.2859H106C107.894 42.2859 109.429 40.7508 109.429 38.8573C109.429 36.9637 107.894 35.4287 106 35.4287Z'
        fill='#E2F8F4'
      />
      <path
        d='M37.4288 14.8572H15.7145C12.5586 14.8572 10.0002 17.4156 10.0002 20.5715V42.2857C10.0002 45.4417 12.5586 48 15.7145 48H37.4288C40.5847 48 43.1431 45.4417 43.1431 42.2857V20.5715C43.1431 17.4156 40.5847 14.8572 37.4288 14.8572Z'
        fill='#3FB79A'
      />
      <path
        d='M23.9681 35.1767H26.2539L26.279 34.2967C26.279 33.1401 27.0081 32.8384 28.039 32.5116C29.0731 32.2174 29.9833 31.5946 30.6319 30.7372C31.2806 29.8799 31.6325 28.8346 31.6344 27.7596C31.6571 27.1032 31.5432 26.4492 31.3 25.8391C31.0569 25.229 30.6897 24.676 30.2217 24.2151C29.7538 23.7543 29.1952 23.3956 28.5815 23.1617C27.9677 22.9279 27.3121 22.824 26.6561 22.8567C25.9629 22.8416 25.2738 22.9674 24.6307 23.2266C23.9875 23.4858 23.4037 23.873 22.9147 24.3646C22.4257 24.8562 22.0416 25.442 21.7858 26.0865C21.5299 26.731 21.4077 27.4207 21.4264 28.1138H23.7899C23.7809 27.7311 23.8484 27.3505 23.9884 26.9942C24.1285 26.6379 24.3382 26.3131 24.6054 26.0389C24.8725 25.7647 25.1917 25.5466 25.5443 25.3974C25.8968 25.2482 26.2756 25.1708 26.6584 25.1698C27.0037 25.1498 27.3492 25.2044 27.6714 25.3298C27.9937 25.4552 28.2852 25.6485 28.5261 25.8965C28.7671 26.1446 28.9517 26.4416 29.0676 26.7675C29.1836 27.0933 29.228 27.4402 29.1979 27.7847C29.1826 28.3423 28.9887 28.8802 28.6447 29.3193C28.3007 29.7584 27.8248 30.0754 27.287 30.2236L26.4573 30.5527C25.645 30.7484 24.9357 31.2421 24.4701 31.9359C24.0045 32.6297 23.8163 33.4731 23.943 34.299L23.9681 35.1767ZM25.0996 40.6098C25.3234 40.6178 25.5465 40.5808 25.7558 40.501C25.9651 40.4212 26.1562 40.3003 26.318 40.1454C26.4797 39.9905 26.6087 39.8047 26.6975 39.5991C26.7862 39.3934 26.8327 39.1721 26.8344 38.9481C26.8324 38.7244 26.7856 38.5033 26.6968 38.2979C26.608 38.0925 26.4789 37.907 26.3172 37.7523C26.1555 37.5977 25.9644 37.4769 25.7553 37.3973C25.5462 37.3177 25.3232 37.2808 25.0996 37.2887C24.8759 37.2808 24.653 37.3177 24.4439 37.3973C24.2347 37.4769 24.0437 37.5977 23.882 37.7523C23.7203 37.907 23.5912 38.0925 23.5024 38.2979C23.4135 38.5033 23.3667 38.7244 23.3647 38.9481C23.3664 39.1721 23.413 39.3934 23.5017 39.5991C23.5904 39.8047 23.7194 39.9905 23.8812 40.1454C24.0429 40.3003 24.2341 40.4212 24.4433 40.501C24.6526 40.5808 24.8758 40.6178 25.0996 40.6098Z'
        fill='white'
      />
      <g filter='url(#filter1_d_10865_8155)'>
        <path
          d='M61.4286 60.5713H215.714C217.23 60.5713 218.683 61.1733 219.755 62.245C220.827 63.3166 221.429 64.7701 221.429 66.2856V94.857C221.429 96.3725 220.827 97.826 219.755 98.8976C218.683 99.9693 217.23 100.571 215.714 100.571H61.4286C59.9131 100.571 58.4597 99.9693 57.388 98.8976C56.3164 97.826 55.7144 96.3725 55.7144 94.857V66.2856C55.7144 64.7701 56.3164 63.3166 57.388 62.245C58.4597 61.1733 59.9131 60.5713 61.4286 60.5713V60.5713Z'
          fill='white'
        />
      </g>
      <path
        d='M134.572 69.7141H104.858C102.964 69.7141 101.429 71.2491 101.429 73.1427C101.429 75.0362 102.964 76.5713 104.858 76.5713H134.572C136.465 76.5713 138 75.0362 138 73.1427C138 71.2491 136.465 69.7141 134.572 69.7141Z'
        fill='#8CD4C2'
      />
      <path
        d='M155.143 84.5715H104.857C102.964 84.5715 101.429 86.1066 101.429 88.0001C101.429 89.8937 102.964 91.4287 104.857 91.4287H155.143C157.037 91.4287 158.572 89.8937 158.572 88.0001C158.572 86.1066 157.037 84.5715 155.143 84.5715Z'
        fill='#E2F8F4'
      />
      <path
        d='M86.5716 64H64.8574C61.7014 64 59.1431 66.5584 59.1431 69.7143V91.4286C59.1431 94.5845 61.7014 97.1429 64.8574 97.1429H86.5716C89.7275 97.1429 92.2859 94.5845 92.2859 91.4286V69.7143C92.2859 66.5584 89.7275 64 86.5716 64Z'
        fill='#3FB79A'
      />
      <path
        d='M73.1117 84.3195H75.3974L75.4226 83.4395C75.4226 82.283 76.1517 81.9812 77.1826 81.6544C78.2166 81.3602 79.1268 80.7374 79.7755 79.88C80.4242 79.0227 80.7761 77.9775 80.778 76.9024C80.8006 76.246 80.6868 75.592 80.4436 74.9819C80.2004 74.3718 79.8332 73.8188 79.3653 73.358C78.8973 72.8971 78.3388 72.5384 77.725 72.3046C77.1113 72.0707 76.4557 71.9669 75.7997 71.9995C75.1065 71.9844 74.4174 72.1103 73.7742 72.3695C73.1311 72.6287 72.5473 73.0158 72.0583 73.5074C71.5692 73.999 71.1852 74.5848 70.9293 75.2293C70.6735 75.8738 70.5512 76.5635 70.57 77.2567H72.9334C72.9244 76.8739 72.9919 76.4933 73.132 76.137C73.272 75.7807 73.4818 75.4559 73.7489 75.1817C74.0161 74.9076 74.3353 74.6895 74.6878 74.5402C75.0404 74.391 75.4192 74.3136 75.802 74.3127C76.1472 74.2927 76.4927 74.3472 76.815 74.4726C77.1373 74.598 77.4288 74.7913 77.6697 75.0394C77.9106 75.2874 78.0953 75.5845 78.2112 75.9103C78.3271 76.2361 78.3715 76.583 78.3414 76.9275C78.3262 77.4851 78.1323 78.0231 77.7883 78.4621C77.4443 78.9012 76.9683 79.2182 76.4306 79.3664L75.6008 79.6955C74.7885 79.8913 74.0793 80.3849 73.6137 81.0787C73.148 81.7725 72.9599 82.6159 73.0866 83.4418L73.1117 84.3195ZM74.2431 89.7527C74.4669 89.7606 74.6901 89.7236 74.8994 89.6438C75.1086 89.5641 75.2998 89.4431 75.4615 89.2882C75.6233 89.1333 75.7523 88.9475 75.841 88.7419C75.9297 88.5362 75.9763 88.3149 75.978 88.091C75.976 87.8672 75.9292 87.6461 75.8403 87.4407C75.7515 87.2353 75.6224 87.0498 75.4607 86.8952C75.299 86.7405 75.108 86.6198 74.8988 86.5401C74.6897 86.4605 74.4668 86.4236 74.2431 86.4315C74.0195 86.4236 73.7965 86.4605 73.5874 86.5401C73.3783 86.6198 73.1872 86.7405 73.0255 86.8952C72.8638 87.0498 72.7347 87.2353 72.6459 87.4407C72.5571 87.6461 72.5103 87.8672 72.5083 88.091C72.51 88.3149 72.5565 88.5362 72.6452 88.7419C72.734 88.9475 72.863 89.1333 73.0247 89.2882C73.1865 89.4431 73.3776 89.5641 73.5869 89.6438C73.7962 89.7236 74.0193 89.7606 74.2431 89.7527Z'
        fill='white'
      />
      <g filter='url(#filter2_d_10865_8155)'>
        <path
          d='M12.2858 109.714H166.572C168.087 109.714 169.541 110.316 170.612 111.388C171.684 112.459 172.286 113.913 172.286 115.428V144C172.286 145.515 171.684 146.969 170.612 148.04C169.541 149.112 168.087 149.714 166.572 149.714H12.2858C10.7703 149.714 9.31685 149.112 8.24521 148.04C7.17357 146.969 6.57153 145.515 6.57153 144V115.428C6.57153 113.913 7.17357 112.459 8.24521 111.388C9.31685 110.316 10.7703 109.714 12.2858 109.714V109.714Z'
          fill='white'
        />
      </g>
      <path
        d='M85.4285 118.857H55.7142C53.8207 118.857 52.2856 120.392 52.2856 122.286C52.2856 124.18 53.8207 125.715 55.7142 125.715H85.4285C87.322 125.715 88.8571 124.18 88.8571 122.286C88.8571 120.392 87.322 118.857 85.4285 118.857Z'
        fill='#8CD4C2'
      />
      <path
        d='M106 133.714H55.7145C53.8209 133.714 52.2859 135.249 52.2859 137.143C52.2859 139.036 53.8209 140.571 55.7145 140.571H106C107.894 140.571 109.429 139.036 109.429 137.143C109.429 135.249 107.894 133.714 106 133.714Z'
        fill='#E2F8F4'
      />
      <path
        d='M37.4288 113.143H15.7145C12.5586 113.143 10.0002 115.701 10.0002 118.857V140.572C10.0002 143.728 12.5586 146.286 15.7145 146.286H37.4288C40.5847 146.286 43.1431 143.728 43.1431 140.572V118.857C43.1431 115.701 40.5847 113.143 37.4288 113.143Z'
        fill='#3FB79A'
      />
      <path
        d='M23.9681 133.463H26.2539L26.279 132.583C26.279 131.426 27.0081 131.125 28.039 130.798C29.0731 130.504 29.9833 129.881 30.6319 129.023C31.2806 128.166 31.6325 127.121 31.6344 126.046C31.6571 125.389 31.5432 124.735 31.3 124.125C31.0569 123.515 30.6897 122.962 30.2217 122.501C29.7538 122.04 29.1952 121.682 28.5815 121.448C27.9677 121.214 27.3121 121.11 26.6561 121.143C25.9629 121.128 25.2738 121.254 24.6307 121.513C23.9875 121.772 23.4037 122.159 22.9147 122.651C22.4257 123.142 22.0416 123.728 21.7858 124.373C21.5299 125.017 21.4077 125.707 21.4264 126.4H23.7899C23.7809 126.017 23.8484 125.637 23.9884 125.28C24.1285 124.924 24.3382 124.599 24.6054 124.325C24.8725 124.051 25.1917 123.833 25.5443 123.684C25.8968 123.534 26.2756 123.457 26.6584 123.456C27.0037 123.436 27.3492 123.491 27.6714 123.616C27.9937 123.741 28.2852 123.935 28.5261 124.183C28.7671 124.431 28.9517 124.728 29.0676 125.054C29.1836 125.379 29.228 125.726 29.1979 126.071C29.1826 126.628 28.9887 127.166 28.6447 127.605C28.3007 128.045 27.8248 128.362 27.287 128.51L26.4573 128.839C25.645 129.035 24.9357 129.528 24.4701 130.222C24.0045 130.916 23.8163 131.759 23.943 132.585L23.9681 133.463ZM25.0996 138.896C25.3234 138.904 25.5465 138.867 25.7558 138.787C25.9651 138.707 26.1562 138.586 26.318 138.432C26.4797 138.277 26.6087 138.091 26.6975 137.885C26.7862 137.68 26.8327 137.458 26.8344 137.234C26.8324 137.01 26.7856 136.789 26.6968 136.584C26.608 136.379 26.4789 136.193 26.3172 136.038C26.1555 135.884 25.9644 135.763 25.7553 135.683C25.5462 135.604 25.3232 135.567 25.0996 135.575C24.8759 135.567 24.653 135.604 24.4439 135.683C24.2347 135.763 24.0437 135.884 23.882 136.038C23.7203 136.193 23.5912 136.379 23.5024 136.584C23.4135 136.789 23.3667 137.01 23.3647 137.234C23.3664 137.458 23.413 137.68 23.5017 137.885C23.5904 138.091 23.7194 138.277 23.8812 138.432C24.0429 138.586 24.2341 138.707 24.4433 138.787C24.6526 138.867 24.8758 138.904 25.0996 138.896Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_d_10865_8155'
          x='0.571533'
          y='8.42871'
          width='177.714'
          height='52'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10865_8155' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_10865_8155' result='shape' />
        </filter>
        <filter
          id='filter1_d_10865_8155'
          x='49.7144'
          y='57.5713'
          width='177.714'
          height='52'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10865_8155' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_10865_8155' result='shape' />
        </filter>
        <filter
          id='filter2_d_10865_8155'
          x='0.571533'
          y='106.714'
          width='177.714'
          height='52'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10865_8155' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_10865_8155' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_10865_8155'
          x1='116.286'
          y1='0'
          x2='116.286'
          y2='160'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7F9F3' />
          <stop offset='1' stopColor='#E2F8F4' />
        </linearGradient>
      </defs>
    </svg>
  );
}
