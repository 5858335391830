import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import { AppContext } from '@useContext';
import { getLocalStorageItem, removeLocalStorageItem } from '@utils';
import { INVALID_PAGE, SUCCESSFUL_MESSAGE, TOTAL_ITEMS_PER_PAGE } from '@constants';

export function useDashboardsListing() {
  const { organizationId } = useParams();

  const { dashboardPage, setDashboardPage } = useContext(AppContext);
  const [paginationPage, setPaginationPage] = useState(dashboardPage);
  const dashboardViewType = getLocalStorageItem('dashboardViewType');

  const [dashboardId, setDashboardId] = useState(null);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [deleteDashboardLoading, setDeleteDashboardLoading] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [viewType, setViewType] = useState(dashboardViewType || 'grid');
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);
  const [search, setSearch] = useState('');

  const {
    data: dashboardsListing,
    refetch: dashboardsListingRefetch,
    isFetching: dashboardsListingFetching,
  } = useGetQuery(
    'dashboards-listing',
    apiEndpoints.DASHBOARDS_LISTING(organizationId),
    {
      page: dashboardPage,
      pageSize: totalPagesPerItem,
      search,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: ({ response: { data } }) => {
        if (data?.detail === INVALID_PAGE) {
          setPaginationPage(1);
          setDashboardPage(1);
        }
        toast.error(data?.detail);
      },
    }
  );

  const { mutate: handleDuplicateDashboard } = usePostMutation(
    apiEndpoints.DUPLICATE_DASHBOARD(organizationId, dashboardId),
    () => {
      dashboardsListingRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Dashboard duplicated'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  const { mutate: handleDeleteDashboard } = useDeleteMutation(
    apiEndpoints.DASHBOARD_DETAIL(organizationId, dashboardId),
    () => {
      setShowDeleteDialog(false);
      dashboardsListingRefetch();
      setDeleteDashboardLoading(false);
      toast.success(SUCCESSFUL_MESSAGE('Dashboard deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data.detail);
      setDeleteDashboardLoading(false);
    }
  );

  useEffect(() => {
    if (dashboardPage || search || totalPagesPerItem) dashboardsListingRefetch();
  }, [dashboardPage, organizationId, totalPagesPerItem, search]);

  useEffect(() => {
    const handleBeforeUnload = () => removeLocalStorageItem('dashboardCurrentPage');

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, []);

  useEffect(() => {
    if (search || totalPagesPerItem) {
      setPaginationPage(1);
      setDashboardPage(1);
    }
  }, [search, totalPagesPerItem]);

  return {
    dashboardPage,
    setDashboardPage,
    dashboardsListing,
    dashboardsListingFetching,
    setDashboardId,
    selectedDashboard,
    setSelectedDashboard,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDashboardLoading,
    handleDeleteDashboard,
    handleDuplicateDashboard,
    setDeleteDashboardLoading,
    viewType,
    setViewType,
    totalPagesPerItem,
    setTotalPagesPerItem,
    paginationPage,
    setPaginationPage,
    setSearch,
    search,
  };
}
