import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import {
  FIELD_REQUIRED,
  INVALID_PAGE,
  MANAGE_ORGANIZATION_TABS,
  MAX_LENGTH_REQUIRED,
  MIN_LENGTH_REQUIRED,
  SUCCESSFUL_MESSAGE,
  TOTAL_ITEMS_PER_PAGE,
} from '@constants';

export function useTeamsTab(currentTab) {
  const scrollToRef = useRef(null);
  const { organizationId } = useParams();

  const [showCreateTeamDialog, setShowCreateTeamDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [team, setTeam] = useState(null);
  const [page, setPage] = useState(1);
  const [paginationPage, setPaginationPage] = useState(page);
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);

  const {
    data: teamsListing,
    refetch: teamsListingRefetch,
    isFetching: teamsListingFetching,
  } = useGetQuery(
    'team-listing',
    apiEndpoints.ORGANIZATIONS_TEAMS(organizationId),
    { page, pageSize: totalPagesPerItem },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onError: ({ response: { data } }) => {
        if (data?.detail === INVALID_PAGE) {
          setPage(1);
          setPaginationPage(1);
        }
        toast.error(data?.detail);
      },
    }
  );

  const { data: dashboardsListing } = useGetQuery(
    'team-dashboards-listing',
    apiEndpoints.CREATE_TEAM_DASHBOARDS_LISTING(organizationId)
  );

  const { data: organizationMembersListing } = useGetQuery(
    'organization-members-listing-refetch',
    apiEndpoints.CREATE_TEAM_MEMBERS_LISTING(organizationId)
  );

  const teamInitialValues = {
    teamName: '',
    permission: '',
    dashboards: [],
    members: [],
  };

  const teamValidationSchema = Yup.object({
    teamName: Yup.string()
      .min(3, MIN_LENGTH_REQUIRED('Team name'))
      .max(60, MAX_LENGTH_REQUIRED('Team name'))
      .required(FIELD_REQUIRED('Team name')),
    permission: Yup.object().when('dashboards', {
      is: value => isEmpty(value),
      then: rule => rule.notRequired(),
      otherwise: rule => rule.required(FIELD_REQUIRED('Permission')),
    }),
  });

  const handleSubmit = () => {
    let payload = {
      name: values.teamName,
      permission: values.permission['id'],
      dashboards: values.dashboards.map(dashboard => dashboard.id),
      members: values.members.map(member => member.id),
    };

    !values.teamName && delete payload['name'];
    isEmpty(values.permission['id']) && delete payload['permission'];
    isEmpty(values.dashboards) && delete payload['dashboards'];
    isEmpty(values.members) && delete payload['members'];

    handleCreateTeam({ payload });
  };

  const { ...formik } = useFormikForm(teamInitialValues, handleSubmit, teamValidationSchema);

  const { values, resetForm, setErrors, isSubmitting, errors } = formik;

  const openCreateTeamDialog = () => {
    setShowCreateTeamDialog(true);
    resetForm();
  };

  const { mutate: handleCreateTeam, isLoading: createTeamLoading } = usePostMutation(
    apiEndpoints.CREATE_TEAM(organizationId),
    () => {
      setShowCreateTeamDialog(false);
      teamsListingRefetch();
      resetForm();
      toast.success(SUCCESSFUL_MESSAGE('Team created'));
    },
    ({ response: { data } }) => {
      setErrors({
        teamName: data.name[0] && data.name[0],
      });
      setErrors({
        permission: data.permission[0] && data.permission[0],
      });
      setErrors({
        dashboards: data.dashboards[0] && data.dashboards[0],
      });
      setErrors({
        members: data.members[0] && data.members[0],
      });
    }
  );

  const { mutate: handleDeleteTeam, isLoading: deleteTeamLoading } = useDeleteMutation(
    apiEndpoints.DELETE_TEAM(organizationId, team?.id),
    () => {
      setShowDeleteDialog(false);
      teamsListingRefetch();
      toast.success(SUCCESSFUL_MESSAGE('Team deleted'));
    }
  );

  useEffect(() => {
    teamsListingRefetch();
  }, [page, totalPagesPerItem]);

  useEffect(() => {
    if (isSubmitting && !isEmpty(errors)) {
      // TODO: We will use it later
      // let teamDialog = document.querySelector('#teamDialog');
      // let errorField = teamDialog.querySelector(`#${Object.keys(errors)[0]}`);
      // let coordinates = errorField.getBoundingClientRect();

      scrollToRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [isSubmitting, errors]);

  useEffect(() => {
    if (currentTab === MANAGE_ORGANIZATION_TABS.TEAMS) teamsListingRefetch();
  }, [currentTab]);

  return {
    formik,
    teamsListing,
    showCreateTeamDialog,
    setShowCreateTeamDialog,
    openCreateTeamDialog,
    createTeamLoading,
    scrollToRef,
    dashboardsListing,
    organizationMembersListing,
    setTeam,
    team,
    deleteTeamLoading,
    handleDeleteTeam,
    teamsListingFetching,
    showDeleteDialog,
    setShowDeleteDialog,
    page,
    setPage,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
  };
}
