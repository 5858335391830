import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty, uniq } from 'lodash';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePostMutation } from '@services';
import { getLocalStorageItem } from '@utils';
import {
  EMAIL_REGEX,
  ENTER,
  ENTER_KEY,
  FIELD_REQUIRED,
  INVALID_EMAIL_ADDRESS,
  INVALID_PAGE,
  MANAGE_ORGANIZATION_TABS,
  PRESS_ENTER_MESSAGE,
  ROLES,
  SUCCESSFUL_MESSAGE,
  TOTAL_ITEMS_PER_PAGE,
} from '@constants';

export function useMembersTab(currentTab) {
  const { organizationId } = useParams();

  const [showInviteMemberDialog, setShowInviteMemberDialog] = useState(false);
  const [invitedEmails, setInvitedEmails] = useState([]);
  const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState('');
  const [showDeleteMemberDialog, setShowDeleteMemberDialog] = useState(false);
  const [deleteMemberLoading, setDeleteMemberLoading] = useState(false);
  const [member, setMember] = useState(null);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [page, setPage] = useState(1);
  const [paginationPage, setPaginationPage] = useState(page);
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);

  const {
    data: organizationMembers,
    refetch: organizationMembersRefetch,
    isFetching: organizationMembersFetching,
  } = useGetQuery(
    'organization-members-listing',
    apiEndpoints.ORGANIZATIONS_MEMBERS(organizationId),
    { page, pageSize: totalPagesPerItem },
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onError: ({ response: { data } }) => {
        if (data?.detail === INVALID_PAGE) {
          setPage(1);
          setPaginationPage(1);
        }
        toast.error(data?.detail);
      },
    }
  );

  const { data: teamListing } = useGetQuery('team-listing', apiEndpoints.TEAMS_LISTING(organizationId));

  const inviteMembersValues = {
    role: '',
    team: '',
    email: '',
  };
  const inviteMembersValidationSchema = Yup.object({
    role: Yup.string().required(FIELD_REQUIRED('Role')),
    team: Yup.string().when('role', {
      is: value => value && value === ROLES.ADMIN,
      then: rule => rule.notRequired(),
      otherwise: rule => rule.required(FIELD_REQUIRED('Team')),
    }),
  });

  const handleSubmit = () => {
    if (isEmpty(invitedEmails) && isEmpty(values.email)) {
      setEmailFieldErrorMessage(FIELD_REQUIRED('Email'));
    } else if (!isEmpty(values.email)) {
      setEmailFieldErrorMessage(PRESS_ENTER_MESSAGE);
    } else {
      let payload = {
        organization: organizationId,
        role: values.role,
        team: values.team,
        emails: invitedEmails,
      };

      values.role === ROLES.ADMIN && delete payload['team'];

      handleInviteMember({ payload });
    }
  };

  const { ...formik } = useFormikForm(inviteMembersValues, handleSubmit, inviteMembersValidationSchema);

  const { values, errors, setFieldValue, isSubmitting, resetForm } = formik;

  const handleInvitedEmailKeyDown = e => {
    if (e.code === ENTER || e.keyCode === ENTER_KEY) {
      const match = values.email.match(EMAIL_REGEX);

      if (match) {
        setInvitedEmails(uniq([...invitedEmails, values.email.toLowerCase()]));
        setFieldValue('email', '');
        setEmailFieldErrorMessage('');
      }
    }
  };

  const handleRemoveInvitedEmail = selectedEmail =>
    setInvitedEmails(invitedEmails.filter(email => email !== selectedEmail));

  const { mutate: handleInviteMember, isLoading: inviteMemberLoading } = usePostMutation(
    apiEndpoints.SEND_INVITATION,
    () => {
      setShowInviteMemberDialog(false);
      toast.success(SUCCESSFUL_MESSAGE('Invitation sent'));
    },
    ({ response: { data } }) => {
      data?.detail
        ? (toast.error(data?.detail), data?.emails && setInvalidEmails(data?.emails))
        : data?.map(item => item?.non_field_errors?.map(errMsg => toast.error(errMsg)));
    }
  );

  const { mutate: handleOrganizationMemberDelete } = useDeleteMutation(
    apiEndpoints.DELETE_ORGANIZATION_MEMBER(organizationId, member?.id),
    () => {
      organizationMembersRefetch();
      setMember(null);
      setShowDeleteMemberDialog(false);
      toast.success(SUCCESSFUL_MESSAGE('Member deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data?.detail);
      setDeleteMemberLoading(false);
    }
  );

  const openInviteMemberDialogue = () => {
    setShowInviteMemberDialog(true);
    resetForm();
    setInvalidEmails([]);
    setInvitedEmails([]);
  };

  useEffect(() => {
    if (isSubmitting && !isEmpty(errors?.email)) {
      isEmpty(values.email) && setEmailFieldErrorMessage(FIELD_REQUIRED('Email'));
    }

    if (values.email) {
      const match = values.email.match(EMAIL_REGEX);
      match ? setEmailFieldErrorMessage('') : setEmailFieldErrorMessage(INVALID_EMAIL_ADDRESS);
    } else if (!isEmpty(invitedEmails) && isEmpty(values.email)) {
      setEmailFieldErrorMessage('');
    }
  }, [isSubmitting, errors, values.email]);

  useEffect(() => {
    const userRole = getLocalStorageItem('userOrganizationRole');
    const isNotMember = ROLES.MEMBER !== userRole;

    if (!isNotMember) return;
    if (currentTab === MANAGE_ORGANIZATION_TABS.MEMBERS || page || totalPagesPerItem) organizationMembersRefetch();
  }, [page, totalPagesPerItem, currentTab]);

  return {
    organizationMembers,
    teamListing,
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    showInviteMemberDialog,
    setShowInviteMemberDialog,
    emailFieldErrorMessage,
    showDeleteMemberDialog,
    setShowDeleteMemberDialog,
    setMember,
    member,
    openInviteMemberDialogue,
    inviteMemberLoading,
    deleteMemberLoading,
    setDeleteMemberLoading,
    invalidEmails,
    organizationMembersFetching,
    handleOrganizationMemberDelete,
    page,
    setPage,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
  };
}
