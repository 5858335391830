import { useMemo } from 'react';
import { Table, Modal, Skeleton } from 'antd';
import { Copy, ChevronRight, Trash2, ChevronDown, X } from 'lucide-react';
import DomainsTabHeader from './DomainsTabHeader';
import { useDomainTab } from '../hooks/useDomainTab';
import {
  CustomPagination,
  DeleteDialog,
  FormInput,
  Loader,
  Button,
  TableSkeletonLoader,
  NoDataFoundScreen,
  Badge,
} from '@common/components';
import { capitalizeString } from '@utils';
import { COLORS } from '@constants';

export function DomainTab() {
  const {
    formik,
    emailListing,
    emailListingFetching,
    addDomainLoading,
    showAddDomain,
    setShowAddDomain,
    addDomainValidateLoading,
    setDomainId,
    copyToClipboard,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteDomainLoading,
    setDeleteDomainId,
    handleDeleteDomain,
    page,
    setPage,
    setSearch,
    search,
    deleteDomainId,
    totalPagesPerItem,
    setTotalPagesPerItem,
    setPaginationPage,
    paginationPage,
    setExpandedRowKeys,
    expandedRowKeys,
    domainId,
  } = useDomainTab();

  const { values, setFieldValue, errors, submitForm } = formik;

  const columns = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) =>
        emailListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <Badge variant={record?.isValid === false ? 'pending' : 'success'}>
            <svg width='5' height='5'>
              <circle cx='2.5' cy='2.5' r='10' fill='current-color' stroke='black' strokeWidth='2' />
            </svg>
            {capitalizeString(record?.isValid === false ? 'pending' : 'verified')}
          </Badge>
        ),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      fixed: 'right',
      render: (_, record) => {
        const isExpanded = expandedRowKeys.includes(record.id);
        const isVerifying = record.domainId === domainId;
        return emailListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <span
            onClick={e => {
              e.stopPropagation();
              if (isExpanded && !record.isValid) {
                setDomainId(record?.domainId);
              } else {
                setDeleteDomainId(record.id);
                setShowDeleteDialog(true);
              }
            }}
          >
            {isExpanded && !record.isValid ? (
              <button
                className='w-[70px] rounded-full bg-highlighterText1 px-4 py-2 text-xs font-medium text-shade1'
                disabled={addDomainValidateLoading && isVerifying}
              >
                Verify
              </button>
            ) : (
              <div className='flex h-8 w-[70px] items-center justify-start'>
                <Trash2 color='#EF4444' className='h-4 w-4' />
              </div>
            )}
          </span>
        );
      },
    },
  ];

  const renderWithCopy = (value, copyValue, COLORS) => (
    <div className='inline-flex items-center gap-1.5'>
      {value}
      <span className='cursor-pointer hover:opacity-[.7]' onClick={() => copyToClipboard(copyValue)}>
        <Copy className='h-4 w-4' color={COLORS.PRIMARY_GREEN_COLOR} />
      </span>
    </div>
  );

  const nestedColumns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (text, record) => (
        <div className='!px-[60px] md:!px-20 lg:!px-[6.25rem]'>{renderWithCopy(record.type, record.type, COLORS)}</div>
      ),
    },
    {
      title: 'Host',
      dataIndex: 'host',
      key: 'host',
      render: (text, record) => (
        <div className='!px-[60px] md:!px-20 lg:!px-[6.25rem]'>{renderWithCopy(record.host, record.host, COLORS)}</div>
      ),
    },
    {
      title: 'Data',
      dataIndex: 'data',
      key: 'data',
      render: (text, record) => (
        <div className='!px-[60px] md:!px-20 lg:!px-[6.25rem]'>{renderWithCopy(record.data, record.data, COLORS)}</div>
      ),
    },
  ];

  const nestedDataSource = useMemo(() => {
    return emailListing?.data?.results?.map(({ id, dnsRecords, ...rest }) => ({
      key: id,
      id,
      ...rest,
      dnsRecords: dnsRecords.map(({ host, ...dns }, index) => ({
        key: `${index}-${host}`,
        ...dns,
        host,
      })),
    }));
  }, [emailListing?.data?.results]);

  return (
    <div className='rounded-b-xl bg-white'>
      <DomainsTabHeader setShowAddDomain={setShowAddDomain} setSearch={setSearch} search={search} />
      {emailListingFetching ? (
        <div className='h-[calc(100vh-316px)] min-h-[372px] overflow-auto scrollbar-none'>
          <TableSkeletonLoader columns={columns} />
        </div>
      ) : emailListing?.data?.results?.length === 0 || !emailListing ? (
        <div className='h-[calc(100vh-316px)] min-h-[372px] overflow-auto scrollbar-none'>
          <NoDataFoundScreen />
        </div>
      ) : (
        <div className='flex flex-col justify-between'>
          <Table
            dataSource={nestedDataSource}
            columns={columns}
            className='nested-table cursor-pointer'
            scroll={{ y: 600, x: 'max-content' }}
            expandable={{
              expandedRowRender: record => (
                <Table
                  dataSource={record.dnsRecords}
                  columns={nestedColumns}
                  pagination={false}
                  className='custom-domain'
                  scroll={{ x: 'max-content' }}
                />
              ),
              onExpand: (expanded, record) => {
                setExpandedRowKeys(
                  expanded ? [...expandedRowKeys, record.id] : expandedRowKeys.filter(key => key !== record.id)
                );
              },
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <ChevronDown onClick={e => onExpand(record, e)} className='h-5 w-5 stroke-icon1' />
                ) : (
                  <ChevronRight onClick={e => onExpand(record, e)} className='h-5 w-5 stroke-icon1' />
                ),
              expandRowByClick: true,
            }}
            pagination={false}
          />
          <CustomPagination
            page={page}
            setPage={setPage}
            data={emailListing}
            totalItems={totalPagesPerItem}
            setTotalItems={setTotalPagesPerItem}
            paginationPage={paginationPage}
            setPaginationPage={setPaginationPage}
          />
        </div>
      )}
      <Modal
        footer={null}
        className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
        closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
        open={showAddDomain}
        onCancel={() => {
          setShowAddDomain(false);
        }}
        centered={true}
      >
        <div className='flex flex-col gap-4 md:gap-6'>
          <h2 className='text-xl font-bold md:text-2xl md:leading-[2.125rem]'>Add Custom Email Domain</h2>
          <FormInput
            labelText='Domain'
            value={values?.emailDomain}
            handleChange={e => setFieldValue('emailDomain', e?.target.value)}
            placeholder='eg- gmail.com'
            showError={errors.emailDomain}
          />
          <Button
            size='lg'
            disabled={addDomainLoading || !values?.emailDomain || addDomainValidateLoading}
            onClick={submitForm}
          >
            Add
            {addDomainLoading && <Loader />}
          </Button>
        </div>
      </Modal>
      <Modal
        footer={null}
        className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
        closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
        open={showDeleteDialog}
        onCancel={() => setShowDeleteDialog(false)}
        centered={true}
      >
        <DeleteDialog
          text={emailListing?.data?.results?.find(({ id }) => id === deleteDomainId)?.domain}
          deleteBtnClick={() => handleDeleteDomain()}
          goBackBtnClick={() => setShowDeleteDialog(false)}
          isLoading={deleteDomainLoading}
        />
      </Modal>
    </div>
  );
}
