import * as React from 'react';
import { cn } from '@shadcnUtils';

const Table = React.forwardRef(({ className, ...props }, ref) => (
  <table ref={ref} className={cn('w-full caption-bottom text-sm', className)} {...props} />
));
Table.displayName = 'Table';

const TableHeader = React.forwardRef(({ className, ...props }, ref) => (
  <thead
    ref={ref}
    className={cn('z-10 hover:bg-themeColor [&_tr]:border-b [&_tr]:hover:bg-transparent', className)}
    {...props}
  />
));
TableHeader.displayName = 'TableHeader';

const TableBody = React.forwardRef(({ className, ...props }, ref) => (
  <tbody
    ref={ref}
    className={cn(
      'text-#64748B text-sm leading-[1.0588rem] md:leading-6 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-6',
      className
    )}
    {...props}
  />
));
TableBody.displayName = 'TableBody';

const TableFooter = React.forwardRef(({ className, ...props }, ref) => (
  <tfoot ref={ref} className={cn('border-t bg-muted/50 font-medium [&>tr]:last:border-b-0', className)} {...props} />
));
TableFooter.displayName = 'TableFooter';

const TableRow = React.forwardRef(({ className, ...props }, ref) => (
  <tr ref={ref} className={cn('border-b border-secondaryStrokeButton transition-colors', className)} {...props} />
));
TableRow.displayName = 'TableRow';

const TableHead = React.forwardRef(({ className, ...props }, ref) => (
  <th
    ref={ref}
    className={cn(
      'px-4 py-4 text-left align-middle text-sm leading-[1.0588rem] text-muted-foreground md:px-[2.75rem] md:pb-3.5 md:pt-4 md:leading-6 lg:px-[2.75rem] lg:py-3 lg:text-xs lg:leading-[0.9075rem] xxl:pb-3.5 xxl:pt-[1.1875rem] xxl:text-sm xxl:leading-6 [&:has([role=checkbox])]:pr-0',
      className
    )}
    {...props}
  />
));
TableHead.displayName = 'TableHead';

const TableCell = React.forwardRef(({ className, ...props }, ref) => (
  <td
    ref={ref}
    className={cn(
      'cursor-pointer px-4 py-3.5 align-middle text-sm leading-[1.0588rem] md:px-[2.75rem] md:py-4 md:leading-6 lg:px-[2.75rem] lg:py-3.5 lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-6 [&:has([role=checkbox])]:pr-0',
      className
    )}
    {...props}
  />
));
TableCell.displayName = 'TableCell';

const TableCaption = React.forwardRef(({ className, ...props }, ref) => (
  <caption ref={ref} className={cn('mt-4 text-sm text-muted-foreground', className)} {...props} />
));
TableCaption.displayName = 'TableCaption';

export { Table, TableHeader, TableBody, TableFooter, TableHead, TableRow, TableCell, TableCaption };
