import { useCallback } from 'react';
import { useTour } from '@common/hooks/useTour';
import {
  CREATE_TEAM_BUTTON_ID,
  CREATE_TEAM_DASHBOARD_DROPDOWN_ID,
  CREATE_TEAM_DASHBOARD_ID,
  CREATE_TEAM_MEMBER_DROPDOWN_ID,
  CREATE_TEAM_MEMBER_ID,
  CREATE_TEAM_MODAL_BUTTON_ID,
  CREATE_TEAM_NAME_ID,
  CREATE_TEAM_PERMISSION_ID,
} from '@constants';

export const useCreateTeamTour = () => {
  const { createTour, createStep, createClickStep, createMousedownStep } = useTour();

  const getCreateTeamTourSteps = useCallback(
    () => [
      createClickStep(CREATE_TEAM_BUTTON_ID, 'Create Team Button', 'Click here to create a new team.'),
      createStep(CREATE_TEAM_NAME_ID, 'Enter Team name', 'Enter team name, it is a mandatory field.', {
        showButtons: ['next'],
      }),
      createClickStep(CREATE_TEAM_DASHBOARD_ID, 'From here you can select the dashboards.'),
      createMousedownStep(CREATE_TEAM_DASHBOARD_DROPDOWN_ID, 'From here you can select the dashboards of the team.'),
      createClickStep(CREATE_TEAM_MEMBER_ID, 'From here you can select the members of the team.'),
      createMousedownStep(CREATE_TEAM_MEMBER_DROPDOWN_ID, 'From here you can select the members of the team.'),
      createClickStep(CREATE_TEAM_PERMISSION_ID, 'From here you can select the permission of the team members.'),
      {
        ...createStep(CREATE_TEAM_MODAL_BUTTON_ID, 'Click here to create team.'),
        onHighlightStarted: () => {
          const teamDialog = document.getElementById('scrollTeamDialog');
          teamDialog.scrollTop = teamDialog.scrollHeight;
        },
        onHighlighted: (element, step, option, moveToNextStep, safelyAddEventListener) => {
          safelyAddEventListener(element, 'click', step.destroy);
        },
      },
    ],
    [createStep, createClickStep, createMousedownStep]
  );

  const createTeamTour = useCallback(() => createTour(getCreateTeamTourSteps()), [createTour, getCreateTeamTourSteps]);

  return { createTeamTour };
};
