export function ClosedSidebarIcon() {
  return (
    <svg width='24' height='32' viewBox='0 0 24 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_11671_6050)'>
        <path d='M17.4771 3.77051H13.9399V19.5336H17.4771V3.77051Z' fill='#3FB79A' />
        <path d='M11.4058 7.53613H7.86865V23.2993H11.4058V7.53613Z' fill='#386360' />
        <path
          d='M19.9236 11.5352V20.1332C19.9246 21.4399 19.6119 22.7278 19.0118 23.8886C18.4118 25.0493 17.5418 26.0491 16.4752 26.8039C15.4085 27.5586 14.1762 28.0463 12.8819 28.2259C11.5876 28.4055 10.2691 28.2718 9.03717 27.836C7.80527 27.4003 6.69597 26.6752 5.80251 25.7216C4.90904 24.7681 4.25751 23.6141 3.90267 22.3565C3.54784 21.0989 3.50006 19.7745 3.76336 18.4946C4.02667 17.2146 4.59335 16.0166 5.41579 15.0012V10.1377C4.03528 11.0297 2.85555 12.1993 1.95166 13.572C1.04778 14.9448 0.439531 16.4907 0.165618 18.1113C-0.108295 19.7319 -0.0418753 21.3918 0.360654 22.9854C0.763182 24.579 1.49301 26.0713 2.50369 27.3674C3.51437 28.6636 4.78378 29.7352 6.23115 30.514C7.67852 31.2928 9.27216 31.7619 10.9107 31.8913C12.5492 32.0207 14.1967 31.8076 15.7484 31.2656C17.3001 30.7236 18.722 29.8646 19.9236 28.7432V32H23.6304V0H19.9236V11.5352Z'
          fill='#1A202C'
        />
      </g>
      <defs>
        <clipPath id='clip0_11671_6050'>
          <rect width='24' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
