import { cn } from '@shadcnUtils';
import { Skeleton } from 'antd';

export function ImageUpload({
  id,
  icon,
  imageRef,
  placeHolderIcon,
  className,
  profileImage,
  handleChange,
  showError,
  errorMessage,
  inputWrapperClassName,
  wrapperClassName,
  editIconClassName,
  showSkeletonLoader,
  avatarSkeletonSize = 100,
  inputClassName,
}) {
  const triggerFileInputClick = () => {
    if (imageRef && imageRef.current) {
      imageRef.current.click();
    }
  };

  return (
    <div className={`flex flex-col items-center gap-3 ${wrapperClassName}`}>
      <div
        className={`relative mx-auto flex items-center justify-center rounded-full border bg-secondaryStrokeButton ${className} ${showError ? 'border-errorColor' : ''}`}
      >
        {showSkeletonLoader ? (
          <Skeleton.Avatar active shape='circle' size={avatarSkeletonSize} />
        ) : (
          <>
            {profileImage ? (
              <div className={`overflow-hidden ${className} rounded-full`}>
                <img src={profileImage} alt='logo' className='h-full w-full object-contain' />
              </div>
            ) : (
              <div onClick={triggerFileInputClick} className='h-full w-full cursor-pointer'>
                {placeHolderIcon}
              </div>
            )}
          </>
        )}
        <div
          className={cn(
            `absolute bottom-1 right-1 flex h-5 w-5 items-center justify-center rounded-full ${inputWrapperClassName}`
          )}
        >
          <input
            type='file'
            ref={imageRef}
            id={id}
            accept='.jpg, .jpeg, .png'
            onChange={handleChange}
            className={cn('absolute z-10 h-8 w-8 opacity-0', inputClassName)}
          />
          <div className={editIconClassName} onClick={triggerFileInputClick}>
            {icon}
          </div>
        </div>
      </div>
      {showError && <p className='text-xs font-normal leading-4 text-errorTooltip'>{errorMessage}</p>}
    </div>
  );
}
