import { Tooltip } from 'react-tooltip';
import { WidgetMenu } from '@common/widgets';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';
import { TooltipIcon } from '@assets/svgs';
import 'react-tooltip/dist/react-tooltip.css';

export function TextWidget({
  widget,
  onRemoveClick,
  handleClone,
  textClass,
  handleEditWidget,
  isSharedDashboard,
  handleEditCommentMode,
  hideTooltip,
  onSlideMode,
  setIsPopUpMenuOpen,
}) {
  return (
    <div className='flex h-full items-center justify-between'>
      <div className='flex items-center'>
        <h3
          className={`break-all font-semibold focus:outline-none focus:ring-0 ${textClass || 'text-xl'} ${isSharedDashboard ? 'leading-[3rem]' : 'leading-7'}`}
          style={
            widget?.themeColor === DEFAULT_VALUE
              ? { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY }
              : { color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground }
          }
        >
          {widget?.displayLabel}
        </h3>
        {widget?.tooltip?.length > 0 && (
          <span className='ml-2 cursor-pointer' id={widget?.id}>
            <TooltipIcon
              strokeColor={
                widget?.themeColor === DEFAULT_VALUE
                  ? widget.themeStyle === THEME_STYLE.DARK
                    ? COLORS.WHITE_COLOR
                    : COLORS.DARK_PRIMARY
                  : widget.themeStyle === THEME_STYLE.DARK
                    ? COLORS.WHITE_COLOR
                    : COLORS.DARK_PRIMARY
              }
            />
          </span>
        )}
        {!hideTooltip && (
          <Tooltip
            className='!max-w-[12.5rem] break-words !bg-highlighterText1 !opacity-[1]'
            anchorSelect={`#${widget?.id}`}
            content={widget?.tooltip}
            place='right'
          />
        )}
      </div>
      {!isSharedDashboard && !onSlideMode && (
        <WidgetMenu
          widget={widget}
          handleEditWidget={handleEditWidget}
          handleClone={handleClone}
          onRemoveClick={onRemoveClick}
          handleEditCommentMode={() => handleEditCommentMode(widget)}
          setIsPopUpMenuOpen={setIsPopUpMenuOpen}
        />
      )}
    </div>
  );
}
