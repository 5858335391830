const StandardModeIcon = ({ className }) => {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M72.9842 38.6573C72.9842 40.1207 72.9712 41.5842 72.9842 43.0455C73.0081 45.321 74.6692 46.9929 76.9447 47.0081C79.8434 47.0277 82.7421 47.0081 85.6408 47.0081C87.558 47.0081 88.6024 48.0699 88.5807 49.9763C88.483 59.0024 81.474 66.6953 72.4588 67.6876C60.9943 68.9535 51.5752 60.0294 52.181 48.4759C52.6739 39.0915 60.6686 31.4703 70.0856 31.4073C71.8943 31.3965 72.9647 32.4517 72.9734 34.2669C72.9886 35.7325 72.9842 37.196 72.9842 38.6573Z'
        fill='#AAB0B8'
      />
      <path
        d='M44.3442 88.6019H28.7651V53.9913C28.7651 51.1686 30.3198 49.6161 33.1295 49.6161C35.5657 49.6161 38.004 49.5987 40.4402 49.6161C42.6702 49.6357 44.3616 51.2989 44.3638 53.5245C44.3797 65.0599 44.3797 76.5953 44.3638 88.1307C44.3703 88.2696 44.3551 88.3999 44.3442 88.6019Z'
        fill='#AAB0B8'
      />
      <path d='M98.9487 93.8936V99.0005H21.0293V93.8936H98.9487Z' fill='#E2E8F0' />
      <path
        d='M88.5848 41.8101C86.124 41.8101 83.6632 41.8101 81.2024 41.8101C79.6151 41.8101 78.7249 41.1261 78.3797 39.578C78.256 38.9963 78.1948 38.4031 78.1973 37.8084C78.1842 33.1763 78.1842 28.5442 78.1973 23.9121C78.1973 21.9926 79.2482 20.9786 81.1893 21.0003C90.1438 21.1046 97.9279 28.1656 98.8594 37.0484C98.9289 37.7194 98.9918 38.3968 98.9962 39.0721C98.9962 40.6789 97.9105 41.7906 96.3124 41.8014C93.7373 41.8231 91.1578 41.8101 88.5848 41.8101Z'
        fill='#E2E8F0'
      />
      <path
        d='M67.7819 88.6039H52.218C52.2028 88.3412 52.1768 88.1067 52.1768 87.8722C52.1768 83.4601 52.1768 79.0487 52.1768 74.6381C52.1768 72.0456 53.7922 70.4323 56.3782 70.4258C58.8115 70.4258 61.247 70.4258 63.6847 70.4258C66.1187 70.4258 67.7732 72.0716 67.7776 74.4948C67.7906 78.9894 67.7776 83.4818 67.7776 87.9742L67.7819 88.6039Z'
        fill='#AAB0B8'
      />
      <path d='M20.999 26.1443V21.0439H57.3401V26.1443H20.999Z' fill='#E2E8F0' />
      <path
        d='M91.1699 88.5969H75.6147C75.6147 86.2432 75.5104 83.9199 75.6472 81.6097C75.7601 79.7033 77.4755 78.2615 79.4014 78.2398C82.0518 78.2065 84.703 78.2065 87.3549 78.2398C89.3221 78.2615 91.0461 79.7337 91.1416 81.6705C91.2632 83.9568 91.1699 86.2541 91.1699 88.5969Z'
        fill='#E2E8F0'
      />
      <path d='M21.0259 31.4268H44.324V36.5684H21.0259V31.4268Z' fill='#AAB0B8' />
    </svg>
  );
};

export { StandardModeIcon };
