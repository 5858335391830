import { Table } from 'antd';
import { isEmpty } from 'lodash';
import { Upload } from 'lucide-react';
import { BarLoader } from 'react-spinners';
import { TableWidgetPagination, WidgetMenu, WidgetTitle } from '@common/widgets';
import { convertCamelCaseToReadable } from '@utils';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function CsvWidget({
  widget,
  onRemoveClick,
  handleCsvInputChange,
  isSharedDashboard,
  handleEditWidget,
  handleClone,
  csvRef,
  isUploadedWidgetCsv,
  page,
  handleTablePagination,
  handleEditCommentMode,
  hideTooltip,
  className,
  titleText,
  inputText,
  inputWrapper,
  uploadIcon,
  onSlideMode,
  setIsPopUpMenuOpen,
  tableCellClass,
}) {
  const columns = Object.entries(widget?.widgetData?.data?.csv[0] || {}).map(([key], i) => ({
    title: () => (
      <span
        className='block h-full w-full px-[1rem] py-3.5'
        style={
          widget?.themeColor === DEFAULT_VALUE
            ? {
              color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
              backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : '',
              borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
            }
            : {
              color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
              backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? widget?.background : '',
              borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
            }
        }
      >
        {convertCamelCaseToReadable(key)}
      </span>
    ),
    dataIndex: key,
    key: i,
    className: onSlideMode ? 'slider-table-widget' : 'table-widget',
    render: text => (
      <span
        className={`block h-full w-full px-[1rem] py-3.5 ${tableCellClass}`}
        style={
          widget?.themeColor === DEFAULT_VALUE
            ? {
              color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
              backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : 'white',
              borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_BLACK_THEME : COLORS.DARK_SECONDARY,
            }
            : {
              color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
              backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? widget?.background : 'white',
              borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
            }
        }
      >
        {text}
      </span>
    ),
  }));

  const dataSource = widget?.widgetData?.data?.csv?.map((row, i) => ({
    key: i,
    ...row,
  }));

  return (
    <div className={`flex h-full flex-col gap-3 ${className}`}>
      <div
        className={`flex items-center justify-between ${!onSlideMode && 'gap-3'} ${isSharedDashboard ? 'h-8' : 'h-6'}`}
      >
        {widget?.showLabel && (
          <WidgetTitle
            titleText={titleText}
            widget={widget}
            hideTooltip={hideTooltip}
            isSharedDashboard={isSharedDashboard}
          />
        )}
        {!isSharedDashboard && !onSlideMode && (
          <WidgetMenu
            widget={widget}
            handleEditWidget={handleEditWidget}
            handleClone={handleClone}
            onRemoveClick={onRemoveClick}
            handleEditCommentMode={() => handleEditCommentMode(widget)}
            setIsPopUpMenuOpen={setIsPopUpMenuOpen}
          />
        )}
      </div>
      {isEmpty(widget?.widgetData?.data?.csv) && (
        <div
          className={`relative flex h-full justify-center rounded-lg border border-secondaryStrokeButton ${inputWrapper}`}
        >
          <input
            type='file'
            accept='.csv'
            onChange={e => handleCsvInputChange(e, widget)}
            ref={csvRef}
            className='w-available absolute z-10 h-full w-full opacity-0'
            disabled={isSharedDashboard || onSlideMode}
          />
          <div
            className={`flex flex-col items-center justify-center font-medium ${inputText || 'gap-2.5 text-sm leading-6'}`}
            style={{
              color: widget?.themeStyle === THEME_STYLE.DARK ? '#fff' : COLORS.SECONDARY_GREEN_COLOR,
            }}
          >
            <span
              className={`flex items-center justify-center rounded-full bg-shade1 ${onSlideMode ? 'h-3 w-3' : 'h-10 w-10'}`}
            >
              <Upload color={COLORS.SECONDARY_GREEN_COLOR} className={uploadIcon || 'h-5 w-5'} />
            </span>
            Upload
            {isUploadedWidgetCsv?.index === widget?.i && <BarLoader />}
          </div>
        </div>
      )}
      {!isEmpty(widget?.widgetData?.data?.csv) && (
        <div
          className={`w-full ${onSlideMode ? 'mt-1' : 'mt-4 h-[calc(100%-3.4375rem)] '} overflow-auto scrollbar-none`}
        >
          <div className='relative z-[1]'>
            <Table columns={columns} dataSource={dataSource} pagination={false} />
            {widget?.widgetData?.data?.count > 1 && !onSlideMode && (
              <div className='my-6'>
                <TableWidgetPagination page={page} widget={widget} handleTablePagination={handleTablePagination} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
