import { Select } from 'antd';
import { FormSelect } from '@common/components';
import {
  COLORS,
  DEFAULT_VALUE,
  DISPLAY_TAB_OPTIONS,
  THEME_COLORS_OPTIONS,
  THEME_STYLE,
  THEME_STYLE_OPTIONS,
  WIDGETS_TYPE,
} from '@constants';

export function DisplayTab({ editWidget, setEditWidget }) {
  return (
    <div className='flex flex-col gap-6'>
      {editWidget?.id?.split('-')[0] === WIDGETS_TYPE.LINE_CHART && (
        <FormSelect
          labelText='Curved Lines'
          placeholder='Yes'
          value={editWidget?.showCurvedLine}
          handleChange={value => setEditWidget({ ...editWidget, showCurvedLine: value })}
          options={DISPLAY_TAB_OPTIONS}
        />
      )}
      {![WIDGETS_TYPE.VIDEO, WIDGETS_TYPE.IMAGE, WIDGETS_TYPE.TEXT, WIDGETS_TYPE.CSV].includes(
        editWidget?.id?.split('-')[0]
      ) && (
        <FormSelect
          labelText='Show Integration Icon'
          placeholder='Yes'
          value={editWidget?.showIntegrationIcon}
          handleChange={value => setEditWidget({ ...editWidget, showIntegrationIcon: value })}
          options={DISPLAY_TAB_OPTIONS}
        />
      )}
      {!editWidget?.id?.includes(WIDGETS_TYPE.TEXT) && (
        <FormSelect
          labelText='Show Data Labels'
          placeholder='Yes'
          value={editWidget?.showLabel}
          handleChange={value => setEditWidget({ ...editWidget, showLabel: value })}
          options={DISPLAY_TAB_OPTIONS}
        />
      )}
      <div className='flex flex-col gap-1.5'>
        <div className='relative flex flex-col gap-1.5'>
          <label className='relative text-sm leading-5 text-labelText'>Theme Color</label>
          <Select
            value={editWidget?.themeColor}
            popupClassName='form-select-options p-0'
            className='form-select h-[unset]'
            dropdownRender={() => (
              <div>
                <div className='theme-select bg-transparent'>
                  <div className='mx-auto flex h-[13rem] flex-col justify-start gap-2 overflow-y-auto px-6 py-4'>
                    <p className='text-bodyText'>Default</p>
                    <div className='flex flex-wrap gap-x-6 gap-y-3.5'>
                      <div
                        className='h-min w-min cursor-pointer p-0'
                        onClick={() => setEditWidget({ ...editWidget, themeColor: DEFAULT_VALUE })}
                      >
                        <div
                          className='relative h-10 w-11 rounded-md border'
                          style={{ backgroundColor: COLORS.WHITE_COLOR }}
                        >
                          <span
                            className='absolute bottom-1 left-1 h-2 w-8 rounded-[1.875rem]'
                            style={{ backgroundColor: COLORS.DARK_PRIMARY }}
                          ></span>
                        </div>
                      </div>
                    </div>
                    <p className='mt-3 text-bodyText'>Social</p>
                    <div className='flex flex-wrap gap-x-6 gap-y-3.5'>
                      {THEME_COLORS_OPTIONS.map((themeColor, index) => (
                        <div
                          key={index}
                          className='h-min w-min cursor-pointer p-0'
                          onClick={() => {
                            setEditWidget({
                              ...editWidget,
                              themeColor: themeColor.id,
                              foreground:
                                editWidget?.themeStyle === THEME_STYLE.LIGHT
                                  ? themeColor.foreground
                                  : themeColor.background,
                              background:
                                editWidget?.themeStyle === THEME_STYLE.LIGHT
                                  ? themeColor.background
                                  : themeColor.foreground,
                            });
                          }}
                        >
                          <div
                            className='relative h-10 w-10 rounded-md border'
                            style={{ backgroundColor: themeColor.foreground }}
                          >
                            <span
                              className='absolute bottom-1 left-[0.1875rem] h-2 w-8 rounded-[1.875rem]'
                              style={{ backgroundColor: themeColor.background }}
                            ></span>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          >
            <Select.Option value={editWidget?.themeColor}>
              <div
                className='relative h-6 w-6 rounded-md border border-placeholderStrokeInput p-1'
                style={{ backgroundColor: editWidget.background }}
              >
                <span
                  className='absolute bottom-1 left-0.5 h-1 w-[75%] rounded-[1.875rem]'
                  style={{ backgroundColor: editWidget.foreground }}
                ></span>
              </div>
            </Select.Option>
          </Select>
        </div>
      </div>
      <div className='flex flex-col gap-1.5'>
        <FormSelect
          labelText='Theme Style'
          placeholder='Light'
          value={editWidget?.themeStyle}
          handleChange={value =>
            setEditWidget({
              ...editWidget,
              themeStyle: value,
              foreground: editWidget.background,
              background: editWidget.foreground,
            })
          }
          options={THEME_STYLE_OPTIONS}
        />
      </div>
    </div>
  );
}
