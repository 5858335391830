import { Drawer } from 'antd';
import { X } from 'lucide-react';
import { BaseLoader, Button, FormInput, FormSelect, Loader } from '@common/components';
import { restrictedCharacterOnNumberField } from '@utils';
import {
  COLORS,
  CREATE_GOAL,
  CREATE_GOAL_CONDITION,
  CREATE_GOAL_CONDITION_TOUR_ID,
  CREATE_GOAL_DASHBOARD,
  CREATE_GOAL_DASHBOARD_TOUR_ID,
  // CREATE_GOAL_DESCRIPTION,
  CREATE_GOAL_METRIC,
  CREATE_GOAL_NAME,
  CREATE_GOAL_TIME_PERIOD,
  CREATE_GOAL_TIME_PERIOD_TOUR_ID,
  CREATE_GOAL_WIDGET,
  CREATE_GOAL_WIDGET_TOUR_ID,
  GOAL_CONDITION,
  TIME_PERIOD,
} from '@constants';

export function CreateEditGoalDrawer({
  title,
  formik,
  dashboardsListing,
  widgetListing,
  isLoading,
  setSelectedDashboardId,
  disableDashboardField = false,
  disableWidgetField = false,
  showDrawer,
  setShowDrawer,
  showBaseLoader = false,
}) {
  const { values, handleChange, setFieldValue, errors, touched, submitForm, resetForm, dirty } = formik;

  const handleClose = () => {
    setShowDrawer(false);
    resetForm();
  };

  return (
    <Drawer
      title={title}
      open={showDrawer}
      onClose={handleClose}
      className='goalsDrawer rounded-s-2xl'
      closeIcon={null}
      width='35.125rem'
      extra={
        <div className='flex h-6 w-6 items-center justify-center rounded-[0.25rem] border border-icon1 md:h-8 md:w-8'>
          <X onClick={handleClose} className='h-3.5 w-3.5 md:h-5 md:w-5' color={COLORS.ICON_COLOR} />
        </div>
      }
      footer={
        <div className='flex items-center justify-end'>
          <Button
            size='sm'
            disabled={isLoading || !dirty}
            onClick={submitForm}
            className='w-[8.75rem]'
            id={CREATE_GOAL}
          >
            {title === 'Edit Goal' ? 'Update' : 'Create'} {isLoading && <Loader />}
          </Button>
        </div>
      }
    >
      {showBaseLoader ? (
        <BaseLoader />
      ) : (
        <div className='flex flex-col gap-8 p-5 md:p-8'>
          <FormInput
            id={CREATE_GOAL_NAME}
            labelText='Goal Name'
            placeholder='Add goal name'
            value={values[CREATE_GOAL_NAME]}
            handleChange={handleChange}
            showError={touched[CREATE_GOAL_NAME] && errors[CREATE_GOAL_NAME]}
            className='gap-1.5'
            labelClassName='text-sm'
            inputClassName='h-11'
          />
          <FormSelect
            labelText='Dashboard'
            id={CREATE_GOAL_DASHBOARD}
            tourId={CREATE_GOAL_DASHBOARD_TOUR_ID}
            placeholder='Select dashboard'
            handleChange={value => {
              setFieldValue('dashboard', value);
              setSelectedDashboardId(value?.id);
            }}
            options={dashboardsListing?.data?.results}
            value={values?.dashboard}
            showError={touched.dashboard && errors.dashboard}
            triggerClassName={values?.dashboard ? '' : 'custom-select'}
            optionPlaceholder={'No dashboard'}
            disabled={disableDashboardField}
          />
          <FormSelect
            id={CREATE_GOAL_TIME_PERIOD}
            tourId={CREATE_GOAL_TIME_PERIOD_TOUR_ID}
            labelText='Time Period'
            placeholder='Select time period'
            handleChange={value => setFieldValue('timePeriod', value)}
            options={TIME_PERIOD}
            value={values?.timePeriod}
            showError={touched.timePeriod && errors.timePeriod}
            triggerClassName={values?.timePeriod ? '' : 'custom-select'}
          />

          <FormSelect
            id={CREATE_GOAL_WIDGET}
            tourId={CREATE_GOAL_WIDGET_TOUR_ID}
            value={values?.widget?.widgetId}
            disabled={!values?.dashboard || disableWidgetField}
            optionPlaceholder='No widget'
            handleChange={value =>
              setFieldValue(
                'widget',
                widgetListing?.data?.results?.find(item => item.widgetId === value)
              )
            }
            placeholder='Choose widget'
            options={
              widgetListing?.data?.results?.map(({ widgetId, widgetLabel }) => ({ id: widgetId, name: widgetLabel })) ||
              []
            }
            showError={touched.widget && errors.widget}
            triggerClassName={values?.widget ? '' : 'custom-select'}
            labelText='Widget'
          />

          <FormSelect
            id={CREATE_GOAL_CONDITION}
            tourId={CREATE_GOAL_CONDITION_TOUR_ID}
            labelText='Condition'
            placeholder='Greater than or equal to'
            handleChange={value => setFieldValue('condition', value)}
            options={GOAL_CONDITION}
            value={values?.condition}
            showError={touched.condition && errors.condition}
            triggerClassName={values?.condition ? '' : 'custom-select'}
          />
          <FormInput
            id={CREATE_GOAL_METRIC}
            labelText='Target'
            placeholder='Enter desired target'
            type='number'
            onKeyDown={restrictedCharacterOnNumberField}
            inputClassName='h-10'
            value={values[CREATE_GOAL_METRIC]}
            handleChange={handleChange}
            showError={touched[CREATE_GOAL_METRIC] && errors[CREATE_GOAL_METRIC]}
            isMultipleEmail={true}
            isMultipleEmailMessage='This will be compared to all time data'
          />

          {/* <FormTextArea
            id={CREATE_GOAL_DESCRIPTION}
            labelText='Description'
            placeholder='Enter description'
            value={values.description}
            handleChange={e => setFieldValue('description', e?.target?.value)}
            showError={touched.description && errors.description}
            labelClassName='text-labelText'
          /> */}
        </div>
      )}
    </Drawer>
  );
}
