import { Button } from '@common/components';

export function BackButton({
  showSelectField,
  showNameField,
  showAccount,
  showProperties,
  isNonAuthConnector,
  handleBackClick,
  connectedDataSourceLoading,
  showConnectorNameScreen,
  clearDialogue,
}) {
  const handleClick = () => {
    if (
      showNameField ||
      showAccount ||
      showProperties ||
      isNonAuthConnector ||
      showSelectField ||
      showConnectorNameScreen
    ) {
      handleBackClick();
    } else {
      clearDialogue();
    }
  };

  const getButtonText = () => {
    return showNameField ||
      showAccount ||
      showProperties ||
      isNonAuthConnector ||
      showSelectField ||
      showConnectorNameScreen
      ? 'Back'
      : 'Close';
  };

  return (
    <Button variant='tertiary' onClick={handleClick} disabled={connectedDataSourceLoading}>
      {getButtonText()}
    </Button>
  );
}
