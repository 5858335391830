import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { Header, Sidebar } from '@common/components';
import { ROUTES } from '@routes';
import { convertToTitleCase } from '@utils';

export function Layout() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('Dashboards');
  const [openSidebar, setOpenSidebar] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname) {
      let split = location.pathname.split('/');
      if (convertToTitleCase(split[1]) === 'Profile') setActiveLink('Setting');
      else setActiveLink(convertToTitleCase(split[1]));
      location.pathname === '/' && navigate(ROUTES.HOME);
    }
  }, [location.pathname]);

  return (
    <div className='flex'>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <Sidebar openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} />
      </ErrorBoundary>
      <div className='w-full flex-1 bg-[#F7F7F7] lg:pl-[4.75rem]'>
        <Header openSidebar={openSidebar} setOpenSidebar={setOpenSidebar} ActiveText={activeLink} />
        <div className='h-[calc(100vh-53px)] overflow-y-auto overflow-x-hidden md:h-[calc(100vh-73px)]'>
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </div>
    </div>
  );
}
