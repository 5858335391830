import { MailIcon } from 'lucide-react';
import { Button, FormInput, FormSelect, Loader } from '@common/components';
import {
  COLORS,
  INVITE_MEMBER_EMAIL,
  INVITE_MEMBER_ROLE,
  INVITE_MEMBER_ROLE_DROPDOWN,
  INVITE_MEMBER_SUBMIT,
  INVITE_MEMBER_TEAM,
  INVITE_MEMBER_TEAM_DROPDOWN,
  ROLES,
  ROLE_DROP_DOWN_OPTIONS,
} from '@constants';

export function InviteMemberDialog({
  teamListing,
  formik,
  onEnter,
  invitedEmails,
  onCrossBtnClick,
  emailFieldErrorMessage,
  inviteMemberLoading,
  invalidEmails,
}) {
  const { values, handleChange, setFieldValue, touched, errors, submitForm, dirty } = formik;

  return (
    <div className='flex flex-col gap-4 md:gap-6'>
      <h3 className='font-Manrope text-xl font-bold leading-7 text-mainText md:text-2xl md:leading-[2.125rem]'>
        Invite Members
      </h3>
      <div className='flex flex-col gap-4 md:gap-6'>
        <FormSelect
          id={INVITE_MEMBER_ROLE}
          tourId={INVITE_MEMBER_ROLE_DROPDOWN}
          labelText='Role'
          placeholder='Select role'
          value={values.role || undefined}
          handleChange={selectedRole => {
            setFieldValue('role', selectedRole);
          }}
          options={ROLE_DROP_DOWN_OPTIONS}
          showError={touched.role && errors.role}
        />
        {values.role !== ROLES.ADMIN && (
          <FormSelect
            id={INVITE_MEMBER_TEAM}
            tourId={INVITE_MEMBER_TEAM_DROPDOWN}
            labelText='Team'
            placeholder='Select team'
            value={values.team || undefined}
            optionPlaceholder='First create a team'
            handleChange={selectedTeam => setFieldValue('team', selectedTeam)}
            options={teamListing?.data?.results}
            showError={touched.team && errors.team}
          />
        )}
        <FormInput
          id={INVITE_MEMBER_EMAIL}
          labelText='Email'
          placeholder='Enter email'
          value={values.email}
          handleChange={handleChange}
          showError={emailFieldErrorMessage}
          onKeyDown={onEnter}
          isMultipleEmail={true}
          isMultipleEmailMessage='*Press enter after adding an email'
        />
        {invitedEmails.length !== 0 && (
          <div className='flex flex-col gap-4'>
            <h4 className='font-Manrope text-sm font-semibold leading-5 text-mainText md:text-lg md:leading-[1.625rem]'>
              Selected Members
            </h4>
            <div className='flex flex-col gap-4'>
              {invitedEmails.map(email => (
                <div key={email} className='flex items-center justify-between'>
                  <div className='flex items-center gap-2'>
                    <MailIcon
                      className='h-[18px] w-[18px] md:h-6 md:w-6'
                      color={invalidEmails.includes(email) ? COLORS.ERROR_COLOR : COLORS.ICON_COLOR}
                    />
                    <p
                      className={`text-xs leading-4 md:text-sm md:leading-5 ${invalidEmails.includes(email) ? 'text-errorColor' : 'text-mainText'}`}
                    >
                      {email}
                    </p>
                  </div>
                  <div
                    onClick={() => onCrossBtnClick(email)}
                    className={`cursor-pointer rounded-full border px-3 py-1.5 text-xs leading-4 ${invalidEmails.includes(email) ? 'border border-errorColor text-errorColor' : 'border-secondaryStrokeButton text-bodyText'}`}
                  >
                    Remove
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        <Button
          className='w-full md:h-11'
          id={INVITE_MEMBER_SUBMIT}
          onClick={submitForm}
          disabled={inviteMemberLoading || !dirty}
        >
          Invite {inviteMemberLoading ? <Loader /> : ''}{' '}
        </Button>
      </div>
    </div>
  );
}
