import { Trash2 } from 'lucide-react';
import { Badge } from '@common/components';
import { capitalizeString } from '@utils';
import { COLORS, POLL_STATUS_TYPE } from '@constants';

function DataSourceCard({ data, showDeleteDialog, setShowDeleteDialog }) {
  const statusCheck = {
    [POLL_STATUS_TYPE.SUCCEEDED]: 'success',
    [POLL_STATUS_TYPE.FAILED]: 'failed',
    [POLL_STATUS_TYPE.PENDING]: 'pending',
    [POLL_STATUS_TYPE.RUNNING]: 'running',
  };

  return (
    <div className='relative flex flex-col justify-between gap-6 rounded-xl border border-secondaryStrokeButton p-5'>
      <div className='flex flex-col gap-2'>
        <div className='flex h-[3.25rem] w-[3.25rem] items-center justify-center rounded-full bg-tableHeaderBackground'>
          <img src={data?.connector?.logo?.image} alt='dashboard' className='h-8 w-8 object-contain' />
        </div>
        <p className='text-sm font-medium leading-5 text-mainText'>{data?.connector?.sourceName}</p>
        <p className='text-sm leading-5 text-bodyText'>{data?.name}</p>
      </div>
      <Badge variant={statusCheck[data?.syncStatus.toLowerCase()]}>
        <svg width='5' height='5' className='relative'>
          <circle
            className={data?.syncStatus.toLowerCase() === POLL_STATUS_TYPE.RUNNING && 'animate-ping'}
            cx='2.5'
            cy='2.5'
            r='10'
            fill='current-color'
            stroke='black'
            strokeWidth='2'
          />
        </svg>
        {capitalizeString(data?.syncStatus)}
      </Badge>
      <div
        className='absolute right-3 top-3 cursor-pointer'
        onClick={e => {
          e.stopPropagation();
          setShowDeleteDialog({
            ...showDeleteDialog,
            [data.id]: true,
            id: data?.id,
            connector: data.connector,
          });
        }}
      >
        <Trash2 className='h-5 w-5' color={COLORS.ERROR_TOOLTIP} />
      </div>
    </div>
  );
}

export default DataSourceCard;
