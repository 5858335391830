import * as React from 'react';
import { cn } from '@shadcnUtils';
import { Check } from 'lucide-react';

const Checkbox = React.forwardRef(({ className, ...props }, ref) => (
  <div ref={ref} className={cn('relative h-5 min-w-5', className)}>
    <button
      className={`relative flex h-5 w-5 cursor-pointer items-center justify-center rounded border
                    ${props.checked ? 'border-highlighterText1 bg-highlighterText1' : 'border-[#AAB0B8]'} ${className}`}
      {...props}
    >
      {props.checked && <Check className='h-3.5 w-3.5' stroke='white' />}
    </button>
  </div>
));

Checkbox.displayName = 'Checkbox';

export { Checkbox };
