export function SubscriptionExpiredIcon({ className }) {
  return (
    <svg
      width='160'
      height='160'
      viewBox='0 0 160 160'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M80.0002 159.999C124.183 159.999 160 124.182 160 79.9997C160 35.8171 124.183 0 80.0002 0C35.8176 0 0.000488281 35.8171 0.000488281 79.9997C0.000488281 124.182 35.8176 159.999 80.0002 159.999Z'
        fill='url(#paint0_linear_321_1169)'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.0005 56.5334V144C32.0005 144 50.5005 160 80.0002 160C109.5 160 128 144 128 144L128 56.5334C125.758 56.5384 123.538 56.0986 121.467 55.2395C119.397 54.3805 117.517 53.1191 115.937 51.5286C114.347 49.9487 113.086 48.0692 112.227 45.9988C111.369 43.9284 110.929 41.7083 110.934 39.4668H49.0671C49.062 43.9916 47.2623 48.3296 44.0628 51.5291C40.8633 54.7286 36.5253 56.5283 32.0005 56.5334Z'
        fill='white'
      />
      <path
        d='M80.0003 108.8C94.1388 108.8 105.6 97.3385 105.6 83.2C105.6 69.0616 94.1388 57.6001 80.0003 57.6001C65.8619 57.6001 54.4004 69.0616 54.4004 83.2C54.4004 97.3385 65.8619 108.8 80.0003 108.8Z'
        fill='#3FB79A'
      />
      <path
        d='M89.0514 95.2671L80.0005 86.2162L70.9496 95.2671L67.9326 92.2501L76.9835 83.1992L67.9326 74.1482L70.9496 71.1313L80.0005 80.1822L89.0514 71.1313L92.0684 74.1482L83.0175 83.1992L92.0684 92.2501L89.0514 95.2671Z'
        fill='white'
      />
      <path
        d='M93.8661 115.2H66.1329C64.3655 115.2 62.9329 116.633 62.9329 118.4C62.9329 120.167 64.3655 121.6 66.1329 121.6H93.8661C95.6334 121.6 97.0661 120.167 97.0661 118.4C97.0661 116.633 95.6334 115.2 93.8661 115.2Z'
        fill='#8CD4C2'
      />
      <path
        d='M103.467 128H56.5342C54.7669 128 53.3342 129.433 53.3342 131.2C53.3342 132.967 54.7669 134.4 56.5342 134.4H103.467C105.235 134.4 106.667 132.967 106.667 131.2C106.667 129.433 105.235 128 103.467 128Z'
        fill='#E2F8F4'
      />
      <defs>
        <linearGradient
          id='paint0_linear_321_1169'
          x1='80.0002'
          y1='0'
          x2='80.0002'
          y2='159.999'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7F9F3' />
          <stop offset='1' stopColor='#E2F8F4' />
        </linearGradient>
      </defs>
    </svg>
  );
}
