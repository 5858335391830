import { SendHorizonal } from 'lucide-react';
import { Mention, MentionsInput } from 'react-mentions';
import CustomSuggestionComponent from './CustomSuggestionComponent';
import { COLORS, ENTER } from '@constants';

const mentionInputStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'sans-serif',
    },
    highlighter: {
      padding: '10px 48px 10px 16px',
      border: '1px solid transparent',
    },
    input: {
      padding: '10px 48px 10px 16px',
      outline: 'none',
      border: `1px solid ${COLORS.SECONDARY_STROKE_BTN}`,
      borderRadius: '24px',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 5,
      borderRadius: '4px',
      maxHeight: '300px',
      overflow: 'auto',
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: COLORS.SHADE_1,
      },
    },
  },
};

const TaggedUsers = ({ users, setFieldValue, values, submitComment }) => {
  return (
    <section className='relative w-[20.875rem] rounded-full scrollbar-none'>
      <MentionsInput
        placeholder='Type your comment here'
        value={values.text}
        onChange={e => setFieldValue('text', e.target.value)}
        allowSuggestionsAboveCursor={false}
        forceSuggestionsAboveCursor={false}
        style={mentionInputStyle}
        className='mention-input'
        onKeyDown={e => {
          if (e.key === ENTER && e.shiftKey) {
            e.preventDefault();
            submitComment();
          }
        }}
      >
        <Mention
          markup='@[__display__](user:__id__)'
          trigger='@'
          style={{ backgroundColor: '#dcdcdc' }}
          data={users}
          appendSpaceOnAdd={true}
          renderSuggestion={suggestion => {
            return <CustomSuggestionComponent user={suggestion} />;
          }}
        />
      </MentionsInput>
      <span className='absolute bottom-2.5 right-4 cursor-pointer' onClick={submitComment}>
        <SendHorizonal color={COLORS.PRIMARY_GREEN_COLOR} />
      </span>
    </section>
  );
};

export default TaggedUsers;
