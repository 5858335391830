import { Switch } from 'antd';
import { isEmpty } from 'lodash';
import { X } from 'lucide-react';
import { Button, FormInput, FormSelect, Loader } from '@common/components';
import { normalizeOptionsArray } from '@utils';
import {
  CREATE_TEAM_MODAL_BUTTON_ID,
  PERMISSION_DROP_DOWN_OPTIONS,
  CREATE_TEAM_NAME_ID,
  CREATE_TEAM_PERMISSION_ID,
  CREATE_TEAM_DASHBOARD_DROPDOWN_ID,
  CREATE_TEAM_DASHBOARD_ID,
  CREATE_TEAM_MEMBER_ID,
  PERMISSION_TOGGLES,
  COLORS,
  CREATE_TEAM_MEMBER_DROPDOWN_ID,
} from '@constants';

export function AddEditTeamDialog({
  label,
  formik,
  teamLoading,
  scrollToRef,
  dashboardsListing,
  organizationMembersListing,
  isLoading = false,
}) {
  const { values, handleChange, touched, errors, submitForm, setFieldValue, dirty } = formik;

  const handleToggle = permissionId => {
    const selectedPermission = PERMISSION_DROP_DOWN_OPTIONS.find(option => option.id === permissionId);

    setFieldValue('permission', selectedPermission);
  };

  const handleSelect = (key, obj) => setFieldValue(key, [...values[key], obj]);
  const handleRemove = (key, id) => {
    setFieldValue(
      key,
      values[key]?.filter(item => item.id !== id)
    );
  };

  return (
    <div id='teamDialog' className='flex flex-col gap-4 md:gap-6'>
      <h3 className='font-Manrope text-xl font-bold leading-7 text-mainText md:text-2xl md:leading-[2.125rem]'>
        {label}
      </h3>
      {isLoading ? (
        <div className='flex h-[12.5rem] items-center justify-center'>
          <Loader strokeColorClass={COLORS.SECONDARY_GREEN_COLOR} className='h-10 w-10' />
        </div>
      ) : (
        <div id='scrollTeamDialog' ref={scrollToRef} className='flex flex-col gap-4 md:gap-6'>
          <FormInput
            id={CREATE_TEAM_NAME_ID}
            labelText='Name'
            placeholder='Enter your team name'
            value={values.teamName}
            handleChange={handleChange}
            showError={touched.teamName && errors.teamName}
          />
          <div className='flex flex-col gap-2 md:gap-4'>
            <FormSelect
              id={CREATE_TEAM_DASHBOARD_ID}
              labelText='Dashboards'
              tourId={CREATE_TEAM_DASHBOARD_DROPDOWN_ID}
              placeholder='Select dashboards'
              handleChange={value => {
                const selectedOption = dashboardsListing?.data?.results.find(option => option.id === value);
                if (selectedOption) {
                  handleSelect('dashboards', { id: selectedOption.id, name: selectedOption.name });
                }
              }}
              options={dashboardsListing?.data?.results.filter(
                option => !values.dashboards?.some(dashboard => dashboard.id === option.id)
              )}
              value='Select dashboards'
              showError={touched.dashboards && errors.dashboards}
              optionPlaceholder='No dashboard'
            />
            {!isEmpty(values?.dashboards) && (
              <>
                <div className='flex flex-wrap gap-2'>
                  {values?.dashboards?.map(dashboard => {
                    return (
                      <span
                        key={dashboard?.id}
                        onClick={() => handleRemove('dashboards', dashboard?.id)}
                        className='flex items-center gap-1.5 rounded-3xl bg-chipColor px-2.5 py-1.5 text-[0.625rem] font-normal leading-[0.875rem] md:gap-2 md:px-3 md:py-2 md:text-xs'
                      >
                        {dashboard?.name}
                        <X
                          className='h-3 w-3 cursor-pointer md:h-4 md:w-4 lg:h-3 lg:w-3 xxl:h-4 xxl:w-4'
                          color={COLORS.ICON_COLOR}
                        />
                      </span>
                    );
                  })}
                </div>
              </>
            )}
          </div>
          <div className='flex flex-col gap-2 md:gap-4'>
            <FormSelect
              id={CREATE_TEAM_MEMBER_ID}
              tourId={CREATE_TEAM_MEMBER_DROPDOWN_ID}
              labelText='Select Members'
              placeholder='Select members'
              value='Select Members'
              handleChange={value => {
                const selectedOption = organizationMembersListing?.data?.results?.find(option => option?.id === value);
                if (selectedOption) {
                  handleSelect('members', { id: selectedOption.id, email: selectedOption.email });
                }
              }}
              options={normalizeOptionsArray(organizationMembersListing?.data?.results, 'id', 'email').filter(
                option => !values?.members?.some(member => member?.id === option?.id)
              )}
              optionPlaceholder='No member'
              showError={touched?.members && errors?.members}
            />
            {!isEmpty(values?.members) && (
              <div className='flex flex-wrap gap-2'>
                {values?.members?.map(member => {
                  return (
                    <span
                      key={member?.id}
                      onClick={() => handleRemove('members', member?.id)}
                      className='flex items-center gap-1.5 rounded-3xl bg-chipColor px-2.5 py-1.5 text-[0.625rem] font-normal leading-[0.875rem] md:gap-2 md:px-3 md:py-2 md:text-xs'
                    >
                      {member?.email}
                      <X
                        className='h-3 w-3 cursor-pointer md:h-4 md:w-4 lg:h-3 lg:w-3 xxl:h-4 xxl:w-4'
                        color={COLORS.ICON_COLOR}
                      />
                    </span>
                  );
                })}
              </div>
            )}
          </div>
          {!isEmpty(values?.dashboards) && (
            <div id={CREATE_TEAM_PERMISSION_ID} className='relative flex flex-col gap-4'>
              <h4 className='font-Manrope text-sm font-semibold leading-5 text-mainText md:text-lg md:leading-[1.625rem]'>
                Permissions
              </h4>
              <div className='flex items-center justify-between gap-2'>
                <div className='flex flex-col gap-[0.125rem]'>
                  <h5 className='font-Manrope text-sm font-medium leading-5 md:text-base md:leading-[1.375rem]'>
                    Viewer
                  </h5>
                  <p className='font-Manrope text-xs font-normal text-bodyText md:text-sm'>Can view the dashboard</p>
                </div>
                <Switch
                  style={{
                    backgroundColor:
                      values.permission.id === PERMISSION_TOGGLES.CAN_VIEW
                        ? COLORS.PRIMARY_GREEN_COLOR
                        : COLORS.SWITCH_DISABLE_COLOR,
                  }}
                  checked={values.permission?.id === PERMISSION_TOGGLES.CAN_VIEW}
                  onChange={() => handleToggle(PERMISSION_TOGGLES.CAN_VIEW)}
                />
              </div>
              <div className='flex items-center justify-between gap-2'>
                <div className='flex flex-col gap-[0.125rem]'>
                  <h5 className='font-Manrope text-sm font-medium leading-5 md:text-base md:leading-[1.375rem]'>
                    Editor
                  </h5>
                  <p className='font-Manrope text-xs font-normal text-bodyText md:text-sm'>Can edit the dashboard</p>
                </div>
                <Switch
                  style={{
                    backgroundColor:
                      values.permission.id === PERMISSION_TOGGLES.CAN_EDIT
                        ? COLORS.PRIMARY_GREEN_COLOR
                        : COLORS.SWITCH_DISABLE_COLOR,
                  }}
                  checked={values.permission?.id === PERMISSION_TOGGLES.CAN_EDIT}
                  onChange={() => handleToggle(PERMISSION_TOGGLES.CAN_EDIT)}
                />
              </div>
              {touched.permission && errors.permission && (
                <div className='absolute -bottom-9 z-[2] rounded bg-errorTooltip px-2 py-[0.375rem] text-xs font-medium leading-4 text-white'>
                  {errors.permission}
                </div>
              )}
            </div>
          )}

          <Button
            className='w-full md:h-11'
            id={CREATE_TEAM_MODAL_BUTTON_ID}
            onClick={submitForm}
            disabled={teamLoading || !dirty}
          >
            {label === 'Edit Team' ? 'Edit' : 'Create'}
            {teamLoading ? <Loader /> : ''}
          </Button>
        </div>
      )}
    </div>
  );
}
