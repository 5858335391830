import { cn } from '@shadcnUtils';
import { cva } from 'class-variance-authority';

const badgeVariants = cva(
  'flex items-center w-fit rounded-full border px-3 py-1 gap-1.5 text-xs font-semibold leading-4 [&>svg]:rounded-full',
  {
    variants: {
      variant: {
        success: 'bg-successStateBg border-successStateBg text-successState [&>svg]:fill-successState',
        running: 'bg-runningStateBg border-runningStateBg text-runningState [&>svg]:fill-runningState',
        failed: 'bg-failedStateBg border-failedStateBg text-failedState [&>svg]:fill-failedState',
        pending: 'bg-pendingStateBg border-pendingStateBg text-pendingState [&>svg]:fill-pendingState',
      },
    },
    defaultVariants: {
      variant: 'success',
    },
  }
);

function Badge({ className, variant, ...props }) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
