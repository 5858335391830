import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { BulbIcon } from '@assets/svgs/BulbIcon';
import { goalTrackingProgressValues } from '@constants/constants';
import { useCreateGoalTrackingTour } from '@pages/goalTracking/hooks/useCreateGoalTrackingTour';
import { Alert, Progress } from 'antd';
import { CircleX, LayoutGrid, List, Plus, Search } from 'lucide-react';
import { BaseLoader, Button, CustomPagination, NoDataFoundScreen, SearchInput, TourButton } from '@common/components';
import {
  CreateEditGoalDrawer,
  GoalTrackGridView,
  GoalTrackTabularView,
  NoGoalsView,
  useEditGoal,
  useGoalsProgress,
} from '@pages/goalTracking';
import { useManageOrganization } from '@pages/manageOrganization';
import { ROUTES } from '@routes';
import { getLocalStorageItem } from '@utils';
import { COLORS, CREATE_GOAL_BUTTON_ID, GOAL_TRACKING_TAB, ROLES } from '@constants';

export function GoalTrack() {
  const [searchParams, setSearchParams] = useSearchParams();

  const initialTab =
    searchParams.get('view') === GOAL_TRACKING_TAB.TABULAR_VIEW
      ? GOAL_TRACKING_TAB.TABULAR_VIEW
      : GOAL_TRACKING_TAB.GRID_VIEW;

  const [currentTab, setCurrentTab] = useState(initialTab);

  const userOrganizationRole = getLocalStorageItem('userOrganizationRole');

  const { createGoalTrackingTour } = useCreateGoalTrackingTour();

  const {
    goalsProgress,
    goalsListing,
    goalDetail,
    setGoalDetail,
    handleGoalDelete,
    showDeleteDialog,
    setShowDeleteDialog,
    deleteGoalLoading,
    setDeleteGoalLoading,
    formik,
    dashboardsListing,
    widgetListing,
    createGoalLoading,
    showCreateDialog,
    setShowCreateDialog,
    setDashboardGoal,
    page,
    setPage,
    setSearch,
    paginationPage,
    setPaginationPage,
    totalPagesPerItem,
    setTotalPagesPerItem,
    goalListingFetching,
  } = useGoalsProgress({
    showToast: true,
  });

  const {
    formik: editFormik,
    showEditDialog,
    setShowEditDialog,
    setEditGoalDetail,
    goalEditLoading,
    setSelectedDashboardId,
    goalDetailLoading,
  } = useEditGoal();

  useEffect(() => {
    setSearchParams({ view: currentTab });
  }, [currentTab, setSearchParams]);

  const { manageOrganization, manageOrganizationFetching } = useManageOrganization();

  const isFirstVisit = !manageOrganization?.data?.haveGoals;
  const noSearchResults = goalsListing?.data?.count === 0;

  return (
    <div className='flex flex-col gap-5 py-5 md:gap-8 md:py-8'>
      <>
        {userOrganizationRole !== ROLES.MEMBER && (
          <CreateEditGoalDrawer
            title='Create Goal'
            formik={formik}
            dashboardsListing={dashboardsListing}
            widgetListing={widgetListing}
            isLoading={createGoalLoading}
            setSelectedDashboardId={setSelectedDashboardId}
            showDrawer={showCreateDialog}
            setShowDrawer={setShowCreateDialog}
            showBaseLoader={goalDetailLoading}
          />
        )}
        <div className='px-5 md:px-8'>
          <Alert
            message='The goals overall progress will be updated after every 24 hours'
            type='info'
            icon={
              <div className='!mr-3 flex h-8 w-8 items-center justify-center rounded-full bg-white p-2'>
                <BulbIcon />
              </div>
            }
            showIcon
            closable={false}
            closeIcon={<CircleX className='h-5 w-5' color={COLORS.ICON_COLOR} />}
            className='goal-alert flex items-center rounded-xl bg-shade3 px-3 py-3 font-Manrope text-sm md:px-6 md:text-base'
          />
        </div>

        <div className='flex gap-5 overflow-x-auto pe-5 ps-5 scrollbar-none md:pe-8 md:ps-8 xxl:mx-8 xxl:overflow-hidden xxl:pe-0 xxl:ps-0'>
          {goalTrackingProgressValues(goalsProgress).map(progress => (
            <div
              key={progress.id}
              className='flex items-center gap-4 rounded-xl border bg-white p-5 md:h-[9.875rem] md:gap-10 md:p-6 lg:h-[200px] lg:w-full lg:justify-between'
            >
              <div className='flex w-[6.875rem] flex-col gap-2 md:w-[12.875rem] lg:w-[15rem] xxl:w-[11.75rem]'>
                <h1 className='font-Manrope text-sm font-semibold leading-5 md:text-[1.125rem] md:leading-[1.625rem]'>
                  {progress.title}
                </h1>
                <p className='font-Manrope text-[0.625rem] font-normal leading-4 md:text-sm md:leading-6'>
                  {progress.description}
                </p>
              </div>
              <div className='flex items-center justify-center'>
                <Progress
                  percent={progress.value}
                  type='circle'
                  strokeColor={progress.color}
                  trailColor='#E2E8F0'
                  strokeWidth={10}
                  className='text-[2rem] font-bold'
                  size={
                    window?.innerWidth < 480
                      ? 80
                      : window?.innerWidth >= 768 && window?.innerWidth <= 1023
                        ? 110
                        : window?.innerWidth >= 1024
                          ? 140
                          : ''
                  }
                />
              </div>
            </div>
          ))}
        </div>
        <div className='px-5 md:px-8'>
          {manageOrganizationFetching ? (
            <BaseLoader />
          ) : (
            <div className='rounded-xl border bg-white'>
              <div className='flex flex-col justify-between gap-3 border-b px-5 py-4 md:flex-row md:items-center lg:h-[4.75rem] lg:px-6'>
                <h1 className='text-[1.125rem] font-semibold leading-[1.625rem] text-mainText'>All Goals</h1>
                {!isFirstVisit && (
                  <div className='flex flex-col-reverse items-center gap-3 md:flex-row'>
                    <div className='flex w-full items-center gap-2 md:gap-3 lg:w-auto'>
                      <SearchInput
                        onChange={e => setSearch(e.target.value)}
                        placeholder='Search'
                        parentClassName='w-full md:w-[16.25rem]'
                        className='pl-11'
                        leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
                        disabled={isFirstVisit}
                      />
                      <div className='ant-tabs-nav flex gap-3'>
                        <div className='ant-tabs-nav-list flex items-center'>
                          <Link
                            to={ROUTES.GOAL_TRACKING_GRID_VIEW}
                            onClick={() => setCurrentTab(GOAL_TRACKING_TAB.GRID_VIEW)}
                            className={`ant-tabs-tab h-[2.375rem] !w-[2.6875rem] cursor-pointer !px-3 !py-2.5 md:h-[2.625rem] md:!w-full md:!px-4 md:!py-3 ${currentTab === GOAL_TRACKING_TAB.GRID_VIEW ? 'ant-tabs-tab-active-left' : ''}`}
                          >
                            <div className='ant-tabs-tab-btn'>
                              <LayoutGrid
                                className='h-5 w-5'
                                color={
                                  currentTab === GOAL_TRACKING_TAB.GRID_VIEW
                                    ? COLORS.TAB_ACTIVE_COLOR
                                    : COLORS.TAB_COLOR
                                }
                              />
                            </div>
                          </Link>
                          <Link
                            to={ROUTES.GOAL_TRACKING_TABULAR_VIEW}
                            onClick={() => setCurrentTab(GOAL_TRACKING_TAB.TABULAR_VIEW)}
                            className={`ant-tabs-tab h-[2.375rem] !w-[2.6875rem] cursor-pointer !px-3 !py-2.5 md:h-[2.625rem] md:!w-full md:!px-4 md:!py-3 ${currentTab === GOAL_TRACKING_TAB.TABULAR_VIEW ? 'ant-tabs-tab-active-right' : ''}`}
                          >
                            <div className='ant-tabs-tab-btn'>
                              <List
                                className='h-5 w-5'
                                color={
                                  currentTab === GOAL_TRACKING_TAB.TABULAR_VIEW
                                    ? COLORS.TAB_ACTIVE_COLOR
                                    : COLORS.TAB_COLOR
                                }
                              />
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                    {!isFirstVisit && (
                      <Button
                        size='lg'
                        id={CREATE_GOAL_BUTTON_ID}
                        className='w-full md:w-[10.625rem]'
                        onClick={() => setShowCreateDialog(true)}
                      >
                        <Plus className='h-5 w-5' color='white' />
                        Create New
                      </Button>
                    )}
                  </div>
                )}
              </div>

              {isFirstVisit ? (
                <NoGoalsView handleClick={() => setShowCreateDialog(true)} />
              ) : noSearchResults || !goalsListing ? (
                <div className='h-[calc(100vh-448px)] min-h-[372px] overflow-auto'>
                  <NoDataFoundScreen />
                </div>
              ) : (
                <>
                  {currentTab === GOAL_TRACKING_TAB.TABULAR_VIEW && (
                    <GoalTrackTabularView
                      goalsListing={goalsListing}
                      goalDetail={goalDetail}
                      setGoalDetail={setGoalDetail}
                      setEditGoalDetail={setEditGoalDetail}
                      handleGoalDelete={handleGoalDelete}
                      showDeleteDialog={showDeleteDialog}
                      setShowDeleteDialog={setShowDeleteDialog}
                      deleteGoalLoading={deleteGoalLoading}
                      setDeleteGoalLoading={setDeleteGoalLoading}
                      showEditDialog={showEditDialog}
                      setShowEditDialog={setShowEditDialog}
                      widgetListing={widgetListing}
                      formik={editFormik}
                      dashboardsListing={dashboardsListing}
                      isLoading={goalEditLoading}
                      setSelectedDashboardId={setSelectedDashboardId}
                      setDashboardGoal={setDashboardGoal}
                      goalListingFetching={goalListingFetching}
                    />
                  )}
                  {currentTab === GOAL_TRACKING_TAB.GRID_VIEW && (
                    <GoalTrackGridView
                      goalsListing={goalsListing}
                      goalDetail={goalDetail}
                      setGoalDetail={setGoalDetail}
                      setEditGoalDetail={setEditGoalDetail}
                      handleGoalDelete={handleGoalDelete}
                      showDeleteDialog={showDeleteDialog}
                      setShowDeleteDialog={setShowDeleteDialog}
                      deleteGoalLoading={deleteGoalLoading}
                      setDeleteGoalLoading={setDeleteGoalLoading}
                      showEditDialog={showEditDialog}
                      setShowEditDialog={setShowEditDialog}
                      widgetListing={widgetListing}
                      formik={editFormik}
                      dashboardsListing={dashboardsListing}
                      isLoading={goalEditLoading}
                      setSelectedDashboardId={setSelectedDashboardId}
                      setDashboardGoal={setDashboardGoal}
                      goalListingFetching={goalListingFetching}
                    />
                  )}
                  <CustomPagination
                    page={page}
                    setPage={setPage}
                    data={goalsListing}
                    totalItems={totalPagesPerItem}
                    setTotalItems={setTotalPagesPerItem}
                    paginationPage={paginationPage}
                    setPaginationPage={setPaginationPage}
                  />
                </>
              )}
              <TourButton Label='“Create Goal”' onClick={() => createGoalTrackingTour().drive()} />
            </div>
          )}
        </div>
      </>
    </div>
  );
}
