import { useContext } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import {
  BarChartWidget,
  CounterWidget,
  CsvWidget,
  GoalWidget,
  ImageWidget,
  LineChartWidget,
  MapWidget,
  PieChartWidget,
  ProgressWidget,
  TableWidget,
  TextWidget,
  VideoWidget,
  WidgetTopHead,
} from '@common/widgets';
import { AppContext } from '@useContext';
import { calculateSharedDashboardHeight } from '@utils';
import { COLORS, DEFAULT_VALUE, STATIC, THEME_STYLE, WIDGETS_TYPE } from '@constants';

export function SharedDashboardLayout({ layout, handleTablePagination, handleCsvPagination, isSlideMode }) {
  const { screenHeight } = useContext(AppContext);

  const ResponsiveGridLayout = WidthProvider(Responsive);

  return (
    <ResponsiveGridLayout
      className='layout flex w-full flex-wrap items-start gap-2.5'
      layouts={{ lg: layout }}
      rowHeight={isSlideMode ? calculateSharedDashboardHeight(screenHeight) - 20 : 30}
      isDraggable={false}
      isResizable={false}
      autoSize={true}
      cols={isSlideMode ? { lg: 6, md: 6, sm: 6, xs: 6, xxs: 6 } : { lg: 12, md: 12, sm: 9, xs: 6, xxs: 1 }}
      breakpoints={{ lg: 1440, md: 1024, sm: 768, xs: 500, xxs: 0 }}
      margin={{ xxs: [0, 16] }}
      useCSSTransforms={false}
    >
      {layout?.map(widget => (
        <div key={widget?.i} className={`${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'text-widget' : ''}`}>
          <div
            className={`h-full w-full overflow-hidden overflow-y-auto border border-widgetBorderColor bg-white px-4 py-5 shadow-[0_5px_20px_0_rgba(0,0,0,0.05)] scrollbar-none ${widget?.id?.includes(WIDGETS_TYPE.TEXT) ? 'rounded-[0.375rem] px-6 py-4' : ''} ${isSlideMode ? 'overflow-y-hidden' : ''}`}
            style={
              widget?.themeColor === DEFAULT_VALUE
                ? {
                  backgroundColor: widget.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : COLORS.WHITE_COLOR,
                }
                : { backgroundColor: widget?.background }
            }
          >
            {!widget?.connectorName?.includes(STATIC) && <WidgetTopHead widget={widget} isSharedDashboard={true} />}
            {widget?.widgetData?.error ? (
              <p
                className='text-md flex h-[calc(100%-3.4375rem)] w-full items-center justify-center font-bold'
                style={
                  widget?.themeColor === DEFAULT_VALUE
                    ? {
                      color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
                    }
                    : {
                      color: widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.COLOR_PRIMARY,
                    }
                }
              >
                {widget?.widgetData?.error}
              </p>
            ) : (
              <>
                {widget?.id?.includes(WIDGETS_TYPE.GOAL) && (
                  <div className='h-[calc(100%-6rem)] w-full'>
                    <GoalWidget widget={widget} isSharedDashboard={true} />
                  </div>
                )}
                {widget?.id?.includes(WIDGETS_TYPE.COUNTER) && (
                  <div className='h-[calc(100%-3.4375rem)] w-full'>
                    <CounterWidget
                      value={widget?.widgetData?.data}
                      isComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                      widget={widget}
                    />
                  </div>
                )}
                {widget?.id?.includes(WIDGETS_TYPE.BAR_CHART) && (
                  <div className='h-[calc(100%-3.4375rem)] w-full'>
                    <BarChartWidget widget={widget} />
                  </div>
                )}
                {widget?.id?.includes(WIDGETS_TYPE.LINE_CHART) && (
                  <div className='h-[calc(100%-3.4375rem)] w-full'>
                    <LineChartWidget widget={widget} />
                  </div>
                )}
                {widget?.id?.includes(WIDGETS_TYPE.PIE_CHART) && (
                  <div className='flex h-[calc(100%-3.4375rem)] w-full flex-col'>
                    <PieChartWidget
                      widget={widget}
                      isWidgetComparisonFilterApply={widget?.isWidgetComparisonFilterApply}
                    />
                  </div>
                )}
                {widget?.id?.includes(WIDGETS_TYPE.PROGRESS_BAR) && (
                  <div className='h-[calc(100%-3.4375rem)] w-full overflow-auto scrollbar-none'>
                    <ProgressWidget widget={widget} />
                  </div>
                )}
                {widget?.id?.includes(WIDGETS_TYPE.TABLE) && (
                  <div className='h-[calc(100%-3.4375rem)] w-full overflow-auto scrollbar-none'>
                    <TableWidget
                      widget={widget}
                      handleTablePagination={page => handleTablePagination(page, widget?.i)}
                    />
                  </div>
                )}
                {widget?.id?.includes(WIDGETS_TYPE.MAP) && <MapWidget widget={widget} />}
              </>
            )}
            {widget?.id?.includes(WIDGETS_TYPE.TEXT) && <TextWidget widget={widget} isSharedDashboard={true} />}
            {widget?.id?.includes(WIDGETS_TYPE.VIDEO) && <VideoWidget widget={widget} isSharedDashboard={true} />}
            {widget?.id?.includes(WIDGETS_TYPE.IMAGE) && <ImageWidget widget={widget} isSharedDashboard={true} />}
            {widget?.id?.includes(WIDGETS_TYPE.CSV) && (
              <CsvWidget
                widget={widget}
                page={widget?.page}
                handleTablePagination={page => handleCsvPagination(page, widget?.i)}
                isSharedDashboard={true}
              />
            )}
          </div>
        </div>
      ))}
    </ResponsiveGridLayout>
  );
}
