import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { Timer } from 'lucide-react';
import { COLORS } from '@constants';

export function CustomTimePicker({ value, handleChange, showError, disabled, scheduleClass, className }) {
  return (
    <div className={`flex w-full flex-col gap-1.5 !rounded-full ${disabled ? 'disable-time' : ''} ${scheduleClass}`}>
      <div className='font-Manrope text-xs leading-4 text-labelText md:text-sm md:leading-5'>Time</div>
      <TimePicker
        value={value ? dayjs(value, 'h:mm A') : null}
        format='h:mm A'
        use12Hours={true}
        placeholder='Select time'
        disabled={disabled}
        onChange={(time, timeString) => handleChange(timeString)}
        className={`${className} h-10 rounded-[1.5rem] px-4 py-3 font-Manrope text-xs leading-4 text-mainText hover:border hover:!border-highlighterText1 md:h-11 [&>.ant-picker-input>input]:text-xs [&>.ant-picker-input>input]:placeholder:text-xs md:[&>.ant-picker-input>input]:text-sm md:[&>.ant-picker-input>input]:placeholder:text-sm ${showError ? 'border border-errorColor' : ''} !rounded-full`}
        size='large'
        suffixIcon={<Timer className='h-4 w-4' color={COLORS.ICON_COLOR} />}
      />
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorTooltip'>{showError}</p>
      )}
    </div>
  );
}
