import { debounce } from 'lodash';
import { Plus, Search } from 'lucide-react';
import { Button, SearchInput } from '@common/components';
import { DomainInstruction } from '@pages/whiteLabel';
import { COLORS } from '@constants';

const DEBOUNCE_VALUE = 600;

const DomainsTabHeader = ({ setShowAddDomain, setSearch }) => {
  const handleSearchChange = debounce(e => setSearch(e.target.value), DEBOUNCE_VALUE);

  return (
    <div className='flex flex-col justify-between gap-3 border-b border-secondaryStrokeButton px-5 py-4 md:flex-row md:px-6'>
      <div className='flex items-center gap-2'>
        <p className='text-base font-semibold md:py-2 md:text-lg'>Email Domains</p>
        <DomainInstruction parentClass='!static' showDomainTabInstruction={true} />
      </div>
      <div className='flex flex-col gap-3 md:flex-row md:gap-4 lg:gap-[1.125rem]'>
        <SearchInput
          onChange={handleSearchChange}
          placeholder='Search'
          parentClassName='md:w-[12.5rem] lg:w-[16.25rem]'
          className='h-10 pl-11 md:h-11'
          leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
        />
        <Button onClick={() => setShowAddDomain(true)} size='lg' className='md:w-[10.75rem] lg:w-[10.75rem]'>
          <Plus className='h-5 w-5' color='white' />
          Add New
        </Button>
      </div>
    </div>
  );
};

export default DomainsTabHeader;
