import { useCallback } from 'react';
import { useTour } from '@common/hooks/useTour';
import {
  CREATE_GOAL,
  CREATE_GOAL_BUTTON_ID,
  CREATE_GOAL_CONDITION,
  CREATE_GOAL_CONDITION_TOUR_ID,
  CREATE_GOAL_DASHBOARD,
  CREATE_GOAL_DASHBOARD_TOUR_ID,
  // CREATE_GOAL_DESCRIPTION,
  CREATE_GOAL_METRIC,
  CREATE_GOAL_NAME,
  CREATE_GOAL_TIME_PERIOD,
  CREATE_GOAL_TIME_PERIOD_TOUR_ID,
  CREATE_GOAL_WIDGET,
  CREATE_GOAL_WIDGET_TOUR_ID,
} from '@constants';

export const useCreateGoalTrackingTour = () => {
  const { createTour, createClickStep, createStep, moveToStep } = useTour();

  const getGoalTrackingTourSteps = () => [
    createClickStep(
      CREATE_GOAL_BUTTON_ID,
      'Create New Goal',
      'Click this button to initiate the goal creation process.'
    ),
    createStep(CREATE_GOAL_NAME, 'Goal Name', 'Provide a name for the goal that reflects its purpose.', {
      showButtons: ['next', 'close'],
    }),
    createClickStep(CREATE_GOAL_DASHBOARD, 'Select Dashboard', 'Choose the dashboard where this goal will be tracked.'),
    createClickStep(
      CREATE_GOAL_DASHBOARD_TOUR_ID,
      'Dashboard Selection',
      'Use this dropdown to select the appropriate dashboard.'
    ),
    createClickStep(
      CREATE_GOAL_TIME_PERIOD,
      'Define Time Period',
      'Specify the duration for which the goal should be tracked.'
    ),
    createClickStep(
      CREATE_GOAL_TIME_PERIOD_TOUR_ID,
      'Time Period Selection',
      'Use the dropdown to select the desired time period.'
    ),
    createClickStep(CREATE_GOAL_WIDGET, 'Choose Widget', 'Select the widget that will be used to monitor this goal.', {
      showButtons: ['next', 'close'],
      onNextClick: () => {
        moveToStep(8);
      },
    }),
    createClickStep(
      CREATE_GOAL_WIDGET_TOUR_ID,
      'Widget Selection',
      'Use the dropdown to select the widget for tracking the goal.'
    ),
    createClickStep(
      CREATE_GOAL_CONDITION,
      'Set Condition',
      'Define the condition that will be used to evaluate the goal’s progress.'
    ),
    createClickStep(
      CREATE_GOAL_CONDITION_TOUR_ID,
      'Condition Selection',
      'Use the dropdown to specify the goal’s condition.'
    ),
    createStep(CREATE_GOAL_METRIC, 'Enter Metric', 'Input the key metric that will determine the goal’s success.', {
      showButtons: ['next', 'close'],
    }),
    // createStep(
    //   CREATE_GOAL_DESCRIPTION,
    //   'Add Description',
    //   'Provide a detailed description of the goal, including its objectives and context.',
    //   {
    //     showButtons: ['next', 'close'],
    //   }
    // ),
    createClickStep(
      CREATE_GOAL,
      'Finalize Goal Creation',
      'Click here to complete the goal setup and create the goal.'
    ),
  ];

  const createGoalTrackingTour = useCallback(() => createTour(getGoalTrackingTourSteps()), [createTour]);

  return {
    createGoalTrackingTour,
  };
};
