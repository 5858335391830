import { cn } from '@shadcnUtils';
import { PlayCircleIcon } from 'lucide-react';
import { Button } from '.';
import { COLORS } from '@constants';

export function TourButton({ onClick, className, iconClass, Label }) {
  return (
    <Button
      className={cn(
        'button-transition fixed bottom-[5.75rem] right-8 z-[20] h-[3.875rem] w-min !gap-0 !rounded-full bg-white p-[0.9375rem] shadow-lg transition duration-150 ease-out hover:bg-white hover:ease-in focus:bg-white md:bottom-[6.875rem] md:right-10 md:h-20 md:p-5 lg:bottom-[5.125rem] lg:!h-[3.25rem] lg:p-[0.8125rem]  xxl:bottom-[6.25rem] xxl:right-[3.75rem] xxl:!h-[3.875rem] xxl:p-[0.9375rem] [&>p]:hover:block',
        className
      )}
      onClick={onClick}
    >
      <PlayCircleIcon
        color={COLORS.PRIMARY_GREEN_COLOR}
        className={cn('h-8 w-8 md:h-10 md:w-10 lg:h-[1.625rem] lg:w-[1.625rem] xxl:h-8 xxl:w-8', iconClass)}
      />
      <p className='text-sm font-light leading-[1.0588rem] md:text-base md:leading-[1.21rem] lg:text-xs lg:leading-[0.9075rem] xxl:text-sm xxl:leading-[1.0588rem]'>
        Take <span className='font-normal'>{Label}</span> Tour
      </p>
    </Button>
  );
}
