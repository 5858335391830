import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormikForm } from '@common/hooks';
import { useCreateOrganizationTour } from '@pages/profile';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';
import { delay, getLocalStorageItem, setLocalStorageItem } from '@utils';
import {
  ERROR_MESSAGE,
  FIELD_REQUIRED,
  SUCCESSFUL_MESSAGE,
  SELECTED_ORGANIZATION_ID,
  DEFAULT_ORGANIZATION_ID,
  MAX_LENGTH_REQUIRED,
  MIN_LENGTH_REQUIRED,
  ROLES,
} from '@constants';

export function useCreateOrganization() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { organizationId } = useParams();

  const imageRef = useRef(null);

  const selectedOrganizationId = getLocalStorageItem(SELECTED_ORGANIZATION_ID);
  const defaultOrganizationId = getLocalStorageItem(DEFAULT_ORGANIZATION_ID);

  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [isUploadedLogo, setIsUploadedLogo] = useState(false);
  const [switcherSelectedOrganization, setSwitcherSelectedOrganization] = useState('');
  const [showAddEditOrgDialog, setShowAddEditOrgDialog] = useState(false);

  const {
    data: organizationListing,
    refetch: organizationListingRefetch,
    isLoading: organizationListingLoading,
  } = useGetQuery('organization-listing', apiEndpoints.ORGANIZATIONS);

  const navigateToOrganizationPath = selectedOrganization => {
    let splitPathname = pathname.split('/');
    const navigateTo = basePath => navigate(`/${basePath}/${selectedOrganization?.id}`);

    if (['settings'].includes(splitPathname[1])) navigateTo('dashboards');
    else if (selectedOrganization?.userRole === ROLES.MEMBER) {
      ['dashboards', 'manage-organization'].includes(splitPathname[1])
        ? navigateTo(splitPathname[1])
        : navigateTo('dashboards');
    } else if (splitPathname[3]) {
      navigate(`/${splitPathname[1]}/${selectedOrganization?.id}/${splitPathname[3]}`);
    } else navigateTo(splitPathname[1]);
  };

  const handleSelectedOrganization = selectedOrganization => {
    let filteredOrganization = organizationListing?.data?.results.find(
      organization => organization.id === selectedOrganization.id
    );
    setSwitcherSelectedOrganization(filteredOrganization);
    setLocalStorageItem(SELECTED_ORGANIZATION_ID, selectedOrganization.id);
    navigateToOrganizationPath(selectedOrganization);
  };

  const createOrgInitialValues = { logo: '', organizationName: '' };

  const createOrgValidationSchema = Yup.object({
    logo: Yup.string().optional(),
    organizationName: Yup.string()
      .min(3, MIN_LENGTH_REQUIRED('Organization name'))
      .max(60, MAX_LENGTH_REQUIRED('Organization name'))
      .required(FIELD_REQUIRED('Organization name')),
  });

  const handleSubmit = () => {
    handleCreateOrg({
      payload: {
        logo: uploadedLogo?.id,
        name: values.organizationName,
      },
    });
  };

  const openCreateDialogue = () => {
    setUploadedLogo(null);
    setIsUploadedLogo(false);
    resetForm();
  };

  const { ...formik } = useFormikForm(createOrgInitialValues, handleSubmit, createOrgValidationSchema);

  const { values, resetForm } = formik;

  const { createOrgTour } = useCreateOrganizationTour();

  const { mutate: handleImageUpload } = usePostMutation(
    apiEndpoints.UPLOAD_IMAGE,
    async resp => {
      setUploadedLogo(resp?.data);
      setIsUploadedLogo(false);
      await delay(100);
      createOrgTour().moveNext();
    },
    ({ response: { data } }) => {
      data.image[0]
        ? toast.error(data.image[0])
        : data?.image?.detail
          ? toast.error(data?.image?.detail)
          : toast.error(ERROR_MESSAGE);
      setIsUploadedLogo(false);
      imageRef.current.value = '';
      resetForm();
    }
  );

  const { mutate: handleCreateOrg, isLoading: createOrganizationLoading } = usePostMutation(
    apiEndpoints.ORGANIZATIONS,
    resp => {
      organizationListingRefetch();
      setShowAddEditOrgDialog(false);
      navigate(`/dashboards/${resp?.data?.id}`);
      setLocalStorageItem(SELECTED_ORGANIZATION_ID, resp?.data?.id);
      toast.success(SUCCESSFUL_MESSAGE('Organization created'));
      resetForm();
      setUploadedLogo(null);
    },
    ({ response: { data } }) => {
      data?.detail ? toast.error(data?.detail) : toast.error(data?.name?.name[0]);
    }
  );

  useEffect(() => {
    let filteredOrganization;
    if (selectedOrganizationId || organizationId) {
      filteredOrganization = organizationListing?.data?.results.find(
        organization => organization.id === selectedOrganizationId || organization.id === parseInt(organizationId)
      );
    } else {
      filteredOrganization = organizationListing?.data?.results.find(
        organization => organization.id === defaultOrganizationId
      );
    }

    setSwitcherSelectedOrganization(filteredOrganization);
  }, [organizationListing]);

  return {
    organizationListing,
    formik,
    imageRef,
    uploadedLogo,
    setUploadedLogo,
    setIsUploadedLogo,
    handleImageUpload,
    switcherSelectedOrganization,
    handleSelectedOrganization,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    isUploadedLogo,
    organizationListingLoading,
    openCreateDialogue,
    createOrganizationLoading,
  };
}
