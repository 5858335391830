import { EmptyDashboardScreenIcon } from '@assets/svgs';

export function NoDataFoundScreen({
  title = 'No Data Found',
  message = 'It looks like there\'s nothing here right now.',
}) {
  return (
    <div className='flex h-full flex-col items-center justify-center gap-5'>
      <EmptyDashboardScreenIcon className='h-[6.25rem] w-[8.5rem] md:h-[10rem] md:w-[13.375rem]' />
      <div className='flex max-w-[15.25rem] flex-col items-center gap-2 md:max-w-[22.25rem]'>
        <h4 className='font-Manrope text-lg font-semibold leading-[1.625rem] text-mainText md:text-xl md:leading-normal'>
          {title}
        </h4>
        <p className='text-center font-Manrope text-xs font-normal leading-5 text-bodyText'>{message}</p>
      </div>
    </div>
  );
}
