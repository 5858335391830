import { generatePath, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { DashboardActionPopover, DataSourcePopover, TeamPopover } from '@pages/dashboard';
import { ROUTES } from '@routes';

export function DataSourceCard({
  dashboard,
  setDashboardId,
  setSelectedDashboard,
  showDeleteDialog,
  setShowDeleteDialog,
  handleDuplicateDashboard,
  isMember,
  setDeleteDashboardLoading,
  page,
  viewType,
}) {
  const navigate = useNavigate();
  const { organizationId } = useParams();

  return (
    <div
      className='relative max-h-[14rem] cursor-pointer rounded-xl border border-secondaryStrokeButton p-5'
      onClick={() =>
        navigate(generatePath(ROUTES.CUSTOM_DASHBOARD, { organizationId, dashboardId: dashboard?.id }), {
          state: { page, viewType },
        })
      }
    >
      <div className='mb-2 flex justify-between'>
        <span className='inline-block h-[3.25rem] w-[3.25rem] overflow-hidden rounded-full'>
          <img src={dashboard?.logo?.image} alt='logo' className='h-full w-full object-contain' />
        </span>
        <span className='flex flex-col items-end'>
          <DashboardActionPopover
            dashboard={dashboard}
            setDashboardId={setDashboardId}
            handleDuplicateDashboard={handleDuplicateDashboard}
            setSelectedDashboard={setSelectedDashboard}
            setShowDeleteDialog={setShowDeleteDialog}
            showDeleteDialog={showDeleteDialog}
            isMember={isMember}
            isTable={false}
            setDeleteDashboardLoading={setDeleteDashboardLoading}
          />
        </span>
      </div>
      <h4 className='mb-2 font-Manrope text-base font-medium leading-[1.375rem] text-mainText'>{dashboard?.name}</h4>
      <DataSourcePopover dashboard={dashboard} />
      <div className='mt-11 flex items-center justify-between'>
        <TeamPopover dashboard={dashboard} />
        <p className='font-Manrope text-xs leading-4 text-placeholderText'>{moment(dashboard?.modified).fromNow()}</p>
      </div>
    </div>
  );
}
