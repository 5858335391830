import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { isEmpty, isEqual, uniq } from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { apiEndpoints, useDeleteMutation, useGetQuery, usePatchMutation } from '@services';
import { convertTimeFormat, formatTime, handleTimeChange, validateCheckField } from '@utils';
import {
  DATE_FORMAT,
  EMAIL_REGEX,
  ENTER,
  ENTER_KEY,
  FIELD_REQUIRED,
  INVALID_EMAIL_ADDRESS,
  MONTHLY,
  ONE_TIME,
  PAST_TIME_ERROR_MESSAGE,
  PRESS_ENTER_MESSAGE,
  SUCCESSFUL_MESSAGE,
  REPORT_TIME_PERIOD,
  TOTAL_ITEMS_PER_PAGE,
} from '@constants';

export function useScheduledReport() {
  const { organizationId, dashboardId } = useParams();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [dialogReport, setDialogReport] = useState();
  const [editReport, setEditReport] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [invitedEmails, setInvitedEmails] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [emailFieldErrorMessage, setEmailFieldErrorMessage] = useState('');
  const [reportDetails, setReportDetails] = useState(null);
  const [paginationPage, setPaginationPage] = useState(1);
  const [page, setPage] = useState(1);
  const [totalPagesPerItem, setTotalPagesPerItem] = useState(TOTAL_ITEMS_PER_PAGE);

  const {
    data: reportDetail,
    refetch: reportDetailRefetch,
    isFetching: reportDetailFetching,
    error,
  } = useGetQuery(
    'report-detail',
    apiEndpoints.REPORT_DETAIL(organizationId, dashboardId, reportDetails ? reportDetails : editReport?.id),
    {},
    {
      enabled: false,
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess: resp => setInvitedEmails(resp?.data?.recipients?.split(',')),
      onError: error => toast.error(error?.response?.data?.detail || 'Something went wrong'),
    }
  );

  useEffect(() => {
    if (error) toast.error(error?.response?.data?.detail);
  }, [error]);

  const initialValues = {
    email: '',
    subject: reportDetail?.data?.subject,
    description: reportDetail?.data?.description,
    date: reportDetail?.data?.scheduledDate,
    time: convertTimeFormat(reportDetail?.data?.scheduledTime),
    frequency: reportDetail?.data?.scheduledFrequency || '',
    type: REPORT_TIME_PERIOD.find(v => v.id === reportDetail?.data?.scheduledType)?.id || '',
    dayChecked: reportDetail?.data?.scheduledDay ? reportDetail?.data?.scheduledDay?.split(',') : '',
    monthly: '',
    scheduleMonthly: reportDetail?.data?.scheduledMonth || reportDetail?.data?.scheduledLabel,
    scheduledLabel: reportDetail?.data?.scheduledLabel,
    scheduledDay: reportDetail?.data?.scheduledDay,
  };

  const validationSchema = Yup.object({
    subject: Yup.string().required(FIELD_REQUIRED('Subject')),
    date: Yup.string().required(FIELD_REQUIRED('Date')),
    time: Yup.string().required(FIELD_REQUIRED('Time')),
    frequency: Yup.number().positive().min(0),
    description: Yup.string().required(FIELD_REQUIRED('Description')),
  });

  const validate = values => {
    const errors = {};

    validateCheckField(
      !(isEmpty(values.type) && isEmpty(values.dayChecked)) && !values.frequency,
      'frequency',
      FIELD_REQUIRED('Field'),
      errors
    );
    validateCheckField(
      !(!values.frequency && isEmpty(values.dayChecked)) && isEmpty(values.type),
      'type',
      FIELD_REQUIRED('Field'),
      errors
    );
    validateCheckField(
      !(!values.frequency && isEmpty(values.type)) && isEmpty(values.dayChecked) && values?.type !== MONTHLY,
      'dayChecked',
      FIELD_REQUIRED('Field'),
      errors
    );

    validateCheckField(
      !(!values.frequency && isEmpty(values.type)) && isEmpty(values.scheduleMonthly) && values?.type === MONTHLY,
      'scheduleMonthly',
      FIELD_REQUIRED('Field'),
      errors
    );

    return errors;
  };

  const onSubmit = () => {
    if (isEmpty(invitedEmails) && isEmpty(values.email)) {
      setEmailFieldErrorMessage(FIELD_REQUIRED('Email'));
    } else if (!isEmpty(values.email)) {
      setEmailFieldErrorMessage(PRESS_ENTER_MESSAGE);
    } else {
      let payload = {
        dashboard: dashboardId,
        subject: values.subject,
        description: values.description,
        recipients: invitedEmails.join(','),
        date: moment(values.date).format(DATE_FORMAT.REPORT_FORMAT),
        time: formatTime(values.time),
        scheduledFrequency: values.frequency,
        scheduledType: values?.type ? values?.type : ONE_TIME,
        scheduledDay: isEmpty(values?.dayChecked) ? '' : values?.dayChecked?.join(','),
        scheduledMonth: values?.type === MONTHLY ? values.scheduleMonthly?.id || values.scheduleMonthly : '',
        scheduledLabel: values?.type === MONTHLY ? values.scheduleMonthly.name : '',
        scheduledRegion: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      if (isEqual(values.date, initialValues.date) && isEqual(values.time, initialValues.time)) {
        delete payload['date'];
        delete payload['time'];
      } else if (handleTimeChange(values.date, values.time).isPast) {
        toast.error(PAST_TIME_ERROR_MESSAGE);
        return;
      }

      (values?.frequency < 0 || values?.frequency === '') && delete payload['scheduledFrequency'];

      handleReportUpdate({ payload });
    }
  };

  const formik = useFormik({ initialValues, validate, onSubmit, validationSchema, enableReinitialize: true });

  const { values, errors, setFieldValue, isSubmitting } = formik;

  const handleInvitedEmailKeyDown = e => {
    if (e.code === ENTER || e.keyCode === ENTER_KEY) {
      const match = values.email.match(EMAIL_REGEX);

      if (match) {
        setInvitedEmails(uniq([...invitedEmails, values.email.toLowerCase()]));
        setFieldValue('email', '');
        setEmailFieldErrorMessage('');
      }
    }
  };

  const handleRemoveInvitedEmail = selectedEmail =>
    setInvitedEmails(invitedEmails.filter(email => email !== selectedEmail));

  const handleSelectedDay = value => {
    setFieldValue(
      'dayChecked',
      values.dayChecked.includes(value)
        ? values.dayChecked.filter(item => item !== value)
        : [...values.dayChecked, value]
    );
  };

  const queryKey = `schedule-reports-${organizationId}-${dashboardId}-${page}-${totalPagesPerItem}`;

  const {
    data: reportsListing,
    refetch: reportListingRefetch,
    isFetching: reportsLoading,
    error: reportError,
  } = useGetQuery(
    queryKey,
    apiEndpoints.GET_REPORTS(organizationId, dashboardId),
    {
      page,
      pageSize: totalPagesPerItem,
    },
    {
      onError: error => toast.error(error?.response?.data?.detail || 'Something went wrong'),
    }
  );

  const { mutate: handleDeleteReport, isLoading: deleteReportLoading } = useDeleteMutation(
    apiEndpoints.REPORT_DETAIL(organizationId, dashboardId, dialogReport?.id),
    () => {
      reportListingRefetch();
      setDialogReport(null);
      setShowDeleteDialog(false);
      toast.success(SUCCESSFUL_MESSAGE('Report deleted'));
    },
    ({ response: { data } }) => {
      toast.error(data?.detail);
    }
  );

  const { mutate: handleReportUpdate, isLoading: reportUpdateLoading } = usePatchMutation(
    apiEndpoints.REPORT_DETAIL(organizationId, dashboardId, editReport?.id),
    () => {
      reportListingRefetch();
      setShowEditDialog(false);
      setEditReport(null);
      toast.success(SUCCESSFUL_MESSAGE('Report updated'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  useEffect(() => {
    if (isSubmitting && isEmpty(invitedEmails)) {
      isEmpty(values.email) && setEmailFieldErrorMessage(FIELD_REQUIRED('Email'));
    }

    if (values.email) {
      const match = values.email.match(EMAIL_REGEX);
      match ? setEmailFieldErrorMessage('') : setEmailFieldErrorMessage(INVALID_EMAIL_ADDRESS);
    } else if (!isEmpty(invitedEmails) && isEmpty(values.email)) {
      setEmailFieldErrorMessage('');
    }
  }, [isSubmitting, errors, values.email]);

  useEffect(() => {
    !!editReport?.id && reportDetailRefetch();
  }, [editReport?.id]);

  useEffect(() => {
    setPage(1);
  }, [totalPagesPerItem, reportError]);

  useEffect(() => {
    if (reportDetails) reportDetailRefetch();
  }, [reportDetails]);

  return {
    editReport,
    reportsListing,
    showDeleteDialog,
    setShowDeleteDialog,
    dialogReport,
    setDialogReport,
    page,
    setPage,
    deleteReportLoading,
    showEditDialog,
    setShowEditDialog,
    handleDeleteReport,
    formik,
    handleInvitedEmailKeyDown,
    invitedEmails,
    handleRemoveInvitedEmail,
    emailFieldErrorMessage,
    invalidEmails,
    setInvalidEmails,
    handleSelectedDay,
    setEditReport,
    reportUpdateLoading,
    reportDetailFetching,
    reportDetails,
    setReportDetails,
    totalPagesPerItem,
    setTotalPagesPerItem,
    paginationPage,
    setPaginationPage,
    reportsLoading,
    reportListingRefetch,
  };
}
