import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery, usePatchMutation } from '@services';
import { getLocalStorageItem, setLocalStorageItem } from '@utils';
import {
  ACCESS_TOKEN,
  INVITATION_EXPIRED,
  INVITED_ORGANIZATION,
  NOT_FOUND,
  ORGANIZATION_ID_BY_INVITE_ACCEPTANCE,
  SUCCESSFUL_MESSAGE,
} from '@constants';

export function useEmailAcceptance() {
  const { uuid } = useParams();
  const navigate = useNavigate();
  const [expiredMessage, setExpiredMessage] = useState('');

  const accessToken = getLocalStorageItem(ACCESS_TOKEN);

  const { data: organizationInvitation, isLoading } = useGetQuery(
    'organization-invitation',
    apiEndpoints.ORGANIZATIONS_INVITATIONS_ACCEPT(uuid)
  );

  useGetQuery(
    'invitation-verification',
    apiEndpoints.VERIFY_INVITATIONS(uuid),
    {},
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!accessToken,
      onError: ({ response: { data } }) => {
        if (data?.detail === NOT_FOUND) setExpiredMessage(INVITATION_EXPIRED);
        else navigate(ROUTES.ACCESS_DENIED);
      },
    }
  );

  const { mutate: handleAcceptInvitation } = usePatchMutation(
    apiEndpoints.ORGANIZATIONS_INVITATIONS_ACCEPT(uuid),
    () => {
      isEmpty(accessToken)
        ? navigate(ROUTES.SIGN_IN)
        : navigate(generatePath(ROUTES.DASHBOARD, { organizationId: organizationInvitation?.data?.organization?.id }));
      setLocalStorageItem(INVITED_ORGANIZATION, organizationInvitation?.data?.organization);
      setLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE, organizationInvitation?.data?.organization?.id);
      toast.success(SUCCESSFUL_MESSAGE('Invitation accepted'));
    },
    ({ response: { data } }) => toast.error(data?.detail)
  );

  return { organizationInvitation, handleAcceptInvitation, expiredMessage, isLoading };
}
