import { Link } from 'react-router-dom';
import { Button } from '@common/components';
import { ROUTES } from '@routes';
import { MEMBERSHIP_CONSUMED } from '@constants';
import { Logo, SubscriptionExpiredIcon } from '@assets/svgs';

export function SubscriptionExpired() {
  return (
    <div className='h-screen overflow-hidden bg-shade3 p-20'>
      <div className='min-h-37.5rem] relative h-full rounded-3xl border border-secondaryStrokeButton bg-white'>
        <div className='absolute left-14 top-[3.125rem]'>
          <Logo />
        </div>
        <div className='mx-auto flex h-full max-w-[22.25rem] flex-col items-center justify-center gap-5'>
          <SubscriptionExpiredIcon />
          <div className='flex flex-col gap-2'>
            <h2 className='text-center text-xl font-semibold text-mainText'>Subscription Expired!</h2>
            <p className='text-center font-Manrope text-xs leading-5 text-bodyText'>{MEMBERSHIP_CONSUMED}</p>
          </div>
          <Link to={ROUTES.HOME}>
            <Button size='lg' className='w-[17.875rem]'>
              Go Back
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
