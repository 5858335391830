import { cn } from '@shadcnUtils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

function Calendar({ className, classNames, showOutsideDays = true, ...props }) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn(className)}
      classNames={{
        months: 'flex flex-col sm:flex-row p-2',
        month: 'px-3',
        caption: 'flex justify-center relative items-center',
        caption_label: 'text-sm text-mainText font-semibold leading-5 font-Manrope p-[0.3125rem]',
        nav: 'space-x-1 flex items-center',
        nav_button: 'w-4 h-4 [&>svg]:stroke-icon1',
        nav_button_previous: 'absolute left-0',
        nav_button_next: 'absolute right-0',
        table: 'w-full border-collapse my-3',
        head_row: 'flex gap-0 pb-1.5',
        head_cell:
          'min-w-9 h-[1.875rem] text-mainText leading-5 text-sm flex items-center justify-center font-Manrope font-normal',
        row: 'flex w-full py-1.5 gap-2 md:gap-0',
        cell: 'w-9 h-[1.875rem] text-bodyText text-sm font-normal font-Manrope leading-5 [&:has([aria-selected].day-range-start)]:rounded-l-sm [&:has([aria-selected].day-range-end)]:rounded-r-sm first:[&:has([aria-selected])]:rounded-l-sm last:[&:has([aria-selected])]:rounded-r-sm focus-within:relative focus-within:z-20',
        day: 'p-0 w-9 h-[1.875rem] font-normal aria-selected:opacity-100 hover:bg-shade3 hover:rounded-sm',
        day_range_start: 'day-range-start aria-selected:bg-highlighterText1 rounded-l-sm',
        day_range_end: 'day-range-end aria-selected:bg-highlighterText1 rounded-r-sm',
        day_selected: 'text-white bg-highlighterText1 rounded-sm hover:text-mainText/70',
        day_today: 'bg-darkPrimary text-white',
        day_outside: 'day-outside',
        day_disabled: 'text-muted-foreground opacity-50',
        day_range_middle: 'aria-selected:text-bodyText aria-selected:bg-shade3',
        day_hidden: 'invisible',
        ...classNames,
        // [&:has([aria-selected])]:bg-shade3 [&:has([aria-selected].day-range-start)]:bg-highlighterText1 [&:has([aria-selected].day-outside)]:bg-tableHeaderBackground [&:has([aria-selected].day-range-end)]:bg-highlighterText1
      }}
      components={{
        IconLeft: () => <ChevronLeft className='h-4 w-4' />,
        IconRight: () => <ChevronRight className='h-4 w-4' />,
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
