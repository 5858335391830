import axios from 'axios';
import * as caseConverter from 'change-object-case';
import { startsWith } from 'lodash';
import { toast } from 'react-toastify';
import { getLocalStorageItem } from '@utils';
import { ACCESS_TOKEN, ERROR_MESSAGE, ERROR_STATUS_401, ERROR_STATUS_500 } from '@constants';

const options = { recursive: true, arrayRecursive: true };

let errorDisplayed = false;

export function useSetupAxios() {
  axios.interceptors.request.use(config => {
    // errorDisplayed = false;
    const accessToken = getLocalStorageItem(ACCESS_TOKEN);

    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    if (!(config?.data instanceof FormData)) {
      const caseConvertedData = caseConverter.snakeKeys(config?.data, { ...options, exclude: ['accounts-server'] });
      config.data = caseConvertedData;
    }

    const caseConvertedData = caseConverter.snakeKeys(config.params);
    config.params = caseConvertedData;

    return config;
  });

  axios.interceptors.response.use(
    response => {
      // errorDisplayed = false;

      const caseConvertedData = caseConverter.camelKeys(response?.data, options);
      response.data = caseConvertedData;
      return response;
    },
    error => {
      const { response: { status } = {} } = error;

      if (!errorDisplayed) {
        if (status === ERROR_STATUS_401) {
          localStorage.clear();
          window.location.reload();
          errorDisplayed = true;
        } else if (startsWith(status, ERROR_STATUS_500)) {
          toast.error(ERROR_MESSAGE);
          errorDisplayed = true;
        }
      }

      return Promise.reject(error);
    }
  );

  return;
}
