import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import { toast } from 'react-toastify';
import { apiEndpoints, usePatchMutation, usePostMutation } from '@services';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from '@utils';
import { ACCESS_TOKEN } from '@constants';

export function useGoogleSignIn() {
  const navigate = useNavigate();
  const [googleLoginResponse, setGoogleLoginResponse] = useState();

  const hmacValue = getLocalStorageItem('hmac');
  const hostValue = getLocalStorageItem('host');
  const timestampValue = getLocalStorageItem('timestamp');
  const codeValue = getLocalStorageItem('code');
  const shopValue = getLocalStorageItem('shop');
  const stateValue = getLocalStorageItem('state');

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: tokenResponse => {
      setGoogleLoginResponse(tokenResponse);
    },
  });

  const { mutate: addUser } = usePatchMutation(
    `${apiEndpoints.OAUTH_ADD_USER}?code=${codeValue}&host=${hostValue}&timestamp=${timestampValue}&shop=${shopValue}&state=${stateValue}&hmac=${hmacValue}`,
    () => {
      navigate(`/oauth/shopify/redirect/?code=${codeValue}&shop=${shopValue}&state=${stateValue}`);
      setLocalStorageItem('showConnector', {
        show: true,
        id: 2,
        sourceName: 'SHOPIFY',
        description: 'Shopify shop connector',
        logo: {
          id: 540,
          image: 'https://stage.zendash.com/django-media/images/2024/03/shopify-logo-png-transparent.png',
        },
      });
      removeLocalStorageItem('code');
      removeLocalStorageItem('shop');
      removeLocalStorageItem('state');
      removeLocalStorageItem('hmac');
      removeLocalStorageItem('host');
      removeLocalStorageItem('timestamp');
    },
    ({ response: { data } }) => data?.non_field_errors?.map(errMsg => toast.error(errMsg))
  );

  const { mutate: googleLogin } = usePostMutation(
    apiEndpoints.GOOGLE_LOGIN,
    resp => {
      setLocalStorageItem(ACCESS_TOKEN, resp?.data?.accessToken);
      setLocalStorageItem('defaultOrganizationId', resp?.data?.user?.defaultOrganization);
      if (resp?.data?.accessToken && hmacValue && hostValue && timestampValue && codeValue && shopValue && stateValue) {
        addUser({});
      } else window.location.reload();
    },
    ({ response: { data } }) => data?.non_field_errors?.map(errMsg => toast.error(errMsg))
  );

  useEffect(() => {
    googleLoginResponse &&
      googleLogin({
        payload: { access_token: googleLoginResponse?.access_token },
      });
  }, [googleLoginResponse]);

  return {
    handleGoogleLogin,
  };
}
