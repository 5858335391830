import React from 'react';
import { COLORS } from '@constants';

const CircleProgress = React.forwardRef(({ className, child, value = 0, size = 50, ...props }, ref) => {
  const radius = (size - 10) / 2; // Adjust for stroke width
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (value / 100) * circumference;

  return (
    <div ref={ref} className={`${className}`} style={{ width: size, height: size }} {...props}>
      <svg width={size} height={size}>
        <circle stroke='#E2E8F0' fill='transparent' strokeWidth='5' r={radius} cx={size / 2} cy={size / 2} />
        <circle
          stroke={COLORS.TAB_ACTIVE_COLOR}
          fill='transparent'
          strokeWidth='5'
          r={radius}
          cx={size / 2}
          cy={size / 2}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          strokeLinecap='round'
          style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
        />
      </svg>
      {child}
    </div>
  );
});

CircleProgress.displayName = 'CircleProgress';
export { CircleProgress };
