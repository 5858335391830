export function EmptyDashboardIcon({ className }) {
  return (
    <svg
      width='200'
      height='203'
      viewBox='0 0 200 203'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M100 200C155.228 200 200 155.228 200 100C200 44.7715 155.228 0 100 0C44.7715 0 0 44.7715 0 100C0 155.228 44.7715 200 100 200Z'
        fill='url(#paint0_linear_257_603)'
      />
      <g filter='url(#filter0_d_257_603)'>
        <path
          d='M157.333 57.3335H42.6667C38.9848 57.3335 36 60.3183 36 64.0002V177C36 177 61 200 100 200C139 200 164 177 164 177V64.0002C164 60.3183 161.015 57.3335 157.333 57.3335Z'
          fill='white'
        />
      </g>
      <path
        d='M88.0007 70.6667H53.334C51.1248 70.6667 49.334 72.4576 49.334 74.6667C49.334 76.8759 51.1248 78.6667 53.334 78.6667H88.0007C90.2098 78.6667 92.0007 76.8759 92.0007 74.6667C92.0007 72.4576 90.2098 70.6667 88.0007 70.6667Z'
        fill='#E7F9F3'
      />
      <path
        d='M88.0007 126.667H53.334C51.1248 126.667 49.334 128.457 49.334 130.667C49.334 132.876 51.1248 134.667 53.334 134.667H88.0007C90.2098 134.667 92.0007 132.876 92.0007 130.667C92.0007 128.457 90.2098 126.667 88.0007 126.667Z'
        fill='#E7F9F3'
      />
      <path
        d='M143.999 90.6665H55.9993C53.0538 90.6665 50.666 93.0543 50.666 95.9998V109.333C50.666 112.279 53.0538 114.667 55.9993 114.667H143.999C146.945 114.667 149.333 112.279 149.333 109.333V95.9998C149.333 93.0543 146.945 90.6665 143.999 90.6665Z'
        stroke='#3FB79A'
        strokeWidth='2'
      />
      <path
        d='M144.001 145.333H56.0007C52.3188 145.333 49.334 148.318 49.334 152V162.666C49.334 166.348 52.3188 169.333 56.0007 169.333H144.001C147.683 169.333 150.667 166.348 150.667 162.666V152C150.667 148.318 147.683 145.333 144.001 145.333Z'
        fill='#E2F8F4'
      />
      <path
        d='M70.6673 42.6667C73.6128 42.6667 76.0007 40.2789 76.0007 37.3333C76.0007 34.3878 73.6128 32 70.6673 32C67.7218 32 65.334 34.3878 65.334 37.3333C65.334 40.2789 67.7218 42.6667 70.6673 42.6667Z'
        fill='white'
      />
      <path
        d='M100.001 42.6667C102.947 42.6667 105.335 40.2789 105.335 37.3333C105.335 34.3878 102.947 32 100.001 32C97.0558 32 94.668 34.3878 94.668 37.3333C94.668 40.2789 97.0558 42.6667 100.001 42.6667Z'
        fill='#3FB79A'
      />
      <path
        d='M129.335 42.6667C132.281 42.6667 134.669 40.2789 134.669 37.3333C134.669 34.3878 132.281 32 129.335 32C126.39 32 124.002 34.3878 124.002 37.3333C124.002 40.2789 126.39 42.6667 129.335 42.6667Z'
        fill='white'
      />
      <path
        d='M114.667 117.333C118.349 117.333 121.333 114.348 121.333 110.666C121.333 106.985 118.349 104 114.667 104C110.985 104 108 106.985 108 110.666C108 114.348 110.985 117.333 114.667 117.333Z'
        fill='#E2F8F4'
      />
      <path
        d='M119.876 139.16C118.809 139.16 117.813 139.16 116.907 139.103C115.79 139.027 114.716 138.645 113.802 137.998C112.888 137.352 112.171 136.466 111.728 135.437L106.103 124.32C105.69 123.84 105.484 123.216 105.53 122.584C105.575 121.953 105.868 121.364 106.345 120.948C106.736 120.634 107.224 120.465 107.725 120.469C108.095 120.48 108.457 120.571 108.788 120.735C109.119 120.9 109.41 121.134 109.641 121.421L112.196 124.908L112.235 124.953V111.707C112.235 111.05 112.496 110.419 112.961 109.955C113.425 109.49 114.056 109.229 114.713 109.229C115.37 109.229 116 109.49 116.465 109.955C116.93 110.419 117.191 111.05 117.191 111.707V120.373C117.162 120.055 117.2 119.733 117.302 119.43C117.405 119.126 117.569 118.847 117.785 118.611C118.001 118.375 118.264 118.186 118.557 118.057C118.85 117.928 119.167 117.861 119.487 117.861C119.807 117.861 120.124 117.928 120.417 118.057C120.71 118.186 120.973 118.375 121.189 118.611C121.405 118.847 121.569 119.126 121.671 119.43C121.774 119.733 121.812 120.055 121.783 120.373V122.18C121.754 121.861 121.792 121.54 121.894 121.236C121.997 120.933 122.161 120.654 122.377 120.418C122.593 120.181 122.856 119.993 123.149 119.863C123.442 119.734 123.759 119.668 124.079 119.668C124.399 119.668 124.716 119.734 125.009 119.863C125.302 119.993 125.565 120.181 125.781 120.418C125.997 120.654 126.161 120.933 126.263 121.236C126.366 121.54 126.404 121.861 126.375 122.18V123.572C126.346 123.253 126.384 122.932 126.486 122.628C126.589 122.325 126.753 122.046 126.969 121.81C127.185 121.573 127.448 121.385 127.741 121.255C128.034 121.126 128.351 121.06 128.671 121.06C128.991 121.06 129.308 121.126 129.601 121.255C129.894 121.385 130.157 121.573 130.373 121.81C130.589 122.046 130.753 122.325 130.855 122.628C130.958 122.932 130.996 123.253 130.967 123.572V132.021C130.921 134.62 129.747 139.001 125.615 139.001C125.315 139.015 122.773 139.161 119.881 139.161L119.876 139.16Z'
        fill='#3FB79A'
        stroke='white'
      />
      <defs>
        <filter
          id='filter0_d_257_603'
          x='30'
          y='48.3335'
          width='140'
          height='154.667'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='-3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0.788235 0 0 0 0 0.803922 0 0 0 0 0.85098 0 0 0 0.349 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_257_603' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_257_603' result='shape' />
        </filter>
        <linearGradient id='paint0_linear_257_603' x1='100' y1='0' x2='100' y2='200' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#E7F9F3' />
          <stop offset='1' stopColor='#E2F8F4' />
        </linearGradient>
      </defs>
    </svg>
  );
}
