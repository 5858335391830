export function PlusIconWhite({ className }) {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <circle cx='10' cy='10' r='10' fill='#3FB79A' />
      <path
        d='M6.50098 10H13.501M10.001 6.5V13.5'
        stroke='white'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
