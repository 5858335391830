import { useRef } from 'react';
import { Skeleton, Table } from 'antd';
import { Eye } from 'lucide-react';
import { NoDataFoundScreen, TableSkeletonLoader } from '@common/components';
import { TemplateDataSourcePopover } from '@pages/dashboard';

export function TemplateListingTabContent({
  setListingDashboardView,
  templateListing,
  handleScroll,
  templateListingFetching,
  handleSelectedTemplate,
  selectedTemplateId,
}) {
  const scrollContainerRef = useRef();

  const columns = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: text => <p className='w-[6.25rem]'>{text}</p>,
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      key: 'categoryName',
      render: text => <p className='w-[6.25rem]'>{text}</p>,
    },
    {
      title: 'Data Sources',
      dataIndex: 'categoryName',
      key: 'categoryName',
      render: (text, record) =>
        templateListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <TemplateDataSourcePopover dashboard={record} />
        ),
    },
    {
      title: 'Action',
      key: 'action',
      render: () =>
        templateListingFetching ? (
          <Skeleton active title={false} paragraph={{ rows: 1, width: '100%' }} />
        ) : (
          <Eye className='h-5 w-5 cursor-pointer' color='#3FB79A' onClick={() => setListingDashboardView(true)} />
        ),
    },
  ];

  return (
    <div>
      {templateListingFetching ? (
        <TableSkeletonLoader columns={columns} />
      ) : (
        <div className='h-available'>
          {templateListing?.pages[0]?.data?.results.length === 0 || !templateListing ? (
            <NoDataFoundScreen />
          ) : (
            <div
              ref={scrollContainerRef}
              onScroll={handleScroll}
              className='relative z-[1] h-[30rem] overflow-y-auto rounded-xl'
            >
              <Table
                columns={columns}
                dataSource={templateListing?.pages[0]?.data?.results}
                onRow={record => ({
                  onClick: () => handleSelectedTemplate(record?.id),
                })}
                rowClassName={record => (record?.id === selectedTemplateId ? 'bg-shade1' : '')}
                rowKey='id'
                pagination={false}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
}
