export const NoGoalsImage = () => {
  return (
    <svg width='180' height='150' viewBox='0 0 188 151' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M75.5225 150.5C116.887 150.5 150.42 116.921 150.42 75.5C150.42 34.0786 116.887 0.5 75.5225 0.5C34.158 0.5 0.625488 34.0786 0.625488 75.5C0.625488 116.921 34.158 150.5 75.5225 150.5Z'
        fill='url(#paint0_linear_11895_289147)'
      />
      <g filter='url(#filter0_d_11895_289147)'>
        <path
          d='M41.5669 34.5078H176.382C177.706 34.5078 178.976 35.0346 179.912 35.9723C180.849 36.91 181.375 38.1817 181.375 39.5078V64.5078C181.375 65.8339 180.849 67.1057 179.912 68.0433C178.976 68.981 177.706 69.5078 176.382 69.5078H41.5669C40.2426 69.5078 38.9726 68.981 38.0362 68.0433C37.0998 67.1057 36.5737 65.8339 36.5737 64.5078V39.5078C36.5737 38.1817 37.0998 36.91 38.0362 35.9723C38.9726 35.0346 40.2426 34.5078 41.5669 34.5078V34.5078Z'
          fill='white'
        />
      </g>
      <path
        d='M105.478 42.5078H79.5142C77.8596 42.5078 76.5183 43.851 76.5183 45.5078C76.5183 47.1647 77.8596 48.5078 79.5142 48.5078H105.478C107.133 48.5078 108.474 47.1647 108.474 45.5078C108.474 43.851 107.133 42.5078 105.478 42.5078Z'
        fill='#8CD4C2'
      />
      <path
        d='M123.454 55.5059H79.5142C77.8596 55.5059 76.5183 56.849 76.5183 58.5059C76.5183 60.1627 77.8596 61.5059 79.5142 61.5059H123.454C125.108 61.5059 126.45 60.1627 126.45 58.5059C126.45 56.849 125.108 55.5059 123.454 55.5059Z'
        fill='#E2F8F4'
      />
      <path
        d='M68.5324 52.0059C68.5324 43.9977 62.0495 37.5059 54.0524 37.5059C46.0552 37.5059 39.5723 43.9977 39.5723 52.0059C39.5723 60.014 46.0552 66.5059 54.0524 66.5059C62.0495 66.5059 68.5324 60.014 68.5324 52.0059Z'
        fill='#3FB79A'
      />
      <path
        d='M53.1533 56.7054C54.393 56.7052 55.5969 56.2898 56.5736 55.5254L59.6444 58.6004L60.632 57.6114L57.5603 54.5354C58.332 53.5362 58.7431 52.3047 58.7264 51.0416C58.7098 49.7786 58.2664 48.5584 57.4686 47.5799C56.6708 46.6015 55.5655 45.9225 54.333 45.6535C53.1004 45.3846 51.8133 45.5417 50.6813 46.0991C49.5493 46.6565 48.6392 47.5814 48.0993 48.723C47.5595 49.8647 47.4217 51.1558 47.7084 52.3859C47.9951 53.616 48.6894 54.7127 49.6782 55.4971C50.6669 56.2814 51.8919 56.7074 53.1533 56.7054ZM53.1533 46.9114C53.9829 46.9114 54.7938 47.1577 55.4835 47.6192C56.1732 48.0807 56.7108 48.7367 57.0283 49.5041C57.3457 50.2716 57.4288 51.116 57.267 51.9308C57.1051 52.7455 56.7057 53.4939 56.1191 54.0812C55.5325 54.6686 54.7852 55.0686 53.9716 55.2307C53.158 55.3927 52.3146 55.3096 51.5482 54.9917C50.7819 54.6738 50.1268 54.1355 49.6659 53.4448C49.2051 52.7541 48.9591 51.9421 48.9591 51.1114C48.9591 49.9975 49.401 48.9292 50.1875 48.1415C50.9741 47.3539 52.0409 46.9114 53.1533 46.9114Z'
        fill='white'
      />
      <g filter='url(#filter1_d_11895_289147)'>
        <path
          d='M161.402 79.5078H26.5876C23.83 79.5078 21.5945 81.7464 21.5945 84.5078V109.508C21.5945 112.269 23.83 114.508 26.5876 114.508H161.402C164.16 114.508 166.395 112.269 166.395 109.508V84.5078C166.395 81.7464 164.16 79.5078 161.402 79.5078Z'
          fill='white'
        />
      </g>
      <path
        d='M90.4988 87.5078H64.5345C62.8799 87.5078 61.5386 88.851 61.5386 90.5078C61.5386 92.1647 62.8799 93.5078 64.5345 93.5078H90.4988C92.1533 93.5078 93.4946 92.1647 93.4946 90.5078C93.4946 88.851 92.1533 87.5078 90.4988 87.5078Z'
        fill='#8CD4C2'
      />
      <path
        d='M108.474 100.504H64.5345C62.8799 100.504 61.5386 101.847 61.5386 103.504C61.5386 105.161 62.8799 106.504 64.5345 106.504H108.474C110.129 106.504 111.47 105.161 111.47 103.504C111.47 101.847 110.129 100.504 108.474 100.504Z'
        fill='#E2F8F4'
      />
      <path
        d='M53.5537 97.0059C53.5537 88.9977 47.0707 82.5059 39.0736 82.5059C31.0765 82.5059 24.5935 88.9977 24.5935 97.0059C24.5935 105.014 31.0765 111.506 39.0736 111.506C47.0707 111.506 53.5537 105.014 53.5537 97.0059Z'
        fill='#3FB79A'
      />
      <path
        d='M38.1733 101.705C39.413 101.705 40.6169 101.29 41.5936 100.525L44.6644 103.6L45.652 102.611L42.5803 99.5354C43.352 98.5362 43.7631 97.3047 43.7464 96.0416C43.7298 94.7786 43.2864 93.5584 42.4886 92.5799C41.6908 91.6015 40.5855 90.9225 39.353 90.6535C38.1205 90.3846 36.8333 90.5417 35.7013 91.0991C34.5693 91.6565 33.6592 92.5814 33.1194 93.723C32.5795 94.8647 32.4417 96.1558 32.7284 97.3859C33.0151 98.616 33.7094 99.7127 34.6982 100.497C35.6869 101.281 36.9119 101.707 38.1733 101.705ZM38.1733 91.9114C39.0029 91.9114 39.8138 92.1577 40.5035 92.6192C41.1933 93.0807 41.7308 93.7367 42.0483 94.5041C42.3657 95.2716 42.4488 96.116 42.287 96.9308C42.1251 97.7455 41.7257 98.4939 41.1391 99.0812C40.5525 99.6686 39.8052 100.069 38.9916 100.231C38.178 100.393 37.3347 100.31 36.5683 99.9917C35.8019 99.6738 35.1468 99.1355 34.686 98.4448C34.2251 97.7541 33.9791 96.9421 33.9791 96.1114C33.9791 94.9975 34.421 93.9292 35.2076 93.1415C35.9941 92.3539 37.0609 91.9114 38.1733 91.9114Z'
        fill='white'
      />
      <defs>
        <filter
          id='filter0_d_11895_289147'
          x='30.5737'
          y='31.5078'
          width='156.801'
          height='47'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_11895_289147' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_11895_289147' result='shape' />
        </filter>
        <filter
          id='filter1_d_11895_289147'
          x='15.5945'
          y='76.5078'
          width='156.801'
          height='47'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dy='3' />
          <feGaussianBlur stdDeviation='3' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_11895_289147' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_11895_289147' result='shape' />
        </filter>
        <linearGradient
          id='paint0_linear_11895_289147'
          x1='75.5225'
          y1='0.5'
          x2='75.5225'
          y2='150.5'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#E7F9F3' />
          <stop offset='1' stopColor='#E2F8F4' />
        </linearGradient>
      </defs>
    </svg>
  );
};
