import React from 'react';
import { cn } from '@shadcnUtils';
import { cva } from 'class-variance-authority';

const buttonVariants = cva(
  'w-full flex items-center justify-center gap-2 font-Manrope rounded-full whitespace-nowrap disabled:bg-disableButton disabled:border-disableButton disabled:text-white [&>span]:disabled:text-white',
  {
    variants: {
      variant: {
        default: 'text-white bg-highlighterText2 border-highlighterText2 ',
        destructive: 'bg-errorColor text-white',
        destructiveSecondary: 'bg-transparent text-errorTooltip border border-secondaryStrokeButton',
        outline: 'text-mainTextColor font-semibold bg-white border border-mainTextColor',
        secondary: 'text-white font-semibold bg-highlighterText1 border-none',
        tertiary: 'bg-white border border-secondaryStrokeButton text-secondaryTextButton',
        ghost: 'text-highlighterText2 font-semibold bg-white border border-highlighterText2',
        link: 'text-colorTertiary/40',
        active: 'text-darkPrimary',
      },
      size: {
        xs: 'h-8 px-4 py-2 text-xs font-medium leading-4',
        sm: 'h-9 px-3.5 py-2 text-sm leading-5',
        default: 'px-4 py-2.5 h-10 text-sm leading-5',
        lg: 'h-10 px-4 py-3 text-xs leading-4 font-semibold md:h-11 md:text-sm md:leading-5',
        xl: 'h-12 px-5 py-3 text-base leading-6',
        xxl: 'gap-3 h-[3.75rem] px-7 py-4 text-lg leading-7',
        icon: 'h-10 w-10',
        auto: 'p-0 w-min',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

const Button = React.forwardRef(({ className, variant, size, ...props }, ref) => {
  const Comp = 'button';
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = 'Button';

export { Button, buttonVariants };
