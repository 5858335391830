import { useState } from 'react';
import { toast } from 'react-toastify';
import { apiEndpoints, useGetQuery, usePostMutation } from '@services';

export function useAIToken() {
  const [selectedTokenId, setSelectedTokenId] = useState(null);
  const {
    data: aiTokenListing,
    refetch: aiTokenListingRefetch,
    isFetching: aiTokenListingFetching,
  } = useGetQuery('ai-token', apiEndpoints.AI_PRODUCT_LISTING);

  const { mutate: handleAiTokenCheckout, isLoading: aiTokenCheckoutLoading } = usePostMutation(
    apiEndpoints.AI_PRODUCT_CHECKOUT,
    resp => {
      window.location.href = resp?.data?.checkoutSessionUrl;
      aiTokenListingRefetch();
      setSelectedTokenId(null);
    },
    ({ response: { data } }) => {
      toast.error(data?.detail);
      setSelectedTokenId(null);
    }
  );

  return {
    aiTokenListing,
    handleAiTokenCheckout,
    aiTokenListingFetching,
    aiTokenCheckoutLoading,
    setSelectedTokenId,
    selectedTokenId,
  };
}
