export function ZendashLogo({
  // fillColor = '#1C1C23',
  className,
}) {
  return (
    // <svg
    //   width='127'
    //   height='24'
    //   viewBox='0 0 127 24'
    //   fill='none'
    //   xmlns='http://www.w3.org/2000/svg'
    //   className={`${className}`}
    // >
    //   <path d='M67.4961 2.79492H64.9258V14.4906H67.4961V2.79492Z' fill='url(#paint0_linear_4892_74753)' />
    //   <path d='M63.084 5.59375H60.5137V17.2895H63.084V5.59375Z' fill='url(#paint1_linear_4892_74753)' />
    //   <path
    //     d='M0 8.52878H11.1935L0 21.32V23.7247H14.4925V21.32H3.26454L14.4925 8.52878V6.15625H0V8.52878Z'
    //     fill={fillColor}
    //   />
    //   <path
    //     d='M98.5623 10.7694C98.5623 9.36057 99.8187 8.3354 101.921 8.3354C104.216 8.3354 105.533 9.6154 105.651 11.3787H108.519C108.327 7.94877 105.817 5.85742 102.016 5.85742C98.2152 5.85742 95.7051 8.06886 95.7051 10.7607C95.7051 17.3012 105.877 15.0254 105.877 18.9356C105.877 20.3797 104.621 21.5337 102.363 21.5337C99.945 21.5337 98.4676 20.2508 98.3127 18.5519H95.3608C95.5473 21.7563 98.3729 24 102.392 24C106.193 24 108.674 21.8208 108.674 18.9356C108.545 12.6616 98.5623 14.9375 98.5623 10.7694Z'
    //     fill={fillColor}
    //   />
    //   <path
    //     d='M92.2882 14.9375V6.13282H89.5946V8.54929C88.3712 7.36453 86.8345 6.57197 85.1746 6.26964C83.5147 5.96732 81.8045 6.16849 80.2554 6.84828C78.7063 7.52807 77.3863 8.65664 76.4589 10.0944C75.5314 11.5321 75.0371 13.216 75.0371 14.9375C75.0371 16.6591 75.5314 18.343 76.4589 19.7807C77.3863 21.2185 78.7063 22.347 80.2554 23.0268C81.8045 23.7066 83.5147 23.9078 85.1746 23.6055C86.8345 23.3031 88.3712 22.5106 89.5946 21.3258V23.7423H92.2882V14.9375ZM83.665 20.9948C82.4916 20.9954 81.3443 20.6406 80.3684 19.9754C79.3924 19.3102 78.6316 18.3644 78.1822 17.2576C77.7327 16.1508 77.6148 14.9328 77.8434 13.7576C78.072 12.5824 78.6368 11.5029 79.4663 10.6554C80.2959 9.80802 81.3529 9.23083 82.5038 8.99687C83.6546 8.76291 84.8476 8.8827 85.9317 9.34107C87.0159 9.79945 87.9426 10.5758 88.5946 11.572C89.2466 12.5682 89.5946 13.7394 89.5946 14.9375C89.5946 16.5435 88.9699 18.0838 87.858 19.2197C86.7461 20.3555 85.2379 20.9941 83.665 20.9948Z'
    //     fill={fillColor}
    //   />
    //   <path
    //     d='M44.6094 5.83398C42.2857 5.83398 40.2146 6.82693 39.0814 8.65466V6.13275H36.3906V23.7422H39.0814V14.009C39.0814 10.2246 41.0895 8.36762 44.0127 8.36762C46.9358 8.36762 48.8148 10.1924 48.8148 13.7834V23.7246H51.6347V13.3675C51.6347 8.33247 48.5968 5.83398 44.6094 5.83398Z'
    //     fill={fillColor}
    //   />
    //   <path
    //     d='M119.966 5.83467C117.643 5.83467 115.571 6.82762 114.441 8.65535V0H111.748V23.7429H114.441V14.0097C114.441 10.2253 116.449 8.36831 119.37 8.36831C122.29 8.36831 124.172 10.1931 124.172 13.7841V23.7253H127V13.3682C127 8.33316 123.954 5.83467 119.966 5.83467Z'
    //     fill={fillColor}
    //   />
    //   <path
    //     d='M24.6938 6.13286C23.1188 6.13144 21.5735 6.57049 20.2256 7.40237C18.8776 8.23425 17.7786 9.42718 17.0477 10.8517C16.3168 12.2762 15.982 13.8779 16.0796 15.483C16.1771 17.0881 16.7034 18.6352 17.6012 19.9566C18.499 21.2779 19.7341 22.3229 21.1724 22.9782C22.6107 23.6335 24.1973 23.874 25.7601 23.6738C27.3228 23.4735 28.802 22.84 30.0371 21.8421C31.2722 20.8442 32.2161 19.52 32.7662 18.0131H29.7771C29.1609 19.0888 28.2331 19.9428 27.1216 20.4574C26.0101 20.972 24.7696 21.1219 23.5711 20.8864C22.3726 20.6508 21.2753 20.0414 20.4303 19.1423C19.5853 18.2431 19.0343 17.0985 18.8532 15.8661H33.2769C33.4072 14.6372 33.2822 13.394 32.9099 12.2178C32.5376 11.0416 31.9265 9.95886 31.1164 9.04026C30.3064 8.12166 29.3156 7.38789 28.2089 6.8869C27.1022 6.38591 25.9044 6.12897 24.6938 6.13286ZM18.9909 13.3647C19.3241 12.0795 20.0642 10.943 21.096 10.1321C22.1278 9.32122 23.3932 8.88153 24.6952 8.88153C25.9973 8.88153 27.2627 9.32122 28.2945 10.1321C29.3262 10.943 30.0664 12.0795 30.3996 13.3647H18.9909Z'
    //     fill={fillColor}
    //   />
    //   <path
    //     d='M69.2728 8.55869V14.9382C69.2735 15.9077 69.0463 16.8633 68.6102 17.7245C68.1742 18.5858 67.542 19.3276 66.7669 19.8876C65.9918 20.4476 65.0963 20.8094 64.1558 20.9427C63.2153 21.0759 62.2571 20.9767 61.362 20.6534C60.4668 20.3301 59.6607 19.7921 59.0114 19.0846C58.3622 18.3771 57.8887 17.5208 57.6309 16.5877C57.373 15.6546 57.3383 14.672 57.5296 13.7223C57.721 12.7727 58.1328 11.8838 58.7304 11.1304V7.52181C57.7272 8.18364 56.87 9.05143 56.2131 10.07C55.5563 11.0885 55.1143 12.2355 54.9153 13.438C54.7162 14.6404 54.7645 15.872 55.057 17.0544C55.3495 18.2367 55.8798 19.344 56.6143 20.3057C57.3487 21.2674 58.2711 22.0625 59.3229 22.6403C60.3747 23.2182 61.5327 23.5662 62.7234 23.6622C63.914 23.7583 65.1112 23.6002 66.2388 23.198C67.3664 22.7959 68.3996 22.1585 69.2728 21.3264V23.7429H71.9664V0H69.2728V8.55869Z'
    //     fill={fillColor}
    //   />
    //   <defs>
    //     <linearGradient
    //       id='paint0_linear_4892_74753'
    //       x1='67.6166'
    //       y1='5.021'
    //       x2='62.3713'
    //       y2='17.9745'
    //       gradientUnits='userSpaceOnUse'
    //     >
    //       <stop stopColor='#8AC33D' />
    //       <stop offset='1' stopColor='#00ACE7' />
    //     </linearGradient>
    //     <linearGradient
    //       id='paint1_linear_4892_74753'
    //       x1='64.7421'
    //       y1='3.86268'
    //       x2='59.4968'
    //       y2='16.8162'
    //       gradientUnits='userSpaceOnUse'
    //     >
    //       <stop stopColor='#8AC33D' />
    //       <stop offset='1' stopColor='#00ACE7' />
    //     </linearGradient>
    //   </defs>
    // </svg>
    <svg
      width='174'
      height='32'
      viewBox='0 0 174 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path d='M92.8824 3.73047H89.3833V19.3242H92.8824V3.73047Z' fill='#3FB79A' />
      <path d='M86.8775 7.45557H83.3784V23.0493H86.8775V7.45557Z' fill='#386360' />
      <path
        d='M1 11.3679H16.2384L1 28.4222V31.6284H20.7294V28.4222H5.4442L20.7294 11.3679V8.20459H1V11.3679Z'
        fill='#1A202C'
      />
      <path
        d='M135.179 14.3592C135.179 12.4808 136.889 11.1139 139.752 11.1139C142.876 11.1139 144.668 12.8205 144.829 15.1715H148.734C148.472 10.5984 145.055 7.81006 139.881 7.81006C134.706 7.81006 131.289 10.7585 131.289 14.3475C131.289 23.068 145.137 20.0336 145.137 25.2471C145.137 27.1724 143.427 28.7111 140.353 28.7111C137.061 28.7111 135.05 27.0006 134.839 24.7355H130.82C131.074 29.0079 134.921 31.9993 140.392 31.9993C145.567 31.9993 148.945 29.0938 148.945 25.2471C148.769 16.882 135.179 19.9164 135.179 14.3592Z'
        fill='#1A202C'
      />
      <path
        d='M126.636 19.9165V8.17725H122.969V11.3991C121.304 9.81949 119.212 8.76277 116.952 8.35968C114.692 7.95659 112.364 8.22481 110.255 9.13117C108.146 10.0375 106.35 11.5422 105.087 13.4592C103.824 15.3761 103.151 17.6211 103.151 19.9165C103.151 22.2119 103.824 24.4569 105.087 26.3738C106.35 28.2907 108.146 29.7955 110.255 30.7018C112.364 31.6082 114.692 31.8764 116.952 31.4733C119.212 31.0702 121.304 30.0135 122.969 28.4339V31.6557H126.636V19.9165ZM114.897 27.9926C113.3 27.9934 111.738 27.5204 110.409 26.6334C109.08 25.7465 108.045 24.4854 107.433 23.0098C106.821 21.5341 106.661 19.9102 106.972 18.3433C107.283 16.7765 108.052 15.3371 109.181 14.2072C110.31 13.0774 111.749 12.3078 113.316 11.9959C114.883 11.6839 116.507 11.8436 117.983 12.4548C119.459 13.0659 120.72 14.1011 121.608 15.4292C122.495 16.7574 122.969 18.319 122.969 19.9165C122.969 22.0577 122.119 24.1113 120.605 25.6258C119.091 27.1402 117.038 27.9916 114.897 27.9926Z'
        fill='#1A202C'
      />
      <path
        d='M61.7296 7.77881C58.5663 7.77881 55.7467 9.1027 54.2042 11.5396V8.17715H50.541V31.6556H54.2042V18.6784C54.2042 13.6328 56.9378 11.1569 60.9173 11.1569C64.8968 11.1569 67.4547 13.5899 67.4547 18.3777V31.6322H71.2936V17.8232C71.2936 11.11 67.1579 7.77881 61.7296 7.77881Z'
        fill='#1A202C'
      />
      <path
        d='M164.314 7.7793C161.15 7.7793 158.331 9.10319 156.792 11.5401V0H153.125V31.6561H156.792V18.6789C156.792 13.6333 159.526 11.1574 163.501 11.1574C167.477 11.1574 170.039 13.5903 170.039 18.3782V31.6327H173.889V17.8237C173.889 11.1105 169.742 7.7793 164.314 7.7793Z'
        fill='#1A202C'
      />
      <path
        d='M34.6168 8.1773C32.4726 8.17541 30.3689 8.7608 28.5339 9.86993C26.6989 10.9791 25.2027 12.5696 24.2077 14.4689C23.2127 16.3682 22.7569 18.5037 22.8897 20.6438C23.0226 22.7838 23.739 24.8466 24.9612 26.6083C26.1834 28.37 27.8648 29.7633 29.8229 30.637C31.781 31.5107 33.9409 31.8314 36.0684 31.5644C38.1958 31.2974 40.2095 30.4528 41.891 29.1223C43.5724 27.7918 44.8573 26.0262 45.6062 24.0171H41.5369C40.6981 25.4513 39.4351 26.5899 37.9219 27.2761C36.4087 27.9622 34.7199 28.162 33.0884 27.848C31.4569 27.5339 29.9629 26.7215 28.8126 25.5226C27.6622 24.3238 26.9121 22.7976 26.6657 21.1545H46.3014C46.4788 19.516 46.3086 17.8585 45.8018 16.2903C45.295 14.7221 44.463 13.2785 43.3603 12.0537C42.2575 10.8289 40.9087 9.85063 39.4021 9.18266C37.8955 8.5147 36.2649 8.17212 34.6168 8.1773ZM26.8531 17.8194C27.3067 16.1059 28.3143 14.5906 29.7189 13.5095C31.1235 12.4283 32.8462 11.8421 34.6187 11.8421C36.3913 11.8421 38.114 12.4283 39.5186 13.5095C40.9232 14.5906 41.9308 16.1059 42.3844 17.8194H26.8531Z'
        fill='#1A202C'
      />
      <path
        d='M95.3042 11.4112V19.9169C95.3052 21.2095 94.9959 22.4836 94.4023 23.6319C93.8087 24.7802 92.9481 25.7692 91.8928 26.5158C90.8376 27.2625 89.6186 27.7449 88.3382 27.9226C87.0578 28.1003 85.7534 27.968 84.5348 27.5369C83.3161 27.1058 82.2187 26.3885 81.3349 25.4452C80.451 24.502 79.8065 23.3603 79.4555 22.1162C79.1044 20.8721 79.0572 19.562 79.3176 18.2958C79.5781 17.0297 80.1387 15.8445 80.9523 14.84V10.0287C79.5866 10.9111 78.4196 12.0682 77.5254 13.4262C76.6312 14.7842 76.0295 16.3134 75.7586 17.9167C75.4876 19.5199 75.5533 21.1619 75.9515 22.7384C76.3497 24.3148 77.0717 25.7911 78.0715 27.0733C79.0713 28.3556 80.3271 29.4156 81.7589 30.1861C83.1907 30.9566 84.7673 31.4206 86.3882 31.5486C88.0091 31.6766 89.6389 31.4658 91.1739 30.9296C92.7089 30.3935 94.1155 29.5437 95.3042 28.4343V31.6561H98.9712V0H95.3042V11.4112Z'
        fill='#1A202C'
      />
    </svg>
  );
}
