import ReactPaginate from 'react-paginate';
import { COLORS, THEME_STYLE } from '@constants';
import { ArrowLeft } from '@assets/svgs';

export function TableWidgetPagination({ page = 1, widget, handleTablePagination }) {
  return (
    <ReactPaginate
      pageCount={widget?.widgetData?.data?.count}
      onPageChange={({ selected }) => handleTablePagination(selected, widget)}
      disabledClassName='disabled'
      className='flex items-center justify-center gap-1'
      breakLabel='...'
      breakClassName={`${widget.themeStyle === THEME_STYLE.DARK ? '!text-white' : '!text-darkSecondary'}`}
      nextLabel={
        <>
          Next
          {
            <div className='h-4 w-4 rotate-180'>
              <ArrowLeft
                strokeColor={widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY}
              />
            </div>
          }
        </>
      }
      previousLabel={
        <>
          {
            <div className='h-4 w-4'>
              <ArrowLeft
                strokeColor={widget.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY}
              />
            </div>
          }
          Previous
        </>
      }
      pageRangeDisplayed={page === 1 ? 3 : 2}
      marginPagesDisplayed={1}
      forcePage={page - 1}
      renderOnZeroPageCount={null}
      activeClassName='!bg-slateGray'
      activeLinkClassName='text-white'
      pageClassName={`rounded-lg md:h-10 h-8 lg:h-8 xxl:h-10 text-center flex items-center w-8 md:w-10 lg:w-8 xxl:w-10 justify-center xxl:px-2 px-1 ${widget.themeStyle === THEME_STYLE.DARK ? '!text-white' : '!text-darkSecondary'}`}
      previousClassName={`mr-1 px-4 py-2 flex ${widget?.widgetData?.data?.count === 0 && 'disabled'}`}
      nextClassName={`ml-1 px-4 py-2 ${widget?.widgetData?.data?.count === 0 && 'disabled'}`}
      pageLinkClassName='text-sm '
      previousLinkClassName={`flex gap-1 items-center text-sm leading-6 font-medium ${widget.themeStyle === THEME_STYLE.DARK ? '!text-white' : '!text-darkSecondary'}`}
      nextLinkClassName={`flex gap-1 items-center font-medium text-sm leading-6 ${widget.themeStyle === THEME_STYLE.DARK ? '!text-white' : '!text-darkSecondary'}`}
    />
  );
}
