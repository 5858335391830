import { useEffect, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Tabs } from 'antd';
import { ROUTES } from '@routes';
import { apiEndpoints, useGetQuery } from '@services';
import { getLocalStorageItem } from '@utils';
import { PROFILE_TABS, PROFILE_TABS_LIST } from '@constants';

export function Profile() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showAiToken = getLocalStorageItem('showAiToken');

  const [currentTab, setCurrentTab] = useState(PROFILE_TABS.PROFILE);

  const { refetch: userDetailRefetch } = useGetQuery('user-details', apiEndpoints.USER_DETAIL);

  const filteredTabs = useMemo(() => {
    if (showAiToken === null) return PROFILE_TABS_LIST;
    return PROFILE_TABS_LIST.filter(v =>
      showAiToken ? v : ![PROFILE_TABS.AI_TOKEN, PROFILE_TABS.DOMAIN].includes(v.value)
    );
  }, [showAiToken]);

  const items = useMemo(
    () =>
      filteredTabs.map(({ value, label }) => ({
        key: value,
        label: label,
        children: <Outlet />,
      })),
    [filteredTabs]
  );

  const handleChange = key => {
    setCurrentTab(key);
    const selectedTab = PROFILE_TABS_LIST.find(tab => tab.value === key);
    const targetRoute = selectedTab ? selectedTab.path : ROUTES.PROFILE_TAB;
    if (key === PROFILE_TABS.PROFILE) userDetailRefetch();
    navigate(targetRoute);
  };

  useEffect(() => {
    const currentPath = pathname.split('/')?.pop();
    const selectedTab = PROFILE_TABS_LIST.find(tab => tab.path === currentPath);

    if (!selectedTab) {
      navigate(ROUTES.PROFILE_TAB);
      setCurrentTab(PROFILE_TABS.PROFILE);
      userDetailRefetch();
    } else setCurrentTab(selectedTab.value);
  }, [pathname, navigate, userDetailRefetch]);

  return (
    <div className='p-5 md:p-8'>
      <div className='rounded-xl border border-secondaryStrokeButton'>
        <Tabs activeKey={currentTab} items={items} className='profile-tabs' onChange={handleChange} />
      </div>
    </div>
  );
}
