import { useRef } from 'react';
import { Ban } from 'lucide-react';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function EmptyWidget({ widget, onSlideMode }) {
  const widgetRef = useRef(null);

  // const widgetFontSize = Math.min(widgetRef?.current?.clientHeight, widgetRef?.current?.clientWidth) / 12;

  // const widgetIconSize = Math.min(widgetRef?.current?.clientHeight, widgetRef?.current?.clientWidth) / 4.3;

  return (
    <div
      ref={widgetRef}
      className={`flex h-full flex-col items-center justify-center ${onSlideMode ? 'gap-0' : 'gap-4'}`}
    >
      <Ban
        color={
          widget?.themeColor === DEFAULT_VALUE
            ? widget?.themeStyle === THEME_STYLE.DARK
              ? COLORS.WHITE_COLOR
              : COLORS.DARK_SECONDARY
            : widget?.themeStyle === THEME_STYLE.DARK
              ? COLORS.WHITE_COLOR
              : widget?.foreground
        }
        className={onSlideMode ? 'h-3 w-3' : 'h-10 w-10'}
        // style={{
        //   height: Math.floor(widgetIconSize),
        //   width: Math.floor(widgetIconSize),
        // }}
      />
      <p
        className={`leading-4 ${onSlideMode ? 'text-[6px]' : 'text-xl'}`}
        style={
          widget?.themeColor === DEFAULT_VALUE
            ? {
              color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
              // fontSize: Math.floor(widgetFontSize) || '14px',
            }
            : {
              color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget?.foreground,
              // fontSize: Math.floor(widgetFontSize) || '14px',
            }
        }
      >
        No Data Available
      </p>
    </div>
  );
}
