import { useState } from 'react';
import { NavLink, useParams, generatePath } from 'react-router-dom';
import { debounce } from 'lodash';
import { LayoutGrid, List, Plus, Search } from 'lucide-react';
import { Button, SearchInput } from '@common/components';
import { DataSourceGridView, DataSourceTabularView, useDataSource } from '@pages/dataSource';
import { ROUTES } from '@routes';
import { COLORS, DASHBOARD_VIEW_TYPE, DATA_SOURCE_NOT_CONNECTED } from '@constants';
import { DataSourceIntegrationIcon, EmptyDashboardScreenIcon } from '@assets/svgs';

export function DataSource() {
  const { organizationId } = useParams();
  const {
    connectedDataSource,
    showDeleteDialog,
    setShowDeleteDialog,
    handleDeleteDataSource,
    deleteDataSourceLoading,
    setDeleteDataSourceLoading,
    connectedDataSourceLoading,
    setSearch,
    search,
    dataSourcePage,
    setDataSourcePage,
    totalPagesPerItem,
    setTotalPagesPerItem,
    paginationPage,
    setPaginationPage,
    connectedDataSourceFetching,
  } = useDataSource();

  const handleSearchChange = debounce(e => setSearch(e.target.value), 600);

  const [viewType, setViewType] = useState('grid');

  return (
    <div className='h-full p-5 md:p-8'>
      <div className='rounded-lg border border-secondaryStrokeButton bg-white'>
        <div className='flex h-auto flex-col justify-between gap-3 border-b border-secondaryStrokeButton px-5 py-4 md:px-6 lg:h-[4.625rem] lg:flex-row lg:items-center'>
          <h3 className='whitespace-nowrap font-Manrope text-lg font-semibold leading-[26px] text-mainText'>
            Connected Data Sources
          </h3>
          {(connectedDataSource?.data?.results.length > 0 || !!search) && (
            <div className='relative flex w-full flex-col-reverse justify-end gap-3 md:flex-row xxl:gap-4'>
              <SearchInput
                onChange={handleSearchChange}
                placeholder='Search'
                size='lg'
                parentClassName='lg:w-[16.25rem]'
                className='pl-9 md:pl-11'
                leadIcon={<Search className='h-4 w-4 md:h-5 md:w-5' color={COLORS.ICON_COLOR} />}
              />
              <div className='flex items-center gap-2 xxl:gap-4'>
                <div className='ant-tabs-nav'>
                  <div className='ant-tabs-nav-list flex items-center'>
                    <div
                      onClick={() => {
                        setViewType('grid');
                      }}
                      className={`ant-tabs-tab h-[2.375rem] !w-[2.6875rem] cursor-pointer !px-3 !py-2.5 md:h-[2.625rem] md:!w-full md:!px-4 md:!py-3 ${viewType === DASHBOARD_VIEW_TYPE.GRID ? 'ant-tabs-tab-active-left' : ''}`}
                    >
                      <div className='ant-tabs-tab-btn'>
                        <LayoutGrid
                          className='h-5 w-5'
                          color={viewType === DASHBOARD_VIEW_TYPE.GRID ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR}
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        setViewType('table');
                      }}
                      className={`ant-tabs-tab h-[2.375rem] !w-[2.6875rem] cursor-pointer !px-3 !py-2.5 md:h-[2.625rem] md:!w-full md:!px-4 md:!py-3 ${viewType === DASHBOARD_VIEW_TYPE.TABLE ? 'ant-tabs-tab-active-right' : ''}`}
                    >
                      <div className='ant-tabs-tab-btn'>
                        <List
                          className='h-5 w-5'
                          color={viewType === DASHBOARD_VIEW_TYPE.TABLE ? COLORS.TAB_ACTIVE_COLOR : COLORS.TAB_COLOR}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <NavLink className='w-full' to={generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: 1 })}>
                  <Button className='w-full md:w-[10.75rem]'>
                    <Plus className='h-3.5 w-3.5 md:h-5 md:w-5' color='white' />
                    Add Data Source
                  </Button>
                </NavLink>
              </div>
            </div>
          )}
        </div>
        {connectedDataSource?.data?.results.length === 0 ? (
          <div className='flex h-[calc(100vh-215px)] min-h-[23.25rem] flex-col items-center justify-center gap-6 overflow-y-auto rounded-b-xl scrollbar-none'>
            {search ? (
              <div className='flex flex-col items-center gap-10'>
                <EmptyDashboardScreenIcon className='h-[6.25rem] w-[8.5rem] md:h-[10rem] md:w-[13.375rem]' />
                <div className='flex flex-col items-center gap-5 md:gap-10'>
                  <div className='flex w-[14.875rem] flex-col items-center gap-3 md:w-[25rem]'>
                    <h3 className='font-Manrope text-lg font-semibold leading-[1.625rem] text-mainText md:text-xl md:leading-normal'>
                      Invalid Search
                    </h3>
                    <p className='text-center font-Manrope text-xs leading-4 text-bodyText md:text-sm md:leading-5'>
                      Please check your input and try again.
                    </p>
                  </div>
                  <Button className='w-[14rem] md:w-[16.25rem]'>Submit Request for Integration</Button>
                </div>
              </div>
            ) : (
              !connectedDataSourceFetching && (
                <div className='flex flex-col items-center gap-10'>
                  <DataSourceIntegrationIcon className='h-[6.25rem] w-[8.375rem] md:h-[12.5rem] md:w-[16.75rem]' />
                  <div className='flex w-[14.875rem] flex-col items-center gap-5 md:w-[25rem] md:gap-10'>
                    <div className='flex w-[14.875rem] flex-col items-center gap-3 md:w-[25rem]'>
                      <h3 className='font-Manrope text-lg font-semibold leading-[1.625rem] text-mainText md:text-2xl md:leading-normal'>
                        Connect Data Sources
                      </h3>
                      <p className='text-Manrope text-center text-xs leading-4 text-bodyText md:text-sm md:leading-5'>
                        {DATA_SOURCE_NOT_CONNECTED}
                      </p>
                    </div>
                    <NavLink to={generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: 1 })}>
                      <Button size='lg' className='w-[14rem] md:w-[10.75rem]'>
                        <Plus className='h-3.5 w-3.5 md:h-5 md:w-5' color='white' />
                        Add Data Source
                      </Button>
                    </NavLink>
                  </div>
                </div>
              )
            )}
          </div>
        ) : (
          <div className='flex flex-col gap-8'>
            {viewType === DASHBOARD_VIEW_TYPE.TABLE && (
              <DataSourceTabularView
                connectedDataSourceFetching={search ? connectedDataSourceFetching : connectedDataSourceLoading}
                connectedDataSource={connectedDataSource}
                handleDeleteDataSource={handleDeleteDataSource}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteDataSourceLoading={deleteDataSourceLoading}
                setDeleteDataSourceLoading={setDeleteDataSourceLoading}
                page={dataSourcePage}
                setPage={setDataSourcePage}
                totalItems={totalPagesPerItem}
                setTotalItems={setTotalPagesPerItem}
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
              />
            )}
            {viewType === DASHBOARD_VIEW_TYPE.GRID && (
              <DataSourceGridView
                connectedDataSourceFetching={search ? connectedDataSourceFetching : connectedDataSourceLoading}
                connectedDataSource={connectedDataSource}
                handleDeleteDataSource={handleDeleteDataSource}
                showDeleteDialog={showDeleteDialog}
                setShowDeleteDialog={setShowDeleteDialog}
                deleteDataSourceLoading={deleteDataSourceLoading}
                setDeleteDataSourceLoading={setDeleteDataSourceLoading}
                page={dataSourcePage}
                setPage={setDataSourcePage}
                totalItems={totalPagesPerItem}
                setTotalItems={setTotalPagesPerItem}
                paginationPage={paginationPage}
                setPaginationPage={setPaginationPage}
              />
            )}
            {/* TODO: Will be checked by Ayyan */}
            {/* {connectedDataSource?.data?.count > TOTAL_ITEMS_PER_PAGE && (
                    <CustomPagination page={dataSourcePage} setPage={setDataSourcePage} data={connectedDataSource} />
                  )} */}
          </div>
        )}
      </div>
    </div>
  );
}
