import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { Calendar, ChevronLeft, ChevronRight, ChevronsLeft, ChevronsRight } from 'lucide-react';
import { COLORS, DATE_FORMAT } from '@constants';

export function CustomDatePicker({ value, handleChange, showError, disabled, scheduleClass, className }) {
  const disablePastDates = current => current && current < dayjs().startOf('day');
  return (
    <div className={`flex w-full flex-col gap-1.5 !rounded-full ${disabled ? 'disable-time' : ''} ${scheduleClass}`}>
      <div className='font-Manrope text-xs leading-4 text-labelText md:text-sm md:leading-5'>Date</div>
      <DatePicker
        value={value ? dayjs(value) : null}
        format={DATE_FORMAT.CUSTOM_DATE_FORMAT}
        placeholder='Select date'
        disabled={disabled}
        disabledDate={disablePastDates}
        onChange={(date, dateString) => handleChange(dateString)}
        className={`${className} h-10 rounded-[1.5rem] px-4 py-3 font-Manrope text-xs leading-4 text-mainText hover:border hover:!border-highlighterText1 md:h-11 [&>.ant-picker-input>input]:text-xs [&>.ant-picker-input>input]:placeholder:text-xs md:[&>.ant-picker-input>input]:text-sm md:[&>.ant-picker-input>input]:placeholder:text-sm ${showError ? 'border border-errorColor' : ''} !rounded-full`}
        size='large'
        suffixIcon={<Calendar className='h-4 w-4' color={COLORS.ICON_COLOR} />}
        nextIcon={<ChevronRight className='h-4 w-4' color={COLORS.ICON_COLOR} />}
        prevIcon={<ChevronLeft className='h-4 w-4' color={COLORS.ICON_COLOR} />}
        superNextIcon={<ChevronsRight className='h-[1.125rem] w-[1.125rem]' color={COLORS.ICON_COLOR} />}
        superPrevIcon={<ChevronsLeft className='h-[1.125rem] w-[1.125rem]' color={COLORS.ICON_COLOR} />}
      />
      {!!showError && (
        <p className='h-min-content text-xs font-normal leading-[0.9075rem] text-errorTooltip'>{showError}</p>
      )}
    </div>
  );
}
