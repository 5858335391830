export function BulbIcon() {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
      <g clipPath='url(#clip0_13287_47263)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.9955 17.5744H7.005C6.65484 17.5744 6.36094 17.31 6.32484 16.9617C6.08625 14.662 5.55141 13.0303 5.07984 11.5908C4.63688 10.2384 4.25391 9.07031 4.25391 7.74609C4.25391 3.47484 7.72875 0 12 0C16.2712 0 19.7461 3.47484 19.7461 7.74609C19.7461 9.07031 19.3631 10.2384 18.9202 11.5908C18.4486 13.0303 17.9138 14.662 17.6752 16.9617C17.6391 17.31 17.3452 17.5744 16.9955 17.5744Z'
          fill='#FFD21A'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M11.9999 23.9995C9.71478 23.9995 7.85571 22.1405 7.85571 19.8553C7.85571 19.4775 8.16181 19.1719 8.53915 19.1719H15.4607C15.8381 19.1719 16.1442 19.478 16.1442 19.8553C16.1442 22.1405 14.2851 23.9995 11.9999 23.9995Z'
          fill='#CCD0DB'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M18.0882 20.5388H5.91195C5.53461 20.5388 5.22852 20.2327 5.22852 19.8553V16.8905C5.22852 16.5131 5.53461 16.207 5.91195 16.207H18.0882C18.4655 16.207 18.7716 16.5131 18.7716 16.8905V19.8553C18.7716 20.2327 18.4655 20.5388 18.0882 20.5388Z'
          fill='#E3E7F3'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0001 14.8213C11.2417 14.8213 10.6248 14.2045 10.6248 13.446V8.75242C10.6248 7.99398 11.2417 7.37711 12.0001 7.37711C12.7585 7.37711 13.3754 7.99398 13.3754 8.75242V13.446C13.3754 14.2045 12.7585 14.8213 12.0001 14.8213ZM12.0001 6.9543C11.1582 6.9543 10.4734 6.26945 10.4734 5.42758C10.4734 4.5857 11.1582 3.90039 12.0001 3.90039C12.842 3.90039 13.5268 4.5857 13.5268 5.42758C13.5268 6.26945 12.842 6.9543 12.0001 6.9543Z'
          fill='#CFAA15'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.0001 14.7276C11.2937 14.7276 10.7185 14.1529 10.7185 13.446V8.75242C10.7185 8.04555 11.2937 7.47086 12.0001 7.47086C12.7065 7.47086 13.2817 8.04555 13.2817 8.75242V13.446C13.2817 14.1529 12.7065 14.7276 12.0001 14.7276ZM12.0001 6.86055C11.2098 6.86055 10.5671 6.21789 10.5671 5.42758C10.5671 4.63727 11.2098 3.99414 12.0001 3.99414C12.7904 3.99414 13.4331 4.63727 13.4331 5.42758C13.4331 6.21789 12.7904 6.86055 12.0001 6.86055Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M5.78297 11.5908C5.34 10.2384 4.95703 9.07031 4.95703 7.74609C4.95703 3.5925 8.24297 0.192188 12.3516 0.00796875C12.2353 0.0028125 12.1181 0 12 0C7.72875 0 4.25391 3.47484 4.25391 7.74609C4.25391 9.07031 4.63688 10.2384 5.07984 11.5908C5.49797 12.8677 5.96625 14.2959 6.23297 16.2075H6.93609C6.66937 14.2959 6.20109 12.8677 5.78297 11.5908Z'
          fill='#FFBD17'
        />
      </g>
      <defs>
        <clipPath id='clip0_13287_47263'>
          <rect width='24' height='24' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
