import { generatePath, Link, useParams } from 'react-router-dom';
import { PlugZap } from 'lucide-react';
import { Button } from '@common/components';
import { ROUTES } from '@routes';
import { DataSourceIntegrationIcon } from '@assets/svgs';

export function ConnectDatasource() {
  const { organizationId } = useParams();
  return (
    <div className='flex h-full flex-col items-center justify-center gap-6 md:gap-10'>
      {/* py-[214px] */}
      <DataSourceIntegrationIcon className='h-[6.25rem] w-[8.375rem] md:h-[12.5rem] md:w-[16.75rem]' />
      <div className='flex flex-col items-center gap-5 md:gap-10'>
        <div className='flex w-[14.875rem] flex-col items-center gap-3 md:w-[25rem]'>
          <h3 className='font-Manrope text-lg font-semibold leading-[1.625rem] text-mainText md:text-2xl md:leading-normal'>
            Connect Data Source
          </h3>
          <p className='text-Manrope text-center text-xs leading-4 text-bodyText md:text-sm md:leading-5'>
            Please first connect your data source to create dashboard
          </p>
        </div>
        <Link to={generatePath(ROUTES.ADD_DATA_SOURCE, { organizationId, page: 1 })}>
          <Button size='lg' className='w-[14rem] md:w-[17.875rem]'>
            <PlugZap className='h-3.5 w-3.5 md:h-5 md:w-5' color='white' />
            Connect Data Source
          </Button>
        </Link>
      </div>
    </div>
  );
}
