import { useState, useEffect } from 'react';
import { OAuthAccountListing } from '@pages/dataSource';
import { capitalizeSourceName } from '@utils';
import { UPPERCASE_DATA_SOURCES } from '@constants';

export function NoDataDisplay() {
  return (
    <div className='flex h-full flex-col items-center justify-center gap-3 font-Manrope text-xs font-medium leading-4 text-highlighterText2'>
      No data to be displayed here
    </div>
  );
}

export function AccountField({
  showProperties,
  accountPropertiesListing,
  googleAdsManagerAccountListing,
  checkedPropertyId,
  setCheckedPropertyId,
  setCheckedProperty,
  oAuthListing,
  linkedInAccounts,
  dataSource,
  fbAccountListing,
  accountListing,
  checkedAccountId,
  setCheckedAccountId,
  setCheckedAccount,
  showStep2,
  instagramPagesListing,
  elementId,
  setElementId,
  tikTokAccountListing,
  googleAdsCustomerListing,
  youtubeAccountListing,
  googleAdsCustomerLoading,
}) {
  const [displayLabel, setDisplayLabel] = useState('');
  const [listing, setListing] = useState('');

  const renderOAuthAccountListing = (listing, checkedId, setCheckedId, setCheckedAccount) => (
    <OAuthAccountListing
      listing={listing}
      checkedId={checkedId}
      setCheckedId={setCheckedId}
      setCheckedAccount={setCheckedAccount}
      dataSource={dataSource}
      isLoading={googleAdsCustomerLoading}
    />
  );

  const getListingBySourceName = sourceName => {
    const listings = {
      [UPPERCASE_DATA_SOURCES.LINKEDIN_ADS]: linkedInAccounts,
    };
    return listings[sourceName] || [];
  };

  useEffect(() => {
    const sourceName = capitalizeSourceName(dataSource?.sourceName);

    let listingData = '';
    let label = 'Accounts';

    switch (sourceName) {
    case UPPERCASE_DATA_SOURCES.FACEBOOK_MARKETING:
      listingData = fbAccountListing?.data?.accounts;
      label = fbAccountListing?.data?.label;
      break;
    case UPPERCASE_DATA_SOURCES.INSTAGRAM:
      listingData = instagramPagesListing?.data?.pages;
      label = instagramPagesListing?.data?.label;
      break;
    case UPPERCASE_DATA_SOURCES.YOUTUBE_ANALYTICS:
      listingData = youtubeAccountListing?.data?.results;
      label = youtubeAccountListing?.data?.label;
      break;
    case UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS:
      listingData = accountListing?.data?.accounts;
      label = accountListing?.data?.label;
      break;
    default:
      listingData = '';
    }

    setDisplayLabel(label);
    setListing(listingData);
  }, [dataSource, fbAccountListing, instagramPagesListing, youtubeAccountListing, accountListing]);

  useEffect(() => {
    if (!showProperties) return;

    const accountLabel = accountPropertiesListing?.data?.label;
    const googleAdsLabel = googleAdsManagerAccountListing?.data?.label;

    if (accountLabel) {
      setDisplayLabel(accountLabel);
    } else if (googleAdsLabel) {
      setDisplayLabel(googleAdsLabel);
    }
  }, [showProperties, accountPropertiesListing, googleAdsManagerAccountListing]);

  useEffect(() => {
    if (showStep2) {
      const sourceName = capitalizeSourceName(dataSource?.sourceName);

      let step2Listing = '';
      let step2Label = 'Accounts';

      switch (sourceName) {
      case UPPERCASE_DATA_SOURCES.LINKEDIN_ADS:
        step2Listing = getListingBySourceName(sourceName);
        break;
      case UPPERCASE_DATA_SOURCES.TIKTOK_MARKETING:
        step2Listing = tikTokAccountListing?.data?.results;
        step2Label = tikTokAccountListing?.data?.label;
        break;
      case UPPERCASE_DATA_SOURCES.GOOGLE_ADS:
        step2Listing = googleAdsCustomerListing?.data?.results;
        step2Label = googleAdsCustomerListing?.data?.label;
        break;
      default:
        step2Listing = '';
      }

      setDisplayLabel(step2Label);
      setListing(step2Listing);
    }
  }, [showStep2, dataSource, tikTokAccountListing, googleAdsCustomerListing]);

  const renderContent = () => {
    if (showProperties) {
      const capitalizedSourceName = capitalizeSourceName(dataSource?.sourceName);

      const threeStepsDataSourcesAccountsActions = {
        [UPPERCASE_DATA_SOURCES.GOOGLE_ANALYTICS]: accountPropertiesListing?.data?.properties,
        [UPPERCASE_DATA_SOURCES.GOOGLE_ADS]: googleAdsManagerAccountListing?.data?.results,
      };

      if (threeStepsDataSourcesAccountsActions[capitalizedSourceName]?.length > 0) {
        return renderOAuthAccountListing(
          threeStepsDataSourcesAccountsActions[capitalizedSourceName],
          checkedPropertyId,
          setCheckedPropertyId,
          setCheckedProperty
        );
      } else {
        return <NoDataDisplay />;
      }
    } else if (oAuthListing?.data?.results.length > 0 && !showStep2) {
      return renderOAuthAccountListing(listing, checkedAccountId, setCheckedAccountId, setCheckedAccount);
    } else if (showStep2) {
      return renderOAuthAccountListing(listing, elementId, setElementId, setCheckedAccount);
    } else {
      return <NoDataDisplay />;
    }
  };

  return (
    <div>
      <p className='border-b border-secondaryStrokeButton px-4 py-3 font-Manrope text-base font-semibold leading-[1.375rem] text-mainText'>
        {displayLabel}
      </p>
      <div className='flex-grow'>{renderContent()}</div>
    </div>
  );
}
