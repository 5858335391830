export function TemplateIcon({ className }) {
  return (
    <svg
      width='120'
      height='120'
      viewBox='0 0 120 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M28.2872 73.6301C28.2872 69.2079 28.2872 64.7841 28.2872 60.3586C28.2872 57.3162 30.1382 55.4628 33.202 55.4604C41.9898 55.4494 50.7768 55.4494 59.563 55.4604C62.6575 55.4604 64.5274 57.3233 64.5274 60.4248C64.54 69.2142 64.54 78.0028 64.5274 86.7906C64.5274 89.8969 62.674 91.7124 59.5464 91.7148C50.7902 91.7148 42.0331 91.7148 33.2753 91.7148C30.0886 91.7148 28.2896 89.9158 28.2872 86.7315C28.2825 82.3628 28.2825 77.9957 28.2872 73.6301Z'
        fill='#AAB0B8'
      />
      <path
        d='M59.9955 28.2861C68.8699 28.2861 77.7444 28.2861 86.6189 28.2861C89.9285 28.2861 91.6849 30.0497 91.6897 33.364C91.6897 36.1063 91.6897 38.8485 91.6897 41.5907C91.6897 44.5292 89.8221 46.3826 86.8671 46.3826C68.9401 46.3826 51.0146 46.3826 33.0908 46.3826C30.1499 46.3826 28.2989 44.5174 28.2871 41.5718C28.2871 38.735 28.2871 35.9037 28.2871 33.078C28.2871 30.1395 30.1523 28.2909 33.1073 28.2885C42.0716 28.2869 51.0343 28.2861 59.9955 28.2861Z'
        fill='#AAB0B8'
      />
      <path
        d='M91.692 73.6016C91.692 77.9986 91.692 82.3941 91.692 86.788C91.692 89.8612 89.8718 91.698 86.8151 91.7051C84.013 91.7146 81.2117 91.7146 78.4111 91.7051C75.4632 91.7051 73.6027 89.8447 73.6003 86.9015C73.6003 78.0223 73.6003 69.1439 73.6003 60.2663C73.6003 57.3727 75.449 55.4933 78.3425 55.4626C81.2361 55.4319 84.0634 55.4342 86.9239 55.4626C89.8576 55.4886 91.6873 57.3704 91.692 60.3277C91.6991 64.7531 91.692 69.1833 91.692 73.6016Z'
        fill='#E2E8F0'
      />
    </svg>
  );
}
