import { Badge } from 'antd';
import { Goal } from 'lucide-react';
import { Progressbar } from '@common/components';
import { calculateBadgeColor, calculateTrend } from '@utils';
import { COLORS } from '@constants';

export function GoalTrackingProgress({ goal, className, showBadge = false }) {
  return (
    <div className={`flex w-[14.5rem] flex-col gap-2 ${className}`}>
      <div className='flex items-center justify-between'>
        <span className='font-Manrope text-sm font-semibold text-mainText md:text-sm'>{goal.achieved}</span>
        <div className='flex items-center gap-1'>
          <Goal className='h-4 w-4' color={COLORS.ICON_COLOR} />
          <span className='font-Manrope text-xs font-normal text-bodyText md:text-sm'>{goal.target}</span>
        </div>
      </div>
      <Progressbar
        progressValue={(goal.achieved * 100) / goal.target}
        progressColor={calculateTrend(goal.growthTrend, 'progress')}
      />
      {showBadge && (
        <div className='mt-1 flex items-center justify-between'>
          <Badge
            style={calculateBadgeColor(goal.growthTrend)}
            count={
              <span className='!inline-flex items-center gap-1 rounded-[1.25rem] px-1.5 py-1 font-Manrope text-xs font-medium'>
                {calculateTrend(goal.growthTrend, 'icon')} {goal.growthTrend.toFixed(2)}%
              </span>
            }
          />
          <span className='font-Manrope text-xs font-normal text-bodyText'>
            {Math.floor(goal.remainingDays) === 0 ? 'Completed' : `${Math.floor(goal.remainingDays)} days left`}
          </span>
        </div>
      )}
    </div>
  );
}
