import { Table } from 'antd';
import { isEmpty } from 'lodash';
import { EmptyWidget, TableWidgetPagination } from '@common/widgets';
import { convertCamelCaseToReadable } from '@utils';
import { COLORS, DEFAULT_VALUE, THEME_STYLE } from '@constants';

export function TableWidget({ widget, handleTablePagination, tableCellClass, onSlideMode }) {
  const columns = widget?.widgetData?.data?.fields?.map(header => ({
    title: () => (
      <span
        className='block h-full w-full px-[1rem] py-3.5'
        style={
          widget?.themeColor === DEFAULT_VALUE
            ? {
              color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
              backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.DARK_PRIMARY : '',
              borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : COLORS.DARK_SECONDARY,
            }
            : {
              color: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
              backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? widget?.background : '',
              borderColor: widget?.themeStyle === THEME_STYLE.DARK ? COLORS.WHITE_COLOR : widget.foreground,
            }
        }
      >
        {convertCamelCaseToReadable(header)}
      </span>
    ),
    dataIndex: header,
    key: header,
    className: onSlideMode ? 'slider-table-widget' : 'table-widget',
    render: text => (
      <span
        className={`block h-full w-full px-6 py-3.5 ${tableCellClass}`}
        style={{
          color:
            widget?.themeStyle === THEME_STYLE.DARK
              ? COLORS.WHITE_COLOR
              : widget?.themeColor === DEFAULT_VALUE
                ? COLORS.DARK_SECONDARY
                : widget?.foreground,
          backgroundColor: widget?.themeStyle === THEME_STYLE.DARK ? widget?.background : 'white',
        }}
      >
        {text}
      </span>
    ),
  }));

  const dataSource = widget?.widgetData?.data?.results?.map((row, i) => {
    const rowData = {};
    widget?.widgetData?.data?.fields?.forEach((field, index) => {
      rowData[field] = row[index];
    });
    return { key: i, ...rowData };
  });

  return isEmpty(dataSource) ? (
    <EmptyWidget widget={widget} />
  ) : (
    <div className={`${onSlideMode && 'mt-1.5'} relative z-[1]`}>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
      {widget?.widgetData?.data?.count > 1 && !onSlideMode && (
        <div className='my-6'>
          <TableWidgetPagination page={widget?.page} widget={widget} handleTablePagination={handleTablePagination} />
        </div>
      )}
    </div>
  );
}
