export function PickSystemGenerated({ className }) {
  return (
    <svg
      width='100'
      height='100'
      viewBox='0 0 100 100'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M39.7813 44.8518C39.5056 48.6689 40.2967 52.0345 43.2033 54.5998C44.8812 56.0959 47.0059 57.0026 49.2514 57.1805C51.4968 57.3584 53.7389 56.7979 55.6335 55.5848C57.414 54.4484 58.8698 52.9375 59.4986 50.9392C60.0964 49.0178 60.3025 46.9758 60.7045 44.8492C60.5524 44.898 60.6401 44.8492 60.7277 44.8492C64.3918 44.8492 68.0611 44.7441 71.7175 44.8954C80.7 45.2648 88.3091 52.6271 88.9662 61.5388C89.2238 65.048 89.3836 68.5804 88.4688 72.0358C87.4567 75.9601 85.1649 79.439 81.9528 81.9269C78.7407 84.4148 74.79 85.7711 70.7203 85.7829C56.933 85.8496 43.1449 85.8496 29.3559 85.7829C19.2138 85.7265 11.2465 77.8999 10.9038 67.7672C10.7956 64.576 10.6899 61.372 11.5841 58.2347C13.7537 50.6365 20.6439 45.134 28.5545 44.8749C32.2263 44.7518 35.9214 44.8518 39.7813 44.8518ZM70.5631 68.3546C71.2392 68.3639 71.9103 68.2387 72.5372 67.9866C73.1641 67.7344 73.7342 67.3603 74.2141 66.8862C74.694 66.412 75.074 65.8473 75.332 65.2251C75.59 64.6029 75.7207 63.9357 75.7166 63.2626C75.7242 62.2463 75.4285 61.2506 74.8669 60.4019C74.3053 59.5531 73.5031 58.8895 72.5622 58.4953C71.6212 58.1011 70.5838 57.994 69.5816 58.1876C68.5794 58.3812 67.6575 58.8667 66.9329 59.5827C66.2083 60.2987 65.7136 61.2127 65.5116 62.209C65.3096 63.2052 65.4094 64.2388 65.7983 65.1785C66.1872 66.1182 66.8477 66.9218 67.696 67.4872C68.5443 68.0527 69.5422 68.3546 70.5631 68.3546Z'
        fill='#E2E8F0'
      />
      <path
        d='M54.1808 28.1778V33.4596C54.1808 37.9103 54.2065 42.3585 54.1808 46.7989C54.1576 49.8439 51.3721 51.8294 48.6279 50.8186C46.9659 50.2056 45.9404 48.6921 45.9378 46.7732C45.9275 41.0117 45.9275 35.251 45.9378 29.4912V28.2829C45.6002 28.5779 45.3889 28.7447 45.2008 28.9319C43.6909 30.4275 42.217 31.9461 40.689 33.4237C38.9522 35.1116 36.4605 35.1321 34.8063 33.5135C33.152 31.8948 33.1417 29.3783 34.8475 27.6621C38.9033 23.5851 42.9745 19.5337 47.0612 15.508C48.865 13.7277 51.2716 13.7123 53.0754 15.508C57.1415 19.5183 61.1869 23.5466 65.2118 27.5929C66.964 29.3552 66.964 31.8845 65.2994 33.5263C63.6348 35.1681 61.1354 35.0962 59.3471 33.339C57.6954 31.6947 56.0412 30.035 54.1808 28.1778Z'
        fill='#AAB0B8'
      />
    </svg>
  );
}
