import { useEffect, useRef, useState } from 'react';
import { useLocation, generatePath, useNavigate } from 'react-router-dom';
import { Modal, Tabs } from 'antd';
import { debounce } from 'lodash';
import { Search, X } from 'lucide-react';
import { AddEditOrgDialog, Button, Header, SearchInput, TourButton } from '@common/components';
import { InvitedOrganizationTab, MyOrganizationTab, useHome, useHomeEditDialog } from '@pages/Home';
import { ROUTES } from '@routes';
import { getLocalStorageItem } from '@utils';
import {
  COLORS,
  HOME_ORGANIZATION_TABS,
  ORGANIZATION_ID_BY_INVITE_ACCEPTANCE,
  ORGANIZATION_ID_BY_ONBOARDING,
  TOTAL_ITEMS_PER_PAGE,
  CREATE_HOME_ORGANIZATION_ID,
} from '@constants';
import { PlusIcon, PlusIconWhite, WelcomeHeaderIcon, ZendashLogo } from '@assets/svgs';

export function Home() {
  const {
    formik,
    imageRef,
    organizations,
    organizationFetching,
    search,
    setSearch,
    page,
    setPage,
    showAddEditOrgDialog,
    setShowAddEditOrgDialog,
    isUploadedLogo,
    setIsUploadedLogo,
    uploadedLogo,
    setUploadedLogo,
    createOrganizationLoading,
    userDetail,
    handleImageUpload,
    totalPagesPerItem,
    setTotalPagesPerItem,
    paginationPage,
    setPaginationPage,
    createHomeOrgTour,
    imageLoading,
  } = useHome();

  const {
    formik: editFormik,
    isUploadedLogo: isUploadedLogoEdit,
    setIsUploadedLogo: setIsUploadedLogoEdit,
    uploadedLogo: uploadedLogoEdit,
    setUploadedLogo: setUploadedLogoEdit,
    isDeleteOrgLoading,
    isEditOrganizationLoading,
    handleDeleteOrganizationBtn,
    handleDeleteBtn,
    showAddEditDialog,
    showDeleteOrgDialog,
    setShowDeleteOrgDialog,
    setShowAddEditDialog,
    selectedOrganization,
    setSelectedOrganization,
    manageOrganizationFetching,
    handleImageUpload: editHandleImageUpload,
    openEditDialogue,
    imageUploadLoading: editImageLoading,
  } = useHomeEditDialog();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const tabsRef = useRef(null);
  const [searchWidth, setSearchWidth] = useState('');

  const isTemporaryOrganization = getLocalStorageItem(ORGANIZATION_ID_BY_INVITE_ACCEPTANCE);
  const orgIdFromOnBoarding = getLocalStorageItem(ORGANIZATION_ID_BY_ONBOARDING);

  const [currentTab, setCurrentTab] = useState(HOME_ORGANIZATION_TABS.MY_ORGANIZATION);

  const handleSearchChange = debounce(e => setSearch(e.target.value), 600);

  useEffect(() => {
    if (pathname === '/home/my-organization') {
      navigate(ROUTES.MY_ORGANIZATIONS);
      setCurrentTab('my-organization');
    } else if (pathname === '/home/invited-organization') {
      navigate(ROUTES.INVITED_ORGANIZATIONS);
      setCurrentTab('invited-organization');
    } else if (isTemporaryOrganization || orgIdFromOnBoarding) {
      navigate(generatePath(ROUTES.DASHBOARD, { organizationId: isTemporaryOrganization ?? orgIdFromOnBoarding }));
    } else {
      navigate(ROUTES.MY_ORGANIZATIONS);
    }
    setTotalPagesPerItem(TOTAL_ITEMS_PER_PAGE);
  }, [pathname]);

  useEffect(() => {
    if (tabsRef.current) {
      setSearchWidth(tabsRef.current.offsetWidth);
    }
  }, [tabsRef?.current?.offsetWidth]);

  return (
    <div className='min-h-screen bg-shade3'>
      <Header logo={<ZendashLogo className='w-[5.5rem] md:w-[8.125rem]' fillColor={COLORS.LOGO_DARK} />} />
      <div className='mx-auto max-w-[71.5rem] overflow-auto'>
        <div className='flex h-[calc(100vh-61px)] flex-col gap-6 p-5 md:p-8 lg:gap-8'>
          <div className='relative hidden rounded-[1.25rem] bg-highlighterText2 px-8 py-6 md:block lg:mt-10'>
            <div className='relative z-[1]'>
              <div className='mb-3 flex items-center gap-4'>
                <div className='flex h-10 w-10 items-center justify-center rounded-full bg-highlighterText3'>🎉</div>
                <span className='text-base font-medium leading-[1.375rem] text-white'>Welcome to Zendash</span>
              </div>
              <h1 className='mb-2 text-2xl font-bold leading-[2.125rem] text-white lg:mb-4 lg:text-[2rem] lg:leading-[2.75rem]'>
                Your Marketing Data, Unified and Simplified
              </h1>
              <p className='max-w-[45.5rem] text-sm font-normal leading-[1.5rem] text-white lg:text-base'>
                We&apos;re thrilled to have you on board. With Zendash, you&apos;re equipped to seamlessly manage your
                business and integrate data sources like never before.
              </p>
            </div>
            <div className='absolute bottom-0 right-0 z-0'>
              <WelcomeHeaderIcon />
            </div>
          </div>
          <div className='shadow-main mb-5 rounded-xl bg-white'>
            <div className='flex flex-col justify-between gap-3 border-b border-secondaryStrokeButton px-6 py-4 md:flex-row md:items-center'>
              <h2 className='text-left text-lg font-semibold leading-[1.625rem] text-mainText'>Organizations</h2>
              {userDetail?.data?.isSubscribed && (
                <Button
                  className='w-full md:w-[8.75rem]'
                  id={CREATE_HOME_ORGANIZATION_ID}
                  onClick={() => {
                    formik.resetForm();
                    setUploadedLogo(null);
                    setIsUploadedLogo(false);
                    setShowAddEditOrgDialog(true);
                  }}
                >
                  <PlusIcon />
                  Create New
                </Button>
              )}
              <Modal
                footer={null}
                className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
                closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
                open={showAddEditOrgDialog}
                onCancel={() => setShowAddEditOrgDialog(false)}
                centered={true}
              >
                <AddEditOrgDialog
                  dialogTitle='Create Organization'
                  formik={formik}
                  imageRef={imageRef}
                  uploadedLogo={uploadedLogo}
                  handleImageUpload={handleImageUpload}
                  setUploadedLogo={setUploadedLogo}
                  setIsUploadedLogo={setIsUploadedLogo}
                  icon={
                    <div className='h-5 w-5'>
                      <PlusIconWhite />
                    </div>
                  }
                  isUploadedLogo={isUploadedLogo}
                  organizationLoading={createOrganizationLoading}
                  imageLoading={imageLoading}
                />
              </Modal>
            </div>
            <div className='relative' ref={tabsRef}>
              <div className='absolute right-4 top-[4.25rem] z-10 md:top-3 '>
                <div style={{ width: `${searchWidth - 32}px` }} className='md:!w-[18.75rem] lg:!w-[25rem]'>
                  <SearchInput
                    onChange={handleSearchChange}
                    placeholder='Search'
                    parentClassName='w-full'
                    className='pl-11'
                    leadIcon={<Search className='h-5 w-5' color={COLORS.ICON_COLOR} />}
                  />
                </div>
              </div>
              <Tabs
                className='home-tabs'
                activeKey={currentTab}
                onTabClick={e => navigate(e)}
                items={[
                  {
                    label: 'My Spaces',
                    key: HOME_ORGANIZATION_TABS.MY_ORGANIZATION,
                    children: (
                      <MyOrganizationTab
                        organizations={organizations}
                        page={page}
                        setPage={setPage}
                        organizationFetching={organizationFetching}
                        search={search}
                        editFormik={editFormik}
                        imageRef={imageRef}
                        uploadedLogoEdit={uploadedLogoEdit}
                        setUploadedLogoEdit={setUploadedLogoEdit}
                        setIsUploadedLogoEdit={setIsUploadedLogoEdit}
                        isUploadedLogoEdit={isUploadedLogoEdit}
                        isEditOrganizationLoading={isEditOrganizationLoading}
                        isDeleteOrgLoading={isDeleteOrgLoading}
                        handleDeleteOrganizationBtn={handleDeleteOrganizationBtn}
                        handleDeleteBtn={handleDeleteBtn}
                        showAddEditDialog={showAddEditDialog}
                        showDeleteOrgDialog={showDeleteOrgDialog}
                        setShowDeleteOrgDialog={setShowDeleteOrgDialog}
                        setShowAddEditDialog={setShowAddEditDialog}
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                        userDetail={userDetail}
                        manageOrganizationFetching={manageOrganizationFetching}
                        editHandleImageUpload={editHandleImageUpload}
                        openEditDialogue={openEditDialogue}
                        totalPagesPerItem={totalPagesPerItem}
                        setTotalPagesPerItem={setTotalPagesPerItem}
                        paginationPage={paginationPage}
                        setPaginationPage={setPaginationPage}
                        currentTab={currentTab}
                        editImageLoading={editImageLoading}
                      />
                    ),
                  },
                  {
                    label: 'Invited',
                    key: HOME_ORGANIZATION_TABS.INVITED_ORGANIZATION,
                    children: (
                      <InvitedOrganizationTab
                        currentTab={currentTab}
                        organizations={organizations}
                        page={page}
                        setPage={setPage}
                        organizationFetching={organizationFetching}
                        search={search}
                        editFormik={editFormik}
                        imageRef={imageRef}
                        uploadedLogoEdit={uploadedLogoEdit}
                        setUploadedLogoEdit={setUploadedLogoEdit}
                        setIsUploadedLogoEdit={setIsUploadedLogoEdit}
                        isUploadedLogoEdit={isUploadedLogoEdit}
                        isEditOrganizationLoading={isEditOrganizationLoading}
                        isDeleteOrgLoading={isDeleteOrgLoading}
                        handleDeleteOrganizationBtn={handleDeleteOrganizationBtn}
                        handleDeleteBtn={handleDeleteBtn}
                        showAddEditDialog={showAddEditDialog}
                        showDeleteOrgDialog={showDeleteOrgDialog}
                        setShowDeleteOrgDialog={setShowDeleteOrgDialog}
                        setShowAddEditDialog={setShowAddEditDialog}
                        selectedOrganization={selectedOrganization}
                        setSelectedOrganization={setSelectedOrganization}
                        userDetail={userDetail}
                        manageOrganizationFetching={manageOrganizationFetching}
                        editHandleImageUpload={editHandleImageUpload}
                        openEditDialogue={openEditDialogue}
                        totalPagesPerItem={totalPagesPerItem}
                        setTotalPagesPerItem={setTotalPagesPerItem}
                        paginationPage={paginationPage}
                        setPaginationPage={setPaginationPage}
                        editImageLoading={editImageLoading}
                      />
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
      {currentTab === HOME_ORGANIZATION_TABS.MY_ORGANIZATION && (
        <TourButton Label='“Create Organization”' onClick={() => createHomeOrgTour().drive()} />
      )}
    </div>
  );
}
