import { useCallback, useRef, useState } from 'react';
import { driver } from 'driver.js';

export const useTour = () => {
  const timeoutRef = useRef(null);
  const driverObjRef = useRef(null);
  const [isTourActive, setIsTourActive] = useState(false);

  const createStep = useCallback(
    (element, title, description = '', options = {}) => ({
      element: `#${element}`,
      popover: { title, description, ...options },
    }),
    []
  );

  const createClickStep = useCallback(
    (element, title, description = '', options = {}) => ({
      ...createStep(element, title, description, options),
      onHighlighted: (element, step, option, moveToNextStep, safelyAddEventListener) => {
        safelyAddEventListener(element, 'click', () => {
          if (option?.state?.activeIndex === step.index) moveToNextStep();
        });
      },
    }),
    [createStep]
  );

  const createMousedownStep = useCallback(
    (element, title, description = '', options = {}) => ({
      ...createStep(element, title, description, options),
      onHighlighted: (element, step, option, moveToNextStep, safelyAddEventListener) => {
        safelyAddEventListener(element, 'mousedown', () => {
          if (option?.state?.activeIndex === step.index) moveToNextStep();
        });
      },
    }),
    [createStep]
  );

  const moveToNextStep = useCallback((delay = 300) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => driverObjRef.current?.moveNext(), delay);
  }, []);

  const moveToStep = useCallback(index => driverObjRef.current?.moveTo(index), []);

  const safelyAddEventListener = (element, event, handler) => {
    if (element) {
      element.removeEventListener(event, handler);
      element.addEventListener(event, handler);
    }
  };

  const createTour = useCallback(
    steps => {
      driverObjRef.current = driver({
        showProgress: true,
        showButtons: ['close'],
        steps: steps.map((step, index) => ({
          ...step,
          onHighlighted: (element, driverStep, option) => {
            if (step.onHighlighted) {
              step.onHighlighted(
                element,
                { ...driverStep, index },
                option,
                moveToNextStep,
                safelyAddEventListener,
                moveToStep
              );
            }
          },
          onDeselected: () => clearTimeout(timeoutRef.current),
        })),
        onDestroyStarted: () => {
          setIsTourActive(false);
          driverObjRef.current?.destroy();
        },
        onCloseClick: () => {
          setIsTourActive(false);
          driverObjRef.current?.destroy();
        },
      });

      return driverObjRef.current;
    },
    [moveToNextStep, moveToStep]
  );

  const closeTour = useCallback(() => {
    driverObjRef.current?.destroy();
    setIsTourActive(false);
  }, []);

  return {
    createTour,
    createStep,
    createClickStep,
    moveToStep,
    createMousedownStep,
    moveToNextStep,
    setIsTourActive,
    isTourActive,
    closeTour,
  };
};
