import { useNavigate } from 'react-router-dom';
import authScreenAnimation from '@assets/gifs/authScreenConnectors.gif';
import AuthImage from '@assets/svgs/authRightSideImage.svg';
import { ArrowLeft } from 'lucide-react';
import { COLORS } from '@constants';
import { ZendashLogo } from '@assets/svgs';

export function AuthScreenWrapper({ title, desc, children, backBtnEnable }) {
  const navigate = useNavigate();
  return (
    <div className='flex h-screen items-center justify-center overflow-y-auto bg-gradient-to-b from-shade1 to-shade2'>
      <div className='h-available relative mx-5 my-[3.75rem] flex w-full max-w-[71.5rem] items-center justify-between rounded-[1.5rem] bg-white md:mx-[5.75rem]'>
        <div className='flex flex-1 justify-center px-5 py-14 md:px-[3.75rem] lg:px-12 xxl:px-[4.875rem]'>
          <span className='absolute left-5 top-5 md:left-[3.75rem] md:top-[3.75rem] lg:left-[2rem] lg:top-[2rem]'>
            <ZendashLogo className='w-[7rem] md:w-[7.5rem]' />
          </span>
          <div className='flex h-full w-full flex-col justify-center'>
            {backBtnEnable && (
              <span
                className='mb-3 inline-flex cursor-pointer items-center gap-2 font-Manrope text-xs leading-4 text-labelText hover:opacity-[.7]'
                onClick={() => navigate(-1)}
              >
                <ArrowLeft color={COLORS.BODY_TEXT_COLOR} className='h-3.5 w-3.5 md:h-4 md:w-4' /> Back
              </span>
            )}
            <div className='flex flex-col gap-2 md:gap-3'>
              <h2 className='font-Manrope text-2xl font-bold leading-[2.125rem] text-mainText md:text-[2rem] md:font-extrabold md:leading-[2.75rem]'>
                {title}
              </h2>
              <p className='font-Manrope text-xs leading-4 text-bodyText md:text-sm md:leading-5'>{desc}</p>
            </div>
            {children}
          </div>
        </div>
        <div className='hidden flex-[0_0_50%] p-6 pl-0 lg:flex'>
          <div className='min-h-[43.375rem] rounded-[1.5rem] bg-lightGrayColor px-3 py-[1.125rem]'>
            <div className='pb-[4.9375rem] pt-[6rem]'>
              <img src={AuthImage} alt='auth-image' />
            </div>
            <div>
              <img src={authScreenAnimation} alt='logos' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
