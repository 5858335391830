import { useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Drawer, Dropdown, Modal, Skeleton } from 'antd';
import {
  Share2,
  FileText,
  Save,
  ChevronLeft,
  Layers,
  PencilLine,
  ChevronDown,
  Goal,
  MessageCircle,
  X,
  Copy,
  Check,
  ArrowLeftToLine,
  Plus,
} from 'lucide-react';
import {
  BaseLoader,
  BaseToolTip,
  Button,
  DatePicker,
  FormInput,
  GoalTrackingProgress,
  Loader,
  NoDataFoundScreen,
} from '@common/components';
import { useGoalWidgets } from '@pages/dashboard';
import { CreateEditGoalDrawer, useGoalsProgress } from '@pages/goalTracking';
import { ROUTES } from '@routes';
import { getLocalStorageItem, setLocalStorageItem, truncateString } from '@utils';
import { COLORS, EDIT_DASHBOARD_BUTTON_ID, ROLES } from '@constants';
import { ImageUploaderIcon } from '@assets/svgs';

export function DashboardHeader({
  uploadedLogo,
  isUploadedLogo,
  dashboardName,
  isCommentMode,
  isEditMode,
  dashboard,
  setIsEditMode,
  setIsCommentMode,
  setIsEditWidgetMode,
  setEditWidget,
  setIsDateCleared,
  dateRange,
  handleDateRange,
  handleRangeDateFilter,
  showDateTimeFilter,
  setShowDateTimeFilter,
  handleClearRangeDateFilter,
  dateRangeSet,
  handleSelectDateRange,
  dateRangeStart,
  setDateRangeStart,
  dateRangeEnd,
  setDateRangeEnd,
  compareRangeSet,
  handleSelectCompareRange,
  compareRangeEnd,
  compareRangeStart,
  setCompareRangeEnd,
  setCompareRangeStart,
  resetForm,
  shareDashboardUrlRefetch,
  shareDashboardUrlFetching,
  copyToClipboard,
  isCopied,
  sharedDashboardURL,
  organizationId,
  dashboardId,
  setCommentedWidget,
  setShowTemplateDialog,
  isGoalTrackingMode,
  setIsGoalTrackingMode,
  page,
  viewType,
  saveAsTemplateFormik,
  widgetListing,
  dashboardsListing,
  handleOnDrag,
}) {
  const params = useParams();
  const queryClient = useQueryClient();

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [showShareDashboardModal, setSharedDashboardModal] = useState(false);

  const onCreateGoalSuccess = () => queryClient.invalidateQueries({ queryKey: ['goal-widgets'] });

  const { formik, createGoalLoading, showCreateDialog, setShowCreateDialog } = useGoalsProgress({
    onCreateGoalSuccess,
  });

  const { goalWidgets, goalWidgetsFetching } = useGoalWidgets(isGoalTrackingMode);

  const navigate = useNavigate();

  const items = [
    {
      key: 'COMMENT',
      label: (
        <div
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
          onClick={() => {
            setIsCommentMode(true);
            setCommentedWidget(null);
            resetForm();
          }}
        >
          <MessageCircle color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Comments
        </div>
      ),
    },
  ];

  const adminOnlyDropdownItems = [
    {
      key: 'GOAL',
      label: (
        <div
          onClick={() => {
            setIsGoalTrackingMode(!isGoalTrackingMode);
            const openedDashboard =
              dashboardsListing?.data?.results?.find(option => option?.id == params.dashboardId) || '';
            formik.setFieldValue('dashboard', openedDashboard?.id);
          }}
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
        >
          <Goal color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Goal Tracking
        </div>
      ),
    },
    {
      key: 'REPORT',
      label: (
        <div
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
          onClick={() => navigate(generatePath(ROUTES.REPORTS, { organizationId, dashboardId }))}
        >
          <FileText color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Reports
        </div>
      ),
    },
    {
      key: 'SHARE',
      label: (
        <div
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
          onClick={() => {
            setSharedDashboardModal(true);
            shareDashboardUrlRefetch();
          }}
        >
          <Share2 color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Share
        </div>
      ),
    },
    {
      key: 'TEMPLATE',
      label: (
        <div
          onClick={() => {
            setShowTemplateDialog(true);
            saveAsTemplateFormik.resetForm();
          }}
          className='flex items-center gap-2 px-2 py-1.5 text-sm leading-5 text-mainText'
        >
          <Save color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Save as Template
        </div>
      ),
    },
  ];

  const isMember = getLocalStorageItem('userOrganizationRole') === ROLES.MEMBER;
  const dropdownItems = isMember ? items : [...adminOnlyDropdownItems, ...items];

  return (
    <div className='flex flex-col justify-between gap-5 border-b border-b-secondaryStrokeButton bg-white px-5 py-4 md:px-8 lg:flex-row lg:items-center'>
      <div className='inline-flex items-center'>
        <span
          onClick={() => {
            navigate(generatePath(ROUTES.DASHBOARD, { organizationId }), {
              state: { page, viewType },
            });
            setLocalStorageItem('dashboardCurrentPage', page);
            setLocalStorageItem('dashboardViewType', viewType);
          }}
          className='mr-3 cursor-pointer'
        >
          <ChevronLeft color={COLORS.ICON_COLOR} />
        </span>
        <div className={'flex h-[3rem] w-[3rem] items-center rounded-full bg-lightGrayColor'}>
          {isUploadedLogo ? (
            <Skeleton active title={false} avatar={{ shape: 'circle', size: 48 }} />
          ) : (
            <>
              {uploadedLogo ? (
                <img src={uploadedLogo} alt='logo' className='h-full w-full object-contain' />
              ) : (
                <ImageUploaderIcon />
              )}
            </>
          )}
        </div>
        <p className='ml-4 text-lg font-semibold leading-[1.625rem] text-mainTextColor'>{dashboardName}</p>
      </div>
      <>
        <div className='flex flex-col-reverse gap-4 md:flex-row md:gap-6 lg:flex-col-reverse lg:items-center xxl:flex-row'>
          {!(isEditMode || isCommentMode) && (
            <DatePicker
              placeholder='Filter by date'
              setIsDateCleared={setIsDateCleared}
              date={dateRange}
              setDate={handleDateRange}
              handleRangeDateFilter={handleRangeDateFilter}
              showDateTimeFilter={showDateTimeFilter}
              setShowDateTimeFilter={setShowDateTimeFilter}
              handleClearRangeDateFilter={handleClearRangeDateFilter}
              dateRangeSet={dateRangeSet}
              handleSelectDateRange={handleSelectDateRange}
              dateRangeStart={dateRangeStart}
              setDateRangeStart={setDateRangeStart}
              dateRangeEnd={dateRangeEnd}
              setDateRangeEnd={setDateRangeEnd}
              compareRangeSet={compareRangeSet}
              handleSelectCompareRange={handleSelectCompareRange}
              compareRangeEnd={compareRangeEnd}
              compareRangeStart={compareRangeStart}
              setCompareRangeEnd={setCompareRangeEnd}
              setCompareRangeStart={setCompareRangeStart}
              isComparable={true}
              className='w-full lg:ps-6'
            />
          )}
          <div className="relative flex items-center gap-3 before:absolute before:left-0 before:top-[-18px] before:w-[1px] before:bg-secondaryStrokeButton before:content-none md:pl-6 md:before:top-0 md:before:h-11 md:before:content-[''] lg:before:h-[6.75rem] xxl:before:h-[2.875rem]">
            <Dropdown
              menu={{ items: dropdownItems }}
              placement='bottomLeft'
              open={dropdownVisible}
              onOpenChange={() => setDropdownVisible(false)}
            >
              <Button
                variant='outline'
                className='flex w-full items-center justify-between px-4 md:w-[13.5rem] lg:w-[12.25rem]'
                size='lg'
                onClick={() => setDropdownVisible(true)}
              >
                <span className='flex items-center gap-2'>
                  <Layers color={COLORS.TEXT_MAIN_COLOR} className='h-5 w-5' /> Default Actions{' '}
                </span>
                <ChevronDown color={COLORS.ICON_COLOR} className='h-4 w-4' />
              </Button>
            </Dropdown>
            {dashboard?.data?.canEdit && (
              <Button
                id={EDIT_DASHBOARD_BUTTON_ID}
                onClick={() => {
                  setIsEditMode(true);
                  setIsEditWidgetMode(false);
                  setIsGoalTrackingMode(false);
                  setEditWidget({});
                }}
                size='lg'
                className='hidden w-[11.25rem] items-center gap-2 px-4 lg:flex'
              >
                <PencilLine color='#fff' className='h-5 w-5' />
                Edit Dashboard
              </Button>
            )}
          </div>
        </div>
      </>
      <Modal
        footer={null}
        className='!w-[calc(100vw-2.5rem)] md:!w-[420px]'
        closeIcon={<X className='h-5 w-5' color={COLORS.ICON_COLOR} />}
        open={showShareDashboardModal}
        onCancel={() => setSharedDashboardModal(false)}
        centered={true}
      >
        {shareDashboardUrlFetching ? (
          <div className='flex min-h-[10rem] items-center justify-center'>
            <Loader strokeColorClass={COLORS.SECONDARY_GREEN_COLOR} className='h-12 w-12' />
          </div>
        ) : (
          <div className='flex flex-col gap-6 md:gap-6'>
            <h3 className='font-Manrope text-xl font-bold leading-7 text-mainText md:text-2xl md:leading-[2.125rem]'>
              Share Dashboard
            </h3>
            <FormInput labelText='URL' value={sharedDashboardURL} placeholder='www.example.com' />
            <Button
              size='lg'
              disabled={!sharedDashboardURL}
              variant={isCopied ? 'secondary' : 'default'}
              onClick={copyToClipboard}
            >
              {isCopied ? (
                <Check color='#fff' className='h-3.5 w-3.5 md:h-5 md:w-5' />
              ) : (
                <Copy color='#fff' className='h-3.5 w-3.5 md:h-5 md:w-5' />
              )}{' '}
              {isCopied ? 'Copied' : 'Copy URL'}
            </Button>
          </div>
        )}
      </Modal>

      <Drawer
        open={isGoalTrackingMode}
        onClose={() => setIsGoalTrackingMode(false)}
        closeIcon={null}
        className='goalTrackingDrawer'
        width={
          window?.innerWidth < 480
            ? '480'
            : window?.innerWidth >= 768 && window?.innerWidth <= 1023
              ? '340'
              : window?.innerWidth >= 1024
                ? '452'
                : ''
        }
        mask={false}
      >
        <div className='flex flex-col gap-6 bg-tableHeaderBackground px-5 py-5 md:gap-4 md:px-6 md:py-6 lg:gap-6 lg:px-8'>
          <span className='inline-flex cursor-pointer items-center gap-4 text-lg font-semibold leading-[1.625rem] text-mainText'>
            <ArrowLeftToLine
              className='h-5 w-5'
              color={COLORS.ICON_COLOR}
              onClick={() => setIsGoalTrackingMode(false)}
            />
            Goal Tracking
          </span>
          <Button
            className='w-full'
            onClick={() => {
              setShowCreateDialog(true);
              const openedDashboard =
                dashboardsListing?.data?.results?.find(option => option?.id == params.dashboardId) || '';
              formik.setFieldValue('dashboard', openedDashboard?.id);
            }}
          >
            <Plus className='h-5 w-5' color='white' />
            Create New
          </Button>
        </div>

        <div className='relative flex h-[calc(100vh-212px)] flex-col justify-center gap-4 overflow-y-auto px-5 py-5 md:px-6 md:py-6 lg:px-8'>
          {goalWidgetsFetching ? (
            <BaseLoader />
          ) : goalWidgets?.data?.count === 0 ? (
            <div>
              <NoDataFoundScreen
                title='No Goals Listed Yet'
                message="It looks like you haven't started tracking any goals yet. Click 'Create New' to set your goals and begin tracking your progress!"
              />
            </div>
          ) : (
            goalWidgets?.data?.results.map(goal => (
              <div
                key={goal.id}
                draggable
                onDragStart={e => handleOnDrag(e, goal)}
                className='h-[15rem] rounded-xl border p-5'
              >
                <h3 className='text-[1.125rem] font-semibold leading-[1.625rem]' id={`name-${goal?.id}`}>
                  {goal?.name?.length > 20 && <BaseToolTip id={`name-${goal?.id}`} content={goal?.name} />}
                  {truncateString(goal.name, 20)}
                </h3>
                <div className='mt-3 flex flex-col gap-3 border-secondaryStrokeButton'>
                  <div className='flex items-center justify-between'>
                    <span className='text-sm font-normal text-bodyText'>Widget</span>
                    <h5 id={`label-${goal?.id}`} className='text-sm font-normal'>
                      {goal?.widgetLabel?.length > 20 && (
                        <BaseToolTip id={`label-${goal?.id}`} content={goal?.widgetLabel} />
                      )}
                      {truncateString(goal.widgetLabel, 20)}
                    </h5>
                  </div>
                  <div className='flex items-center justify-between'>
                    <span className='text-sm font-normal text-bodyText'>Time period</span>
                    <h5 className='text-sm font-normal'>{goal.periodInDays}</h5>
                  </div>
                </div>
                <div className='mb-5 mt-5 border-b' />
                <GoalTrackingProgress showBadge={true} goal={goal} className='!w-full' />
              </div>
            ))
          )}
        </div>
      </Drawer>
      <CreateEditGoalDrawer
        title='Create Goal'
        formik={formik}
        dashboardsListing={dashboardsListing}
        widgetListing={widgetListing}
        isLoading={createGoalLoading}
        showDrawer={showCreateDialog}
        setShowDrawer={setShowCreateDialog}
        disableDashboardField={true}
      />
    </div>
  );
}
