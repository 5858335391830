import { Input } from 'antd';
import { ConnectorGuide } from '@common/components';

export function FormInput({
  id,
  labelText,
  placeholder,
  type = 'text',
  value = '',
  handleChange,
  showError,
  isMultipleEmail,
  isMultipleEmailMessage,
  labelClassName,
  className,
  inputClassName,
  showInfoIcon = false,
  infoContent,
  ...restProps
}) {
  return (
    <div id={id} className={`relative flex flex-col gap-1.5 ${className}`}>
      {!!labelText && (
        <div className='flex items-center justify-between'>
          <label
            htmlFor={id}
            className={`relative font-Manrope text-xs leading-4 text-labelText md:text-sm md:leading-5 ${labelClassName}`}
          >
            {labelText}
            <ConnectorGuide showInfoIcon={showInfoIcon} infoContent={infoContent} />
          </label>
          {isMultipleEmail && (
            <span className='text-xs font-normal leading-[0.9075rem] text-mediumGray'>{isMultipleEmailMessage}</span>
          )}
        </div>
      )}
      <Input
        id={id}
        type={type}
        placeholder={placeholder}
        value={value || ''}
        onChange={handleChange}
        className={`h-10 rounded-[1.5rem] px-4 py-3 font-Manrope text-xs leading-4 text-mainText placeholder:text-placeholderText hover:border-highlighterText1 focus:border-highlighterText1 md:h-11 md:text-sm md:leading-5 ${inputClassName} ${showError ? 'border-errorColor' : ''}`}
        {...restProps}
      />
      {!!showError && (
        <div className="absolute -bottom-9 z-[2] rounded bg-errorTooltip px-2 py-[0.375rem] text-xs font-medium leading-4 text-white before:absolute before:left-3 before:top-[-6px] before:h-3 before:w-3 before:rotate-[-90deg] before:bg-[url('data:image/svg+xml,%3Csvg%20width=%2716%27%20height=%2716%27%20viewBox=%270%200%2016%2016%27%20fill=%27none%27%20xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg%20id=%27caret--right%27%3E%3Cpath%20id=%27Vector%27%20fill-rule=%27evenodd%27%20clip-rule=%27evenodd%27%20d=%27M6%204L11%208L6%2012V4Z%27%20fill=%27%23EF4444%27%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E')] before:bg-no-repeat">
          {showError}
        </div>
      )}
    </div>
  );
}
