import { useRef } from 'react';
import { Drawer } from 'antd';
import { Bell, X } from 'lucide-react';
import { BaseLoader, NoDataFoundScreen } from '@common/components';
import { useNotification } from '@common/hooks';
import { calculateDueDate, doInfiniteScroll } from '@utils';
import { COLORS, NO_NOTIFICATION_MESSAGE } from '@constants';

export function NotificationDropdown({ userDetail, userDetailRefetch }) {
  const {
    errorMessage,
    notificationListing,
    notificationListingFetching,
    notificationListingNextPage,
    notificationListingHasNextPage,
    notificationListingRefetch,
    notificationListingLoading,
    openNotificationDrawer,
    setOpenNotificationDrawer,
  } = useNotification();

  const scrollContainerRef = useRef();
  const onBellIconClick = () => {
    setOpenNotificationDrawer(true);
    notificationListingRefetch();
    userDetailRefetch();
  };

  const handleScroll = e =>
    doInfiniteScroll(e, notificationListingHasNextPage, notificationListingFetching, notificationListingNextPage);

  return (
    <>
      <div className='relative cursor-pointer'>
        <span
          onClick={onBellIconClick}
          className='flex h-8 w-8 items-center justify-center rounded-full border border-secondaryStrokeButton p-2 md:h-10 md:w-10'
        >
          <Bell className='h-4 w-4 md:h-5 md:w-5' color={COLORS.SECONDARY_GREEN_COLOR} />
        </span>
        {userDetail?.data?.hasUnreadNotifications && (
          <span className='absolute right-[0.6875rem] top-[0.5625rem] h-1 w-1 rounded-full bg-highlighterText1 md:right-3 md:top-2.5 md:h-2 md:w-2'></span>
        )}
      </div>
      <Drawer
        title='Notifications'
        open={openNotificationDrawer}
        onClose={() => setOpenNotificationDrawer(false)}
        className='notificationDrawer rounded-s-2xl'
        closeIcon={null}
        width='35.125rem'
        extra={
          <div className='flex h-8 w-8 cursor-pointer items-center justify-center rounded-[0.25rem] border border-icon1'>
            <X onClick={() => setOpenNotificationDrawer(false)} className='h-5 w-5' color={COLORS.ICON_COLOR} />
          </div>
        }
      >
        <div ref={scrollContainerRef} onScroll={handleScroll} className='h-[calc(100vh-150px)] overflow-y-auto'>
          {notificationListingLoading ? (
            <BaseLoader />
          ) : (
            <>
              {errorMessage && (
                <p className='p-4 text-center font-Manrope text-[1rem] font-normal leading-5'>{errorMessage}</p>
              )}
              {notificationListing?.pages?.map(page =>
                page?.data?.count === 0 ? (
                  <p key={page} className='p-4 text-center font-Manrope text-[1rem] font-normal leading-5'>
                    <NoDataFoundScreen message={NO_NOTIFICATION_MESSAGE} />
                  </p>
                ) : (
                  page?.data?.results?.map(notification => (
                    <div
                      key={notification.id}
                      className={`h-[6.25rem] w-[35.125rem] border-b px-8 py-5 ${!notification.isRead && 'bg-shade1'}`}
                    >
                      <div className='flex gap-3'>
                        <img
                          src={notification?.senderImage?.image}
                          alt='sender-avatar'
                          className='h-10 w-10 rounded-full'
                        />
                        <div className='flex flex-col gap-1 font-Manrope font-normal'>
                          <p className='text-sm'>{notification.body}</p>
                          <p className='text-xs text-bodyText'>{calculateDueDate(notification.created)}</p>
                        </div>
                      </div>
                    </div>
                  ))
                )
              )}
            </>
          )}
        </div>
      </Drawer>
    </>
  );
}
