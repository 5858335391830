import { Plus } from 'lucide-react';
import { Button } from '@common/components';
import { CREATE_DASHBOARD_BUTTON_ID } from '@constants';
import { EmptyDashboardIcon } from '@assets/svgs';

export function EmptyDashboard({ onClick }) {
  return (
    <div className='flex h-full flex-col items-center justify-center gap-10 py-[10.25rem]'>
      <EmptyDashboardIcon />
      <div className='flex flex-col items-center gap-10'>
        <div className='flex w-[25rem] flex-col items-center gap-3'>
          <h3 className='text-2xl font-semibold text-mainText'>Create Dashboard</h3>
          <p className='text-center text-sm leading-5 text-bodyText'>
            You don’t have any dashboard yet. Please create a new dashboard to start.
          </p>
        </div>
        <Button onClick={onClick} size='lg' className='w-[17.875rem]' id={CREATE_DASHBOARD_BUTTON_ID}>
          <Plus className='h-5 w-5' color='white' />
          Create Dashboard
        </Button>
      </div>
    </div>
  );
}
