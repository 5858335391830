import { Input, Select } from 'antd';
import { range } from 'lodash';
import { restrictedCharacterOnNumberField } from '@utils';
import { COLORS, ENTER } from '@constants';
import { PaginatedArrow } from '@assets/svgs';

/**
 * CustomPagination component for paginating through data with navigation controls and customizable page size.
 *
 * @param {Object} props - Component props.
 * @param {number} props.page - Current page number.
 * @param {Function} props.setPage - Function to update the current page number.
 * @param {Object} props.data - Data object containing the total count of items.
 * @param {number} [props.totalItems=10] - Number of items per page (default is 10).
 * @param {Function} props.setTotalItems - Function to update the number of items per page.
 * @param {Function} props.refetchListing - Function to refetch the data based on pagination changes.
 * @param {number} props.paginationPage - The current pagination input value (can differ from `page` in controlled components).
 * @param {Function} props.setPaginationPage - Function to update the current pagination input value.
 * @returns {JSX.Element} JSX Element for rendering the pagination controls.
 */

export function CustomPagination({
  page,
  setPage,
  data,
  totalItems,
  setTotalItems,
  paginationPage,
  setPaginationPage,
}) {
  const totalCount = data?.data?.count || 0;
  const totalPages = range(1, Math.ceil(totalCount / totalItems) + 1);
  const hasPages = totalPages.length > 0;

  // Calculate the starting and ending item numbers for the current page
  const startItem = (page - 1) * totalItems + 1;
  const endItem = Math.min(page * totalItems, totalCount);

  // Handler to navigate to the previous page
  const handlePrevPage = () => {
    if (page > 1) {
      setPage(page - 1);
      setPaginationPage(paginationPage - 1);
    }
  };

  // Handler to navigate to the next page
  const handleNextPage = () => {
    if (page < totalPages.length) {
      setPage(page + 1);
      setPaginationPage(paginationPage + 1);
    }
  };

  return (
    <div className={'flex w-full items-center justify-between border-t border-secondaryStrokeButton py-2 pl-4'}>
      <span className='flex items-center'>
        <span className='flex items-center border-secondaryStrokeButton font-Manrope text-xs leading-4 text-bodyText md:border-r'>
          Items <span className='md:hidden'>/</span>
          <span className='hidden md:block'>per</span> page:{' '}
          <Select
            defaultValue={totalItems}
            className='paginated-select h-6 w-[3.25rem] font-Manrope'
            onChange={setTotalItems}
            options={[
              { value: '12', label: 12 },
              { value: '24', label: 24 },
              { value: '36', label: 36 },
              { value: '44', label: 44 },
              { value: '56', label: 56 },
              { value: '96', label: 96 },
            ]}
          />
        </span>
        <span className='ml-4 hidden font-Manrope text-xs leading-4 text-secondaryTextButton md:block'>
          {startItem} – {endItem} of {totalCount} items
        </span>
      </span>
      <div className='h-6 border-l border-secondaryStrokeButton md:hidden'></div>
      <span className='flex items-center'>
        <span className='border-r border-secondaryStrokeButton pl-2 pr-4 font-Manrope text-xs leading-4 text-bodyText md:border-l'>
          <Input
            className='mr-2 inline-block h-6 w-7 max-w-11 rounded-[0.25rem] border border-secondaryStrokeButton p-1 text-center font-Manrope text-xs text-mainText hover:border-highlighterText1 focus:border-highlighterText1'
            value={paginationPage}
            type='number'
            onKeyDown={e => {
              restrictedCharacterOnNumberField(e);
              e.key === ENTER && setPage(Number(e.target.value));
            }}
            onChange={e => setPaginationPage(Number(e.target.value))}
          />
          of {totalPages.length} page{totalPages.length > 1 ? 's' : ''}
        </span>
        <span className='flex h-6 w-[5.1875rem] items-center'>
          <span
            className={`rotate-180 cursor-pointer px-3 py-1 hover:opacity-[.9] ${!hasPages || page === 1 ? 'disabled' : ''}`}
            onClick={handlePrevPage}
          >
            <PaginatedArrow
              fillColor={!hasPages || page === 1 ? COLORS.BUTTON_DISABLED_COLOR : COLORS.PRIMARY_GREEN_COLOR}
            />
          </span>
          <span
            className={`cursor-pointer border-l border-secondaryStrokeButton px-3 py-1 hover:opacity-[.9] ${!hasPages || page === totalPages.length ? 'disabled' : ''}`}
            onClick={handleNextPage}
          >
            <PaginatedArrow
              fillColor={
                !hasPages || page === totalPages.length ? COLORS.BUTTON_DISABLED_COLOR : COLORS.PRIMARY_GREEN_COLOR
              }
            />
          </span>
        </span>
      </span>
    </div>
  );
}
