import { Building2 } from 'lucide-react';
import { COLORS, DATA_STAT } from '@constants';
import campagins from '@assets/images/campagins.png';
import category from '@assets/images/category.png';
import click from '@assets/images/click-rate.png';
import financial from '@assets/images/financial-details.png';
import products from '@assets/images/products.png';

export function WhiteLabelPreview({
  dashboardHeader,
  logo,
  sponsorLogo,
  headerColor,
  headerTextColor,
  isTextureApplied,
  className,
}) {
  return (
    <div
      className={`relative z-0 w-full overflow-hidden rounded-xl ${className} ${isTextureApplied ? 'bg-[url("/src/assets/images/SlidesImgBg.png")]' : 'bg-backgroundColor'}`}
    >
      <div
        className='rounded-3 absolute left-0 top-0 z-[-1] h-full w-full opacity-[.4]'
        style={isTextureApplied ? { backgroundColor: headerColor } : {}}
      />
      <div
        style={isTextureApplied ? {} : { backgroundColor: headerColor }}
        className={`px-3 py-2.5 md:px-4 md:py-5 ${dashboardHeader}`}
      >
        <div className='flex items-center gap-2'>
          <div>
            {logo ? <img src={logo} alt='logo' className='h-6 w-6' /> : <Building2 color={COLORS.ICON_COLOR} />}
          </div>
          <h6 style={{ color: headerTextColor }} className='font-Manrope text-xs font-normal leading-6'>
            Dashboard
          </h6>
        </div>
      </div>
      <div className='flex flex-col gap-2.5 p-4'>
        <div className='flex w-full items-center rounded-md bg-white p-2'>
          <h6 className='font-Manrope text-[0.625rem] font-normal text-mainText'>SEO</h6>
        </div>
        <div className='flex gap-2.5'>
          {DATA_STAT.map((data, index) => (
            <div key={index} className=''>
              <img src={data.imgUrl} alt='stat-image' />
            </div>
          ))}
        </div>
        <div className='flex justify-between gap-2.5'>
          <img src={campagins} alt='graph1' className='w-[calc(50%-5px)]' />
          <img src={category} alt='map' className='w-[calc(50%-5px)]' />
        </div>
        <div className='flex justify-between gap-2.5'>
          <img src={click} alt='chart2' className='w-[calc(33.3333%-6.6666px)]' />
          <img src={financial} alt='chart3' className='w-[calc(33.3333%-6.6666px)]' />
          <img src={products} alt='chart1' className='w-[calc(33.3333%-6.6666px)]' />
        </div>
        {!!sponsorLogo && (
          <div className='mt-4 flex flex-col items-center justify-center gap-[0.3956rem]  md:mt-5 lg:mt-[0.8969rem] xxl:mt-[1.4056rem]'>
            <span className='text-[0.3419rem] font-normal leading-[0.4138rem] text-darkGray'>Powered by</span>
            <img
              src={sponsorLogo}
              alt='logo'
              className='h-2.5  w-[3.25rem] object-contain md:h-3.5 md:w-[4.5rem] lg:!h-[0.545rem] lg:w-[2.9444rem] xxl:!h-[0.8538rem] xxl:w-[4.6144rem]'
            />
          </div>
        )}
      </div>
    </div>
  );
}
