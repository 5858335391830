const SubscriptionIcon = ({ className }) => {
  return (
    <svg
      width='52'
      height='40'
      viewBox='0 0 52 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={className}
    >
      <path
        d='M44.5708 28.0909H7.43909C7.20092 27.0666 6.96317 26.0448 6.72586 25.0257C5.92419 21.5463 5.12208 18.0665 4.31954 14.5862C4.16032 13.9002 4.31177 13.3254 4.86319 12.8957C5.43662 12.4504 6.05406 12.4711 6.67538 12.8284C9.64996 14.531 12.6271 16.2305 15.6069 17.9271L16.1881 18.2559C16.3097 18.0798 16.4198 17.9258 16.5246 17.7679C19.217 13.7319 21.9085 9.69545 24.5992 5.65858C25.1299 4.86381 25.8936 4.57127 26.6263 4.94018C26.9214 5.10488 27.1781 5.33033 27.3796 5.60163C30.5475 9.65748 33.7085 13.7163 36.8625 17.7782C36.9713 17.918 37.0839 18.0552 37.225 18.2313C37.3803 18.1303 37.5253 18.0371 37.6677 17.9426C40.1573 16.2823 42.646 14.6216 45.1339 12.9604C45.5507 12.6808 45.9843 12.4892 46.4969 12.5941C47.3913 12.7766 47.9078 13.6063 47.6929 14.5461C47.0509 17.3506 46.4037 20.1552 45.7513 22.9598L44.5708 28.0909Z'
        fill='#FF9F0A'
      />
      <path
        d='M8.16211 31.2305H43.8493C43.5645 32.4382 43.3095 33.6213 42.9988 34.7901C42.8254 35.4464 42.2727 35.8257 41.5957 35.8684C41.4507 35.8761 41.3044 35.8684 41.1595 35.8684H10.861C9.60021 35.8684 9.15105 35.5189 8.86239 34.2931C8.63069 33.2912 8.40158 32.2841 8.16211 31.2305Z'
        fill='#FF9F0A'
      />
    </svg>
  );
};

export { SubscriptionIcon };
